import { toast } from 'react-toastify';
import { FaInfo } from 'react-icons/fa';
import styles from './toast.module.css';

export const toastInfo = (msg) => {
  toast(<InfoToast msg={msg} />);
};

const InfoToast = ({msg}) => {

  return (
    <>
      <FaInfo className={styles.info} />
      {" "}
      {msg}
    </>
  );
};