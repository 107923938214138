import { FaCircle, FaRegCircle } from "react-icons/fa";
import styles from './perks.module.css';

export const CarouselNavigator = ({numSlides, activeIndex, onSelect}) => {

  const render = [];
  for (let i = 0; i < numSlides; i++) {
    render.push(<NavigatorButton key={i} index={i} activeIndex={activeIndex} onSelect={onSelect} />);
  }

  return render;
};

const NavigatorButton = ({index, activeIndex, onSelect}) => {

  const Icon = index === activeIndex ? FaCircle : FaRegCircle;
  const testClass = index === activeIndex ? "active" : "inactive";

  return (
    <button 
        onClick={() => onSelect(index)} 
        data-testid={testClass}
        className={styles.navigatorButton}
    >
      <Icon className={styles.navigatorIcon} size={10} />
    </button>
  );
};