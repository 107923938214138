import { gql } from '@apollo/client';
import { PLAN_FIELDS } from 'enrollment/operations/fragments';

export const GET_PLANS_FOR_PAYER = gql`
  ${PLAN_FIELDS}

  query FindPlans($payerId: ID!) {
  findPlans(filter: {payer: {id: {eq: $payerId}}}) {
    items {
      ...PlanFields
    }
  }
}
`;