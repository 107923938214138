import { Container } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { SectionHeader, Section } from '../form';
import { EditAddressList, EditEmails, EditName, EditPhoneNumber, EditPhoneNumberList, EditSingleAddress} from './';

export const EditContact = ({ registerPrefix, account, options, errors }) => {
  const { control } = useFormContext();
  const registerField = registerPrefix ? `${registerPrefix}.contact` : 'contact';

  options = options ?? {
    addresses: { show: 3 },
    emails: { hide: false },
    phoneNumbers: { show: -1 }
  };

  return (
    <Container>
      <Controller
        control={control}
        name='controller'
        render = {({ ref}) => (
          <EditName inputRef={ref} registerPrefix={registerField} errors={errors} />
        )}
      />
      <hr />
      { options.addresses?.show === 1
      ? 
        <>
          <SectionHeader><h4>Address</h4></SectionHeader>
          <Section>
            <Controller
              control={control}
              name='controller'
              render={({ ref }) => (
                <EditSingleAddress
                  inputRef={ref}
                  registerPrefix={`${registerField}.addresses.0`}
                  allowCancelOnNew={false}
                  showDelete={false}
                  autoFocus={false}
                  errors={errors}
                />
              )}
            />
          </Section>
        </>
      :
        <Controller 
          control={control}
          name='controller'
          defaultValue={[]}
          render = {({ ref }) => (
            <EditAddressList inputRef={ref} registerPrefix={registerField}/>
          )}
        />
      }
      <hr />
      { options.emails?.hide !== true && 
        <>
          <Controller
            control={control}
            name='controller'
            render = {({ ref}) => (
              <EditEmails account={account} inputRef={ref} registerPrefix={registerField} options={options?.email} />
            )}
          />
          <hr />
        </>
      }
      { options.phoneNumbers?.show === 1
        ?
          <>
          <SectionHeader><h4>Phone Number</h4></SectionHeader>
          <Section>
            <Controller
              control={control}
              name='controller'
              render = {({ ref}) => (
              <EditPhoneNumber
                  inputRef={ref} 
                  registerPrefix={`${registerField}.phoneNumbers`} 
                  errors={errors}
                  showButtons={false}
                  index={0}
                  editIndex={0}
                />
              )}
            />
          </Section>
          </>
        : 
          <Controller
            control={control}
            name='controller'
            render = {({ ref}) => (
            <EditPhoneNumberList 
                inputRef={ref} 
                registerPrefix={registerField} 
                options={options?.phoneNumbers} 
                errors={errors}
              />
            )}
          />
      }
      <hr />
    </Container>
  );
};