import { Col, Row } from 'react-bootstrap';
import styles from './edit.module.css';

export const EditHeader = ({title}) => {

  return (
    <Row>
      <Col className={styles.header}>
        <h3>{title}</h3>
      </Col>
    </Row>
  );
};