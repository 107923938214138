import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { EditAddressInList } from './editAddressInList';
import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { AddButton } from '../buttons';
import styles from './contact.module.css';

export const EditAddressList = ({registerPrefix}) => {
  const { control, getValues } = useFormContext();
  const registerField = registerPrefix ? `${registerPrefix}.addresses` : 'addresses';
  const { fields, append, remove } = useFieldArray({name: registerField});
  const [numberOfAddresses, setNumberOfAddresses] = useState(0);
  const [addressTypes, setAddressTypes] = useState({});

  function addAddress() {
    let newAddress = {line1: '', line2: '', type: []};

    if (numberOfAddresses === 0) {
      newAddress.type = ['MAILING', 'BILLING', 'PHYSICAL']
    }

    append(newAddress);
    setAddressTypes(prevState => ({...prevState, [numberOfAddresses]: newAddress.type}))
    setNumberOfAddresses(numberOfAddresses + 1);
  };

  function refreshAddressTypes() {
    var newAddressTypes = {};
    Object.entries(addressTypes).forEach(entry => {
      const [key, value] = entry;
      newAddressTypes[key] = value;
    });

    setAddressTypes(newAddressTypes);
  };

  function removeAddress(index) {

    var newAddressTypes = {};
    Object.entries(addressTypes).forEach(entry => {
      const [key, value] = entry;
      let currIndex = key;
      if (key !== index.toString()){
        if (parseInt(key) > index) {
          currIndex -= 1;
        }
        newAddressTypes[currIndex] = value;
      }
    });

    setAddressTypes(newAddressTypes);
    remove(index);
    setNumberOfAddresses(numberOfAddresses - 1);
  };

  useEffect(() => {
    var currentAddresses = getValues(registerField);
    setNumberOfAddresses(currentAddresses.length);

    var initialAddressTypes = {};
    currentAddresses.forEach((_value, indexIterator) => {
      initialAddressTypes[indexIterator] = _value.type;
    });

    setAddressTypes(initialAddressTypes);
  }, [getValues, registerField]);
  
  return (
    <>
    <Container>
      <Row className={styles.headerRow}>
        <Col lg={5}>
          <h4>Address</h4>
        </Col>
        <Col lg={2}></Col>
        <Col lg={1}>
          <h5>Mailing</h5>
        </Col>
        <Col lg={1}>
          <h5>Billing</h5>
        </Col>
        <Col lg={1}>
          <h5>Physical</h5>
        </Col>
      </Row>
      {fields.map((field, index) => (
        <Controller
          control={control}
          key={field.id} 
          name={field.id} 
          render = {(props) => {
            return(
              <Row>
                <EditAddressInList  
                  inputRef={props.field.ref} 
                  index={index} 
                  registerPrefix={registerPrefix} 
                  removeAddress={removeAddress}
                  showDelete={numberOfAddresses > 1}
                  addressTypes={addressTypes}
                  refreshAddressTypes={refreshAddressTypes} 
                />
              </Row>
            );
          }}
        />
      ))}
    </Container>
    { 
      numberOfAddresses < 3
        ?  <AddButton testid='addressAddButton' onClick={(e) => { addAddress(); }} tooltip="Add address" />
        : null
    }
    <br />
    </> 
  );
};