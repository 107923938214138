import { gql } from '@apollo/client';
import { CARECENTER_FIELDS } from '../fragments';

export const CREATE_CARECENTER = gql`
  ${CARECENTER_FIELDS}
  mutation CreateCareCenter($input: CreateCareCenterInput!) {
    createCareCenter(input: $input) {
      ...CareCenterFields
    }
  }
`;