import { Col, Row } from 'react-bootstrap';
import { Help } from './help';
import styles from './help.module.css';

export const ConciergeHelp = ({ title, copy, children }) => {

  return (
    <Row className="justify-content-center">
      <Col lg={6} className={styles.helpcomponent}>
        <Help title={title} copy={copy} children={children} />
      </Col>
    </Row>
  );
};