import { Col, Row } from 'react-bootstrap';
import { useFormContext, Controller } from "react-hook-form";
import { Input } from 'shared/components/form';

export const IndemnityEnrollmentForm = ({index, registerPrefix, errors}) => {
  const {control} = useFormContext();
  const registerField = `${registerPrefix}.${index}.configuration`;

  return (
    <>
      <Row>
        <Col lg={8}>
          <Controller
            name="controller"
            control={control}
            render={() => (
              <Input 
                type="number"
                autoFocus={true}
                label="Household Income"
                registerInput={`${registerField}.householdIncome`}
                errors={errors}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Controller
            name="controller"
            control={control}
            render={() => (
              <Input 
                type="number"
                autoFocus={true}
                label="Household Size"
                registerInput={`${registerField}.householdSize`}
                errors={errors}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};