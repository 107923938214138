import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DisplayAddress } from 'shared/components/contact';
import { careCenterPaths } from 'careCenter/routes';
import { getFormattedDistanceInMiles } from 'careCenter/services/careCenterDistanceService'
import styles from 'benefits/indemnity/indemnity.module.css';

export const FindHospitalCard = ({ careCenterResult, OptionalComponent}) => {
  const careCenter = careCenterResult.careCenter;
  const coverImageUrl = careCenter.getCoverImageUrl('xs');
  const link = `${careCenterPaths.hospitalDetail}/${careCenter.id}/${careCenterResult?.score}`;
  

  return (
    <Container className={styles.hospitalcard}>
      <Row className={styles.hospitalcardimagecol}>
        <Col className={styles.hospitalcardimagecol} lg="auto">
          <Link to={link}>
            <img src={coverImageUrl} height={200} width={320} alt="Cover" className={styles.hospitalcardimage} />
          </Link>
        </Col>
        <Col className={`${styles.hospitalcarddetails}`}>
          <Row>
            <Col className={styles.name}>
              <Link to={link} className={styles.namelink}>{careCenter.name}</Link>
            </Col>
          </Row>
          <Row>
            <Col className={styles.address}>
              <DisplayAddress address={careCenter.address} lines={2} />
            </Col>
          </Row>
          <Row>
            <Col data-testid="distance" className={styles.distance}>
              {getFormattedDistanceInMiles(careCenterResult.distance)}
            </Col>
          </Row>
        </Col>
        { OptionalComponent && 
        <>
          <Col md={1} className={`d-none d-xs-none d-sm-none d-md-block ${styles.largedivider} my-start text-end`}></Col>
          <Col md={1} className={`d-xl-none d-lg-none d-md-none d-sm-block d-xs-block ${styles.smalldivider} my-start text-end`}></Col>
          <Col className={`justify-content-center my-auto ${styles.score}`} lg={3} md={3}>
            <OptionalComponent careCenterResult={careCenterResult} />
          </Col>
        </>
        }
      </Row>
    </Container>
  );
};