import { FaBan } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const CancelButton = ({...rest}) => {

  return (
    <IconButton 
      IconComponent={FaBan}
      iconClass={styles.cancel}
      {...rest} />
  );
};