import { gql } from '@apollo/client';

export const GET_DRUG_DETAILS = gql`
query query($name: String!) {
  rx {
    getDetails(name: $name) {
      name
      form
      dosages {
        dosage
        quantities
      }
    }
  }
}
`;
