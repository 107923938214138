import { gql } from '@apollo/client';
import { NETWORK_FIELDS } from '../fragments';

export const GET_NETWORK = gql`
  ${NETWORK_FIELDS}
  
  query Query($id: ID!) {
  getNetwork(id: $id) {
    ...NetworkFields
  }
}
`;