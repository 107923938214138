import { Col, Container, Row } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import { PrivacyPolicy } from '../components/policy/privacy';
import { TermsAndConditions } from '../components/policy/tandc';
import { policyPaths } from '../routes';
import styles from './policy.module.css';

export const Policies = () => {
  return (
    <Switch>
      <Route path={policyPaths.tandc}>
        <Policy title="Terms and Conditions">
          <TermsAndConditions />
        </Policy>
      </Route>
      <Route path={policyPaths.privacy}>
        <Policy title="Privacy Policy">
          <PrivacyPolicy />
        </Policy>
      </Route>
    </Switch>
  );
};

const Policy = ({title, children}) => {

  return (
    <Container>
      <Row>
        <Col className={styles.policyHeader}>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row><Col className={styles.policySpacer}>{" "}</Col></Row>
      <Row>
        <Col className={styles.policy}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};