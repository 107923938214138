import { gql } from '@apollo/client';

export const GET_PLAN_PRODUCTS = gql`
  query FindPlanProducts {
  findPlanProducts {
    items {
      id
      title
      intro
      benefits {
        benefit
        sequence
      }
    }
  }
}
`;