import { Col, Row } from 'react-bootstrap';
import { DropdownList } from 'react-widgets/cjs';
import styles from 'case/case.module.css';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CASE } from 'case/operations/mutations/updateCase';
import { DateTime } from 'luxon';
import { createMutableCopy } from "apollo/utils";
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';

const Statuses = [
  'NEW',
  'INPROGRESS',
  'RESOLVED'
];

export const Title = ({ caseData, filter, dispatch }) => {
  const { consumer } = useCurrentConsumer();
  const [updateCase] = useMutation(UPDATE_CASE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => dispatch({type: 'case', caseData: data.updateCase})
   });

  const onStatusUpdate = (updatedStatus) => {
    updateCase({
      variables: { 
        input: {
          id: caseData.id,
          status: updatedStatus, 
          audit: [...createMutableCopy(caseData.audit ?? []), {
            fieldName: 'status',
            oldValue: caseData.status,
            newValue: updatedStatus,
            date: DateTime.now().toMillis(),
            changedBy: `${consumer.contact.name.first} ${consumer.contact.name.last}`
          }]
        }
      }
    });
  }

  useEffect(() => {
    // inputProps should be how we do this, however does not work with DropdownList due to bug
    // https://github.com/jquense/react-widgets/pull/1059
    document.querySelector('.rw-dropdownlist-search').readOnly = true;
  }, []);

  return (
    <Row>
      <Col md={3} style={{padding: '0'}}>
        <DropdownList
          id='case-status-dropdown'
          data={Statuses}
          defaultValue={caseData.status}
          onSelect={(value) => onStatusUpdate(value)}
          renderListItem={({item}) => <StatusDropdownRender status={item} label='option' /> }
          renderValue={({item}) => <StatusDropdownRender status={item} label='selected' /> }
        />
      </Col>
      <Col md={9} className='d-flex justify-content-end'>
        {filter}
      </Col>
    </Row>
  );
};

const StatusDropdownRender = ({status, label}) => {
  let color;

  switch (status) {
    case 'NEW':
      color = styles.red;
      break;
    case 'INPROGRESS':
      color = styles.orange;
      break;
    case 'RESOLVED':
    default:
      color = styles.green;
      break;
  }

  return (
    <span className={styles.titleContainer}>
      <span className={`${styles.dot} ${color}`} />
      <span aria-label={label} className={styles.statusText}>{status}</span>
    </span>
  )
};