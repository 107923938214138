import { useCallback } from 'react';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { GET_CONSUMER_FOR_ACCOUNT } from 'consumer/operations/queries/getConsumerForAccount';
import { useConsumerBase } from "./useConsumerBase";

export const useCurrentConsumer = () => {
  const accountId = useCurrentAccount().id;
  const variables = { accountId: accountId};

  const getData = useCallback(data => {
    return data?.findConsumers?.items?.length > 0 
      ? data.findConsumers.items[0]
      : undefined;
  }, []);

  return useConsumerBase(
    GET_CONSUMER_FOR_ACCOUNT, 
    variables,
    getData
  );
};