import { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FloatingLabelControl } from 'shared/components/form';
import ReactToolTip from 'react-tooltip';
import { currentAccountVar } from 'apollo/cache';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { permissions } from 'auth/permissions';
import { CancelButton } from 'shared/components/buttons/cancelButton';
import { useLocalStorage, localStorageKeys } from 'shared/hooks/useLocalStorage';
import { AdminTenantSelector } from './adminTenantSelector';
import styles from './tenantChanger.module.css';

export const TenantChanger = () => {
  const history = useHistory();
  const [, updateTenant] = useLocalStorage(localStorageKeys.tenant);
  const [showChangeTenant, setShowChangeTenant] = useState(false);
  const account = useCurrentAccount();
  const canSee = account.hasPermission(permissions.admin.super);

  const onTenantChanged = (newTenant) => {
    account.tenant = newTenant;
    currentAccountVar(account);
    updateTenant(newTenant);
    history.go(0);
  };

  return (
    <>
    {canSee && 
      <Col className={styles.changer}>
        { showChangeTenant 
        ? <Row>
            <Col className={styles.dropdown}>
              <AdminTenantSelector account={account} onSelected={(tenant) => onTenantChanged(tenant)} DropDownComponent={TenantDropDown} />
            </Col>
            <Col sm={1} className={`my-auto ${styles.cancel}`}>
              <CancelButton size={20} tooltip="Cancel" onClick={() => setShowChangeTenant(false)} />
            </Col>
          </Row>
        : <>
          <Button 
            className={styles.current} 
            variant="link" 
            onClick={() => setShowChangeTenant(true)}
            data-tip="Current Tenant, click to change"
          >
            {account.tenant.name}
          </Button>
            <ReactToolTip />
          </>
        }
      </Col>
    }
    </>
  );
};

const TenantDropDown = ({ tenants, onSelected }) => {
  const current = useCurrentAccount().tenant.id;

  const handleSelected = (e) => {
    const id = e.target.value;
    const tenant = tenants.find(x => x.value === id);
    onSelected({id: id, name: tenant.text});
  };

  if (tenants) {
    return (
      <FloatingLabelControl label="Current Tenant">
        <Form.Control
            defaultValue={current}
            className="form-select"
            as="select"
            type="select"
            size="sm" 
            onChange={(e) => handleSelected(e)}
        >
          {tenants.map((tenant) => (
            <option key={tenant.value} value={tenant.value}>{tenant.text}</option>
          ))}
        </Form.Control>
      </FloatingLabelControl>
    );
  } else {
    return <></>
  }
};