import { gql } from '@apollo/client';
import { MESSAGE_FIELDS } from '../fragments';

export const CREATE_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...MessageFields
      createdBy
    }
  }
`;