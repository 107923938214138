import { useQuery } from "@apollo/client";
import { ItemList } from 'shared/components/itemList';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { sortPayersByNameThenTenantName } from "shared/services/comparisonService";
import { permissions } from "auth/permissions";
import { SectionHeader } from 'shared/components/form';
import { adminPaths } from 'admin/routes';
import { Link } from "react-router-dom";
import { AddButton } from "shared/components/buttons/addButton";
import { GET_ALL_PAYERS } from "payers/operations/queries";
import { Col, Row } from "react-bootstrap";

export const PayersList = () => {
  let baseFilter = { };
  const account = useCurrentAccount();
  const filterPermission = account.getMostPermissive(permissions.admin.tenant);
  if (filterPermission.context === 'tenant') {
    baseFilter = {
      tenant: { id: { in: account.allowedTenants.map(x => x.id)}}
    };
  }

  const { loading, data } = useQuery(GET_ALL_PAYERS, {
    variables: { filter: baseFilter },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <>
      <SectionHeader>
        <h2>Payers</h2>
      </SectionHeader>
      <Row className="justify-content-end">
        <Col lg={1} md={1} sm={1} xs={1}>
          <Link to={`${adminPaths.payers}/new`}>
            <AddButton tooltip='Add new Payer' />
          </Link>
        </Col>
      </Row>
      { loading && <div>Loading...</div>}
      { !data || data.findPayers.items.length === 0 
        ? <div>No results found</div>
        : <ItemList data={[...data.findPayers.items].sort(sortPayersByNameThenTenantName)} 
                    detailLink={adminPaths.payers}
                    headerRowElement={PayerHeaderRow}
                    dataRowElement={PayerDataRow}
          />
      }
    </>
  );
};

const PayerHeaderRow = () => {
  return (
    <Row style={{marginTop: '10px'}}>
      <Col style={{marginLeft: '10px'}}>Name</Col>
      <Col>Tenant</Col>
    </Row>
  );
}

const PayerDataRow = ({item, index}) => {
  return (
    <Link to={`${adminPaths.payers}/${item.id}`} style={{color: "inherit", textDecoration: "none"}} key={index}>
      <Row>
        <Col aria-label={`payer.${index}.name`} style={{marginLeft: '10px'}}>{item.name}</Col>
        <Col aria-label={`payer.${index}.tenant`}>{item.tenant.name}</Col>
      </Row>
      <hr />
    </Link>
  );
};
