import { Button, Col, Container, Row } from 'react-bootstrap';
import { useState } from 'react';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { FaRegHospital, FaRegCalendarAlt, FaCommentAlt, FaFlag, FaClipboardCheck, FaPhoneSquareAlt, FaStethoscope, FaNotesMedical } from "react-icons/fa";
import { Disclaimer } from 'benefits/telehealth/components/requestAppointment/disclaimer';
import { DisplayPharmacyAddress } from 'integrations/lyric/components/pharmacySelector/displayPharmacyAddress';
import { normalizePhoneNumber } from 'shared/components/contact/phoneNumberDropdown';
import buttonStyles from 'shared/components/buttons/button.module.css';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { GET_LYRIC_REASONS } from 'integrations/lyric/operations/queries/getReasons'
import { GET_LYRIC_STATES } from 'integrations/lyric/operations/queries/getStates';
import { telehealthPaths } from '../../routes';

export const ViewAppointmentRequest = ({appointmentRequest, setShowModal}) => {
  const [reasons, setReasons] = useState([]);
  const [states, setStates] = useState([]);
  const header = appointmentRequest?.error ? 'Request Failed' : 'Request Sent';
  const consult = appointmentRequest?.consult ?? {};
  useQueryWrapper(GET_LYRIC_REASONS, {
    onCompleted: (data) => setReasons(data.findLyricReasons.items.map(reason => ({
      name: reason.name.replace(/"/g, ''),
      id: reason.problemId
    })))
  });
  
  useQueryWrapper(GET_LYRIC_STATES, {
    onCompleted: (data) =>  {
      setStates(data.findLyricStates.items);
    }
  });

  const scheduledForNow = consult.whenScheduled === 'now';
  const chiefComplaint = reasons.find(x => x.id === consult?.problems?.chiefComplaint);
  const otherProblems = reasons.filter(x => consult?.problems?.otherProblems?.includes(x.id));
  const state = states.find(x => x.abbreviation.toString() === consult?.state);
  const modality = consult?.modalities?.length > 0 && consult?.modalities[0] === 'telephone' 
    ? 'Phone appointment' 
    : 'Video appointment';

  const showJoinButton = consult.whenScheduled === 'now' &&
    consult.modalities?.some(x => x === 'video');

  let disclaimerBody = "Your appointment window is confirmed. For details about your upcoming appointment, check your Member Dashboard.";
  if (scheduledForNow) {
    disclaimerBody = consult?.modalities?.length > 0 && consult?.modalities[0] === 'telephone' 
    ? "Your appointment window is confirmed. Please keep your phone nearby and be ready to receive a call."
    : "Your appointment window is confirmed. Please keep your device nearby and be ready for a notification to tell you your doctor is ready to meet.";
  }

  return (
    <Container className={styles.requestSentModalBody}>
    { setShowModal && 
      <Row className="justify-content-end">
        <Col xs={10}>
          <h3>{header}</h3>
        </Col>
        <Col className="text-end">
            <AiOutlineClose size={25} className={styles.closeModalIcon} onClick={() => setShowModal(false)} />
        </Col>
      </Row>
    }
    { appointmentRequest?.error && 
      <Row className="justify-content-center">
        <Col xs={10}>
          <span>{appointmentRequest.error}</span>
        </Col>
      </Row>
    }
    { !appointmentRequest?.error &&
      <>
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaRegCalendarAlt />
          </Col>
          <Col>
            {scheduledForNow ? 'Consultation within 2hrs' : consult.whenScheduled}
          </Col>
        </Row>
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaFlag />
          </Col>
          <Col>
            {state?.name}
          </Col>
        </Row>
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaClipboardCheck />
          </Col>
          <Col>
            {modality}
          </Col>
        </Row>
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaPhoneSquareAlt />
          </Col>
          <Col>
            {consult.phoneNumber ? normalizePhoneNumber(consult.phoneNumber) : consult.phoneNumber}
          </Col>
        </Row>
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaStethoscope />
          </Col>
          <Col>
            {chiefComplaint?.name}
          </Col>
        </Row>
        { otherProblems.length > 0 &&
          <Row className={styles.requestSentModalRow}>
            <Col xs={1}>
              <FaNotesMedical />
            </Col>
            <Col>
              {otherProblems.map(x => x.name).join(', ')}
            </Col>
          </Row>
        }
        { appointmentRequest.additionalNotes &&
          <Row className={styles.requestSentModalRow}>
            <Col xs={1}>
              <FaCommentAlt />
            </Col>
            <Col>
              <Row className={styles.inheritPadding}>
                Additional notes
              </Row>
              <Row className={styles.additionalNotes}>
                {appointmentRequest.additionalNotes}
              </Row>
            </Col>
          </Row>
        }
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaRegHospital />
          </Col>
          <Col>
          { appointmentRequest?.pharmacy &&
            <DisplayPharmacyAddress 
              pharmacy={appointmentRequest.pharmacy} 
              className={styles.requestSentPharmacy} 
              headerClassName={styles.requestSentPharmacyHeader}
            />
          }
          </Col>
        </Row>
        <Row className={styles.requestSentModalRow}>
          <div className={styles.viewAppointmentDisclaimer}>
            <Disclaimer body={disclaimerBody} />
          </div>
        </Row>

        { showJoinButton &&
          <>
          <hr />
          <Row className={styles.requestSentModalRow}>
            <Col xs={12} className="d-flex justify-content-center">
              <Button 
                className={buttonStyles.buttonPrimary}
                type="button"
                href={telehealthPaths.lyricRedirect}
                target="_blank"
              >
                Join Video Consult
              </Button>
            </Col>
          </Row>
          </>
        }
        </>
      }
    </Container>
  )
}