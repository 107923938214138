import { Col, Container, Row } from 'react-bootstrap';
import { DisplayAddress } from "shared/components/contact";
import styles from 'benefits/dpc/dpc.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { FaRegHospital, FaRegClock, FaRegCalendarAlt, FaBriefcaseMedical, FaCommentAlt } from "react-icons/fa";

export const ViewAppointmentRequest = ({appointmentRequest, careCenterResult, setShowModal, adminMode=false}) => {
  return (
    <Container className={styles.requestSentModalBody}>
    { setShowModal && 
      <Row className="justify-content-end">
        <Col xs={10}>
          <h3>Request Sent</h3>
        </Col>
        <Col className="text-end">
            <AiOutlineClose size={25} className={styles.closeModalIcon} onClick={() => setShowModal(false)} />
        </Col>
      </Row>
    }
      <Row className={styles.requestSentModalRow}>
        <Col>
          <Row>
            <Col xs={1}>
              <FaRegHospital />
            </Col>
            <Col>
              <Row className={styles.inheritPadding}>
                {careCenterResult.careCenter.name}
              </Row>
              <Row className={`${styles.inheritPadding} ${styles.careCenterAddress}`}>
                <DisplayAddress address={careCenterResult.careCenter.address} lines={2} />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.requestSentModalRow}>
        <Col xs={1}>
          <FaRegCalendarAlt />
        </Col>
        <Col>
          {appointmentRequest.preferredDates}
        </Col>
      </Row>
      <Row className={styles.requestSentModalRow}>
        <Col xs={1}>
          <FaRegClock />
        </Col>
        <Col>
          {appointmentRequest.preferredTime}
        </Col>
      </Row>
      <Row className={styles.requestSentModalRow}>
        <Col xs={1}>
          <FaBriefcaseMedical />
        </Col>
        <Col>
          {appointmentRequest.appointmentType}
        </Col>
      </Row>
      { appointmentRequest.additionalNotes &&
        <Row className={styles.requestSentModalRow}>
          <Col xs={1}>
            <FaCommentAlt />
          </Col>
          <Col>
            <Row className={styles.inheritPadding}>
              Additional notes
            </Row>
            <Row className={styles.additionalNotes}>
              {appointmentRequest.additionalNotes}
            </Row>
          </Col>
        </Row>
      }
      { !adminMode &&
        <>
          <hr />
          <Row>
            <h6>Thank you for your request. <br/> Someone will contact you soon!</h6>
          </Row>
        </>
      }
    </Container>
  )
}