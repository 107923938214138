import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import styles from './toast.module.css';

export const toastSuccess = (msg) => {
  toast(<SuccessToast msg={msg} />);
};

const SuccessToast = ({msg}) => {

  return (
    <>
      <FaCheck className={styles.success} />
      {" "}
      {msg}
    </>
  );
};