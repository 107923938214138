import React from 'react';
import GoogleMapReact from 'google-map-react';
import { appSettings } from '../../../appSettings';
import { InputWithoutLabel } from "../form/inputWithoutLabel";

/* all potential values places api options
* offset - The position in the input term of the last character that the service uses to match predictions.
* location -The point around which you wish to retrieve place information. It must be specified as latitude, longitude.
* radius - The distance (in meters) within which to return place results.
* language - The language in which to return results.
* types - The types of place results to return.
*/
const defaultPlacesApiOptions = {types: ['address']};

export const AddressLookup = ({
  id, 
  callback, 
  style, 
  inputRef, 
  placeApiOptions=defaultPlacesApiOptions,
  ...otherProps
}) => {

  function handleApiLoaded(googleMaps) {
    var searchElement = document.getElementById('addressLookupSearch'+id);
    var autocomplete = new googleMaps.maps.places.Autocomplete(searchElement, placeApiOptions);
    googleMaps.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      callback(place);
    });
  };

  /* without giving a width / height to the map, it won't render to the dom */
  return (
    <>
      <div data-testid='addressLookup' style={{height: "0px", width: "0px"}}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: appSettings.GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
            yesIWantToUseGoogleMapApiInternals={true}
            defaultCenter={[0,0]}
            defaultZoom={0}
            onGoogleApiLoaded={(googleMaps) => handleApiLoaded(googleMaps)}
        />
      </div>
      <InputWithoutLabel 
        onKeyDown={(e) => {if (e.key === 'Enter') e.preventDefault();}} 
        inputRef={inputRef} 
        id={'addressLookupSearch'+id} 
        type="text" 
        inputStyle={style} 
        {...otherProps}
      />
    </>
  );
};