import { ContainerClient } from '@azure/storage-blob';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MEDIAUPLOAD_URL } from 'shared/operations/queries/getMediaUploadUrl';
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { toastError, toastSuccess } from 'shared/components/toasts';
import styles from 'shared/components/shared.module.css';

export const DragAndDropUpload = ({containerUrl, headerText, uploadRoot, onFilesUploaded, acceptedMediaTypes}) => {
  acceptedMediaTypes = acceptedMediaTypes ?? 'image/jpeg, image/png';
  onFilesUploaded = onFilesUploaded ?? function() {};
  const [currentProgress, setProgress] = useState(0);
  const [progressStyle, setProgressStyle] = useState({display: 'none'});
  let containerClient = new ContainerClient(containerUrl);;
  uploadRoot = uploadRoot ?? '';

  const onDropAccepted = async (acceptedFiles) => {
    setProgress(0);
    setProgressStyle({display: 'block'});
    let uploadedSize = 0;
    let totalSize = acceptedFiles
      .map(x => x.size)
      .reduce((a,b) => a + b);

    const updateProgress = (e) => {
      const fudgeFactor = 0.6; // oh ms...uploadSize is sometimes  1.5 - 2 * total size
      uploadedSize += e.loadedBytes;
      let progress = Math.round(100 * ((uploadedSize / totalSize)* fudgeFactor));
      progress = progress > 100 ? 100 : progress; 
      setProgress(progress);
    };

    let filesUploaded = [];
    const tasks = acceptedFiles.map(async(file) => {
      const extension = file.name.split('.')[1];
      const fileName = v4().toString();
      const newFile = {
        fileName: fileName,
        extension: extension,
        size: file.size,
        title: file.name,
        isVideo: false  //TODO
      };
      filesUploaded.push(newFile);
      const uploadPath = `${uploadRoot}/${fileName}.${extension}`;
      const blobClient = containerClient.getBlockBlobClient(uploadPath);

      const uploadOptions = { 
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: updateProgress
       };
      await blobClient.uploadData(file, uploadOptions);

      toastSuccess(`${file.name} uploaded!`);
    });

    await Promise.all(tasks);
    setProgressStyle({display: 'none'});
    onFilesUploaded({filesUploaded});
  };

  const onDropRejected = (rejectedFiles) => {
    let msg;
    if (rejectedFiles.length === 1) {
      msg = `The file ${rejectedFiles[0].file.name} was not an acceptable format` ;
    } else {
      msg = (
        <>
          The following files were not acceptable formats:
          <ul>
            {rejectedFiles.map(file => (
              <li key={file.file.name}>{file.file.name}</li>
            ))}
          </ul>
        </>
      );
    }

    toastError(msg);
  };

  const {getRootProps, getInputProps} = useDropzone({
    // when video uploads are enabled...
    // accept: 'image/jpeg, image/png, video/3gp, video/x-msvideo, video/x-flv, video/mp4, video/x-ms-wmv, video/quicktime',
    accept: acceptedMediaTypes,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected
  });

  return (
    <>
    <h5 style={{paddingLeft: '12px'}}>{headerText}</h5>
    <div {...getRootProps({className: styles.dropzone})}>
      <input {...getInputProps()} />
      <FaCloudUploadAlt size={30} />
      <p>Drag and drop files here, or click to choose files to upload</p>
    </div>
    <div style={progressStyle}>
      <ProgressBar animated now={currentProgress} label={`${currentProgress}%`} />
    </div>
    </>
  )
};

export const MediaDragAndDropUpload = ({headerText, uploadRoot, onFilesUploaded, acceptedMediaTypes}) => {
  const {data, loading} = useQuery(GET_MEDIAUPLOAD_URL);
  let uploadUrl = data?.config?.mediaUploadUrl;

  return (
    <>
    { loading 
      ? <></>
      : <DragAndDropUpload 
          acceptedMediaTypes={acceptedMediaTypes}
          containerUrl={uploadUrl}
          headerText={headerText}
          uploadRoot={uploadRoot}
          onFilesUploaded={onFilesUploaded}
        />
    }
    </>
  );
};