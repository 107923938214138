import { gql } from '@apollo/client';

export const GET_CONSUMERS_FOR_ACCOUNT_IDS = gql`
  query Query($accountIds: [ID!]) {
  findConsumers(filter: {account: {id: {in: $accountIds}}}) {
    items {
      id
      contact {
        name {
          prefix
          first
          middle
          last
          suffix
        }
        emails {
          email
          sendNotifications
        }
      }
      account {
        id
      }
    }
  }
}
`;