import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';
import { Input } from 'shared/components/form/input';

export const EditVisits = ({registerPrefix}) => {
  const { getValues, setValue } = useFormContext();
  const isUnlimited = getValues(`${registerPrefix}.unlimited`) || false;
  const [countEnabled, toggleCount] = useState(!isUnlimited);

  const onUnlimitedChanged = (event) => {
    setValue(`${registerPrefix}.unlimited`, event.target.checked);
    toggleCount(!event.target.checked);
    if (event.target.checked) setValue(`${registerPrefix}.count`, undefined);
  };

  return(
    <>
      <Col sm={3}>
        <Input 
          type="number"
          label="Count"
          size="sm"
          registerInput={`${registerPrefix}.count`}
          isReadOnly={!countEnabled}
        />
      </Col>
      <Col className="my-auto">
        <Form.Check type="checkbox">
          <Form.Check.Input checked={isUnlimited} onChange={onUnlimitedChanged} type="checkbox" />
          <Form.Check.Label>&nbsp;Unlimited</Form.Check.Label>
        </Form.Check>
      </Col>
    </>
  );
};