import { Row, Col } from 'react-bootstrap';
import logo from 'shared/images/bicc_logo_white.svg';
import styles from './header.module.css';


export const RegistrationHeader = () => {

  return (
    <>
    <Row className={`${styles.bg} $ justify-content-center`}>
      <Col>
      <Row className="text-center">
        <Col className={styles.imgrow}>
          <img src={logo} width="250px" height="36px" alt="Best In Class Care" />
        </Col>
      </Row>
      <Row className="text-center">
        <Col className={styles.row}>
          <h1 className={styles.inverse}>
            Welcome to <br /><br /><br />
            Best In Class Care!
          </h1>
          <br />
          <br />
          <br />
          <h3 className={styles.inverse}>
            Affordable and simple. Finally!
          </h3>
        </Col>
      </Row>
      </Col>
    </Row>
    <Row className="text-center">
      <Col>Please add or verify a few pieces of information and you’ll be ready to get started!<br /></Col>
    </Row>
    </>
  );
};
