import { Col } from 'react-bootstrap';
import { useFormContext } from "react-hook-form";
import { useState, useEffect } from "react";
import { EditSingleAddress } from './editSingleAddress';
import styles from './contact.module.css';

export const EditAddressInList = ({index, registerPrefix, removeAddress, showDelete, addressTypes, refreshAddressTypes}) => {
  const { getValues, setValue } = useFormContext();
  const [mailing, setMailing] = useState(false);
  const [billing, setBilling] = useState(false);
  const [physical, setPhysical] = useState(false);
  const registerField = registerPrefix ? `${registerPrefix}.addresses` : 'addresses';

  const onCancel = () => {
    const line1 = getValues(`${registerField}.${index}.line1`);
    if (!line1 || line1 === '') remove();
  };

  function remove() {
    let backfillIndex = index === 0 ? 1 : 0;

    if (mailing) {
      setRadioOption(backfillIndex, 'MAILING');
    }

    if (billing) {
      setRadioOption(backfillIndex, 'BILLING');
    }

    if (physical) {
      setRadioOption(backfillIndex, 'PHYSICAL');
    }

    removeAddress(index);
  };

  const setRadioOption = (targetIndex, option) => {
    getValues(registerField).forEach((_value, indexIterator) => {
      _value.type = _value.type ?? [];
      var indexOfValue = _value.type.findIndex(x => x === option);
      if (targetIndex === indexIterator) {
        if (indexOfValue < 0) {
          _value.type.push(option);
        }
      } else {
        if (indexOfValue >= 0){
          _value.type.splice(indexOfValue, 1);
        }
      }

      setValue(`${registerField}.${indexIterator}.type`, _value.type);
    });
    
    refreshAddressTypes();
  };

  useEffect(() => {
    if (addressTypes[index]) {
      setMailing(addressTypes[index].findIndex(x => x === 'MAILING') > -1);
      setBilling(addressTypes[index].findIndex(x => x === 'BILLING') > -1);
      setPhysical(addressTypes[index].findIndex(x => x === 'PHYSICAL') > -1);
    }
  }, [addressTypes, index]);

  return (
    <>
    <EditSingleAddress
      registerPrefix={`${registerField}.${index}`}
      allowCancelOnNew={true}
      onCancel={onCancel}
      showDelete={showDelete}
      onDelete={() => remove()}
    />
    <Col lg={1} className={styles.addressRadioButton}>
      <input
        type="radio"
        name={`mailing${index}`}
        checked={mailing}
        onChange={() => setRadioOption(index, 'MAILING')}
      />
    </Col>
    <Col lg={1} className={styles.addressRadioButton}>
      <input
        type="radio"
        name={`billing${index}`}
        checked={billing}
        onChange={() => setRadioOption(index, 'BILLING')}
      />
    </Col>
    <Col lg={1} className={styles.addressRadioButton}>
      <input
        type="radio"
        name={`physical${index}`}
        checked={physical}
        onChange={() => setRadioOption(index, 'PHYSICAL')}
      />
    </Col>
    </>
  );
};