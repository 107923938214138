import { Button, Container, Col, Row, Offcanvas } from "react-bootstrap";
import styles from "shared/components/shared.module.css";

export const OffCanvasWrapper = ({
  children,
  shouldShow,
  toggleShow,
  offCanvasClass,
  closeClass
}) => {
  const closeOffCanvas = () => {
    toggleShow(false);
  };

  const offCanvasClassName = offCanvasClass 
                          ? `${styles.offCanvas} ${offCanvasClass}`
                          : styles.offCanvas;

  const closeOffCanvasClass = closeClass 
                          ? `${styles.closeOffCanvas} ${closeClass}`
                          : styles.closeOffCanvas;

  return (
    <Offcanvas show={shouldShow} onHide={() => closeOffCanvas()} placement="end" className={offCanvasClassName}>
      <Offcanvas.Body className={styles.offCanvasBody}>
        <Container fluid className={styles.offCanvasDefaultHeader}>
          <Row>
            <Col className="my-start text-end">
              <Button variant='link' className={closeOffCanvasClass} onClick={() => closeOffCanvas()}>X</Button>
            </Col>
          </Row>
        </Container>
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  )
};