import { ClipboardButton } from 'shared/components/buttons';
import styles from './adminHeader.module.css';

export const AdminHeader = ({title, id}) => {
  return (
    <div className={styles.header}>
      <h3 className="inverse">{title}</h3>
        {id &&
        <div className={styles.id}>
          ID: {id}
          <ClipboardButton copyText={id} inverse={true} size={15} />
        </div>
      }
    </div>
  );
};