import { gql } from '@apollo/client';
import {MESSAGE_RECIPIENT_FIELDS, MESSAGELIST_FIELDS} from '../fragments';
import { MessagesPerPage } from 'message/constants';

export const GET_RECIPIENT_MESSAGE_LIST = gql`
  ${MESSAGE_RECIPIENT_FIELDS}
  ${MESSAGELIST_FIELDS}

  query GetRecipientMessageList($filter: MessageRecipientFilter, $offset: Int) {
    findMessageRecipients(
      filter: $filter,
      page: {
        limit: ${MessagesPerPage},
        offset: $offset
      },
      orderBy: {
        field: "sentAt",
        order: DESC
      }
    ) {
      count
      items {
        ...MessageRecipientFields
        message {
          ...MessageListFields
        }
      }
    }
  }
`;