import { Col, Row } from 'react-bootstrap';
import { benefitService} from 'benefits/services/benefitService';
import styles from './components.module.css';
import Carousel from 'react-elastic-carousel';

export const EnrollmentBenefits = ({enrollment}) => {
  const apps = benefitService(enrollment.plan.benefits);

  return (
    <Col>
      <Row>
        <Col className={styles.header}>
          <h2>Your benefits</h2>
        </Col>
      </Row>
      <Row>
        <Col className={styles.instructions}>
          Before we collect a little information, here’s a quick overview of your benefits. You’ll be able to dig in more on your dashboard later.
        </Col>
      </Row>
      <Row data-testid="teasers" className={styles.teasers}>
        <DesktopTeasers enrollment={enrollment} apps={apps} />
        <MobileTeasers enrollment={enrollment} apps={apps} />
      </Row>
    </Col>
  );
};

const DesktopTeasers = ({enrollment, apps}) => {
  return (
    <Col className='d-none d-sm-block'>
      <Row>
        {enrollment.plan.benefits.map(benefit => {
          const Teaser = apps.getTeaser(benefit);
          return(
            <Col key={benefit.benefit} lg={4} md={4} sm={5}>
              <Teaser />
            </Col>
          );
        })}
      </Row>
    </Col>
  )
};


const MobileTeasers = ({enrollment, apps}) => {
  return (
  <Col className='d-sm-none'>
    <Carousel>
      {enrollment.plan.benefits.map(benefit => {
        const Teaser = apps.getTeaser(benefit);
        return(
          <Col key={benefit.benefit} xs={10}>
            <div className={styles.teaserCarouselWrapper}>
              <Teaser />
            </div>
          </Col>
        );
      })}
    </Carousel>
  </Col>
  )
};