import { MessageCenter } from "message/components/messageCenter";
import { useCurrentAccount } from "auth/operations/useCurrentAccount";
import { permissions } from "auth/permissions";

export const ConciergeMessages = () => {  
  const account = useCurrentAccount();
  const hasPermission = 
    account.hasPermission(permissions.admin.super) ||
    account.hasPermission(permissions.admin.tenant) ||
    account.hasPermission(permissions.concierge.access);

  if (!hasPermission) {
    return <h2>Not Found</h2>;
  }

  const correspondent = {
    type: 'TENANT',
    id: account?.tenant?.id,
    name: 'Care Concierge'
  };

  return (
    <MessageCenter 
      correspondent={correspondent}
    />
  );
};