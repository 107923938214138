import { gql } from '@apollo/client';

export const GET_ALL_ROLES = gql`
  query GetAllTRoles {
    findRoles (filter: { not: { name: { contains: "API" } } }) {
      items {
        id
        name
      }
    }
  }
`;
