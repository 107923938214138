import { Col, Row } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { DateTime } from "luxon";
import { MessagesPerPage } from "message/constants";
import { ItemList } from "shared/components/itemList";
import styles from './message.module.css';

export const MessageFolder = ({title, messages, direction, fetchMore, totalCount}) => {
  const HeaderRow = direction === "IN" ? MessageFolderFromHeaderRow : MessageFolderToHeaderRow;

  const handlePageChange = (page) => {
    const offset = ((page - 1) * MessagesPerPage);
    fetchMore({variables: { offset: offset }});
  };

  return (
    <>
    <div className={styles.folderheader}>
      <h4 className="inverse">Messages - {title}</h4>
    </div>
    <div className={styles.messagelist}>
      <ItemList data={messages} 
                headerRowElement={HeaderRow}
                dataRowElement={MessageFolderDataRow}
                handlePageChange={handlePageChange}
                numberOfItems={totalCount}
      />
    </div>
    </>
  );
};

const MessageFolderFromHeaderRow = () => {
  return (
    <Row className={styles.messageRowHeader}>
      <Col>Subject</Col>
      <Col aria-label='correspondentHeader'>From</Col>
      <Col>Date</Col>
    </Row>
  );
}

const MessageFolderToHeaderRow = () => {
  return (
    <Row className={styles.messageRowHeader}>
      <Col>Subject</Col>
      <Col aria-label='correspondentHeader'>To</Col>
      <Col>Date</Col>
    </Row>
  );
}

const MessageFolderDataRow = ({item, index}) => {
  const { url } = useRouteMatch();

  const formatDate = (date) => {
    return DateTime.fromMillis(date).toLocaleString(DateTime.DATETIME_SHORT)
  };

  const formatCorrespondents = (correspondents) => {
    if (correspondents.length === 1) {
      return correspondents[0];
    } else {
      return correspondents.join(', ');
    }
  };

  let rowStyles = item.status === 'UNREAD' ? `${styles.unread} ` : '';
  rowStyles += styles.messageRow;

  return (
    <Link to={`${url}/${item.id}`} className={styles.messageRowLink}>
      <Row key={item.id} className={rowStyles} aria-label={"messageDataRow"}>
        <Col>{item.subject}</Col>
        <Col>{formatCorrespondents(item.correspondents)}</Col>
        <Col>{formatDate(item.sentAt)}</Col>
      </Row>
    </Link>
  );
};