export const getReadRecordsFunction = (currentOffsetVar, messagesPerPage) => {
  return (existing) => {
    const offset = currentOffsetVar() || 0;

    return existing?.items && { 
      count: existing.count,
      items: existing.items.slice(offset, offset + messagesPerPage) 
    };
  };
};


export const getMergeRecordsFunction = (currentOffsetVar) => {
  return (existing, incoming, args) => {
    const offset = args?.page?.offset || 0;
    currentOffsetVar(offset);
    const merged = {
      count: existing?.count || incoming?.count,
      items: existing?.items ? existing.items.slice(0) : [],
    };
    if (!incoming.items) return merged;

    for (let i = 0; i < incoming.items.length; ++i) {
      merged.items[offset + i] = incoming.items[i];
    }

    return merged;
  };
};