const financialAssistanceTypes = {
  ACA501R4: 'ACA501R4'
};

const calculateScore = (careCenter, benefit, consumerAddress) => {
  if (!careCenter.financialAssistancePolicies || careCenter.financialAssistancePolicies.length === 0)  return undefined;

  const policy501 = careCenter.financialAssistancePolicies.find(x => x.type === financialAssistanceTypes.ACA501R4)?.configuration;
  if (!policy501 || policy501.determinationType === 'UNKNOWN') return undefined;

  const fplPercentage = fapCalculator.calculateFplPercentage(benefit, consumerAddress);

  if (policy501.determinationType === 'FLAT') {
    return policy501.maxPercentage >= fplPercentage
      ? 100
      : 0;
  }

  if (policy501.determinationType === 'SLIDING') {
    if (fplPercentage > policy501.maxPercentage) return 0;

    const matchingScale = policy501.assistanceScales.find(scale => 
      scale.minPercentage <= fplPercentage && scale.maxPercentage >= fplPercentage);

    if (!matchingScale) return 0;

    return matchingScale.isUnknown
      ? undefined
      : matchingScale.financialAssistancePercentage;
  }

};

const calculateFplPercentage = (benefit, consumerAddress, fplRates) => {
  fplRates = fplRates ?? FplRates2021;
  const rateState = ['AK', 'HI'].includes(consumerAddress.state)
    ? consumerAddress.state
    : '*';
  const fplRate = fplRates.find(x => x.state === rateState);
  const fpl = fplRate.baseAmount + ((benefit.householdSize - 1) * fplRate.marginalAmount);
  const percentage = 100 * benefit.householdIncome / fpl;

  return Math.ceil(percentage);
};

const FplRates2021 = [
  { 
    state: '*',
    baseAmount: 12880,
    marginalAmount: 4540
  },
  {
    state: 'AK',
    baseAmount: 16090,
    marginalAmount: 5680
  },
  {
    state: 'HI',
    baseAmount: 14820,
    marginalAmount: 5220
  }
]

export const fapCalculator = {
  calculateScore,
  calculateFplPercentage,
  types: financialAssistanceTypes
};