import { Col, Row } from 'react-bootstrap';
import { PlanLogo } from 'benefits/shared'
import parse from 'react-html-parser';
import styles from './components.module.css';

export const EnrollmentPlan = ({ enrollment }) => {
  const plan = enrollment.plan;

  return(
    <>
      <Row className={`justify-content-md-center ${styles.plantop}`}>
        <Col className="text-center">
          <div className={styles.yourplan}>
            YOUR PLAN:
          </div>
          <PlanLogo />
        </Col>
      </Row>
      <Row className={`justify-content-md-center ${styles.planheader}`}>
        <Col className={styles.planintrocell}>
          <div className={styles.planintro}>
            {parse(plan.intro)}
          </div>
          <div className={styles.planbio}>
            Best in Class Care was born of the idea that everyone has a right to great healthcare. That health plans and employers shouldn't be restricted by region in helping people get the care they need. And that where you live or what you earn shouldn't limit the quality of care you get.
          </div>
        </Col>
      </Row>
    </>
  );
};