import { FaEdit } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const EditButton = ({...rest}) => {

  return (
    <IconButton 
      IconComponent={FaEdit} 
      iconClass={styles.edit}
      {...rest}
      />
  );
};