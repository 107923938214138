import { gql } from '@apollo/client';
import { FIND_CARECENTER_FIELDS } from 'careCenter/operations/fragments'

export const FIND_501_CARECENTERS = gql`
  ${FIND_CARECENTER_FIELDS}
  query Find501CareCenters($search: IndemnityFilter!) {
    indemnity {
      find501CareCenters(search: $search) {
        careCenters {
          careCenter {
            ...FindCareCenterFields
          }
          distance
        }
      }
    }
  }
`;