import { gql } from '@apollo/client';

export const GET_OPEN_LYRIC_APPOINTMENT = gql`
  query FindOpenLyricAppointments($consumerId: ID!) {
    findAppointments(
      filter: {
        consumer: { id: { eq: $consumerId } },
        integrations: { type: { eq: "LYRIC" } },
        status: { eq: "OPEN" }
      },
      page: {
        limit: 1,
      }) {
      items {
        id
        type
        status
        date
        time
        duration
      }
    }
  }
`;