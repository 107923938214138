import React from "react";
import { Form } from "react-bootstrap";
import { getError } from "shared/services/validationService";
import style from './form.module.css';

export const InputWithoutLabel = ({
  id, 
  type, 
  placeholder, 
  onClick, 
  onKeyDown, 
  inputRef, 
  inputStyle, 
  onFocus,
  onBlur,
  errorRegister,
  errors
}) => {

  const getInputError = () => getError(errorRegister, errors);

  return (
    <div className={style.input}>
      <Form.Control 
        size="lg" 
        id={id} 
        ref={inputRef} 
        onClick={onClick} 
        onKeyDown={onKeyDown} 
        placeholder={placeholder} 
        type={type} 
        style={inputStyle} 
        onFocus={e => {onFocus(e)}}
        onBlur={e => {onBlur(e)}}
        isInvalid={getInputError()}
      />
      <Form.Control.Feedback type="invalid">
        {getInputError()?.message}
      </Form.Control.Feedback>
    </div>
  )
};
