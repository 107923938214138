import { gql } from '@apollo/client';

export const CORRESPONDENT_FIELDS = gql`

  fragment CorrespondentFields on Correspondent {
    type
    id
    name
  }
`;

export const MESSAGE_RECIPIENT_FIELDS = gql`

  fragment MessageRecipientFields on MessageRecipient {
    id
    status
    sentAt
  }
`;

export const MESSAGELIST_FIELDS = gql`
  ${CORRESPONDENT_FIELDS}

  fragment MessageListFields on Message {
    id
    subject
    sender {
      ...CorrespondentFields
    }
    sentAt
    sentTo {
      name
    }
  }
`;

export const MESSAGE_FIELDS = gql`
  ${MESSAGELIST_FIELDS}

  fragment MessageFields on Message {
    ...MessageListFields
    text
    tenant { id }
    replyTo { id }
    parent { id }
    case { id }
    tags
  }
`;