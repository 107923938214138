import { Section, Input } from 'shared/components/form';
import { Col } from 'react-bootstrap';

export const EditLyricIntegration = ({integration}) => {

  return (
    <>
      <Section>
        <Col xs={4}>
          <Input
            label="Plan ID"
            registerInput="integration.configuration.planId"
          />
        </Col>
      </Section>
    </>
  );
};