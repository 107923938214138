import { useState, useEffect, useMemo } from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { FaEdit, FaShieldAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Button } from 'shared/components/buttons';
import { PlanLogoSmall } from 'benefits/shared/planLogoSmall';
import { EnrollmentAdditionalInfo, EnrollmentBenefits, EnrollmentNavigation, EnrollmentPlan } from '../components';
import { useCurrentEnrollment } from 'enrollment/hooks/useCurrentEnrollment';
import { consumerPaths } from 'consumer/routes';
import styles from './enrollment.module.css';

export const Enrollment = ({hitKey}) => {
  const { enrollment} = useCurrentEnrollment();

  if (enrollment) {
    return <EnrollmentForm enrollment={enrollment} hitKey={hitKey} />
  }

  return (<h1>Loading...</h1>);
};

const EnrollmentForm = ({enrollment, hitKey}) => {
  const history = useHistory();
  const buttonTexts = useMemo(() => ([
    'Get Started!',
    'Next: Additional Info'
  ]), []);
  const [activeKey, setActiveKey] = useState(hitKey ?? 1);
  const [buttonText, setButtonText] = useState(buttonTexts[0]);

  useEffect(() => {
    const text = buttonTexts[activeKey - 1];
    if (text !== buttonText) setButtonText(text);
  }, [activeKey, buttonText, buttonTexts]);

  const handleButtonClick = () => {
    setActiveKey(activeKey + 1);
  };

  const onCompleted = () => {
    history.push(consumerPaths.root);
  };

  return (
    <Tab.Container id="enrollment" activeKey={activeKey}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} sm={12}>
            <Row>
              {/* breadcrumbs */}
              <Nav className="justify-content-center">
                <EnrollmentNavigation navKey={1} activeKey={activeKey} setActiveKey={setActiveKey} text="1. Plan" />
                <EnrollmentNavigation navKey={2} activeKey={activeKey} setActiveKey={setActiveKey} text="2. Benefits" />
                <EnrollmentNavigation navKey={3} activeKey={activeKey} setActiveKey={setActiveKey} text="3. Information" />
              </Nav>
              <hr />
            </Row>
            <Row className="justify-content-center" hidden={activeKey === 1}>
              <Col lg="auto" className="text-center">
              {/* Plan name / logo ...only on 2nd & 3rd views*/} <PlanLogoSmall />
              </Col>
            </Row>
            <Row className="justify-content-center">
              { activeKey !== 1 &&
                <>
                  <Col md={1} className={`d-none d-xs-none d-sm-none d-lg-block ${styles.divider} my-start text-end`}>
                    <EnrollmentIcon activeKey={activeKey} size={30} />
                  </Col>
                  <Col sm={12} xs={12} className={`d-md-none text-center`}>
                    <EnrollmentIcon activeKey={activeKey} size={20} />
                  </Col>
                </>
              }
              <Col>
                <Row>
                  <Col>
                    {/* tab contents go here */}
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <EnrollmentPlan enrollment={enrollment} />
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <EnrollmentBenefits enrollment={enrollment} />
                      </Tab.Pane>
                      <Tab.Pane eventKey={3}>
                        <EnrollmentAdditionalInfo enrollment={enrollment} onCompleted={onCompleted} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
                {activeKey < 3 &&
                  <Row className="justify-content-md-center">
                    <Col className="d-flex justify-content-center">
                      <Button variant="action" text={buttonText} onClick={handleButtonClick} />
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </Col></Row>
      </Container>
    </Tab.Container>
  );
};

const EnrollmentIcon = ({ activeKey, size }) => {
  return (
    <>
      {activeKey === 2
        ? <FaShieldAlt className={styles.dividericon} size={size} />
        : <FaEdit className={styles.dividericon} size={size} />
      }
    </>
  );
};