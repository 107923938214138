import { Col, Container, Row } from 'react-bootstrap';
import styles from 'case/case.module.css';
import { FaRegStickyNote, FaRegEnvelope, FaPaperclip } from 'react-icons/fa';
import { MessageThread } from './messageThread';
import { useEffect, useReducer, useRef, useState } from 'react';
import { TagsReducer, RefreshTags } from './tagsReducer';
import { Note } from './note';
import { Audit, enrichAuditData } from './audit';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { NotesLoader } from './loaders/notesLoader';
import { MessagesLoader } from './loaders/messagesLoader';
import { ContentEditor } from './contentEditor';
import { orderBy } from "lodash";
import isEmpty from 'lodash.isempty';

export const Contents = ({caseData, filter}) => {
  const [state, dispatch] = useReducer(TagsReducer, {tags:[]});
  const [notes, setNotes] = useState([]);
  const [messages, setMessages] = useState([]);
  const [auditHistory, setAuditHistory] = useState(enrichAuditData(caseData));
  const [activityContents, setActivityContents] = useState([]);
  const [adding, setAdding] = useState(false);
  const [typeToAdd, setTypeToAdd] = useState('');
  const addingRef = useRef();
  const onScreen = useOnScreen(addingRef);
  const onNotesLoaded = (notes) => setNotes([...notes.map(x => ({...x, type: 'Note', sortDate: x.sentAt}))]);
  const onMessagesLoaded = (messages) => setMessages([...messages.map(x => ({...x, type: 'Message'}))]);

  const addContent = (type) => {
    RefreshTags(dispatch);
    setAdding(true);
    setTypeToAdd(type);
  };

  const onAddComplete = (content) => {
    if (content) {
      setActivityContents([...activityContents, {...content, type: typeToAdd}]);
    }

    setTypeToAdd('');
    setAdding(false);
  };

  useEffect(() => {
    if(!isEmpty(caseData)) {
      setAuditHistory(enrichAuditData(caseData))
    }
  }, [caseData])

  useEffect(() => {
    let newContents = [...auditHistory, ...notes, ...messages];
    setActivityContents(orderBy(newContents, ['sortDate'], ['asc']));
  }, [auditHistory, notes, messages])

  useEffect(() => {
    if (adding && !onScreen) {
      addingRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [adding, onScreen, addingRef]);

  return (
    <>
      <NotesLoader caseId={caseData.id} loadNotes={filter.includes('Notes')} onNotesLoaded={onNotesLoaded}/>
      <MessagesLoader caseId={caseData.id} loadMessages={filter.includes('Messages')} onMessagesLoaded={onMessagesLoaded} />
      <Row>
        <Col className={styles.scrollable}>
          {activityContents.map((content, index) => (
            <Row key={index}>
              <Col>
                <Content caseData={caseData} content={content} allowedTypes={filter} />
              </Col>
            </Row>
          ))}
          { adding &&
          <Container>
            <ContentEditor
              caseData={caseData}
              onCompleted={onAddComplete}
              tags={state.tags}
              dispatch={dispatch}
              contentType={typeToAdd}
            />
          </Container>
          }
          <div id='addingScrollTo' ref={addingRef}/>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Row className={styles.addNewButtons}>
            <Col className={styles.newButtonLabel}>
              NEW:
            </Col>
            <Col className={styles.newButton} onClick={() => addContent('Note')}>
              <FaRegStickyNote />
              NOTE
            </Col>
            <Col className={styles.newButton} onClick={() => addContent('Message')}>
              <FaRegEnvelope />
              MESSAGE
            </Col>
            <Col className={styles.newButton}>
              <FaPaperclip />
              ATTACHMENT
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const Content = ({caseData, content, allowedTypes}) => {
  let Component;

  switch (content.type) {
    case 'Activity': 
      Component = Audit;
      break;
    case 'Note':
      Component = Note;
      break;
    default: 
      Component = MessageThread;
  }

  return allowedTypes.includes(`${content.type}s`) || allowedTypes.includes(content.type) 
          ? <Component content={content} caseData={caseData} /> 
          : <></>;
};