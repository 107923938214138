import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import { Button } from 'shared/components/buttons/button';
import { BoxLoading } from 'shared/components/spinners';
import { AvailableVisits } from 'benefits/shared/availableVisits';
import { GET_BENEFIT_USAGES } from 'benefits/operations/queries/getBenefitUsages';
import { DPCIcon } from './icon';
import { dpcPaths } from '../routes';

export const YouGetDPC = ({enrollment}) => {
  const benefitPeriod = enrollment.getCurrentBenefitPeriod();
  const history = useHistory();
  const { data } = useQuery(GET_BENEFIT_USAGES, { 
    variables: {
      filter: {
        enrollment: { id: { eq: enrollment.id } },
        benefitPeriod: {
          start: { eq: benefitPeriod.start.toISO() },
          end: { eq: benefitPeriod.end.toISO() }
        }
      }
    }
  });

  const handleButtonClick = () => {
    history.push(dpcPaths.findHospital);
  };

  return (
    <YouGetCard 
      title='Primary Care'
      icon={<DPCIcon />}
      copy="Whether you need a wellness checkup or to see a provider for a sick visit or a specialty referral, you can do all of that our Primary Care network, along with full Care Concierge support."
    >
        <>
        {data?.findBenefitUsages
          ?
            <Row>
              <Col>
                <AvailableVisits
                  title="Wellness:"
                  enrollment={enrollment}
                  benefitUsage={data?.findBenefitUsages?.items[0]}
                  benefitName="dpc"
                  allowedProperty="visits.wellness"
                  usageProperty="wellness"
                />
              </Col>
              <Col>
                <AvailableVisits
                  title="Sickness:"
                  enrollment={enrollment}
                  benefitUsage={data?.findBenefitUsages?.items[0]}
                  benefitName="dpc"
                  allowedProperty="visits.sickness"
                  usageProperty="sickness"
                />
              </Col>
            </Row>
          : 
            <Row xs={2} style={{maxHeight: '60px'}}>
              <Col><BoxLoading height={80} width={120} /></Col>
              <Col><BoxLoading height={80} width={120} /></Col>
            </Row>
        }
        <Row className="justify-content-center" style={{ 'position': 'relative', 'bottom': -25}}>
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <Button variant="secondary" text="Request Appointment" onClick={handleButtonClick}/>
          </Col>
        </Row>
        </>
    </YouGetCard>
  );
};