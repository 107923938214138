import { gql } from '@apollo/client';
import { CARECENTER_FIELDS } from '../fragments';

export const GET_CARECENTER = gql`
  ${CARECENTER_FIELDS}
  query GetCareCenter($id: ID!) {
    getCareCenter(id: $id) {
      ...CareCenterFields
    }
  }
`;