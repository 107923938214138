import { Card, Col, Row } from 'react-bootstrap';
import styles from './todo.module.css';

export const TodoCard = ({title, children, buttonVariant, buttonTitle, showButton=true, onButtonClick}) => {
  let buttonClass = `${styles.button} `;
  buttonClass += buttonVariant === 'secondary'
    ? styles.buttonSecondary
    : styles.buttonPrimary;
  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}><h4>{title}</h4></Card.Header>
      <Card.Body className={styles.cardBody}>
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
        { showButton &&
        <Row className={`justify-content-center ${styles.buttonRow}`}>
          <Col className="text-center align-self-center">
            <button className={buttonClass} onClick={onButtonClick}>{buttonTitle}</button>
          </Col>
        </Row>
        }
      </Card.Body>
    </Card>
  );
};
