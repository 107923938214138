import {
  gql
} from '@apollo/client';

export const CARECENTER_FIELDS = gql`
  fragment CareCenterFields on CareCenter {
    id
    tenant {
      id
    }
    name
    address {
      type
      line1
      line2
      city
      state
      postalCode
      countryCode
      geometry {
        type
        coordinates
      }
    }
    phoneNumbers {
      number
      extension
      country {
        prefix
        code
      }
      type
      isPrimary
    }
    overview
    termsAndConditions
    amenities {
      id
    }
    media {
      sequenceNumber
      fileName
      extension
      size
      title
      isCoverImage
      isVideo
      videoId
    }
    financialAssistancePolicies {
      type
      configuration
    }
    networks {
      id
    }
    isPublished
  }
`;

export const FIND_CARECENTER_FIELDS = gql`
  fragment FindCareCenterFields on CareCenter {
    id
    name
    address {
      type
      line1
      line2
      city
      state
      postalCode
      countryCode
      geometry {
        coordinates
      }
    }
    financialAssistancePolicies {
      type
      configuration
    }
    media {
      fileName
      extension
      isCoverImage
    }
  }
`;