import { ConciergeConsumers } from 'concierge/pages/conciergeConsumers';
import { EditConciergeConsumer } from 'concierge/pages/editConciergeConsumer';
import { ConciergeCases } from 'concierge/pages/conciergeCases';
import { ConciergeMessages } from './pages/conciergeMessages';
import { ConciergeSurvey } from 'concierge/pages/conciergeSurvey';
import { CaseDetails } from 'case/pages/caseDetails';
import { TabPath } from 'shared/components/tablayout';

const rootPath = '/admin/concierge';

export const conciergePaths = {
  conciergeConsumers: `${rootPath}/consumers`,
  conciergeCases: `${rootPath}/cases`,
  conciergeMessages: `${rootPath}/messages/secure`,
  conciergeSurvey: `${rootPath}/survey`,
}

export const conciergeRoutes = [
  {
    exact: true,
    path: [
      conciergePaths.conciergeConsumers,
      `${conciergePaths.conciergeConsumers}/search/:search`
    ],
    key: 'admin_concierge_consumers',
    component: ConciergeConsumers
  },
  {
    path: `${conciergePaths.conciergeConsumers}/:consumerId/${TabPath}`,
    key: 'admin_consumer_edit',
    component: EditConciergeConsumer
  },
  {
    exact: true,
    path: conciergePaths.conciergeCases,
    key: 'admin_concierge_cases',
    component: ConciergeCases
  },
  {
    path: `${conciergePaths.conciergeCases}/:caseId/${TabPath}`,
    key: 'admin_concierge_case_detail',
    component: CaseDetails
  },
  {
    path: `${conciergePaths.conciergeMessages}/:folder?/:messageId?`,
    key: 'admin_concierge_messages',
    component: ConciergeMessages
  },
  {
    path: `${conciergePaths.conciergeSurvey}/:code`,
    key: 'concierge_survey',
    component: ConciergeSurvey
  }
];