import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from 'shared/components/buttons';

export const PolicyModal = ({ title, show, toggleShow, children }) => {

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => toggleShow(false)}
      aria-labelledby="policy-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="policy-modal">
          Best in Class Care, Inc.
          <br />
          <br />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        <Row className="justify-content-md-end">
          <Col sm="auto">
            <Button text="Close" onClick={() => toggleShow(false)} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};