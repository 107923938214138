import { Col, Container, Row } from 'react-bootstrap';
import styles from 'enrollment/enrollment.module.css';
import { useEffect, useState } from 'react';
import { useCurrentEnrollment } from 'enrollment/hooks/useCurrentEnrollment';

export const EnrollmentTerminated = () => {
  const { consumer, enrollment } = useCurrentEnrollment();
  const [number, setNumber] = useState('');
  const [dial, setDial] = useState('');

  useEffect(() => {
    if (consumer) {
      const phoneNumber = consumer.tenant.concierge.phoneNumber;
      let dialNumber = phoneNumber.replace(/[^0-9]+/g, '');
      dialNumber = `+1${dialNumber}`;
      setNumber(phoneNumber);
      setDial(dialNumber);
    }
  }, [consumer])

  return (
    <Container fluid>
      <Row className={`justify-content-center ${styles.header}`}>
        <Col className={`text-center my-auto ${styles.salutation}`}>
          <h1 className="inverse">Hi, {consumer?.contact?.name?.first}.</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} className={`text-center ${styles.intro}`}>
          Our system reports that your {enrollment?.plan?.name} benefit plan is currently inactive. The most common reason for this is that you 
          have ended employment and the benefit term has expired. However, if you believe this to be an error, please call 
          our <a className={styles.telephoneLink} href={`tel:${dial}`}>Care Concierge at {number}</a> for assistance.
          <hr />
          As always, you will continue to have access to your Best in Class Care account and information, even if you do not have an active plan.
        </Col>
      </Row>
      {/* <div className={styles.enrollmentTerminatedText}>
      </div> */}
    </Container>
  )
}