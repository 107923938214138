import { Accordion, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { TodoCard } from './todoCard';
import styles from './todo.module.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { consumerPaths } from 'consumer/routes';
import { AppointmentActionItem } from './appointmentActionItem';

export const Appointments = ({loading, appointments}) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(consumerPaths.appointments);
  };

  return (
    <TodoCard 
      title="Appointments"
      buttonVariant="secondary"
      buttonTitle="View all appointments"
      showButton={appointments.length > 0}
      onButtonClick={handleButtonClick}
    >
      { appointments.length > 0 && 
      <>
        { appointments.map((appointment, index) => (
          <Row key={`todo-appointment-${index}`}>
            <Accordion defaultActiveKey="1" className={styles.appointmentAccordion}>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={styles.appointmentAccordionHeaderWrapper}>
                  <div>
                    <Row>
                      <Col>
                        <FaRegCalendarAlt className={styles.icon}/>
                        <span className={styles.appointmentAccordionHeader}>{appointment.careCenter.name}&nbsp; &#9656;</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={styles.appointmentAccordionHeaderDate}>
                        {DateTime.fromISO(appointment.date).toLocaleString()}&nbsp;&nbsp;&nbsp;&nbsp;{appointment.time}
                      </Col>
                    </Row>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className={styles.appointmentAccordionBody}>
                    <AppointmentActionItem appointment={appointment} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <hr />
          </Row>
        ))}
      </>
      } 
      { appointments.length === 0 &&
      <>
        <Row className={styles.cardSubheaderNoBorder}>
          Take it easy.
        </Row>
        <Row className={styles.cardContent}>
          You have no upcoming appointments. Stay healthy!
        </Row>
      </>
      }
    </TodoCard>
  );
};