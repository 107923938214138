import { TeaserCard } from 'enrollment/components/teaserCard';
import { IndemnityIcon } from './icon';

export const IndemnityTeaser = () => {
  return (
    <TeaserCard
        title="Hospital Finder"
        icon={<IndemnityIcon />}
    >
      Selected hospitals with the right prices for you
    </TeaserCard>
  );
};