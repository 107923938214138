import { Row } from 'react-bootstrap';
import { TodoCard } from 'consumer/components/todo/todoCard';
import styles from 'consumer/components/todo/todo.module.css';
import { useHistory } from 'react-router-dom';
import { consumerPaths } from 'consumer/routes';

export const DoctorsOrders = ({orders}) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(consumerPaths.doctorsOrders);
  };

  return (
    <TodoCard 
      title="Doctor's Orders"
      buttonVariant="secondary"
      buttonTitle="View all Doctor's Orders"
      showButton={orders.length > 0}
      onButtonClick={handleButtonClick}
    >
      { orders.length > 0 && 
      <>
        { orders.map((order, index) => (
          <Row key={`todo-order-${index}`}>
            <order.Component order={order} />
            <hr />
          </Row>
        ))}
      </>
      } 
      { orders.length === 0 &&
      <>
        <Row className={styles.cardSubheaderNoBorder}>
          Nothing to see here.
        </Row>
        <Row className={styles.cardContent}>
          You have no pending doctor's orders.
        </Row>
      </>
      }
    </TodoCard>
  );
};
