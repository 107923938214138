import { useMutationWrapper } from "apollo/useMutationWrapper";
import { Col, Container, Row } from "react-bootstrap";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'shared/components/buttons';
import { benefitService } from 'benefits/services/benefitService';
import { UPDATE_ENROLLMENT } from "enrollment/operations/mutations/updateEnrollment";
import { useEffect } from "react";
import isEmpty from "lodash.isempty";

export const EditEnrollment = ({enrollment, buttonOpts, onSaved, setBlockNavigation}) => {
  buttonOpts = buttonOpts ?? {};
  setBlockNavigation = setBlockNavigation ?? function() {};
  const apps = benefitService(enrollment.plan.benefits);
  const benefitForms = apps.getEnrollmentForms();
  const formEnrollment = {
    id: enrollment.id,
    benefits: ensureOrderedBenefits(enrollment.benefits, benefitForms)
  };
  let validationSchema;
  const benefitValidtion = apps.getValidationSchema();
  if (benefitValidtion) {
    validationSchema = Yup.object().shape({
      enrollment: Yup.object().shape({
        benefits: benefitValidtion
      })
    });
  }

  const formMethods = useForm({
    defaultValues: { enrollment: formEnrollment },
    mode: "onChange",
    resolver: validationSchema ? yupResolver(validationSchema) : undefined
  });
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: 'enrollment.benefits',
    keyName: 'benefit'
  });

  const [saveEnrollment, {loading}] = useMutationWrapper(UPDATE_ENROLLMENT, {
    onCompleted: onSaved
  });

  const onSubmit = (data) => {
    data.enrollment.completed = true;
    saveEnrollment({ variables: { input: data.enrollment }});
  };

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields);
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  return(
    <>
    { loading 
      ? <h1>Saving...</h1>
      : <FormProvider {...formMethods}>
        <form name="enrollment" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Container>
            <Row data-testid="enrollmentForms">
            {fields.map((field, index) => {
              const BenefitComponent = benefitForms[field.benefit];
              return (
                <BenefitComponent 
                  key={`benefit_${index}`}
                  index={index} 
                  registerPrefix="enrollment.benefits"
                  enrollment={enrollment} 
                  errors={formMethods.formState.errors} 
                />
              );
            })}
            </Row>
            <Row className={buttonOpts.alignmentClass} data-testid="buttonRow">
              <Col md="auto" className={buttonOpts.alignmentClass}>
                <Button type="submit" variant={buttonOpts.variant} text={buttonOpts.text} disabled={!formMethods.formState.isValid} />
              </Col>
            </Row>
          </Container>
        </form>
      </FormProvider>
      }
    </>
  );
};

const ensureOrderedBenefits = (benefits, benefitForms) => {
  const orderedBenefits = [];
  benefits = benefits ?? [];

  Object.keys(benefitForms).forEach(benefitName => {
    const existing = benefits.find(x => x.benefit === benefitName);
    if (existing) {
      orderedBenefits.push(existing);
    } else {
      orderedBenefits.push({ benefit: benefitName, configuration: {} });
    }
  });

  return orderedBenefits;
};