import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { policyPaths} from '../../registration/routes';
import { appSettings } from 'appSettings';

export const Footer = () => {

  const year = new Date().getFullYear();

  const copyRightLayout = {
    span: 6
  };
  const policyLayout={
    span: 4,
    offset: 2
  };

  return(
    <Container>
      <Row style={{'margin': '20px 0px 20px 0px'}}>
        <Col lg={copyRightLayout} md={copyRightLayout} sm={copyRightLayout} xs={copyRightLayout}>
          <p onMouseOver={() => console.log(appSettings.APP_VERSION)}>
            &copy; All Rights Reserved {year} Best In Class Care, Inc. 
          </p>
        </Col>
        <Col lg={policyLayout} md={policyLayout} sm={policyLayout} xs={policyLayout} className="d-flex justify-content-end">
          <Link to={policyPaths.privacy}>Privacy Policy</Link>
          &nbsp;|&nbsp;
          <Link to={policyPaths.tandc}>Terms and Conditions</Link>
        </Col>
      </Row>
    </Container>
  )
};