import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ReactToolTip from 'react-tooltip';
import { Redirect, Route } from 'react-router-dom';
import { Layout } from './shared/components/layout';
import { AppRouter, ScrollToTop } from './shared/routing';
import { Registration } from './registration/pages/registration';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { TenantChanger } from 'tenant/components/tenantChanger';

export const App = () => {
  const { consumer } = useCurrentConsumer();

  if (consumer) {
    const isNewUser = !consumer.tandcAccepted || !consumer.privacyPolicyAccepted;

    return (
      <>
        <ScrollToTop />
        {isNewUser
          ? <NewUser />
          : <ExistingUser />
        }
        <ToastContainer 
          hideProgressBar={true}
        />
        <ReactToolTip />
      </>
    );
  }

  return 'Loading...';
};

const ExistingUser = () => {
  return (
    <Layout>
      <AppRouter />
      <TenantChanger />
    </Layout>
  );
};

const NewUser = () => {
  return (
    <>
      <Route path="/registration">
        <Registration />
      </Route>

      <Redirect to="/registration" />
    </>
  );
};