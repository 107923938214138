import { HospitalDetail } from 'careCenter/pages/hospitalDetail';
import { RenderRoutes } from 'shared/routing/renderRoutes';

const pathRoot = '/careCenter';

export const careCenterPaths = {
  hospitalDetail: `${pathRoot}/details`
};

export const careCenterRoutes = [
  {
    path: pathRoot,
    key: 'careCenter',
    component: RenderRoutes,
    routes: [
      {
        path: `${careCenterPaths.hospitalDetail}/:careCenterId/:score?`,
        key: 'carecenter_hospital_detail',
        component: HospitalDetail
      }
    ]
  }
]