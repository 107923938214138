import { useEffect, useState } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import parse from 'react-html-parser';
import Vimeo from '@u-wave/react-vimeo';
import { appSettings } from 'appSettings';
import { CategoryTitle } from './categoryTitle';
import styles from './perks.module.css';

export const PerkCard = ({ perk, categoryTitle, CategoryIcon, onTogglePlaying, inactive }) => {
  const [showDetail, toggleDetail] = useState(false);
  const [paused, togglePaused] = useState(true);

  let vimeoId;
  if (perk.videoUrl) {
    const parts = perk.videoUrl.split('/');
    vimeoId = parts[parts.length - 1];
  }

  useEffect(() => {
    if (inactive && !paused) {
      togglePaused(true);
    }
  }, [inactive, paused]);

  const togglePlay = () => {
    togglePaused(false);
    onTogglePlaying(true);
  };

  const togglePause = () => {
    onTogglePlaying(false);
  };


  return (
    <>
      <Row>
        <Col className={styles.perkCard}>
          <Row>
            <Col>
              {perk.videoUrl
                ? <Vimeo video={vimeoId} width={280} height={170} onPlay={togglePlay} onPause={togglePause} paused={paused} />
                : <img src={`${appSettings.MEDIA_URL}/perks/thumbnails/${perk.id}.${perk.thumbnailSuffix}`} width={280} height={170} alt={perk.title} />
              }
            </Col>
          </Row>
          <Row>
            <Col className={styles.perkTitle}>{perk.title}</Col>
          </Row>
          <Row>
            <Col className={styles.teaser}>{perk.teaser}</Col>
          </Row>
          {perk.copy &&
            <Row>
              <Col>
                <Button variant='link' onClick={() => toggleDetail(true)} className={styles.learnMore}>
                  Learn More &nbsp; &#9656;
                </Button>
              </Col>
            </Row>
          }
        </Col>
      </Row>

    <Offcanvas show={showDetail} onHide={() => toggleDetail(false)} placement="end" className={styles.offCanvas}>
      <Offcanvas.Body className={styles.canvasBody}>
        <Row>
          <Col className="my-start text-end">
            <Button variant='link' className={styles.closeOffCanvas} onClick={() => toggleDetail(false)}>X</Button>
          </Col>
        </Row>
        <Row>
          <CategoryTitle title={categoryTitle} Icon={CategoryIcon} />
        </Row>
        <Row>
          <Col className="mx-auto text-center">
            { perk.videoUrl
              ? <Vimeo video={vimeoId} width={400} height={255} />
              : <img src={`${appSettings.MEDIA_URL}/perks/thumbnails/${perk.id}.${perk.thumbnailSuffix}`} width={400} height={255} alt={perk.title} className={styles.canvasImage} />
            }
          </Col>
        </Row>
        <Row>
          <Col className={styles.canvasTitle}>
            <h4>{perk.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col className={styles.canvasCopy}>
            { perk.videoUrl
              ? perk.teaser
              : parse(perk.copy)
            }
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
    </>
  );
};