import { gql } from '@apollo/client';
import { PAYER_FIELDS } from '../fragments';

export const CREATE_PAYER = gql`
  ${PAYER_FIELDS}

  mutation CreatePayer($input: CreatePayerInput!) {
    createPayer(input: $input) {
      ...PayerFields
    }
  }
`;