import { gql } from '@apollo/client';
import { NOTE_FIELDS } from 'case/operations/fragments';

export const UPDATE_NOTE = gql`
  ${NOTE_FIELDS}
  mutation UpdateNote($input: MutateNoteInput!) {
    updateNote(input: $input) {
      ...NoteFields
    }
  }
`;
