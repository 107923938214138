import { gql } from '@apollo/client';

export const GET_MY_PERKS = gql`
  query GetMyPerks {
    findPerks(filter: { active: { eq: true } }) {
      items {
        id
        category
        title
        teaser
        videoUrl
        copy
        thumbnailSuffix
      }
    }
  }
`;