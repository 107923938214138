import { TelehealthTeaser } from "./components/teaser";
import { YouGetTelehealth } from "./components/youGet";
import { TelehealthConfig } from "./components/config";

export const TelehealthApp = {
  key: 'telehealth',
  name: 'Telehealth',
  teaser: TelehealthTeaser,
  youGetCard: YouGetTelehealth,
  config: TelehealthConfig
};