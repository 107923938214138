import { Col, Row } from 'react-bootstrap';
import { ContactButton } from './contactButton';
import { constants } from './constants';
import styles from './help.module.css';

export const Help = ({ title, copy, children }) => {
  const ConciergeIcon = constants.icon;

  return (
    <>
      <Row className="justify-content-center">
        <Col className={`${styles.helpsection} text-center`}>
          <ConciergeIcon size={30} className={styles.modalicon} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`${styles.helpsection} text-center`}>
          <h4>{title}</h4>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`${styles.helpsection} text-center ${styles.copy}`}>
          {copy}
        </Col>
      </Row>
      { children && 
        <Row className="justify-content-center">
          <Col className={`${styles.helpsection} text-center`}>
            {children}
          </Col>
        </Row>
      }
      <Row className="justify-content-center">
        <Col className={`${styles.helpsection} text-center`}>
          <ContactButton />
        </Col>
      </Row>
    </>
  );
};
