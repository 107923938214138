import { gql } from '@apollo/client';
import { NAME_FIELDS, ADDRESS_FIELDS_WITH_GEO, EMAIL_FIELDS, PHONE_FIELDS } from 'shared/operations/fragments';

export const CONSUMER_FIELDS = gql`
  ${ADDRESS_FIELDS_WITH_GEO}
  ${EMAIL_FIELDS}
  ${NAME_FIELDS}
  ${PHONE_FIELDS}

  fragment ConsumerFields on Consumer {
    id
    account {
      id
    }
    tenant {
      id
      concierge {
        phoneNumber
      }
    }
    contact {
      name {
        ...NameFields
      }
      emails {
        ...EmailFields
      }
      phoneNumbers {
        ...PhoneFields
      }
      addresses {
        ...AddressWithGeoFields
      }
    }
    dateOfBirth
    sex
    pronoun
    tandcAccepted
    privacyPolicyAccepted
    integrations {
      type
      id
      configuration
    }
  }
`;