import { useQueryWrapper } from "apollo/useQueryWrapper";
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { GET_ORDER } from "orders/operations/getOrder";
import { useState } from "react";
import { FaRegHospital, FaUserMd, FaRegCalendarCheck } from "react-icons/fa";
import { DateTime } from 'luxon';
import ReactToolTip from 'react-tooltip';
import { OrderType } from "orders/components/orderType";
import styles from 'case/case.module.css';
import { OffCanvasWrapper } from "shared/components/offCanvasWrapper";
import { ViewHospitalDetail } from "careCenter/components/viewHospitalDetail";
import offCanvasStyles from "shared/components/shared.module.css";

export const ViewOrder = ({orderId}) => { 
  const [order, setOrder] = useState(null);
  const [showCareCenter, toggleShowCareCenter] = useState(false);
  useQueryWrapper(GET_ORDER, {
    skip: !orderId,
    variables: { id: orderId },
    onCompleted: (data) => setOrder(data.getOrder)
  });

  const getOrderHeader = (order) => {
    if (!order) return null;

    switch (order.type) {
      case OrderType.Referral:
        return order.configuration.description
      case OrderType.Followup: 
        return `Followup on ${order.configuration.appointmentDate}`
      default:
        return 'Order';
    }
  };

  const header = getOrderHeader(order);

  return (
    <>
    { order &&
      <Accordion defaultActiveKey="0" className={styles.contextViewAccordion}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className={styles.contextViewAccordionHeader}>
            <span className={styles.viewOrderHeader}>{header}</span>
          </Accordion.Header>
          <Accordion.Body className={styles.viewOrderAccordionBody}>
            <Row>
              <Col>
                <FaRegCalendarCheck data-tip='Issued On' className={styles.viewOrderDate}/> 
                {DateTime.fromMillis(order.date).toLocaleString(DateTime.DATE_MED)}
              </Col>
            </Row>
            <Row>
              <Col>
                <FaUserMd data-tip='Provider' className={styles.viewOrderProvider}/>
                {`${order.provider.contact.name.first} ${order.provider.contact.name.last}`}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant='link' onClick={() => toggleShowCareCenter(true)} className={styles.viewOrderLink}>
                  <FaRegHospital data-tip='CareCenter' className={styles.viewOrderCareCenter}/>
                  {order.careCenter.name} &nbsp;&#9656;
                </Button>
                <ReactToolTip />
              </Col>
              <OffCanvasWrapper shouldShow={showCareCenter} toggleShow={toggleShowCareCenter} closeClass={offCanvasStyles.closeOffCanvasWhiteHover}>
                <ViewHospitalDetail careCenterId={order.careCenter.id} consumerId={order.consumer.id} condenseUI={true}/>
              </OffCanvasWrapper>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }
    </>
  )
}