//for now, we are hard-coding boutique
import color from 'shared/images/boutique_sm_color.svg';
import white from 'shared/images/boutique_sm_white.svg';
import styles from './logo.module.css'

export const PlanLogoSmall = ({variant}) => {
  const img = variant === 'white'
    ? white
    : color;

  return (
    <img src={img} alt="Boutique" className={styles.sm} />
  );
};