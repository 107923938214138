import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { ItemList } from "shared/components/itemList";
import { SectionHeader } from 'shared/components/form';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { consumerPaths } from "consumer/routes";
import { Section } from "shared/components/form/section";
import { useEffect, useMemo, useState } from "react";
import { GET_ORDERS } from 'consumer/operations/queries/getOrders';
import { DateTime } from 'luxon';
import { OrderType } from "orders/components/orderType";
import { OrderStatus } from "orders/components/orderStatus";
import { OrdersPerPage } from 'orders/constants';
import { OrderComponentMap } from 'orders/components/orderComponentMap';
import styles from './doctorsOrders.module.css';

const statusLookup = {
  [OrderStatus.Open]: 'Open',
  [OrderStatus.Acknowledged]: 'Acknowledged',
  [OrderStatus.Processed]: 'Processed'
};

const typeLookup = {
  [OrderType.Followup]: 'Follow Up',
  [OrderType.Instructions]: 'Instructions',
  [OrderType.Referral]: 'Referral',
  [OrderType.Rx]: 'Rx'
}

export const DoctorsOrdersList = ({consumer}) => {
  const [orders, setOrders] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [typeFilters, setTypeFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const baseFilter = useMemo(() => ({ consumer: { id: { eq: consumer?.id } }}), [consumer?.id]);
  const { loading, data, fetchMore, refetch } = useQueryWrapper(GET_ORDERS, {
    skip: !consumer?.id,
    variables: { 
      filter: {...baseFilter},
      offset: 0
    },
    notifyOnNetworkStatusChange: true
  });

  const handlePageChange = (page) => {
    const offset = ((page - 1) * OrdersPerPage);
    fetchMore({variables: { offset: offset }});
  };

  const toggleFilterCollection = (filterItem, filterCollection, setFilterCollection) => {
    let currentFilterCollection = [...filterCollection];
    let itemToAddIndex = currentFilterCollection.indexOf(filterItem);
    if (itemToAddIndex >= 0) {
      currentFilterCollection.splice(itemToAddIndex, 1);
    } else {
      currentFilterCollection.push(filterItem);
    }

    setFilterCollection(currentFilterCollection);
    setShouldRefetch(true);
  };

  const toggleStatusFilter = (status) => toggleFilterCollection(status, statusFilters, setStatusFilters);
  const toggleTypeFilter = (type) => toggleFilterCollection(type, typeFilters, setTypeFilters);

  useEffect(() => {
    if (!shouldRefetch) return;

    let vars = {};

    if (typeFilters.length > 0) {
      vars = {...vars, type: {in: typeFilters}};
    } 

    if (statusFilters.length > 0) {
      vars = {...vars, status: {in: statusFilters}};
    }

    refetch({ filter: {...baseFilter, ...vars}});
    setShouldRefetch(false);
  }, [statusFilters, typeFilters, refetch, shouldRefetch, setShouldRefetch, baseFilter]);

  useEffect(() => {
    if (!loading && data) {
      setOrders(data.findOrders.items);
      setNumberOfItems(data.findOrders.count);
    }
  }, [data, loading, setNumberOfItems, setOrders])

  return (
    <>
    <Container fluid className={styles.headerContainer}>
      <Row className="justify-content-center">
        <Col className={`text-center my-auto ${styles.title}`}>
          <h1 className="inverse">Doctor's Orders</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5} className={`text-center d-none d-lg-block d-md-block ${styles.headercopy}`}>
          All of your orders, all in one place.
        </Col>
      </Row>
    </Container>
    <Container className={styles.bodyContainer}>
      <Row>
        <Col lg={3}>
          <FilterOrders toggleTypeFilter={toggleTypeFilter} toggleStatusFilter={toggleStatusFilter}/>
        </Col>
        <Col lg={9} className={styles.ordersResults}>
          <ItemList 
            data={orders} 
            detailLink={consumerPaths.doctorsOrders} 
            headerRowElement={OrderHeaderRow}
            dataRowElement={OrderDataRow}
            handlePageChange={handlePageChange}
            itemsPerPage={OrdersPerPage}
            numberOfItems={numberOfItems}
            loading={loading}
          />
        </Col>
      </Row>
    </Container>
    </>
  );
};

const FilterOrders = ({toggleStatusFilter, toggleTypeFilter}) => {
  return (
    <div className={styles.filterContainer}>
      <SectionHeader>
        <h2 className={styles.centerText}>Filters</h2>
        <hr />
      </SectionHeader>
      <Section>
        <Col lg={3}>
          Status
        </Col>
        <Col lg={9}>
          <Form.Check aria-label={OrderStatus.Open} type='checkbox' label={statusLookup[OrderStatus.Open]} onChange={() => toggleStatusFilter(OrderStatus.Open)}/>
          <Form.Check aria-label={OrderStatus.Acknowledged} type='checkbox' label={statusLookup[OrderStatus.Acknowledged]} onChange={() => toggleStatusFilter(OrderStatus.Acknowledged)}/>
          <Form.Check aria-label={OrderStatus.Processed} type='checkbox' label={statusLookup[OrderStatus.Processed]} onChange={() => toggleStatusFilter(OrderStatus.Processed)}/>
        </Col>
        <hr />
        <Col lg={3}>
          Type
        </Col>
        <Col lg={9}>
          <Form.Check aria-label={OrderType.Instructions} type='checkbox' label={typeLookup[OrderType.Instructions]} onChange={() => toggleTypeFilter(OrderType.Instructions)}/>
          <Form.Check aria-label={OrderType.Followup} type='checkbox' label={typeLookup[OrderType.Followup]} onChange={() => toggleTypeFilter(OrderType.Followup)}/>
          <Form.Check aria-label={OrderType.Referral} type='checkbox' label={typeLookup[OrderType.Referral]} onChange={() => toggleTypeFilter(OrderType.Referral)}/>
          <Form.Check aria-label={OrderType.Rx} type='checkbox' label={typeLookup[OrderType.Rx]} onChange={() => toggleTypeFilter(OrderType.Rx)}/>
        </Col>
      </Section>
    </div>
  )
}

const OrderHeaderRow = () => {
  return (
    <Row className={styles.centerText}>
      <Col lg={3}>Date</Col>
      <Col lg={2}>Status</Col>
      <Col lg={3}>Type</Col>
      <Col lg={4}>Order</Col>
    </Row>
  );
}

const OrderDataRow = ({item, index}) => {
  const Component = OrderComponentMap[item.type]

  return (
    <>
    <Row className={styles.centerText}>
      <Col lg={3} aria-label={`order.${index}.date`}>{DateTime.fromMillis(item.date).toLocaleString(DateTime.DATE_MED)}</Col>
      <Col lg={2} aria-label={`order.${index}.status`}>{statusLookup[item.status]}</Col>
      <Col lg={3} aria-label={`order.${index}.type`}>{typeLookup[item.type]}</Col>
      <Col lg={4} className={styles.gridViewOrder}>
        <Component order={item} />
      </Col>
    </Row>
    <hr />
    </>
  );
};