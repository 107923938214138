import { gql } from '@apollo/client';
import { CARE_CENTER_IMPORT_FIELDS } from '../fragments';

export const GET_NETWORK_IMPORTS = gql`
  ${CARE_CENTER_IMPORT_FIELDS}
  
  query FindCareCenterImport($filter: CareCenterImportFilter) {
    findCareCenterImports(filter: $filter) {
      items {
        ...CareCenterImportFields
      }
    }
  }
`;
