import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { SectionHeader } from 'shared/components/form';
import { CaseList } from 'case/components/caseList';
import styles from 'concierge/concierge.module.css';

export const ConciergeCases = () => {
  const account = useCurrentAccount();
  const baseFilter = {
    tenant: { id: { eq: account.tenant.id } }
  };

  return (
    <>
      <SectionHeader>
        <h2 className={styles.centerText}>Concierge Cases</h2>
      </SectionHeader>
      <CaseList baseFilter={baseFilter} />
    </>
  );
};
