import { gql } from '@apollo/client';

export const GET_ACCOUNT_IDS = gql`
  query Query($filter: AccountFilter) {
  findAccounts(filter: $filter) {
    items {
      id
    }
  }
}
`;