import { InMemoryCache, makeVar } from '@apollo/client';
import { messageCaching } from 'message/operations/cache';
import { findCareCenters } from 'careCenter/operations/cache';
import { consumerCaching } from 'consumer/operations/cache';
import { ordersCaching } from 'orders/operations/cache';
import { casesCaching } from 'case/operations/cache';

const initializeEmptyArray = (arr) => ( arr ?? []);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentAccount: {
          read() {
            return currentAccountVar();
          }
        },
        currentAppMode: {
          read() {
            return currentAppModeVar();
          }
        },
        ...casesCaching,
        ...consumerCaching,
        ...findCareCenters,
        ...messageCaching,
        ...ordersCaching
      }
    },
    Account: {
      fields: {
        roles: { read: initializeEmptyArray },
        allowedTenants: { read: initializeEmptyArray }
      }
    },
    Case: {
      fields: {
        audit: { read: initializeEmptyArray }
      }
    },
    Contact: {
      fields: {
        emails: { read: initializeEmptyArray },
        addresses: { read: initializeEmptyArray },
        phoneNumbers: { read: initializeEmptyArray },
      }
    },
    CareCenter: {
      fields: {
        phoneNumbers: { read: initializeEmptyArray },
        amenities: { read: initializeEmptyArray },
        media: { read: initializeEmptyArray }
      }
    },
    Network: {
      fields: {
        integrations: { read: initializeEmptyArray }
      }
    },
    Payer: {
     fields: {
       phoneNumbers: { read: initializeEmptyArray }
     } 
    },
    Plan: {
      fields: {
        networks: { read: initializeEmptyArray }
      }
    }
  }
});

export const currentAccountVar = makeVar();
export const currentAppModeVar = makeVar();