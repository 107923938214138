import { useQuery } from "@apollo/client"
import { GET_NOTES_FOR_CASE } from "case/operations/queries/getNotesForCase"

export const NotesLoader = ({caseId, loadNotes, onNotesLoaded}) => {
  useQuery(GET_NOTES_FOR_CASE, {
    skip: !loadNotes,
    variables: {caseId: caseId},
    onCompleted: (data) => onNotesLoaded(data.findNotes.items)
  });

  return (<></>)
}