import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { telemetryService } from 'telemetry/telemetryService';
import { ErrorBoundary as REBErrorBoundary } from 'react-error-boundary';
import buttonStyles from 'shared/components/buttons/button.module.css'

const ErrorFallBack = ({ resetErrorBoundary}) => {
  const history = useHistory();

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{margin: '40px 20px'}}>
        <Col className="text-center my-auto">
          <h2>Something's not working right</h2> 
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="d-flex justify-content-center">
          We're sorry about that!  Our team is working to fix it.  Please try again later. If the issue continues or you need immediate assistance, contact your Care Concierge.
          <br />
          <br />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="d-flex justify-content-center">
          <button 
            className={buttonStyles.button}
            onClick={() => {
              resetErrorBoundary();
              history.go(-1);
            }}
          >
            Click here to go back and try again
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export const ErrorBoundary = ({children}) => {
  const path = window.location.pathname;

  const handleError =  (error, componentStack) => {
    let props = { path, componentStack };
    console.error(error, componentStack);
    telemetryService.trackException(error, props);
  };

  return (
    <REBErrorBoundary 
      FallbackComponent={ErrorFallBack}
      onError={handleError}
    >
      {children}
    </REBErrorBoundary>
  );
};