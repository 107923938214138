import { gql } from '@apollo/client';
import { CASE_FIELDS } from 'case/operations/fragments';

export const CREATE_CASE = gql`
  ${CASE_FIELDS}
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      ...CaseFields
    }
  }
`;
