import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { appSettings } from '../appSettings';
import { SyncRedactor } from 'basic-redact-pii';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

class appInsightsWrapper { 
    constructor(){
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: `${appSettings.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING}`,
                extensions: [reactPlugin],
                extensionConfig: {
                  [reactPlugin.identifier]: { history: browserHistory }
                }
            }
        });
        this.redactor = new SyncRedactor({
            customRedactors: {
                before: [
                    {
                        regexpPattern: /{(.*?)}/g, // objects in strings
                        replaceWith: '*****OBJECT*****'
                    }
                ]
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(envelope => {
            // traces
            if (envelope.baseData.message) {
                envelope.baseData.message = this.redactor.redact(envelope.baseData.message);
            }

            // exceptions
            if (envelope.baseData.exceptions) {
                for (var x = 0; x < envelope.baseData.exceptions.length; x++) {
                    envelope.baseData.exceptions[x].message = this.redactor.redact(envelope.baseData.exceptions[x].message);
                    envelope.baseData.exceptions[x].stack = this.redactor.redact(envelope.baseData.exceptions[x].stack);
                }
            }
        });
        this.appInsights.addTelemetryInitializer(envelope => {
            envelope.data.app_version = appSettings.APP_VERSION;
        });
    }

    trackEvent(name) {
        this.appInsights.trackEvent({name: name});
    };

    trackException(exception, properties) {
        this.appInsights.trackException({exception: exception, severityLevel: SeverityLevel.Error, properties: properties});
    };

    trackTrace(message) {
        this.appInsights.trackTrace({message: message, severityLevel: SeverityLevel.Information});
    };
};

function initializeTelemetry(){
    telemetryService = new appInsightsWrapper();
}

let telemetryService = null;

export { reactPlugin, telemetryService, initializeTelemetry };