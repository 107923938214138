import { useQuery } from '@apollo/client';
import { GET_NETWORK } from 'network/operations/queries';
import { FaDatabase, FaEnvelope, FaHospital, FaProjectDiagram, FaSitemap } from 'react-icons/fa';
import { useParams } from 'react-router';
import { EditNetworkBasicInfoForm } from 'network/components/edit/editNetworkBasicInfoForm';
import { NetworkMessages } from 'network/components/networkMessages';
import { prepareNetworkForForm } from "network/services/formHelper";
import { DataImportList } from 'network/components/dataImportList';
import { CareCenterList } from 'network/components/careCenterList';
import { TabLayout } from 'shared/components/tablayout';
import { AdminHeader } from 'shared/components/header/adminHeader';
import { Integrations } from 'network/components/edit/editNetworkIntegration';
import { EditNetworkIntegration } from 'network/components/edit/editNetworkIntegration';
import styles from '../network.module.css';

export const EditNetwork = () => {
  const { networkId } = useParams();
  const isNew = networkId === 'new';

  const tabs = [
    {
      key: 'basic',
      title: 'Basic Info',
      icon: FaSitemap,
      default: true,
      component: ({ setBlockNavigation, network, isNew }) => (<EditNetworkBasicInfoForm setBlockNavigation={setBlockNavigation} network={network} isNew={isNew} />)
    },
    {
      key: 'carecenters',
      title: 'Care Centers',
      icon: FaHospital,
      disabled: isNew,
      component: () => (<CareCenterList />)
    },
    {
      key: 'integrations',
      title: 'Integrations',
      icon: FaProjectDiagram,
      disabled: isNew,
      tabs: Object.entries(Integrations).map(([key, value], index) => {
        return {
          key: key,
          title: value.title,
          default: index === 0,
          component: ({ setBlockNavigation, network }) => (<EditNetworkIntegration setBlockNavigation={setBlockNavigation} network={network} integrationType={key} />)
        };
      })
    },
    {
      key: 'dataimport',
      title: 'Data Import',
      icon: FaDatabase,
      disabled: isNew,
      component: () => (<DataImportList />)
    },
    {
      key: 'messages',
      title: 'Messages',
      icon: FaEnvelope,
      forceChildPath: 'secure',
      disabled: isNew,
      component: ({ network }) => (<NetworkMessages network={network} />)
    }
  ];

  const { loading, data } = useQuery(GET_NETWORK, {
    skip: networkId === 'new',
    variables: { id: networkId } 
  });

  let network = data?.getNetwork ? prepareNetworkForForm(data?.getNetwork) : {};
  const headerTitle = isNew ? 'New Network' : network?.name;

  return (
    <>
      { loading && <h1>Loading...</h1> }
      { !loading &&
        <>
          <AdminHeader title={headerTitle} id={network?.id} />
          <div className={styles.tabs}>
            <TabLayout
              tabs={tabs}
              network={network}
              isNew={isNew}
            />
          </div>
        </>
      }
    </>
  )
}