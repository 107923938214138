import { Form } from "react-bootstrap";
import { FloatingLabelControl } from './floatingLabelControl';

export const SimpleDropDown = ({
  items, 
  defaultValue,
  label, 
  size, 
  onSelected,
  disabled=false,
  removeEmptyOption=false
}) => {

  return (
    <FloatingLabelControl label={label}>
         <Form.Control
            className="form-select"
            as="select"
            type="select"
            size={size} 
            defaultValue={defaultValue}
            onChange={onSelected}
            disabled={disabled}
          >
            {!removeEmptyOption && <option value={null}></option>}
            {items.map((item, index) => (
              <option key={index} value={item.value ?? item}>{item.text ?? item}</option>
            ))}
          </Form.Control>
    </FloatingLabelControl>
  )
};