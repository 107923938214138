import { makeVar } from '@apollo/client';
import { AdminCareCentersPerPage } from "careCenter/constants";

const currentOffset = makeVar();

export const findCareCenters = {
  findCareCenters: {

    read(existing) { 
      const offset = currentOffset() || 0;

      return existing?.items && { 
        count: existing.count,
        items: existing.items.slice(offset, offset + AdminCareCentersPerPage) 
      };
    },

    keyArgs: ['filter'],

    merge(existing, incoming, { args }) {
      const offset = args?.page?.offset || 0;
      currentOffset(offset);
      const merged = { 
        count: existing?.count || incoming?.count,
        items: existing?.items ? existing.items.slice(0) : [] 
      };
      if (!incoming.items) return merged;

      for (let i = 0; i < incoming.items.length; ++i) {
        merged.items[offset + i] = incoming.items[i];
      }

      return merged;
    }
  }
}