import { FormProvider, useForm} from "react-hook-form";
import { Button } from "shared/components/buttons/button";
import { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PLAN_PRODUCTS } from "payers/operations/queries";
import { CREATE_PLAN, UPDATE_PLAN } from "payers/operations/mutations";
import { createMutableCopy } from 'apollo/utils';
import { SectionHeader } from "shared/components/form";
import { toastSuccess } from "shared/components/toasts";
import { preparePlanForSave } from "payers/services/formHelper";
import { DropdownList } from "react-widgets";
import { planValidationSchema } from "payers/payerValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useHistory } from "react-router";
import { EditPlan } from "payers/components/edit/editPlan";

export const EditPayerPlansForm = ({payer, plans, isNew, setBlockNavigation}) => {
  const [activePlan, setActivePlan] = useState(plans.length > 0 ? createMutableCopy(plans[0]) : {});
  const [isNewPlan, setIsNewPlan] = useState(isNew);
  const [pageLoaded, setPageLoaded] = useState(false);
  const registerPrefix = 'plan';
  const location = useLocation();
  const history = useHistory();
  const { loading: planProductLoading, data: planProductData } = useQuery(GET_PLAN_PRODUCTS, {
    skip: !isEmpty(activePlan)
  });

  let plan = activePlan;
  let planNames = plans.filter(x => x.name !== activePlan?.name).map(x => x.name);

  const formMethods = useForm({
    defaultValues: {plan},
    mode: 'onChange',
    resolver: yupResolver(planValidationSchema(planNames))
  });

  const onSaved = (data) => {
    toastSuccess(`Plan ${isNewPlan ? 'created' : 'saved'}`);
    let plan = isNewPlan ? data.createPlan : data.updatePlan;

    if (isNewPlan) {
      formMethods.setValue(`${registerPrefix}.id`, plan.id);
      setUrl(plan.id);
      setIsNewPlan(false);
    } else {
      formMethods.reset({plan});
    }
  };

  const mutationQuery = isNewPlan
    ? CREATE_PLAN
    : UPDATE_PLAN;

  const [savePlan] = useMutation(mutationQuery, {
    onCompleted: onSaved
  });

  const onSubmit = async (data) => {
    const input = preparePlanForSave(data.plan);
    await savePlan({ variables: { input: input }});
  };

  const setUrl = useCallback((planId, useReplace=false) => {
    let currentUrlParams = new URLSearchParams(location.search);
    if (currentUrlParams.get('id') !== planId) {
      currentUrlParams.set('id', planId);
      if (useReplace) {
        history.replace(location.pathname + "?" + currentUrlParams.toString());
      } else {
        history.push(location.pathname + "?" + currentUrlParams.toString());
      }
    }    
  }, [history, location.pathname, location.search]);

  const onSelect = useCallback((value) => {
    let plan = createMutableCopy(plans.filter(x => x.name === value)[0]);
    setActivePlan(plan);
    formMethods.reset({plan});
    setIsNewPlan(false);
    setUrl(plan.id);
  }, [formMethods, plans, setUrl]);

  useEffect(() => {
    if (!planProductLoading && planProductData) {
      let plan = createMutableCopy(planProductData.findPlanProducts.items.filter(x => x.title === 'Boutique')[0]);
      plan.intro = plan.intro.replace('<<payer>>', payer.name)
      plan.payer = {id: payer.id};
      plan.tenant = {id: payer.tenant.id};
      plan.product = {id: plan.id};
      delete plan.id;
      setActivePlan(plan)
      formMethods.reset({plan});
      setIsNewPlan(true);
    }
  }, [planProductLoading, planProductData, formMethods, setIsNewPlan, payer])

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      let currentUrlParams = new URLSearchParams(location.search);
      let planId = currentUrlParams.get('id');
      if (planId) {
        let queryPlan = plans.filter(x => x.id === planId);
        if (queryPlan && queryPlan.length > 0) {
          onSelect(queryPlan[0].name);
        }
      } else if (activePlan?.id) {
        setUrl(activePlan.id, true);
      }
    }
  }, [location.search, onSelect, plans, pageLoaded, activePlan, setUrl]);

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields);
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  return (
    <FormProvider {...formMethods}>
      <form name="plan" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Container fluid>
        <SectionHeader>
          <h4>Plans</h4>
        </SectionHeader>
          <DropdownList
            data={planNames}
            onSelect={onSelect}
            value={activePlan.name}
          />
        </Container>
        <EditPlan registerPrefix={registerPrefix} errors={formMethods.formState.errors} isNew={isNewPlan}/>
        <Button type="submit" text={isNewPlan ? 'Create' : 'Save'} />
      </form>
    </FormProvider>
  );
};