import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { FaRegCalendarCheck } from 'react-icons/fa'
import styles from 'consumer/components/todo/todo.module.css';
import { DateTime } from 'luxon';
import { conciergePaths } from "concierge/routes";
import { useCurrentEnrollment } from "enrollment/hooks/useCurrentEnrollment";
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CASES } from 'case/operations/queries/getCases';
import { useLocalStorage, localStorageKeys } from 'shared/hooks/useLocalStorage';
import { useHistory } from "react-router";

export const ReferralOrder = ({order}) => {
  const [, setSurveyData] = useLocalStorage(localStorageKeys.survey);
  const [caseAlreadyCreated, setCaseAlreadyCreated] = useState(false);
  const { consumer, enrollment } = useCurrentEnrollment();
  const history = useHistory();
  const hasIndemnityBenefit = enrollment?.plan?.benefits?.find(x => x.benefit === 'indemnity');
  useQuery(GET_CASES, {
    skip: !hasIndemnityBenefit,
    variables: { filter: { 
      consumer: { id: { eq: consumer?.id }},
      json: { 'context.order': order.id}
    }},
    onCompleted: (data) => { 
      let cases = data.findCases.items;
      if (cases.length > 0) {
        setCaseAlreadyCreated(true);
      }
    }
  });

  const onSurveyLinkClicked = () => { 
    history.push(`${conciergePaths.conciergeSurvey}/hospitalFinder`);
    setSurveyData({
      context: { order: order.id },
      survey: {
        questionAnswers: [1, 0]
      }
    })
  };

  return (
    <Accordion defaultActiveKey="1" className={styles.appointmentAccordion}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.appointmentAccordionHeaderWrapper}>
          <div>
            <Row>
              <Col>
                <FaRegCalendarCheck className={styles.actionIcon} />
                <span>{order.configuration.description}</span>
              </Col>
            </Row>
            <Row>
              <Col className={styles.orderAccordionHeaderDate}>
                {DateTime.fromMillis(order.date).toLocaleString(DateTime.DATE_MED)}
              </Col>
            </Row>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className={styles.appointmentAccordionBody}>
            <Row>
            { hasIndemnityBenefit && !caseAlreadyCreated &&
              <Button onClick={onSurveyLinkClicked} data-testid={`survey-link-${order.id}`} variant='link' className={styles.referralLink}>
                Get help scheduling this referral &nbsp; &#9656;
              </Button>
            } 
            { hasIndemnityBenefit && caseAlreadyCreated &&
              <span>We're on it! Care Concierge will be in touch soon!</span>
            }
            { !hasIndemnityBenefit && 
              <span>Don't forget to schedule an appointment for your referral.</span>
            }
            </Row>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}