import { useState } from "react";
import { Accordion, Col, Nav, Tab, Row } from "react-bootstrap";
import { FaSearchDollar } from 'react-icons/fa';
import styles from './hints.module.css';

export const RxHints = ({drugName}) => {
  const [activeKey, setActiveKey] = useState(1);

  return (
    <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
      <Accordion defaultActiveKey={1}>
        <Row>
          <Col lg={3} md={4} className={styles.hintTabs}>
            <Col lg={12} className={styles.header}>
              <FaSearchDollar className={styles.icon} size={30} />
              <h4>Here’s a few easy ways to save on your {drugName} prescription:</h4>
            </Col>
            <Nav className={`flex-column ${styles.hintnav}`}>
              <HintNav index={1} activeKey={activeKey} title="90-Day Supply" />
              <HintNav index={2} activeKey={activeKey} title="Split Dosage" />
              <HintNav index={3} activeKey={activeKey} title="Lower Cost Alternative" />
            </Nav>
          </Col>
          <Col lg={6} md={6} className={styles.hintContent}>
            <Tab.Content>
              <HintContent
                index={1}
                key={1}
                title="Fill a 90-Day Supply to Save"
                shortTitle="90-Day Supply"
              >
                <p>
                  You may find that filling a 90-day supply will reduce your total cost for this prescription. As an added bonus, you'll make fewer trips to the pharmacy, saving you time and money.
                </p>
                <p>
                  If you have insurance or Medicare, you may find that you receive lower prices if you fill your prescriptions through your plan’s mail order pharmacy. Many insurance plans (and most Medicare plans) are now offering similar rates at a select group of “preferred” retail pharmacies. Some plans may require that you fill through a mail order pharmacy for fills of more than a 30-day supply.
                </p>
                <p>
                  To switch to 90-day fills, note that you'll need a new prescription from your doctor; a 30-day quantity prescription will not allow 90-day fills.
                </p>
              </HintContent>
              <HintContent
                index={2}
                key={2}
                title="Split a Higher Dosage Pill"
                shortTitle="Split Dosage"
              >
                <p>
                  You may be able to save up to 50% each month by splitting a higher dosage of this drug.
                </p>
                <p>
                  Here's how it works: Imagine you take the 20mg dosage of a certain drug. While it may seem odd, the 40mg version of the same drug typically does not cost twice as much as the 20mg—in fact, it often costs the exact same amount!
                </p>
                <p>
                  You'll need to ask your doctor if pill splitting is a good idea for your prescription; if so, they will need to adjust your prescription accordingly.
                </p>
                Some important things to consider before pill splitting:
                <ul>
                  <li>Pill splitters can be bought at most pharmacies for around $5.</li>
                  <li>Some immediate-release tablets may be split, and tablets that are scored have been evaluated by the FDA for safety.</li>
                  <li>Not all pills can be split. Don't split drugs with an enteric coating (designed to protect the stomach), drugs that are time-release or long-acting, drugs taken more often than once a day, drugs in capsules, and prepackaged drugs in specific doses, like birth control pills.</li>
                  <li>Pill splitting is also not recommended for situations where the patient might not understand the concept or be capable of splitting a pill.</li>
                </ul>
                <p>
                  Be sure to check with your doctor or pharmacist when in doubt.
                </p>
              </HintContent>
              <HintContent
                index={3}
                key={3}
                title="Lower Cost Alternative"
                shortTitle="Lower Cost Alternative"
              >
                <p>
                  There may be other prescriptions in the same class (that work in the same way) that could treat your condition just as well at a much lower cost.
                </p>
                <p>
                  If you're taking an expensive brand-name-only drug, it may be worth asking your doctor if there are any other less expensive, generic, or over-the-counter options that might work for you.
                </p>
              </HintContent>
            </Tab.Content>
        </Col>
      </Row>
      </Accordion>
    </Tab.Container>
  );
};

const HintContent = ({index, title, children, shortTitle}) => {

  return (
    <Tab.Pane eventKey={index} className={styles.content}>
      <Accordion.Item eventKey={index} className={styles.accordionContent}>
          <Accordion.Button className={styles.accordionHeader}>
            <HintTile key={index} index={index} activeKey={index} title={shortTitle} />
          </Accordion.Button>
        <Accordion.Body className={styles.accordionBody}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
      <div className={styles.tabtitle}>{title}</div>
      <div className={styles.tabbody}>{children}</div>
    </Tab.Pane>
  );
};


const HintNav = ({index, activeKey, title}) => {

  return (
    <Nav.Item>
      <Nav.Link eventKey={index}>
        <HintTile index={index} activeKey={activeKey} title={title} />
      </Nav.Link>
    </Nav.Item>
  );
};

const HintTile = ({ index, activeKey, title}) => {
  let titleClassName = styles.navlink;
  if (index === parseInt(activeKey)) titleClassName += ` ${styles.navlinkactive}`;
  let pillClassName = `badge pill ${styles.pill}`;
  if (index === parseInt(activeKey)) pillClassName += ` ${styles.pillactive}`;

  return (
    <div className={titleClassName}>
      <span className={pillClassName}>{index}</span>
      {title}
    </div>
  );
};