import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Container, Col, Placeholder, Row } from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { toastSuccess } from 'shared/components/toasts';
import { CloseButton, ArchiveButton, IconSpacer, ReplyButton } from 'shared/components/buttons';
import { GET_RECIPIENT_MESSAGE, GET_SENDER_MESSAGE } from 'message/operations/queries';
import { UPDATE_RECIPIENT_STATUS } from 'message/operations/mutations/updateRecipientStatus';
import { MessageBody } from './messageBody';
import styles from './message.module.css';
import { Reply } from './reply';

export const Message = ({ correspondent, direction }) => {
  let query, variables, message, recipient;
  let { messageId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [isReplying, toggleReplying] = useState(false);

  if (direction === 'IN') {
    query = GET_RECIPIENT_MESSAGE;
    variables = {
      messageId: messageId,
      recipientId: correspondent.id,
      recipientType: correspondent.type,
    };
  } else {
    query = GET_SENDER_MESSAGE;
    variables = {
      messageId: messageId,
      senderId: correspondent.id,
      senderType: correspondent.type
    };
  }

  const { loading, data } = useQuery(query, { variables: variables });
  const [updateStatus] = useMutation(UPDATE_RECIPIENT_STATUS);

  useEffect(() => {
    if (direction === 'IN' 
          && data?.findMessageRecipients
          && data.findMessageRecipients.items[0].status === 'UNREAD') {
      updateStatus({
        variables: {
          id: data.findMessageRecipients.items[0].id,
          status: 'READ'
        }
      });
    }
  }, [data, direction, updateStatus]);

  if (data) {
    if (direction === 'IN') {
      recipient = data.findMessageRecipients.items[0];
      message = recipient.message;
    } else {
      message = data.findMessages.items[0]
    }
  }

  const archiveMessage = () => {
    updateStatus({
      variables: {
        id: recipient.id,
        status: 'ARCHIVED'
      }
    });
    toastSuccess('Message archived!');
  }

  const closeMessage = () => {
    history.push(url.replace(`/${messageId}`, ''));
  };

  return (
    <Container className={styles.messagecontainer}>
      <Row className={styles.messageheader}>
        <Col className="d-flex justify-content-end">
        { direction === 'IN' 
            && !isReplying
            && message?.sender?.type !== 'SYSTEM' &&
          <>
            <ReplyButton inverse={true} size={30} tooltip="Reply" onClick={() => toggleReplying(true)} />
            <IconSpacer />
            <IconSpacer />
          </>
        }
        { direction === 'IN' && recipient?.status !== 'ARCHIVED' &&
          <>
          <ArchiveButton inverse={true} size={30} tooltip="Archive" onClick={archiveMessage} />
          <IconSpacer />
          <IconSpacer />
          </>
        }
          <CloseButton inverse={true} size={30} tooltip="Close" onClick={closeMessage} />
        </Col>
      </Row>

      { isReplying && 
        <Row>
          <Col>
              <Reply 
                message={message}
                correspondent={correspondent}
                onComplete={() => toggleReplying(false)}
              />
          </Col>
        </Row>
      }

      <Row className={styles.subject}>
        <Col>
          <ContentOrLoading loading={loading}>
            {message?.subject}
          </ContentOrLoading>
        </Col>
      </Row>
      <Row>
        { direction === 'IN'
          ? <InCorrespondent data={data} />
          : <OutCorrespondent data={data} />
        }
      </Row>
      <Row>
        <Col className={styles.time}>
          <ContentOrLoading loading={loading}>
            {loading
              ? ''
              : DateTime.fromMillis(message.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
            }
          </ContentOrLoading>
        </Col>
      </Row>
      <Row>
        <Col className={styles.messagetext}>
          <ContentOrLoading loading={loading}>
            <MessageBody text={message?.text} />
          </ContentOrLoading>
        </Col>
      </Row>

    </Container>
  );
};

const OutCorrespondent = ({ data, loading }) => {
  const heading = 'To:';
  const getCorrespondent = data => data.findMessages.items[0].sentTo.map(x => x.name).join(', ');

  return (<Correspondent heading={heading} data={data} getCorrespondent={getCorrespondent} loading={loading} />);
};

const InCorrespondent = ({ data, loading }) => {
  const heading = 'From:';
  const getCorrespondent = data => data.findMessageRecipients.items[0].message.sender.name;

  return (<Correspondent heading={heading} data={data} getCorrespondent={getCorrespondent} loading={loading} />);
};

const Correspondent = ({ heading, data, getCorrespondent, loading }) => {
  const [correspondent, setCorrespondent] = useState();

  useEffect(() => {
    if (data) {
      setCorrespondent(getCorrespondent(data));
    }
  }, [data, getCorrespondent]);

  return (
    <>
      <Col>
        <FaUsers size={30} className={styles.correspondent} />
        <ContentOrLoading loading={loading}>
          {correspondent}
        </ContentOrLoading>
      </Col>
    </>
  );
};

const ContentOrLoading = ({ loading, children }) => { 
  return (
    <>
      { loading
        ? <Placeholder />
        : children
      }
    </>
  );
};