import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CARECENTER } from '../operations/queries/getCareCenter';
import { prepareCareCenterForForm } from 'careCenter/services/formHelper';

export const useCareCenter = (careCenterId) => {
  const [state, setState] = useState({
    loading: undefined,
    careCenter: undefined
  });
  const { loading, data } = useQuery(GET_CARECENTER, { 
    variables: { id: careCenterId }
  });

  useEffect(() => {
    if (loading) setState({loading: true, careCenter: undefined});
  }, [loading]);

  useEffect(() => {
    if (data) {
      const careCenter = prepareCareCenterForForm(data.getCareCenter);
      setState({ loading: false, careCenter: careCenter });
    }
  }, [data]);

  return state;
};