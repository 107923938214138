import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { useCurrentConsumer } from "consumer/hooks/useCurrentConsumer";
import { MessageCenter } from "message/components/messageCenter";

export const AccountMessages = () => {
  const { consumer} = useCurrentConsumer();
  let account = useCurrentAccount();

  if (!consumer) return <>Loading...</>

  const name = consumer.contact.name;
  const correspodent = {
    type: 'ACCOUNT',
    id: account.id,
    name: `${name.first} ${name.last}`
  };

  return (
    <MessageCenter 
      correspondent={correspodent}
    />
  );
};