import { useFormContext } from 'react-hook-form';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { permissions } from 'auth/permissions';
import { AdminTenantSelector } from './adminTenantSelector';

export const TenantSelector = ({ registerPrefix, ignoreIds=[], onSelected }) => {
  const account = useCurrentAccount();
  const registerInput = `${registerPrefix}.tenant.id`;

  return (
    <>
    {account.hasPermission(permissions.admin.super)
      ? <AdminTenantSelector registerInput={registerInput} account={account} ignoreIds={ignoreIds} onSelected={onSelected} />
      : <TenantSetter registerInput={registerInput} account={account} ignoreIds={ignoreIds} onSelected={onSelected} />
    }
    </>
  );
};

const TenantSetter = ({registerInput, account}) => {
  const { setValue } = useFormContext();
  setValue(registerInput, account.tenant.id);

  return (<></>);
};
