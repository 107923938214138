import { Col, Row } from 'react-bootstrap';
import styles from 'integrations/lyric/components/pharmacySelector/pharmacySelector.module.css';
import { FaCheckCircle } from 'react-icons/fa';

export const DisplayPharmacyAddress = ({ pharmacy, className, headerClassName, selected }) => {
  let activeClassName = className ? className : styles.displayPharmacyAddress;
  if (selected) activeClassName = `${activeClassName} ${styles.selectedPharmacy}`;

  return (
    <div className={activeClassName}>
      <Row>
        <Col className={headerClassName}>{pharmacy.storeName}</Col>
        {selected && <Col xs={1} className={styles.selectedIcon} data-testid='selected'><FaCheckCircle size={20} /></Col>}
      </Row>
      <Row>
        <Col>{pharmacy.address} {pharmacy.address2}</Col>
      </Row>
      <Row>
        <Col>{pharmacy.city}, {pharmacy.stateName} {pharmacy.zipCode}</Col>
      </Row>
      <Row>
        <Col>P: {pharmacy.phone}</Col>
      </Row>
    </div>
  )
};

