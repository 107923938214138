import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { useMutationWrapper } from 'apollo/useMutationWrapper';
import { CREATE_ACCOUNT, UPDATE_ACCOUNT } from 'admin/operations/mutations';
import { prepareAccountForSave } from 'admin/services/formHelper';
import { CREATE_CONSUMER, UPDATE_CONSUMER } from 'consumer/operations/mutations';
import { Button } from 'shared/components/buttons';
import { toastSuccess } from 'shared/components/toasts';
import { newAdminUserValidationSchema, existingAdminUserValidationSchema } from 'admin/components/edit/adminUserValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditAccountAllowedTenants } from 'account/components/edit/editAllowedTenants'
import { EditAccountRoles } from 'account/components/edit/editRoles';
import { EditAccountBasicInfo } from 'account/components/edit/editBasicInfo'

export const EditAccountForm = ({isNew, account, afterCreatePath, registerPrefix}) => {
  account = account ?? {roles: [], allowedTenants: [], email: '', tenant: {id: ''} , isAdmin: true, name: {first: '', last: ''}};
  const validationSchema = isNew ? newAdminUserValidationSchema : existingAdminUserValidationSchema;
  const [ accountFormData, setAccountFormData ] = useState(account);
  const [redirectPath, setRedirecPath] = useState();

  const generateBaseConsumerObject = (accountId) => {
    return {
      account: {id: accountId},
      tenant: accountFormData.tenant,
      contact: { 
        name: {first: accountFormData.name.first, last: accountFormData.name.last},
        emails: [{email: accountFormData.email, sendNotifications: true}],
        phoneNumbers: [],
        addresses: []
      },
      dateOfBirth: '',
      pronoun: null,
      privacyPolicyAccepted: false,
      tandcAccepted: false,
      searchTerms: { firstName: accountFormData.name.first.toLowerCase(), lastName: accountFormData.name.last.toLowerCase()},
      type: 'SELF'
    };
  }

  const onSaved = async (data) => {
    if (isNew) {
      const userId = data.createAccount.id;
      const path = afterCreatePath.replace('new', userId);
      await saveConsumer({variables: {input: generateBaseConsumerObject(userId)}});
      setRedirecPath(path);
    }
    toastSuccess(`Admin User ${isNew ? 'created' : 'saved'}`)
  };

  const accountMutationQuery = isNew
    ? CREATE_ACCOUNT
    : UPDATE_ACCOUNT;

  const [saveAccount, { loading }] = useMutationWrapper(accountMutationQuery, {
    onCompleted: onSaved
  });

  const consumerMutationQuery = isNew
    ? CREATE_CONSUMER
    : UPDATE_CONSUMER;

  const [saveConsumer] = useMutationWrapper(consumerMutationQuery, { });

  const formMethods = useForm({
    defaultValues: {account},
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    setAccountFormData({...data.account});
    const input = prepareAccountForSave(data.account);
    await saveAccount({ variables: { input: input }});
  };

  if (loading) return (<h1>Saving...</h1>);

  if (redirectPath) return (<Redirect to={redirectPath} />);

  return (
    <Container fluid>
      <FormProvider {...formMethods}>
        <form name="account" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <EditAccountBasicInfo registerPrefix={registerPrefix} isNew={isNew} errors={formMethods.formState.errors} />
          <hr />
          <EditAccountRoles isNew={isNew} registerPrefix={registerPrefix} errors={formMethods.formState.errors} />
          <hr />
          <EditAccountAllowedTenants />
          <hr />
          <Button type="submit" text={isNew ? 'Create' : 'Save'} />
        </form>
      </FormProvider>
    </Container>
  );
};
