import { useMutationWrapper } from 'apollo/useMutationWrapper';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAllTags } from 'admin/operations/useAllTags';
import { UPDATE_CASE } from 'case/operations/mutations/updateCase';
import { BoxLoading } from 'shared/components/spinners/boxLoading';
import { TagList } from "../tagList";
import { TagSelector } from "../tagSelector";
import styles from 'case/case.module.css';

export const CaseTags = ({ caseData, dispatch }) => {
  const { tags, loading: tagsLoading } = useAllTags();
  const [updateCase] = useMutationWrapper(UPDATE_CASE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => dispatch({type: 'case', caseData: data.updateCase})
  });
  const [currentTags, setCurrentTags] = useState(caseData.tags);

  console.log(tags);

  const onTagAdded = (tag) => {
    const newTags = [...currentTags, tag];
    saveTags(newTags);
    setCurrentTags(newTags);
  };

  const onTagRemoved = (tag) => {
    const newTags = currentTags.filter(t => t !== tag);
    saveTags(newTags);
    setCurrentTags(newTags);
  };

  const saveTags = (newTags) => {
    updateCase({ variables: { input: { id: caseData.id, tags: newTags }}});
  };

  return (
    <>
    {
      tagsLoading 
      ? <BoxLoading width={50} height={50} viewBox="0 0 50 50" />
      : <Container>
          <Row>
            <Col>
              <TagSelector
                currentTags={currentTags}
                allTags={tags}
                onAdd={onTagAdded}
              />
            </Col>
          </Row>
          <Row>
            <Col className={styles.tags}>
              <TagList
                currentTags={currentTags}
                allTags={tags}
                onRemove={onTagRemoved}
              />
            </Col>
          </Row>
        </Container>
    }
    </>
  );
};