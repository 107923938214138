import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { getError } from "shared/services/validationService";

export const TextArea = ({
  placeholder,
  inputRef,
  registerInput,
  errors,
  className
}) => {
  const { register } = useFormContext();

  const getInputError = () => getError(registerInput, errors);

  return (
    <>
      <Form.Control
        as="textarea"
        ref={inputRef}
        placeholder={placeholder}
        isInvalid={getInputError()}
        className={className}
        {...register(registerInput)}
      />
      <Form.Control.Feedback type="invalid">
        {getInputError()?.message}
      </Form.Control.Feedback>
    </>
  )
};