import { useQueryWrapper } from "apollo/useQueryWrapper";
import { GET_APPOINTMENTS } from 'consumer/operations/queries/getAppointments';
import { DateTime } from "luxon";

export const AppointmentsLoader = ({consumerId, onAppointmentsLoaded}) => {
  useQueryWrapper(GET_APPOINTMENTS, {
    skip: !consumerId,
    variables: { 
      filter: {
        consumer: { id: { eq: consumerId } },
        status: { in: ['OPEN']},
        date: { ge: DateTime.now().toISODate() }
      },
      offset: 0
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => onAppointmentsLoaded(data.findAppointments.items)
  })

  return (<></>)
}