import { useCurrentAccount } from 'auth/operations/useCurrentAccount';

export const SecuredMenuItem = ({minimumPermission, children}) => {
  const account = useCurrentAccount();
  const allowed = account.hasPermission(minimumPermission);

  return (
    <>
    {allowed && children}
    </>
  );
};