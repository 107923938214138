import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_NETWORK_IMPORTS } from "network/operations/queries";
import { ItemList } from "shared/components/itemList";
import { useParams, useLocation } from "react-router";
import { useState, useEffect, useCallback } from 'react';
import styles from 'network/network.module.css';
import { DataImport } from "./dataImport";
import buttonStyles from 'shared/components/buttons/button.module.css';
import { MediaDragAndDropUpload } from "shared/components/dragAndDropUpload";

export const DataImportList = () => {
  const { networkId } = useParams();
  const shouldSkipQuery = networkId === 'new'

  const { loading, data } = useQuery(GET_NETWORK_IMPORTS, {
    skip: shouldSkipQuery,
    variables: { 
      filter: {
        network: {id : { eq:  networkId } }
      }
    }
  });

  let importControls = data?.findCareCenterImports?.items ?? [];

  return (
    <Container fluid>
      { shouldSkipQuery && <h1>Network Not Found.</h1>}
      { loading && <h1>Loading...</h1> }
      { !loading && importControls.length === 0 
        ? <div>No results found</div>
        : <ItemList data={[...importControls].sort((a,b) => new Date(b.identifier) - new Date(a.identifier))} 
                    headerRowElement={DataImportHeaderRow}
                    dataRowElement={DataImportDataRow}
          />
      }
    </Container>
  );
};

const DataImportHeaderRow = () => {
  return (
    <Row className={styles.importHeaderRow}>
      <Col md={2} style={{paddingLeft: '20px'}}>External Source</Col>
      <Col md={2}>Identifier</Col>
      <Col md={1}>New</Col>
      <Col md={1}>Imported</Col>
      <Col md={1}>Errored</Col>
      <Col md={4} style={{textAlign: 'center'}}>Actions</Col>
    </Row>
  );
}

const DataImportDataRow = ({item, index}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [activeEventKey, setActiveEventKey] = useState("-1");
  const [isVisible, setIsVisible] = useState(false);
  const [shouldDownload, setShouldDownload] = useState(false);
  const { networkId } = useParams();
  const location = useLocation();
  const uploadRoot = `network/${networkId}/${item.id}`;
  let buttonClassNames = `${buttonStyles.button} ${buttonStyles.buttonPrimary} ${styles.contactButton}`;

  const onClick = useCallback(() => {
    if (isVisible) {
      setActiveEventKey("-1");
    } else {
      setActiveEventKey("0");
    }
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible, setActiveEventKey]);

  const onDownloadClicked = (event) => {
    event.stopPropagation();
    setShouldDownload(true);
  }

  const onDownloadCompleted = () => {
    setShouldDownload(false);
  }

  const onUploadClicked = (event) => {
    event.stopPropagation();
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      let currentUrlParams = new URLSearchParams(location.search);
      let selectedImport = currentUrlParams.get('importId');
      if (selectedImport === item.id) {
        onClick();
      }
    }
  }, [isInitialized, setIsInitialized, item.id, location.search, onClick]);

  return (
    <Accordion className={styles.importAccordion} activeKey={activeEventKey}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.importAccordionHeader} onClick={onClick}>
          <Col md={2} aria-label={`import.${index}.externalSource`}>{`${item.externalSource.type} - Practice ID: ${item.externalSource.id}`}</Col>
          <Col md={2} aria-label={`import.${index}.identifier`}>{item.identifier.split('T')[0]}</Col>
          <Col md={1} aria-label={`import.${index}.newRecords`}>{item.records.filter(x => x.status.toLowerCase() === 'new').length}</Col>
          <Col md={1} aria-label={`import.${index}.importedRecords`}>{item.records.filter(x => x.careCenter).length}</Col>
          <Col md={1} aria-label={`import.${index}.errorRecords`}>{item.records.filter(x => x.status.toLowerCase() === 'error').length}</Col>
          <Col md={2} aria-label={`import.${index}.download`} style={{display: 'flex'}} className={'justify-content-center'}>
            <div className={`${buttonClassNames} text-center`} onClick={(e) => onDownloadClicked(e)}>
              Download
            </div>
          </Col>
          <Col md={2} aria-label={`import.${index}.upload`} style={{display: 'flex'}} className={'justify-content-center'}>
            <div onClick={(e) => onUploadClicked(e)} className={styles.dataImportDragAndDropUpload} >
              <MediaDragAndDropUpload 
                uploadRoot={uploadRoot}
                acceptedMediaTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
              </div>
          </Col>
        </Accordion.Header>
        <Accordion.Body>
          <DataImport importId={item.id} shouldDownloadRecords={shouldDownload} downloadCompleteCallback={onDownloadCompleted} visible={isVisible}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
