import { Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { Input, Section, SectionHeader, TextArea } from 'shared/components/form';
import { EditSingleAddress, EditPhoneNumberList } from 'shared/components/contact';
import { getError } from 'shared/services/validationService';
import { TenantSelector } from 'tenant/components/tenantSelector';
import { EditCareCenterAmenities } from './editAmenities';
import { EditExistingCareCenterForm, ProfileForm } from './editForm';
import { EditHeader } from './editHeader';
import styles from './edit.module.css';

export const EditCareCenterBasicInfo = (props) => {
  const { careCenterId } = useParams();
  const isNew = careCenterId === 'new';

  return (
    <>
    { isNew
      ? <NewCareCenterProfile 
          {...props}
        />
      : <EditExistingCareCenterForm
          component={EditCareCenterBasicInfoForm} 
          {...props}
        />

    }
    </>
  );
};

const NewCareCenterProfile = ({ setBlockNavigation }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const onSaved = (data) => {
      const careCenterId = data.createCareCenter.id;
      const path = pathname.replace('new', careCenterId);
      history.push(path);
  };

  return (
    <ProfileForm 
      isNew={true} 
      component={EditCareCenterBasicInfoForm} 
      setBlockNavigation={setBlockNavigation} 
      onSaved={onSaved}
    />
  );
};

const EditCareCenterBasicInfoForm = ({ registerPrefix, errors }) => {
  registerPrefix = registerPrefix ?? 'careCenter';
  const { control, setValue, register } = useFormContext();

  const getAddressError = () => {
    return getError(`${registerPrefix}.address`, errors);
  };

  const getTenantError = () => {
    return getError(`${registerPrefix}.tenant`, errors);
  };

  const onAddressSelected = () => {
    setValue(`${registerPrefix}.address.type`, ['MAILING', 'BILLING', 'PHYSICAL']);
  };

  return (
    <Container fluid>
      <EditHeader title="Basic Info" />
      <Row>
        <Col lg={6}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <Input 
                autoFocus={true}
                label="Name"
                registerInput={`${registerPrefix}.name`} 
                errors={errors}
              />
            )}
          />
        </Col>
        <Col lg={4}>
          <TenantSelector registerPrefix={registerPrefix} />
          {getTenantError()?.message &&
            <div className={`invalid-feedback ${styles.showValidationError}`}>
              {getTenantError()?.message}
            </div>
          }
        </Col>
        <Col lg={2} className={styles.isPublishedCheckBox}>
          <Form.Check type="checkbox" name="isPublished" >
            <Form.Check.Label style={{marginRight: '5px'}}>
              Is Published:
            </Form.Check.Label>
            <Form.Check.Input {...register(`${registerPrefix}.isPublished`)} type="checkbox" />
          </Form.Check>
        </Col>
      </Row>
      <SectionHeader><h4>Address</h4></SectionHeader>
      <Section>
        <EditSingleAddress
          registerPrefix={`${registerPrefix}.address`}
          allowCancelOnNew={false}
          showDelete={false}
          autoFocus={false}
          onAddressSelected={onAddressSelected}
        />
        {getAddressError()?.message &&
          <div className={`invalid-feedback ${styles.showValidationError}`}>
            {getAddressError()?.message}
          </div>
        }
      </Section>
      <Controller
        control={control}
        name='controller'
        render = {({ ref}) => (
         <EditPhoneNumberList 
            inputRef={ref} 
            registerPrefix={registerPrefix}
            errors={errors}
          />
        )}
      />
      <SectionHeader><h4>Overview</h4></SectionHeader>
      <Section>
        <Controller
          name="controller"
          control={control}
          render={({ ref }) => (
            <TextArea 
              className={styles.profileText}
              registerInput={`${registerPrefix}.overview`} 
              errors={errors}
            />
          )}
        />
      </Section>
      <SectionHeader><h4>Terms & Conditions</h4></SectionHeader>
      <Section>
        <Controller
          name="controller"
          control={control}
          render={({ ref }) => (
            <TextArea 
              className={styles.profileText}
              registerInput={`${registerPrefix}.termsAndConditions`} 
              errors={errors}
            />
          )}
        />
      </Section>
      <SectionHeader><h4>Amenities</h4></SectionHeader>
      <Section>
        <EditCareCenterAmenities registerPrefix={registerPrefix} />
      </Section>
    </Container>
  );
};