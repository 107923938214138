import { gql } from '@apollo/client';

export const FIND_CARECENTERS = gql`
  query FindCareCenters($filter: CareCenterFilter) {
    findCareCenters(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;