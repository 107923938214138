import { Col, Container, Row } from 'react-bootstrap';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { AppointmentList } from 'consumer/components/clinical/appointments';
import styles from './clinical.module.css';

export const Appointments = () => {
  const { consumer } = useCurrentConsumer();

  return (
    <>
    <Container fluid className={styles.headerContainer}>
      <Row className="justify-content-center">
        <Col className="text-center my-auto">
          <h1 className="inverse">Appointments</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5} className="text-center d-none d-lg-block d-md-block">
          All of your appointments, all in one place.
        </Col>
      </Row>
    </Container>
    <AppointmentList consumer={consumer} />
    </>
  );
};