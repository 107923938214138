import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchButton } from 'shared/components/buttons';
import { Input } from './input';

export const Search = ({ placeHolder, onSearch, minimumSearchLength=3 }) => {
  const { search } = useParams();
  placeHolder = placeHolder ?? "Search";
  const validationSchema = Yup.object().shape({
    searchValue: Yup.string().min(minimumSearchLength, `Please enter at least ${minimumSearchLength} characters to search`)
  });
  const formMethods = useForm({
    defaultValues: { searchValue: search},
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data, e) => {
    onSearch(data.searchValue);
  };
  
  return (
    <FormProvider {...formMethods}>
      <form name="search" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col lg={5} md={5} sm={8} xs={8} className="align-self-center">
          <Input
            type="search"
            label={placeHolder}
            autoFocus={true}
            registerInput="searchValue"
            errors={formMethods.formState.errors}
          />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="align-self-center">
          <SearchButton type="submit" tooltip={placeHolder} />
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};