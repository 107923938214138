import { gql } from '@apollo/client';
import { CARECENTER_FIELDS } from '../fragments';

export const UPDATE_CARECENTER = gql`
  ${CARECENTER_FIELDS}
  mutation UpdateCareCenter($input: MutateCareCenterInput!) {
    updateCareCenter(input: $input) {
      ...CareCenterFields
    }
  }
`;