import styles from './button.module.css';
import ReactToolTip from 'react-tooltip';

export const IconButton = ({ 
    IconComponent, 
    iconClass,
    size,
    type, 
    tooltip, 
    onClick, 
    testid, 
    disabled }) => {
  type = type ?? 'button';
  size = size ?? 30;

  return (
    <button 
      data-testid={testid}
      aria-label={tooltip}
      type={type} 
      disabled={disabled}
      className={styles.iconButton} 
      data-tip={tooltip}
      onClick={onClick}>
        <IconComponent 
            className={disabled ? 'disabled': iconClass} 
            size={size} />
        <ReactToolTip />
    </button>
  )
};