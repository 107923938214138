import { Col, Container, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import { DisplayAddress } from "shared/components/contact";
import styles from 'benefits/dpc/dpc.module.css';
import { DropDown, TextArea } from "shared/components/form";
import { CareCenterMap } from "shared/components/maps/googleMap";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useMutationWrapper } from "apollo/useMutationWrapper";
import { CREATE_REQUEST_APPOINTMENT } from "benefits/dpc/operations/mutations/createAppointmentRequest";
import { CREATE_CASE } from "case/operations/mutations/createCase";
import { useCurrentConsumer } from "consumer/hooks/useCurrentConsumer";
import { getFormattedDistanceInMiles } from 'careCenter/services/careCenterDistanceService'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateRangePickerWithPreview } from 'shared/components/dateRangePickerWithPreview';
import { DropdownList } from 'react-widgets/cjs';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { getError } from "shared/services/validationService";

export const RequestAppointmentForm = ({appointmentType, careCenterResult, context, onAppointmentRequestCreated}) => { 
  context = context ?? {}; 
  const { consumer } = useCurrentConsumer(); 
  const defaultPreferredDates = "mm/dd/yyyy to mm/dd/yyyy";
  const [preferredDates, setPreferredDates] = useState(defaultPreferredDates);

  const preferredTimes = ['8 am - Noon', 'Noon - 3 pm', '3 pm - Close'];
  const location = {
    lat: careCenterResult.careCenter.address.geometry.coordinates[1],
    lng: careCenterResult.careCenter.address.geometry.coordinates[0]
  };

  const [createAppointmentRequest] = useMutationWrapper(CREATE_REQUEST_APPOINTMENT);
  const [createCase] = useMutationWrapper(CREATE_CASE);

  const getAppointmentTypes = (appointmentType) => {
    switch(appointmentType) {
      case 'dpc':
        return ['Wellness', 'Sickness'];
      default:
        return [];
    }
  };

  const appointmentTypes = getAppointmentTypes(appointmentType);

  const validationSchema = Yup.object({
    preferredDates: Yup.string().required('Please select some preferred dates')
                                .notOneOf([defaultPreferredDates], 'Please select some preferred dates'),
    preferredTime: Yup.string().required('Please select a preferred time'),
    appointmentType: Yup.string().required('Please select an  type')
  });

  const formMethods = useForm({
    defaultValues: {
      additionalNotes: '',
      appointmentType: appointmentTypes[0],
      preferredDates: preferredDates,
      preferredTime: preferredTimes[0]
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    const input = {
      ...data,
      careCenter: {id: careCenterResult.careCenter.id},
      consumer: {id: consumer.id},
      tenant: {id: consumer.tenant.id},
    };

    const response = await createAppointmentRequest({variables: {input: input}});
    await createCase({variables: { input: {
      status: 'NEW',
      title: `${appointmentType.toUpperCase()} Appointment Request`,
      consumer: {id: consumer.id},
      tenant: {id: consumer.tenant.id},
      context: {...context,  appointmentRequest: response.data.createAppointmentRequest.id },
      tags: ['AppointmentRequest', 'DPC']
    }}});
    onAppointmentRequestCreated({createdAppointmentRequest: input});
  };

  const onDateChange = (date) => {
    const formattedDate = `${DateTime.fromJSDate(date.startDate).toFormat('MM/dd/yyyy')} to ${DateTime.fromJSDate(date.endDate).toFormat('MM/dd/yyyy')}`;
    formMethods.setValue('preferredDates', formattedDate);
    setPreferredDates(formattedDate);
  };

  const distance = getFormattedDistanceInMiles(careCenterResult.distance);

  useEffect(() => {
    if (preferredDates !== defaultPreferredDates) {
      formMethods.trigger(['preferredDates']);
    }
  }, [formMethods.trigger, preferredDates])

  return (
    <Container>
      <Row>
        <CareCenterMap initialZoom={16} startLocation={location} />
      </Row>
      <Row>
        <Col className={`${styles.hospitalcarddetails}`}>
          <Row>
            <Col className={styles.namelink}>{careCenterResult.careCenter.name}</Col>
          </Row>
          <Row>
            <Col className={styles.address}>
              <DisplayAddress address={careCenterResult.careCenter.address} lines={2} />
            </Col>
          </Row>
          { !distance.includes('NaN') && 
            <Row>
              <Col data-testid="distance" className={styles.distance}>{distance}</Col>
            </Row>
          }
        </Col>
      </Row>
      <hr style={{margin: '20px 30px'}}/>
      <div className={styles.requestAppointmentForm}>
      <FormProvider {...formMethods}>
        <form name="account" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Row>
            <OverlayTrigger 
              container={this}
              trigger='click'
              rootClose={true}
              overlay={
                <Popover className={styles.datePickerPopover}>
                  <Popover.Body style={{padding: 0}}>
                    <DateRangePickerWithPreview onChange={onDateChange} onClose={() => document.body.click()} className={styles.datePicker} />
                  </Popover.Body>
                </Popover>
              }
            >
              <div className={styles.preferredDatesLabel}>
                <FloatingLabelControl label='Preferred date range' for='date-range-dropdown'>
                  <DropdownList
                    aria-label='Preferred Date'
                    id='date-range-dropdown'
                    value={preferredDates}
                    open={false}
                  />
                  <div className={`invalid-feedback`} style={{display: 'block'}}>
                    {getError('preferredDates', formMethods.formState.errors)?.message}
                  </div>
                </FloatingLabelControl>
              </div>
            </OverlayTrigger>
          </Row>
          <Row>
            <DropDown label="Preferred time of day" registerInput='preferredTime' items={preferredTimes} errors={formMethods.formState.errors}/>
          </Row>
          <Row>
            <DropDown label="Type of appointment" registerInput='appointmentType' items={appointmentTypes} errors={formMethods.formState.errors}/>
          </Row>
          <Row className={styles.requestAppointmentAdditionalNotes}>
            <Col>
              <Controller
                name="controller"
                control={formMethods.control}
                render={() => (
                  <TextArea 
                    placeholder="Additional notes"
                    registerInput='additionalNotes' 
                    errors={formMethods.formState.errors}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className={styles.requestAppointmentSubmit}>
            <Button type="submit" variant="primary" text="Request Appointment" />
          </Row>
        </form>
      </FormProvider>
      </div>
    </Container>
  )
}