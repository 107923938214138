import { Col, Container, Row } from 'react-bootstrap';
import { SideBar } from './sidebar/sidebar';
import { Title } from './title';
import styles from 'case/case.module.css';
import React from 'react';

export const DetailBase = ({caseData, contents, filter, dispatch}) => {

  return (
    <Container fluid className={styles.container}>
      <Row className="h-100">
        <Col md={10}>
          <Row>
            <Col className={styles.titlecontainer}>
              <Title caseData={caseData} filter={filter} dispatch={dispatch} />
            </Col>
          </Row>
          <Row>
            <Col>{contents}</Col>
          </Row>
        </Col>
        <Col md={2} className={styles.sidebarcontainer}>
          <SideBar caseData={caseData} dispatch={dispatch} />
        </Col>
      </Row>
    </Container>
  );
};