import * as Yup from 'yup';

export const networkBasicInfoValidationSchema = Yup.object().shape({
  network: Yup.object().shape({
    name: Yup.string().required('Name required').nullable(),
    tenant: Yup.object().test('tenant', 'Tenant required', 
      tenant =>  tenant?.id?.length > 0).nullable()
  })
});

export const networkIntegrationValidationSchema = Yup.object().shape({
  integration: Yup.object().shape({
  id: Yup.string().required('ID required').nullable(),
  type: Yup.string().required('Type required').nullable()
  })
})