import * as Yup from 'yup';

export const newAdminUserValidationSchema = Yup.object().shape({
  account: Yup.object().shape({
    email: Yup.string().required('Email required').nullable(),
    name: Yup.object().shape({
      first: Yup.string().required('First Name required').nullable(),
      last: Yup.string().required('Last Name required').nullable(),
    }),
    tenant: Yup.object().test('tenant', 'Tenant required', 
      tenant =>  tenant?.id?.length > 0).nullable(),
    roles: Yup.array().test('roles', 'At least one role is required', 
      roles =>  roles?.length > 0).nullable()
  })
});

export const existingAdminUserValidationSchema = Yup.object().shape({
  account: Yup.object().shape({
    tenant: Yup.object().test('tenant', 'Tenant required', 
      tenant =>  tenant?.id?.length > 0).nullable(),
    roles: Yup.array().test('roles', 'At least one role is required', 
      roles =>  roles?.length > 0).nullable()
  })
});