import { useParams, Link } from 'react-router-dom';
import { adminPaths } from 'admin/routes';
import { telehealthPaths } from 'benefits/telehealth/routes';
import { conciergePaths } from 'concierge/routes';
import parse, { domToReact } from 'html-react-parser';

export const MessageBody = ({text}) => {
  let { networkId } = useParams();
  const linkRegExGlobal = /##(.*?)##/g;
  const linkRegExReplaceFirst = /##(.*?)##/;

  const determineLink = (type, value) => {
    switch (type) {
      case 'careCenterImport': 
        return `${adminPaths.networks}/${networkId}/dataimport?importId=${value}`;
      case 'case':
        return `${conciergePaths.conciergeCases}/${value}`;
      case 'conciergeConsumer':
        return `${conciergePaths.conciergeConsumers}/${value}`;
      case 'redirect':
        switch (value) {
          case 'lyric':
            return telehealthPaths.lyricRedirect;
          default:
            throw new Error(`Redirect for '${value}' is not supported.`);
        }
      default:
        throw new Error(`Type '${type}' is not a known type.`);
    }
  };

  if (text) {
    if (linkRegExGlobal.test(text)) {
      let matches = text.match(linkRegExGlobal);
      for (let x = 0; x < matches.length; x++) {
        let result = matches[x];
        result = result.replaceAll('#', '');
        let parts = result.split('=');
        let link = determineLink(parts[0], parts[1]);
        text = text.replace(linkRegExReplaceFirst, link);
      }
    }
    
    let html = parse(text, {
      replace: domNode => {
        if (domNode.name === 'a') {
          return (
            <Link to={domNode.attribs.href} target={domNode.attribs.target}>
              {domToReact(domNode.children, domNode.options)}
            </Link>
          )
        }
      }
    });

    return html;
  }
};