import { Table } from "react-bootstrap";
import { Link } from 'react-router-dom';

export const CareCenterSearchList = ({careCenters, detailLink}) => {

  if (!careCenters) return (<></>);

  return (
    <Table striped>
      <tbody>
        {careCenters.map((careCenter) => (
          <tr key={careCenter.id}>
            <td>
              <Link to={`${detailLink}/${careCenter.id}`}>{careCenter.name}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};