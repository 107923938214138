import { Container, Col, Row } from "react-bootstrap";
import { benefitService } from "benefits/services/benefitService";

export const YouGetList = ({enrollment}) => {
  const app = benefitService(enrollment.plan.benefits);
  const cards = Object.values(app.getYouGetCards());

  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>You Get</h2>
        </Col>
      </Row>
      <Row md={2}>
        {cards.map((Card, i) => (<Card key={`card_${i}`} enrollment={enrollment} />))}
      </Row>
    </Container>
  );
};