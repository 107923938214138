import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import buttonStyles from 'shared/components/buttons/button.module.css';
import styles from '../rx.module.css';

export const Help = ({header, copy, icon, phoneNumber}) => {
  const HelpIcon = icon;

  return (
    <>
      <Row className="justify-content-center">
        <Col className={`${styles.helpSection} text-center`}>
          <h4>{header}</h4>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`${styles.helpSection} text-center`}>
          <HelpIcon size={30} className={styles.modalIcon} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`${styles.helpSection} text-center ${styles.copy}`}>
          {copy}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`${styles.helpSection} text-center`}>
          <ContactButton phoneNumber={phoneNumber}/>
        </Col>
      </Row>
    </>
  );
};

export const ContactButton = ({ phoneNumber }) => {
  const [dial, setDial] = useState('');
  let classNames = `${buttonStyles.button} ${buttonStyles.buttonSecondary} ${styles.contactButton}`;

  useEffect(() => {
    if (phoneNumber) {
      setDial(`+1${phoneNumber.replace(/[^0-9]+/g, '')}`);
    }
  }, [phoneNumber])
  
  return (
      <a className={classNames} href={`tel:${dial}`}>Call {phoneNumber}</a>
  );
};