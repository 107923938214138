import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import styles from '../rx.module.css';
import "react-widgets/styles.css";
import { useEffect, useState } from 'react';
import buttonStyles from 'shared/components/buttons/button.module.css';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import { telemetryService } from 'telemetry/telemetryService';
import { useLocation, useHistory } from "react-router-dom";
import { BoxLoading } from 'shared/components/spinners/boxLoading';
import { formatPrice } from './helpers';
import { rxPaths } from '../routes';

export const PrescriptionPriceResults = ({consumer, drugName, items, loading, searchErrors, searchVariables}) => {
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const noPricesFoundError = 'No prices found for this drug';
  const history = useHistory();
  const location = useLocation();

  const generateDisplayAddress = () => {
    if (consumer) {
      let address = consumer.contact.addresses[0];
      return `${address.city}, ${address.state} (${address.postalCode})`;
    } else {
      return '';
    }
  };

  const goToCouponUrl = (pharmacy) => {
    let currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set('pharmacy', pharmacy);
    currentUrlParams.set('postalCode', consumer.contact.addresses[0].postalCode);
    history.push(`${rxPaths.prescriptionCoupon}/${drugName}?${currentUrlParams.toString()}`);
  };

  useEffect(() => {
    setResults(items.prices);
  }, [items]);

  useEffect(() => {
    if (searchErrors.length > 0) {
      if (searchErrors[0].message === noPricesFoundError) {
        setErrorMessage('Sorry, no prices found.');
      } else {
        telemetryService.trackException(searchErrors[0]);
        setErrorMessage('Sorry, something went wrong.');
      }
    } else {
      setErrorMessage('');
    }
  }, [searchErrors]);

  return (
    <>
    { loading && 
      <Row className={styles.prescriptionResultsLoading}>
        <div className={styles.loadingContainer}>
          <BoxLoading width={330} height={330} />
        </div>
      </Row>
    }
    { !loading && errorMessage.length > 0 &&
      <Row className={styles.prescriptionResultsError}>
        <div>
          {errorMessage}
        </div>
      </Row>
    }
    { !loading && !results && errorMessage.length === 0 &&
      <Row className={styles.prescriptionResultsEmpty}>
        <div className={styles.prescriptionResultInitialText}>
          To find valuable coupons and discounts for your {drugName} prescription, enter your prescription details.
        </div>
      </Row>
    }
    { !loading && results && 
      <Row className={styles.prescriptionResults}>
        <Row className={styles.lowestPricesRow}>
          <Col lg={3} md={5} xs={12} className={styles.lowestPricesNear}>
            Lowest prices near
          </Col>
          <Col className={styles.lowestPricesNearLocation}>
          <FaLocationArrow /><a href='#'> {generateDisplayAddress()}</a>
          </Col>
        </Row>
        <Row className={styles.prescriptionReminder}>
          <FaExclamationCircle />
          <span className={styles.prescriptionReminderLine1}>Don’t forget your prescription!</span>
          <span className={styles.prescriptionReminderLine2}>When you visit the pharmacy, you must present the coupon and your prescription.</span>
        </Row>
        {results && results.map((item, index) => (
          <Row key={index} className={styles.resultsRow}>
            <Col lg={5} md={2} xs={12} className={styles.resultsPharmacy}>
              {item.pharmacy}
            </Col>
            <Col lg={2} md={2} xs={6} className={styles.resultsCol}>
              {!item.retailPrice 
                ? null
                : <>
                    <Row className={styles.resultsRetailPrice}>
                      {`$${item.retailPrice} retail`}
                    </Row>
                    <Row className={styles.resultsSavings}>
                      {`Save ${item.savings}`}
                    </Row>
                  </> 
              }
            </Col>
            <Col lg={2} md={3} xs={6} className={styles.resultsCol}>
              <Row className={styles.resultsPrice}>
              <span className={styles.dollarSign}>$</span>{formatPrice(item.price)}
              </Row>
              <Row className={styles.resultsPriceSubScript}>
                {item.type === 'CASH'
                  ? 'retail price'
                  : 'with free coupon'
                }
              </Row>
            </Col>
            <Col lg={3} md={3} xs={12} className={styles.resultsCol}>
              {item.type === 'CASH'
                ? <LearnMore item={item} searchVariables={searchVariables}/>
                : <button className={`${styles.getCouponButton} ${buttonStyles.button} ${buttonStyles.buttonSecondary}`}
                          onClick={() => goToCouponUrl(item.pharmacy)}    
                  >
                    Get coupon
                  </button>
              }
            </Col>
          </Row>
        ))}
      </Row>
    }
    </>
  )
}

const LearnMore = ({item, searchVariables}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let popoverPlacement = screenWidth <= 768 ? 'top' : 'left';
  let searchVars = searchVariables?.filter;
  return (
    <OverlayTrigger
      trigger={[ 'click' ]}
      placement={popoverPlacement}
      rootClose
      overlay={
        <Popover className={styles.learnMorePopover}>
          <Popover.Header>
            <span>{item.pharmacy} {searchVars?.quantity} {searchVars?.format}s of {searchVars?.drug} {searchVars?.dosage}</span>
          </Popover.Header>
          <Popover.Body style={{paddingTop: '5px'}}>
            <Row style={{display: 'block'}}>
              <b><span>Retail Price</span> <span>${item.price}</span></b>
            </Row>
            <Row style={{marginTop: '20px'}}>
              <Col lg={10}>
                <span>This is the retail price you'll pay at {item.pharmacy} if you don't have insurance or utilize a discount.</span>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      }
    >
      <button className={`${styles.learnMoreButton} ${buttonStyles.button} ${buttonStyles.buttonSecondary}`}>
        Learn more
      </button>
    </OverlayTrigger>
  )
}