import { Container, Row } from 'react-bootstrap';
import { MediaCard } from './mediaCard';

export const MediaGallery = ({registerPrefix, fields, onRemove, onCoverSelected}) => {

  return (
    <Container fluid>
      <Row>
        {fields.map((item, index) => ( 
          <MediaCard 
            registerPrefix={registerPrefix} 
            index={index} 
            key={index} 
            item={item}
            onRemove={onRemove}
            onCoverSelected={onCoverSelected}
          />
        ))}
      </Row>
    </Container>
  );
};