import { TeaserCard } from "enrollment/components/teaserCard";
import { ConciergeIcon } from "./icon";

export const ConciergeTeaser = () => {

  return (
    <TeaserCard
        title="Care Concierge"
        icon={<ConciergeIcon />}
    >
      Unlimited access to a real person to help you when needed
    </TeaserCard>
  );
};