import { useState } from 'react';
import { Button as LinkButton, Col } from 'react-bootstrap';
import { TermsAndConditions } from './tandc';
import { Section, SectionHeader } from 'shared/components/form';
import { PrivacyPolicy } from './privacy';
import { DisablingCheckBox } from './disablingCheckBox';
import { PolicyModal } from './policyModal';
import styles from './policy.module.css';

export const EditPolicyAcceptance = ({ errors }) => {
  const [showTandCs, toggleTandCs] = useState(false);
  const [showPrivacy, togglePrivacy] = useState(false);
  const tandcField = 'consumer.tandcAccepted';
  const privacyField = 'consumer.privacyPolicyAccepted';

  return (
    <>
      <SectionHeader><h4>Policies</h4></SectionHeader>
      <Section>
        <Col>
          To complete the registration process, you must check both boxes below, indicating that you have read, 
          understand and agree to the Best in Class Care Privacy Policy and Terms and Conditions.
          <br />
          <br />
        </Col>
      </Section>

      <Section>
        <Col>
          <DisablingCheckBox
            name="privacy"
            field={privacyField}
            errors={errors}
          >
            <strong>Privacy Policy</strong>
            <br />
            In the United States, your personal health information is protected by HIPAA, and we abide by all the requirements of that law.
            We also take further steps to safeguard your privacy. Read the full
            <LinkButton tabIndex={-1} variant="link" className={styles.policyLinkButton} onClick={() => togglePrivacy(true)}>Privacy Policy</LinkButton>
          </DisablingCheckBox>
        </Col>
      </Section>

      <Section>
        <Col>
          <DisablingCheckBox
            name="tandc"
            field={tandcField}
            errors={errors}
          >
            <strong>Terms and Conditions</strong>
            <br />
            By signing up for an account, you agree to our Terms and Conditions. Read the full
            <LinkButton tabIndex={-1} variant="link" className={styles.policyLinkButton} onClick={() => toggleTandCs(true)}>Terms and Conditions</LinkButton>
          </DisablingCheckBox>
        </Col>
      </Section>

      <PolicyModal
        title="Terms and Conditions"
        show={showTandCs}
        toggleShow={toggleTandCs}>
          <TermsAndConditions />
      </PolicyModal>

      <PolicyModal
        title="Privacy Policy"
        show={showPrivacy}
        toggleShow={togglePrivacy}>
          <PrivacyPolicy />
      </PolicyModal>
    </>
  );
};