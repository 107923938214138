import { gql } from '@apollo/client';

export const GET_SURVEY = gql`
  query Query($filter: SurveyFilter) {
    findSurveys(filter: $filter) {
      items {
        id
        code
        isPublished
        preamble
        title
        questions {
          id
          subject
          title
          text
          strategy
          sequence
          options {
            text
            value
            actionConfiguration
            action
          }
        }
      }
    }
  }
`;