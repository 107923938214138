import { useMutation } from '@apollo/client';
import { toastSuccess } from 'shared/components/toasts';
import { CREATE_PAYER, UPDATE_PAYER } from 'payers/operations/mutations';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'shared/components/buttons/button';
import { preparePayerForSave } from 'payers/services/formHelper';
import { isEmpty } from 'lodash';
import { payerBasicInfoValidationSchema } from 'payers/payerValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditPayerBasicInfo } from 'payers/components/edit/editBasicInfo';

export const EditPayerBasicInfoForm = ({payer, isNew, setBlockNavigation}) => {
  const formMethods = useForm({
    defaultValues: {payer},
    resolver: yupResolver(payerBasicInfoValidationSchema),
    mode: 'onChange'
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const registerPrefix = 'payer';
  const mutationQuery = isNew
    ? CREATE_PAYER
    : UPDATE_PAYER;

  const onSaved = (data) => {
    toastSuccess(`Payer ${isNew ? 'created' : 'saved'}`);
    if (isNew) {
      const payerId = data.createPayer.id;
      const path = pathname.replace('new', payerId);
      history.push(path);
    } else {
      formMethods.formState.dirtyFields = {};
    }
  };

  const [savePayer] = useMutation(mutationQuery, {
    onCompleted: onSaved
  });
  
  const onSubmit = async (data) => {
    const input = preparePayerForSave(data.payer);
    await savePayer({ variables: { input: input }});
  };

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields);
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  return (
    <FormProvider {...formMethods}>
      <form name="payer" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <EditPayerBasicInfo isNew={isNew} registerPrefix={registerPrefix} errors={formMethods.formState.errors} />
        <Button type="submit" text={isNew ? 'Create' : 'Save'} />
      </form>
    </FormProvider>
  );
};