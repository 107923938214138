import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_APP_MODE } from './shared/operations/queries/getCurrentAppMode';
import { adminPaths } from './admin/routes';
import { consumerPaths } from './consumer/routes';

export const Home = () => {

  const {data} = useQuery(GET_CURRENT_APP_MODE);
  let currentAppMode = data.currentAppMode;

  if (currentAppMode === 'Admin') return (<Redirect to={adminPaths.root} />);
  if (currentAppMode === 'Consumer') return (<Redirect to={consumerPaths.root} />);
  return (<></>);
};