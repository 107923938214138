import { Col, Row } from 'react-bootstrap';
import { Help } from './help';
import styles from '../rx.module.css';

export const CouponHelp = ({header, copy, icon, phoneNumber}) => {

  return (
    <Row className={styles.helpSectionContainer}>
      <Col className={styles.helpComponent}>
        <Help header={header} copy={copy} icon={icon} phoneNumber={phoneNumber}/>
      </Col>
    </Row>
  );
};