import { useCurrentConsumer } from "consumer/hooks/useCurrentConsumer";
import buttonStyles from 'shared/components/buttons/button.module.css';
import contactStyles from './contactButton.module.css';

export const ContactButton = ({ variant }) => {
  const { consumer } = useCurrentConsumer();
  let number;
  let dial;
  let classNames = variant === 'secondary'
    ? buttonStyles.buttonSecondary
    :  buttonStyles.buttonPrimary;
  classNames = `${buttonStyles.button} ${classNames} ${contactStyles.button}`;
  
  if (consumer) {
    number = consumer.tenant.concierge.phoneNumber;
    dial = number.replace(/[^0-9]+/g, '');
    dial = `+1${dial}`;
  }

  return (
      <a className={classNames} href={`tel:${dial}`}>Call {number}</a>
  );
};