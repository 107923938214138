import { DateTime } from "luxon";
import { LyricAppointment } from "benefits/telehealth/components/lyric/appointment";

export const AppointmentActionItem = ({ appointment }) => {

  let AppointmentComponent = DefaultAppointment;

  if (appointment.integrations?.some(x => x.type === 'LYRIC')) {
    AppointmentComponent = LyricAppointment;
  }

  return (
    <AppointmentComponent appointment={appointment} />
  )
};

export const DefaultAppointment = ({ appointment }) => {

  return (
    <span data-testid="default">
      Your appointment with {appointment.provider.contact.name.first}{" "} {appointment.provider.contact.name.last} is confirmed for{" "}
      {DateTime.fromISO(appointment.date).toLocaleString(DateTime.DATE_HUGE)} at{" "} {appointment.time}.
    </span>
  );
};