export const PrivacyPolicy = () => {

  return (
    <>
      <p>
        <strong>1. Introduction</strong>
      </p>
      <p>
        This privacy policy applies to our website and services. By accessing or using this website and/or the associated services, you agree to this privacy policy.
      </p>
      <p>
        Best in Class Care may occasionally change this privacy policy. We will post any changes on this page. We encourage you to check back and review this privacy policy periodically for any updates or changes. Your continued use of this website and/or our services constitutes your agreement to this privacy policy and any future revisions.
      </p>
        <strong>2. Information We Collect</strong>
        <p>
          We collect personally identifiable information (PII) to enable us to provide you services. This information may include any or all of the following, depending upon the service requested:
        </p>
        <ul>
          <li>Contact Information (Name, Email Address, Mailing Address, Telephone Number)</li>
          <li>Demographic Information (Sex, Age and Date of Birth)</li>
          <li>Emergency Contact</li>
          <li>Medical Insurance Carrier, Policy Number, Group Number and Coverage Information</li>
          <li>Passport Number and Expiration Date</li>
          <li>Credit Card Information</li>
          <li>Primary Care Provider Name and Contact Information</li>
          <li>Medical Services that You May View, Inquire About or Request on Our Site or Through our Care Concierge Service</li>
          <li>Medical History / Records</li>
          <li>Copies of your correspondence, if you contact us</li>
          <li>Other Protected Health Information (PHI)</li>
        </ul>
      <p>
        <strong>3. PHI Permitted Uses and Disclosures</strong>
      </p>
      <p>
        We can use or disclose your PHI for purposes of <i>treatment, payment and health care operations</i>. For each of these categories of uses and disclosures, we have provided a description and an example below.  However, not every use or disclosure in every category will be listed.
      </p>
      <ul>
        <li><b><i>Treatment</i></b> means the provision, coordination or management of your health care, including consultations between health care providers relating to your care and referrals for health care from one health care provider to another.  For example, a doctor treating you for a broken leg may need to know if you have diabetes because diabetes may slow the healing process. In addition, the doctor may need to contact a physical therapist to create the exercise regimen appropriate for your treatment.</li>
        <li><b><i>Payment</i></b> means the activities undertaken to obtain reimbursement for the health care provided to you, including billing, collections, claims management, determinations of eligibility and coverage and other utilization review activities.  For example, we may need to provide PHI to your Third Party Payer to determine whether the proposed course of treatment will be covered or if necessary to obtain payment.  Federal or state law may require us to obtain a written release from you prior to disclosing certain specially protected PHI for payment purposes, and we will ask you to sign a release when necessary under applicable law.</li>
        <li><b><i>Health care operations</i></b> means the support functions of the Center of Care, related to treatment and payment, such as quality assurance activities, case management, receiving and responding to patient comments and complaints, physician reviews, compliance programs, audits, business planning, development, management and administrative activities.   We may also combine PHI about many patients to decide what additional services we should offer, what services are not needed, and whether certain new treatments are effective. We may also disclose PHI for review and learning purposes.  In addition, we may remove information that identifies you so that others can use the de-identified information to study health care and health care delivery without learning who you are.</li>
      </ul>
      <p>
        <strong>Other Uses and Disclosures of Protected Health Information</strong>
      </p>
      <ul>
        <li>We may contact you to provide appointment reminders for treatment or medical care.</li>
        <li>We may disclose to your family or friends or any other individual identified by you to the extent directly related to such person’s involvement in your care or the payment for your care.  We may use or disclose your PHI to notify, or assist in the notification of, a family member, a personal representative, or another person responsible for your care, of your location, general condition or death.  If you are able, we will give you an opportunity to object to these disclosures, and we will not make these disclosures if you object.  If you are not able, we will determine whether a disclosure to your family or friends is in your best interest, taking into account the circumstances and based upon our professional judgment.</li>
        <li>When permitted by law, we may coordinate our uses and disclosures of PHI with public or private entities authorized by law or by charter to assist in disaster relief efforts.</li>
        <li>We will use or disclose PHI about you when required to do so by applicable law.</li>
      </ul>
      <p>
        <strong>Special Situations</strong>
      </p>
        Subject to the requirements of applicable law, we will make the following uses and disclosures of your PHI:
        <ul>
          <li><b><i>Military and Veterans. </i></b>If you are a member of the Armed Forces, we may release PHI about you as required by military command authorities.  We may also release PHI about foreign military personnel to the appropriate foreign military authority.</li>
          <li><b><i>Worker’s Compensation. </i></b>We may release PHI about you for programs that provide benefits for work-related injuries or illnesses.</li>
          <li>
            <b><i>Public Health Activities. </i></b>We may disclose health information about you for public health activities, including disclosures:
            <ul>
              <li>to prevent or control disease, injury or disability;</li>
              <li>to report births and deaths;</li>
              <li>to report child abuse or neglect;</li>
              <li>to persons subject to the jurisdiction of the Food and Drug Administration (FDA) for activities related to the quality, safety, or effectiveness of FDA-regulated products or services and to report reactions to medications or problems with products;</li>
              <li>to notify a person who may have been exposed to a disease or may be at risk for contracting or spreading a disease or condition;</li>
              <li>to notify the appropriate government authority if we believe that an adult patient has been the victim of abuse, neglect or domestic violence.  We will only make this disclosure if the patient agrees or when required or authorized by law.</li>
            </ul>
          </li>
          <li><b><i>Lawsuits and Disputes. </i></b>If you are involved in a lawsuit or a dispute, we may disclose PHI subject to certain limitations.</li>
          <li>
            <b><i>Law Enforcement. </i></b>We may release health information if asked to do so by a law enforcement official.
            <ul>
              <li>In response to a court order, subpoena, warrant, summons or similar process;</li>
              <li>To identify or locate a suspect, fugitive, material witness, or missing person;</li>
              <li>About the victim of a crime under certain limited circumstances;</li>
              <li>About a death we believe may be the result of criminal conduct;</li>
              <li>About criminal conduct on our premises; or</li>
              <li>In emergency circumstances, to report a crime, the location of the crime or the victims, or the identity, description or location of the person who committed the crime.</li>
            </ul>
          </li>
          <li><b><i>Serious Threats. </i></b>As permitted by applicable law and standards of ethical conduct, we may use and disclose PHI if we, in good faith, believe that the use or disclosure is necessary to prevent or lessen a serious and imminent threat to the health or safety of a person or the public or is necessary for law enforcement authorities to identify or apprehend an individual.</li>
        </ul>
      <p>
        <strong>Other Uses of your Health Information</strong>
      </p>
      <p>
        Certain uses and disclosures of PHI will be made only with your written <b><i>authorization</i></b>, including uses and/or disclosures: (a) of psychotherapy notes (where appropriate); (b) for marketing purposes; and (c) that constitute a sale of PHI under the Privacy Rule.  Other uses and disclosures of PHI not covered by this notice or the laws that apply to us will be made only with your written authorization.  You have the right to revoke that authorization at any time, provided that the revocation is in writing, except to the extent that we already have taken action in reliance on your authorization.
      </p>
      <p>
        <b>Note</b>
      </p>
      <p>
        <b><i>HIV related information, genetic information, alcohol and/or substance abuse records, mental health records and certain special conditions related to minors may enjoy certain special confidentiality protections under applicable State and Federal law. Any disclosures of these types of records will be subject to these special protections.</i></b>

      </p>
        <b>Your Rights</b>
        <ol>
          <li>You have the right to request restrictions on our uses and disclosures of PHI for treatment, payment and health care operations.  However, we are not required to agree to your request.  We are, however, required to comply with your request if it relates to a disclosure to your health plan regarding health care items or services for which you have paid the bill in full.  To request a restriction, you may make your request in writing to: Best in Class Care, 30 North Racine Avenue, Suite 300, Chicago, IL 60607.</li>
          <li>You have the right to reasonably request to receive confidential communications of your PHI by alternative means or at alternative locations.  To make such a request, you may submit your request in writing to: Best in Class Care, 30 North Racine Avenue, Suite 300, Chicago, IL 60607.</li>
          <li>You have the right to inspect or obtain a copy of the PHI contained in our records, if applicable.</li>
          <p>
            To inspect or obtain a copy of your PHI, you may submit your request in writing to: Best in Class Care, 30 North Racine Avenue, Suite 300, Chicago, IL 60607.  If you request a copy, we may charge you a fee for the costs of copying and mailing your records, as well as other costs associated with your request.
          </p>
          <p>
            We may also deny a request for access to PHI under certain circumstances if there is a potential for harm to yourself or others. If we deny a request for access for this purpose, you have the right to have our denial reviewed in accordance with the requirements of applicable law.
          </p>
          <li>
            You have the right to request an amendment to your PHI, but we may deny your request for amendment if we determine that the PHI or record that is the subject of the request:
            <ol type="i">
              <li>was not created by us, unless you provide a reasonable basis to believe that the originator of PHI is no longer available to act on the requested amendment;</li>
              <li>is not part of your medical or billing records or other records used to make decisions about you;</li>
              <li>is not available for inspection as set forth above; or</li>
              <li>is accurate and complete.</li>
            </ol>
          </li>
          <p>
            In any event, any agreed upon amendment will be included as an addition to, and not a replacement of, already existing records. To request an amendment to your health information, you must submit your request in writing to Best in Class Care, 30 North Racine Avenue, Suite 300, Chicago, IL 60607, along with a description of the reason for your request.
          </p>
          <li>You have the right to receive an accounting of disclosures of PHI made by us to individuals or entities other than to you for the six years prior to your request, except for disclosures:</li>
          <ol type="i">
            <li>to carry out treatment, payment and health care operations as provided above;</li>
            <li>incidental to a use or disclosure otherwise permitted or required by applicable law;</li>
            <li>pursuant to your written authorization;</li>
            <li>for national security or intelligence purposes as provided by law;</li>
            <li>to correctional institutions or law enforcement officials as provided by law;</li>
            <li>as part of a limited data set as provided by law</li>
          </ol>
          <p>
            To request an accounting of disclosures of your PHI, you must submit your request in writing to: Best in Class Care, 30 North Racine Avenue, Suite 300, Chicago IL 60607. Your request must state a specific time period for the accounting (e.g., the past three months).  The first accounting you request within a twelve (12)-month period will be free.  For additional accountings, we may charge you for the costs of providing the list.  We will notify you of the costs involved, and you may choose to withdraw or modify your request at that time before any costs are incurred.
          </p>
          <li>You have the right to receive a notification if there is a breach of your unsecured PHI, which requires notification under the Privacy Rule.</li>
        </ol>
        <strong>We may also collect information:</strong>
        <ul>
          <li>That is about you, but does not individually identify you, such as log data and other information collected through automatic data collection technologies and information collected or provided by third party providers</li>
          <li>About your internet connection, the equipment you use to access the site and usage details</li>
        </ul>
        In addition, we may use third party service providers that collect, monitor and analyze information and browsing activity over time in order to enhance the site’s functionality. We do not control these third parties, and their use of the information is governed by their own privacy policies.

        <br />
        <br />
        <strong>4. How We Use Your Information</strong><br />
        <ul>
          <li>To respond to your requests and inquiries, such as a request for a consult for medical services</li>
          <li>To facilitate the booking of your medical care and any associated travel and/or accommodations</li>
          <li>To help improve our services, including functionality of the site and support provided by our Care Concierge</li>
          <li>To notify you about changes to the site or the services we offer or provide through it</li>
          <li>In any other way we may describe to you or to which you otherwise consent</li>
        </ul>
      <p>
        <strong>5. Measures We Take to Protect Your Information</strong>
      </p>
        The security of your information is very important to us; therefore, we implement and maintain the following commercially reasonable security procedures and practices appropriate to the nature of the information, including personal information we store, to protect it from unauthorized access, destruction, use, modification or disclosure:
        <ul>
          <li>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology</li>
          <li>All financial transactions are processed through a gateway provider and are not stored or processed on our servers</li>
          <li>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible</li>
        </ul>
      <p>
        However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure, and we are unable to guarantee the absolute security of the information, including personal information we have collected from you.
      </p>
      <p>
        <strong>6. COOKIES AND WEB BEACONS</strong>
      </p>
      <p>
        We may store “cookies” to your computer. Cookies are files that identify you as a customer and store your personal preferences and technical information. They are primarily intended to enhance your online experience. There are two types of cookies: 1) permanent cookies, which remain on your computer for a period of time or until you manually remove them, and 2) temporary cookies, that last only until you close your browser. We may also use “Web beacons” that monitor your use of our website. Web beacons enable verification of a user’s viewing or receipt of a Web page or message. When we communicate with you via HTML capable email, Web beacons verify with us whether you received and opened our email message. On their own, cookies or Web beacons do not contain or reveal any personally identifiable information; however, if you choose to provide personally identifiable information on the website, it may be linked to the data stored using cookies or Web beacons.
      </p>
      <p>
        By accepting this Privacy Policy, you agree to our use of cookies and Web beacons. Please note, you may adjust your browser to reject cookies from us or from any other website. To do so, consult the “Help” section of your browser. Also, if you set your Web browser to display HTML email messages as text only, you may be able to prevent the use of some Web beacons; however, if you disable cookies or similar devices, you may not be able to use the full functionality of this website.
      </p>
      <p><strong>7. Google Analytics</strong></p>
      This website uses Google Analytics. Google Analytics is a web analytics service provided by Google, Inc. ("Google"). It uses "cookies", or text files, which are placed on your computer, to help the website analyze how users use the site. Cookies generate information about your use of the website. This information, including your IP address, is transmitted to and stored by Google on servers in the United States. Google uses this information on our behalf to analyze your use of the website, compiling reports on website activity for website operators and providing us with other services relating to website activity and internet usage. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the relevant settings on your browser; however, if you do this, you may not be able to use the full functionality of this website. Please note, if you wish to prevent Google's collection and use of data (cookies and IP address), you may download and install the browser plug-in available at:  https://tools.google.com/dlpage/gaoptout/?hl=en-GB.
      <p><strong>8. Third Party Links</strong></p>
      <p>
        The site may contain links to third-party websites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s website. We strongly advise you to review the privacy policy of every website you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party’s website or services.
      </p>
      <p><strong>9. Your California Privacy Rights</strong></p>
      <p>
        California Civil Code Section 1798.83 permits users of the site that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an e-mail to <a href="mailto:privacy@bestinclasscare.com">privacy@bestinclasscare.com</a> or write to us at Best in Class Care, Inc., 30 N Racine Ave., Suite 300, Chicago, IL 60607.
      </p>
      <p><strong>10. Protecting Privacy of Children</strong></p>
      <p>
        Our site is not designed or intended to attract children under the age of 13. We do not specifically market to children under 13. A parent or guardian may use the site on behalf of a minor. The parent or guardian is solely responsible for supervising the minor’s use of the site. The pare or guardian assumes full responsibility for ensuring that the registration information is kept secure and that the information submitted is accurate. The parent or guardian also assumes full responsibility for the interpretation and use of any information or suggestions provided through the site or our services for the minor.
      </p>
      <p><strong>11. Contact Us</strong></p>
      <p>
        If there are any questions regarding this privacy policy you may contact us using the information below.
      </p>
      <p>
        Best in Class Care, Inc.<br />
        30 North Racine Avenue<br />
        Suite 300<br />
        Chicago, IL 60607<br /><br />
        <a href="mailto:privacy@bestinclasscare.com">privacy@bestinclasscare.com</a>
      </p>
    </>
  );
};