// import { Nav } from 'react-bootstrap';
import { Menu } from './menu';

export const ConsumerMenu = ({ canSwitch, changeMode }) => {
  return (
    <Menu canSwitch={canSwitch} switchToMode='Admin' changeMode={changeMode}>
      <>
        {/* <Nav.Link href="#">My Packages</Nav.Link> */}
      </>
    </Menu>
  );
};
