import { useFormContext } from 'react-hook-form';
import { SectionHeader, Section } from 'shared/components/form';
import { EditVisits } from 'benefits/shared/editVisits';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export const TelehealthConfig = ({registerPrefix, index}) => {
  const registerField = `${registerPrefix}.benefits.${index}.configuration`;
  const [activeNetworkId, setActiveNetworkId] = useState(null);
  const { getValues, setValue } = useFormContext();
  const networks = getValues(`${registerPrefix}.networks`) ?? [];

  const onNetworkSelect = (networkId) => {
    setValue(`${registerField}.activeNetwork`, networkId);
    setActiveNetworkId(networkId);
  };
  
  useEffect(() => {
    const configuration = getValues(registerField);
    if (!configuration) {
      setValue(registerField, { visits: {} });
    } else {
      setActiveNetworkId(configuration.activeNetwork);
    }
  }, [getValues, setValue, setActiveNetworkId, registerField])

  return (
    <>
      <SectionHeader>
        <h5>Active Network</h5>
      </SectionHeader>
      <Section>
      {networks.map((network, networkIndex) => (
        <Row key={networkIndex}>
          <Col>
            <label className={`form-check-label`} htmlFor={networkIndex}>{network.name}</label>
          </Col>
          <Col xs={5}>
            <input
              id={networkIndex}
              type="radio"
              name={`network-${index}`}
              checked={network.id === activeNetworkId}
              onChange={() => onNetworkSelect(network.id)}
            />
          </Col>
        </Row>
      ))}
      </Section>
      <SectionHeader>
        <h5>Visits</h5>
      </SectionHeader>
      <Section>
        <EditVisits registerPrefix={`${registerField}.visits`} />
      </Section>
    </>
  );
};