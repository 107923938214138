import { FaRegWindowClose } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const CloseButton = ({ inverse, ...rest}) => {

  const className = inverse ? styles.closeinverse : styles.close;

  return (
    <IconButton 
      IconComponent={FaRegWindowClose}
      iconClass={className}
      {...rest} />
  );
};