import { Button, Col, OverlayTrigger } from "react-bootstrap";
import styles from 'case/case.module.css';

export const Tag = ({ tag, allTags, onRemove }) => {
  const canDelete = !(allTags?.find(x => x.tag === tag)?.isRoutable);
  const displayTag = (<span>#{tag}</span>);
  const overLay = (<Button variant="link" className={styles.tagremove} onClick={() => onRemove(tag)}>X</Button>);

  return (
    <Col className={styles.tag}>
      {canDelete 
        ? <OverlayTrigger
            key={tag}
            placement="right"
            delay={{ show: 250, hide: 1000 }}
            trigge="hover"
            overlay={overLay}
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, 1] }
                }
              ]
            }}
          >
            {displayTag}
          </OverlayTrigger>
        : displayTag
      }
    </Col>
  );
};