import { useState , useEffect} from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { AddButton, DeleteButton, EditButton, IconSpacer, SaveButton, IconButton } from '../buttons';
import { Input, Section, SectionHeader } from '../form';
import { FaBell, FaBellSlash, FaUser } from 'react-icons/fa';
import { AddMissingEmailModal } from './addMissingEmailModal';
import styles from './contact.module.css';

export const EditEmails = ({ registerPrefix, account, options }) => {
  options = options ?? {};
  const registerField = registerPrefix ? `${registerPrefix}.emails` : 'emails';
  const { control, register, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: registerField });
  const [ editIndex, setEditIndex ] = useState(-1);
  const [ canEdit, enableEdit ] = useState(true);
  const [ sendNotificationsLookup, setSendNotificationsLookup ] = useState({});
  const header = options.header ?? 'Email Addresses';
  const accountEmailIndex = fields?.indexOf(fields.find(x => x.email === account.email)) ?? -1;
  const notificationsTooltip = 'This email will receive notifications';
  const noNotificationTooltip = 'Click to set this email address to receive notifications'

  const addNew = () => {
    const newIndex = fields.length;
    setEditIndex(newIndex);
    append({ email: '', sendNotifications: false });
    enableEdit(false);
  };

  const onDelete = (index) => {
    if (index < editIndex) {
      setEditIndex(editIndex - 1);
    }

    if (index === editIndex) {
      enableEdit(true);
      setEditIndex(-1);
    }

    remove(index);
    
    // if this email was marked for notifications, update to account email 
    if (sendNotificationsLookup[index]) {
      updateSendNotifications(accountEmailIndex);
    }
  };

  const onEdit = (index) => {
    setEditIndex(index);
    enableEdit(false);
  }

  const onSave = () => {
    setEditIndex(-1);
    enableEdit(true);
  }

  const onEmailKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSave();
    }
  };

  const updateSendNotifications = (chosenIndex) => {
    let emails = getValues(registerField);
    if (emails) {
      let newLookup = {};
      emails.forEach((entry, index) => {
        let valueToSet = chosenIndex === index;
        newLookup[index] = valueToSet;
        setValue(`${registerField}.${index}.sendNotifications`, valueToSet);
      });
      setSendNotificationsLookup(newLookup);
    }
  };

  useEffect(() => {
    var emails = getValues(registerField);
    let lookup = {};
    if (emails.length !== 0) {
      emails.forEach((entry, index) => {
        lookup[index] = entry.sendNotifications;
      });
    }

    setSendNotificationsLookup(lookup);
  }, [getValues, registerField]);

  return (
    <>
      <SectionHeader>
        <h4>
          {header}
          {accountEmailIndex < 0 &&
            <AddMissingEmailModal account={account} />
          }
        </h4>
      </SectionHeader>
      <Section>
        <Col lg={12}>
          {fields.map((item, index) => (
            <Row key={item.id} className={styles.lineItem}>
              <Col lg={1} sm={2} xs={2} onClick={(e) => updateSendNotifications(index)} className="my-auto">
              {
                sendNotificationsLookup[index]
                  ? <IconButton 
                      tooltip={notificationsTooltip} 
                      IconComponent={FaBell}
                      iconClass={styles.phoneIcon} 
                    />
                  : <IconButton 
                      tooltip={noNotificationTooltip}
                      IconComponent={FaBellSlash}
                    />
              }
              </Col>
              <Col lg={4} sm={4} xs={9} className="my-auto">
                {editIndex === index
                  ?
                  <Controller
                    control={control}
                    key={item.id}
                    //key={`${registerField}.${index}.email`}
                    name="emailaddress"
                    render={({ ref }) => (
                      <Input 
                        autoFocus={true}
                        autoComplete="email"
                        type="email"
                        inputRef={ref} 
                        onKeyDown={onEmailKeyDown}
                        label='Enter email address' 
                        registerInput={`${registerField}.${index}.email`} 
                      />
                    )}
                  />
                  :
                  <Controller
                    name={`${registerField}.${index}.email`}
                    render={({ field }) => (<div data-testid="emailDisplay">{field.value}</div>)} />
                }
              <input style={{display:'none'}} {...register(`${registerField}.${index}.sendNotifications`)}/>
              </Col>
              <Col lg={2} sm={{span:3, offset: 0}} xs={{span: 6, offset: 8}} className="my-auto">
                {
                  account.email === item.email 
                    ? <IconButton 
                        tooltip='Account Email'
                        IconComponent={FaUser}
                        iconClass={styles.phoneIcon}
                      />
                    : <div>
                        {editIndex !== index
                          ? <EditButton tooltip="Edit" disabled={!canEdit} onClick={() => onEdit(index)} />
                          : <SaveButton tooltip="Save" onClick={() => onSave(-1)} />
                        }
                        <IconSpacer />
                        <DeleteButton tooltip="Delete" onClick={() => onDelete(index)} />
                      </div>
                }
                
              </Col>
            </Row>
          ))}
          <Row className={styles.lineItem}>
            <Col sm={1}>
              <AddButton tooltip="Add new email" onClick={addNew} />
            </Col>
          </Row>
        </Col>
      </Section>
    </>
  );
};