import { useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import styles from "shared/components/shared.module.css";

export const Pager = ({ itemCount, itemsPerPage, onPageChanged, resetPager }) => {
  itemsPerPage = itemsPerPage ?? 10;
  const visiblePages = 3;
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPageNumbers, setCurrentPageNumbers] = useState([]);

  const adjustVisiblePages = useCallback((nextPage) => {
    if (pageNumbers.length <= visiblePages) return;

    const nextPageIndex = nextPage - 1;
    const maxStartIndex = pageNumbers.length - visiblePages;
    let startIndex = nextPageIndex === 0
      ? 0
      : nextPageIndex > maxStartIndex
        ? maxStartIndex
        : nextPageIndex - 1;
    let endIndex = nextPageIndex > (pageNumbers.length - 2) 
      ? pageNumbers.length 
      : startIndex + visiblePages;

    setCurrentPageNumbers(pageNumbers.slice(startIndex, endIndex));
  }, [pageNumbers, visiblePages, setCurrentPageNumbers]);

  const changePage = useCallback((page) => {
    adjustVisiblePages(page);
    setPage(page);
    onPageChanged(page);
  }, [adjustVisiblePages, setPage, onPageChanged]);

  useEffect(() => {
    const numPages = Math.ceil(itemCount / itemsPerPage);
    const pages = Array.from(Array(numPages), (_, i) => i + 1);
    setPageNumbers(pages);
    setCurrentPageNumbers(pages.slice(0, visiblePages));
  }, [itemCount, itemsPerPage]);

  useEffect(() => {
    if (resetPager) {
      adjustVisiblePages(1);
      setPage(1);
    }
  }, [resetPager, adjustVisiblePages, setPage, page])

  return (
    <Pagination className={`justify-content-center ${styles.pager}`}>
      <Pagination.First disabled={page === 1} onClick={() => changePage(1)} />
      <Pagination.Prev disabled={page === 1} onClick={() => changePage(page -1)} />

      {currentPageNumbers.map((pageNumber, index) => (
        <Pagination.Item 
          key={index}
          onClick={() => changePage(pageNumber)}
          active={pageNumber === page}
        >
            {pageNumber}
        </Pagination.Item>
      ))}

      <Pagination.Next disabled={page === pageNumbers.length} onClick={() => changePage(page +1)} />
      <Pagination.Last disabled={page === pageNumbers.length} onClick={() => changePage(pageNumbers.length)} />
    </Pagination>
  );
};