import { FaPlusCircle } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const AddButton = ({...rest}) => {

  return (
    <IconButton 
      IconComponent={FaPlusCircle}
      iconClass={styles.add}
      {...rest} />
  );
};