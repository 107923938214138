import { useQuery } from '@apollo/client';
import { FaHospital, FaUserMd } from 'react-icons/fa';
import { useParams } from "react-router-dom";
import { TabLayout } from 'shared/components/tablayout/tablayout';
import { AdminHeader } from 'shared/components/header/adminHeader';
import { GET_CARECENTER_NAME } from '../operations/queries/getCareCenterName';
import { EditFinancialAssistance, EditCareCenterBasicInfo, EditCareCenterMedia } from "../components/edit";
import styles from './careCenter.module.css';

export const EditCareCenter = () => {
  const { careCenterId } = useParams();
  const isNew = careCenterId === 'new';

  const tabs = [
    {
      key: 'profile',
      title: 'Profile',
      icon: FaHospital,
      default: true,
      tabs: [
        {
          key: 'basic',
          title: 'Basic Info',
          default: true,
          component: ({ onSaved, setBlockNavigation }) => (<EditCareCenterBasicInfo onSaved={onSaved} setBlockNavigation={setBlockNavigation} />)
        },
        {
          key: 'media',
          title: 'Media',
          disabled: isNew,
          component: ({ onSaved, setBlockNavigation }) => (<EditCareCenterMedia onSaved={onSaved} setBlockNavigation={setBlockNavigation} />)
        },
        {
          key: 'financialAssistance',
          title: 'Financial Assistance',
          disabled: isNew,
          component: ({ onSaved, setBlockNavigation }) => (<EditFinancialAssistance onSaved={onSaved} setBlockNavigation={setBlockNavigation} />)
        }
      ]
    },
    {
      key: 'providers',
      title: 'Providers',
      icon: FaUserMd,
      disabled: isNew,
      component: () => <h1>Coming Soon!</h1>
    }
  ];

  return (
    <>
      {!isNew 
        ? <EditCareCenterHeader careCenterId={careCenterId} />
        : <AdminHeader title="New Care Center" />
      }
      <div className={styles.tabs}>
        <TabLayout tabs={tabs} />
      </div>
    </>
  );
};

const EditCareCenterHeader = ({careCenterId}) => {
  const {data} = useQuery(GET_CARECENTER_NAME, {variables: {
    id: careCenterId
  }});

  return (
    <div className={styles.header}>
      { data
        ? <AdminHeader title={data.getCareCenter.name} id={careCenterId} />
        : <h1 className="inverse">Loading...</h1>
      }
    </div>
  );
};