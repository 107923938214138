import { Card } from 'react-bootstrap';
import styles from './components.module.css';

export const TeaserCard = ({icon, title, children}) => {
  return (
    <Card className={styles.teaser}>
      <Card.Body>
        <Card.Title className={styles.teasertitle}>
          <div className={styles.teasericon}>
          {icon}
          </div>
          <h5>{title}</h5>
        </Card.Title>
        <Card.Text>
          {children}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};