import { Col, Row } from 'react-bootstrap';
import { TodoCard } from './todoCard';
import styles from 'consumer/components/todo/todo.module.css';

export const NoTodos = () => {
  return (
    <TodoCard 
      title="You're all caught up!"
      showButton={false}
    >
      <Row>
        <Col className={styles.copy}>
          You have no todos. Take advantage of this time you have to do a little housekeeping or dig into a few of your perks!
        </Col>
      </Row>
    </TodoCard>
  );
}