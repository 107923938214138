import { useQueryWrapper } from "apollo/useQueryWrapper";
import { GET_RECIPIENT_MESSAGE_LIST } from "message/operations/queries/getRecipientMessageList";
import { MessageFolder } from "./messageFolder";

export const RecipientFolder = ({
    correspondent,
    title,
    statuses
}) => {
  const filter = {
    recipient: {
      type: { eq: correspondent.type },
      id: { eq: correspondent.id }
    },
    status: { in: statuses }
  };
  const { data, fetchMore } = useQueryWrapper(
    GET_RECIPIENT_MESSAGE_LIST, { 
      variables: { filter: filter, offset: 0 },
      notifyOnNetworkStatusChange: true
    });

  if (data) {
    const messages = data.findMessageRecipients.items.map(x => ({
      id: x.message.id,
      subject: x.message.subject,
      correspondents: [
        x.message.sender.name
      ],
      sentAt: x.sentAt,
      status: x.status
    }));
    const totalCount = data.findMessageRecipients.count;

    return (
      <MessageFolder title={title} messages={messages} direction='IN' fetchMore={fetchMore} totalCount={totalCount} />
    );
  }

  return 'Loading...';
};