import { gql } from '@apollo/client';

export const GET_APPOINTMENT_REQUEST = gql`
  query Query($id: ID!) {
    getAppointmentRequest(id: $id) {
      id
      consumer {
        id
      }
      tenant {
        id
      }
      careCenter {
        id
        name
        address {
          line1
          line2
          city
          state
          postalCode
          countryCode
          geometry {
            coordinates
          }
        }
      }
      additionalNotes
      appointmentType
      preferredDates
      preferredTime
    }
  }
`;