import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import { DeleteButton } from "shared/components/buttons";
import { ImageLoader } from './imageLoader';
import styles from './media.module.css';

export const MediaCard = ({registerPrefix, index, item, onRemove, onCoverSelected}) => {
  const { control, register } = useFormContext();
  const registerField = `${registerPrefix}.${index}.isCoverImage`;

  const toggleCoverSelection = (e) => {
    if (e.target.checked && onCoverSelected) onCoverSelected(index);
  };

  return (
    <Col>
      <Card className={styles.mediaCard}>
        <Card.Body>
          <Card.Title>
            <Container style={{padding: '0px'}}>
              <Row>
                <Col>
                  <Controller
                    control={control}
                    name="coverImage"
                    render={({ref}) => (
                      <Form.Check 
                        type="checkbox"
                        value={true}
                        id={`cover-${index}`}
                        label="Cover Image"
                        onClick={toggleCoverSelection}
                        {...register(registerField)}
                      />
                    )}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <DeleteButton tooltip="Delete" size={25} onClick={() => onRemove(index)} />
                </Col>
              </Row>
            </Container>
          </Card.Title>
          <Card.Text>
            <ImageLoader item={item} />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};