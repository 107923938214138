import styles from './maps.module.css';

export const MapMarker = ({color, name, id}) => {
  return (
    <div>
      <div id={id}
            className={`${styles.pin} ${styles.bounce}`}
            style={{ backgroundColor: color, cursor: 'pointer'}}
            title={name}
      >
        {name}
      </div>
      <div className={styles.pulse} />
    </div>
  );
};
