import { useFormContext } from "react-hook-form";
import parse from 'react-html-parser';

export const DisplayAddress = ({address, lines}) => {

  const display = getAddressDisplay(address, lines);
  if (!display) return (<></>);

  return (
    <>
    {parse(display)}
    </>
  );
};

export const getAddressDisplay = (address, lines=null) => {
  if (!address) return undefined;

  let display = address.line1;
  if (address.line2) display += `, ${address.line2}`;

  display += lines === 2
    ? '<br />'
    : ', ';
  display += `${address.city}, ${address.state} ${address.postalCode}`;

  return display;
};

export const DisplayAddressInForm = ({registerInput}) => {
  const { watch } = useFormContext();
  const address = watch(registerInput);

  return (
    <DisplayAddress address={address} />
  );
};