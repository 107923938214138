import { Enrollment } from './pages/enrollment';
import { EnrollmentTerminated } from './pages/enrollmentTerminated'

const rootPath = '/enrollment';

export const enrollmentPaths = {
  root: rootPath,
  terminated: `${rootPath}/terminated`
};

export const enrollmentRoutes = [
  {
    exact: true,
    path: enrollmentPaths.root,
    key: 'enrollment',
    component: Enrollment
  },
  {
    exact: true,
    path: enrollmentPaths.terminated,
    key: 'enrollment-terminated',
    component: EnrollmentTerminated
  }
];