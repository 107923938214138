import { Col, Row, Container } from 'react-bootstrap';
import styles from '../rx.module.css';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_DRUG_COUPON, GET_DRUG_PRICES } from '../operations/queries';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Coupon } from '../components/coupon';
import { CouponHelp } from '../components/helpSection';
import { FaHeadset, FaPrescription, FaExclamationCircle } from 'react-icons/fa';

export const PrescriptionCoupon = () => {
  const { prescriptionName } = useParams();
  const [couponData, setCouponData] = useState({});
  const [couponKey, setCouponKey] = useState('');
  const location = useLocation();
  const currentUrlParams = new URLSearchParams(location.search);
  const pharmacy = currentUrlParams.get('pharmacy');
  const postalCode = currentUrlParams.get('postalCode');
  const form = currentUrlParams.get('form');
  const dosage = currentUrlParams.get('dosage');
  const quantity = parseInt(currentUrlParams.get('quantity'));
  const consumerHelpCopy = 'If you are experiencing any issues finding a prescription drug or with utilizing a coupon at your local pharmacy, please call to speak with a GoodRx Advocate. Most issues can be resolved over the phone, in just a few minutes.';
  const pharmaicstHelpCopy = 'For questions about GoodRx coupons or any technical issues, GoodRx Advocates are always happy to help. Questions or other issues can often be resolved while the consumer is still in the pharmacy.';

  const { loading: pricesLoading, data: pricesData } = useQuery(GET_DRUG_PRICES, {
    skip: couponKey.length > 0,
    variables: { filter: {
      drug: prescriptionName,
      postalCode: postalCode,
      format: form,
      dosage: dosage,
      quantity: quantity
    }}
  });

  const { loading, data } = useQuery(GET_DRUG_COUPON, {
    skip: couponKey.length === 0,
    variables: {key: couponKey}
  });

  useEffect(() => {
    if (!pricesLoading && pricesData) {
      let chosenOption = pricesData.rx.getPrices.prices.filter(x => x.pharmacy === pharmacy)[0];
      setCouponKey(chosenOption.couponKey);
    }
  }, [pricesLoading, pricesData, setCouponData, pharmacy]);

  useEffect(() => {
    if (!loading && data) {
      setCouponData(data.rx.getGoodRxCoupon);
    }
  }, [loading, data, setCouponData]);

  return (
    <>
    <PrescriptionCouponHeader pharmacy={pharmacy} />
    <PrescriptionReminder />
    <Container>
      <Coupon drug={prescriptionName} form={form} dosage={dosage} quantity={quantity} couponData={couponData}/>
      <Row>
        <Col className={styles.notInsuranceDisclaimer}>
          <FaExclamationCircle />
          this is not insurance
        </Col>
      </Row>
      <Row className={styles.helpRow}>
        <Col lg={6} md={12} xs={12} className={styles.helpAdjustment}>
          <CouponHelp 
            header='Support for Consumers'
            copy={consumerHelpCopy}
            icon={FaHeadset}
            phoneNumber={couponData.customerServicePhone}
          />
        </Col>
        <Col className={styles.helpAdjustment}>
          <CouponHelp
            header='Support for Pharmacists'
            copy={pharmaicstHelpCopy}
            icon={FaPrescription}
            phoneNumber={couponData.pharmacyServicePhone}
          />
        </Col>
      </Row>
    </Container>
    </>
  )
}

const PrescriptionCouponHeader = ({pharmacy}) => (
  <Container className={styles.header} fluid>
    <Row className="justify-content-center">
      <Col lg={5} className={`text-center ${styles.couponHeaderSlogan}`}>
        Show this FREE coupon to your pharmacist at
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="text-center">
        <h1 className="inverse" style={{fontSize: '50px'}}>{pharmacy}</h1>
      </Col>
    </Row>
  </Container>
);

const PrescriptionReminder = () => {
  return (
    <Row className={styles.couponPrescriptionReminder}>
      <FaExclamationCircle />
      <span className={styles.prescriptionReminderLine1}>Don’t forget your prescription!</span>
      <span className={styles.prescriptionReminderLine2}>When you visit the pharmacy, you must present the coupon and your prescription.</span>
    </Row>
  )
}