import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_CARE_CENTER_IMPORT } from "network/operations/queries";
import { SectionHeader } from "shared/components/form/sectionHeader";
import { ItemList } from "shared/components/itemList";
import { useState, useEffect, useMemo, useRef } from "react";
import { isEmpty } from "lodash";
import styles from 'network/network.module.css';
import { v4 } from "uuid";
import { BoxLoading } from "shared/components/spinners/boxLoading";
import { exportJsonToXlsx } from "shared/services/exportJsonToXlsx";
import { Link } from "react-router-dom";
import { adminPaths } from 'admin/routes';
import { GET_APPLICATION_INSIGHTS_LOGS } from "shared/operations/queries/getApplicationInsightsLogs";

export const DataImport = ({importId, shouldDownloadRecords, downloadCompleteCallback, visible}) => {
  const [activeRecord, setActiveRecord] = useState({});
  const rowRef = useRef(null);

  const { loading, data } = useQuery(GET_CARE_CENTER_IMPORT, {
    skip: !visible && !shouldDownloadRecords,
    variables: { 
      id: importId
    }
  });

  const { data: logsData } = useQuery(GET_APPLICATION_INSIGHTS_LOGS, {
    skip: isEmpty(activeRecord),
    variables: {
      input: {
        customDimensions: {
          ExternalId: activeRecord.externalId,
          InvocationId: activeRecord.invocationId,
        },
        severity: 0
      }
    }
  })

  const onRecordClick = (record) => {
    setActiveRecord(record);
  };

  var careCenterImport = useMemo(() => (data?.getCareCenterImport ?? {}), [data?.getCareCenterImport]);

  useEffect(() => {
    if (shouldDownloadRecords && !isEmpty(careCenterImport)) {
      const filename = v4().toString();
      let data = careCenterImport.records.map(x => { return {...x.payload} })
                                 .sort((a,b) => Object.keys(b).length - Object.keys(a).length);
      const sortedData = [];
      data.forEach(item => {
        let sortedCols = Object.keys(item).sort();
        let newItem = {import: ''};
        sortedCols.forEach(col => {
          newItem[col] = item[col];
        });
        sortedData.push(newItem);
      });
      exportJsonToXlsx(sortedData, filename);
      downloadCompleteCallback();
    }
  }, [shouldDownloadRecords, downloadCompleteCallback, careCenterImport]);

  useEffect(() => {
    if (visible && !loading && rowRef.current){
      window.scrollTo(0, rowRef.current.offsetTop);
    }
  }, [visible, rowRef, loading]);

  return (
    <Container fluid>
      { (loading || isEmpty(careCenterImport)) &&
        <div className={styles.dataImportLoading}><BoxLoading width={330} height={330} /></div> 
      }
      {!loading && !isEmpty(careCenterImport) &&
      <>
        <div id='scrollRefDiv' ref={rowRef}/>
        <Row>
          <Col sm={4}>
            <ItemList data={[...careCenterImport.records]} 
                      onClick={onRecordClick}
                      headerRowElement={DataImportHeaderRow}
                      dataRowElement={DataImportDataRow}
                      itemsPerPage={10}
            />
          </Col>
          <Col>
          { !isEmpty(activeRecord) &&
            <>
            <SectionHeader>
              <h4>{`${activeRecord.externalId} - ${activeRecord.status}`}</h4>
            </SectionHeader>
            <Container>
              {
                Object.entries(activeRecord.payload).map(entry => {
                  const [key, value] = entry;
                  return(
                    <Row key={key}>
                      {key}: {value}
                    </Row>
                  )
                })
              }
            </Container>
            </>
          }
          </Col>
          <Col>
          { !isEmpty(activeRecord) &&
            <>
              <SectionHeader>
                <h4>Logs</h4>
              </SectionHeader>
              { logsData
                ? <>
                    <Container>
                    { logsData.applicationInsights.getFunctionLogs.length > 0
                      ? logsData.applicationInsights.getFunctionLogs.map(x => (
                          <Row key={x.itemId}>
                            {x.message}
                          </Row>
                        ))
                      : <Row>No logs found.</Row>
                    }
                    </Container>
                  </>
                : <div className={styles.dataImportLoading}><BoxLoading width={330} height={330} /></div> 
              }
            </>
          }
          </Col>
        </Row>
      </>
      }
    </Container>
  );
};

const DataImportHeaderRow = () => {
  return (
    <Row style={{marginTop: '10px'}} >
      <Col style={{marginLeft: '10px'}}>ExternalId</Col>
      <Col>Status</Col>
      <Col>CareCenter</Col>
    </Row>
  );
};

const DataImportDataRow = ({item, index, onClick}) => {
  return (
    <>
      <Row onClick={() => onClick(item)} className={styles.recordsRow}>
        <Col aria-label={`import.${index}.externalId`} style={{marginLeft: '10px'}}>{item.externalId}</Col>
        <Col aria-label={`import.${index}.status`}>{item.status}</Col>
        <Col aria-label={`import.${index}.careCenter`}>
        {item.careCenter
          ? <Link to={`${adminPaths.careCenters}/${item.careCenter}`} target="_blank">View</Link>
          : <>N/A</>
        }
        </Col>
      </Row>
      <hr />
    </>
  );
};
