import { Col, Container, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { benefitService } from "benefits/services/benefitService";
import styles from './profile.module.css';

export const EnrollmentInfo = ({enrollment}) => {

  const benefitPeriod = enrollment.getCurrentBenefitPeriod();
  const { apps } = benefitService(enrollment.plan.benefits);
  const benefits = enrollment.plan.benefits.map(b => {
    const benefit = apps[b.benefit];
    return benefit.name;
  });

  return(
    <Container className={styles.enrollmentinfocontainer}>
      <Row className={styles.enrollmentinfoheader}>
        <Col className="w-100">
          <h3 className="inverse">My Plan</h3>
        </Col>
      </Row>
      <Row>
        <Col className={styles.enrollmentinfo}>
          <h4>{enrollment.plan.title}</h4>
        </Col>
      </Row>
      <Row>
        <Col className={styles.enrollmentinfo}>
          <strong>Benefit Period:</strong>
        </Col>
        <Col className={`d-flex justify-content-end ${styles.enrollmentinfo}`}>
          {benefitPeriod.start.toLocaleString(DateTime.DATE_MED)} - {benefitPeriod.end.toLocaleString(DateTime.DATE_MED)}
        </Col>
      </Row>
      <EnrollmentDates period={enrollment.period} />
      <Row><Col><hr /></Col></Row>
      <Row>
        <Col className={styles.enrollmentinfo}>
          <h5>Benefits:</h5>
        </Col>
      </Row>
      <Row>
        <Col className={styles.enrollmentinfo}>
          <ul>
            {benefits.map(benefit => 
              <li key={benefit}>{benefit}</li>
            )}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

const EnrollmentDates = ({period}) => {
  let enrollmentStart;
  if (period?.start) {
    enrollmentStart = DateTime.fromMillis(period.start).toUTC().toLocaleString(DateTime.DATE_MED);
  }
  let terminationDate;
  if (period?.end){
    terminationDate = DateTime.fromMillis(period.end).toUTC().toLocaleString(DateTime.DATE_MED);
  }


  return(
    <Row>
      <Col className={`d-flex justify-content-end ${styles.enrollmentinfo}`}>
      { terminationDate
        ?  <>Terminated on: {terminationDate}</>
        : enrollmentStart && <>Enrolled since: {enrollmentStart}</>
      }
      </Col>
    </Row>
  );
};