import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { useFormContext } from 'react-hook-form';
import { FaHome, FaBriefcase, FaMobileAlt, FaQuestion, FaPrint } from 'react-icons/fa';
import { IconSpacer } from '../buttons';
import styles from './contact.module.css';

export const DisplayPhoneNumber = ({ phoneNumber }) => {

  return (
    <PhoneNumber 
      countryCode={phoneNumber.country.code} 
      number={phoneNumber.number} 
      extension={phoneNumber.extension}
      type={phoneNumber.type}
   />
  );
};

export const DisplayPhoneNumberInForm = ({ namePrefix }) => {
  const { watch } = useFormContext();

  const number = watch(`${namePrefix}.number`);
  const countryCode = watch(`${namePrefix}.country.code`);
  const extension = watch(`${namePrefix}.extension`);
  const type = watch(`${namePrefix}.type`);

  return (
    <PhoneNumber 
      countryCode={countryCode} 
      number={number} 
      extension={extension} 
      type={type}
    />
  );
};

const PhoneNumber = ({countryCode, number, extension, type}) => {

  const formatted = countryCode === 'US'
    ? formatPhoneNumber(number)
    : formatPhoneNumberIntl(number);
  let fullNumber = formatted;

  if (extension && extension !== '') {
    fullNumber = `${fullNumber}, Ext: ${extension}`;
  }

  return (
    <><TypeIcon type={type} />{fullNumber}</>
  );
};

const TypeIcon = ({type}) => {
  const Icons = {
    HOME: FaHome,
    WORK: FaBriefcase,
    MOBILE: FaMobileAlt,
    FAX: FaPrint
  };

  const Icon = type in Icons
    ? Icons[type]
    : FaQuestion;

  return(
    <>
      <Icon className={styles.phoneIcon} size={20} />
      <IconSpacer />
    </>
  );
  
};