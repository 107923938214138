import { gql } from '@apollo/client';

export const NETWORK_FIELDS = gql`
  fragment NetworkFields on Network {
    id
    name
    integrations {
      id
      type
      configuration
    }
    tenant {
      name
      id
    }
  }
`;

export const CARE_CENTER_IMPORT_FIELDS =gql`
  fragment CareCenterImportFields on CareCenterImport {
    id
    externalSource {
      type
      id
    }
    identifier
    records {
      status
      externalId
      careCenter
      invocationId
    }
  }
`;

export const CARE_CENTER_IMPORT_WITH_RECORD_PAYLOAD_FIELDS =gql`
  fragment CareCenterImportWithRecordPayloadFields on CareCenterImport {
    id
    externalSource {
      type
      id
    }
    identifier
    records {
      status
      externalId
      careCenter
      invocationId
      payload
    }
  }
`;

export const CARE_CENTER_IMPORT_CONTROL_FIELDS = gql`
  fragment CareCenterImportControlFields on CareCenterImportControl {
    id
    knownExternalIds
    externalSource {
      type
      id
    }
  }
`;
