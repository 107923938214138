import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import styles from './generosityScore.module.css';

export const GenerosityScore = ({careCenterResult}) => {
  const score = careCenterResult.score;
  const isUnknown = score === undefined || score === 'unknown';
  
  return (
    <>
    <Row className="justify-content-center">
      <Col className="col-auto">
    <table className={styles.table}>
      <tbody>
      <tr>
        <TriangleCell min={0} max={49} score={score} />
        <TriangleCell min={50} max={74} score={score} />
        <TriangleCell min={75} max={99} score={score} />
        <TriangleCell min={100} max={100} score={score} />
      </tr>
      <tr className={styles.nopadding}>
        <td className={`${styles.nopadding} ${styles.percentile} ${styles.percentileOne}`}>
          {isUnknown && <>&nbsp;&nbsp;?</>}
        </td>
        <td className={`${styles.nopadding} ${styles.percentile} ${styles.percentileTwo}`}></td>
        <td className={`${styles.nopadding} ${styles.percentile} ${styles.percentileThree}`}></td>
        <td className={`${styles.nopadding} ${styles.percentile} ${styles.percentileFour}`}></td>
      </tr>
      </tbody>
    </table>
      </Col>
    </Row>
    <Row>
        <Col className={`text-center ${styles.what}`}>
          <OverlayTrigger
            trigger={[ 'hover', 'focus' ]}
            placement="bottom"
            delay={{ show: 0, hide: 300 }}
            overlay={
              <Popover>
                <Popover.Body>
                  You may be eligible for free or reduced services from these listed hospitals. Their individual formulas will determine the final cost to you. We have ranked these hospitals based on their levels of perceived generosity; which may not always result in the lowest out of pocket costs.
                </Popover.Body>
              </Popover>
            }
          >
            <span className={styles.tip}>
              <FaInfoCircle size={12} className={styles.whaticon} />
              What's this?
            </span>
          </OverlayTrigger>
        </Col>
    </Row>
    </>
  );
};

const TriangleCell = ({min, max, score}) => {

  if (score >= min && score <= max) {
    return (
      <td className={styles.trianglecell}>
        <span className={styles.triangle}>
        &#x25BC;	
        </span>
      </td>
    );
  } else {
    return (<td></td>);
  }
};