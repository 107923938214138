import * as Yup from 'yup';

const getPercentageSchema = (fieldLabel) => (
 Yup.number()
    .typeError(`${fieldLabel} required`)
    .min(0, 'Please enter a number between 0 and 100')
    .max(100, 'Please enter a number between 0 and 100')
    .required(`${fieldLabel} required`)
    .nullable()
);

const getBasisSchema = (fieldLabel) => (
  Yup.number()
     .typeError(`${fieldLabel} required`)
     .min(0, 'Please enter a number greater than 0')
     .required(`${fieldLabel} required`)
     .nullable()
 );

const policy501Schema = Yup.object().shape({
  determinationType: Yup.string().nullable().default(null),
  maxPercentage: Yup.number().default(null)
    .when('determinationType', {
      is: undefined,
      then: Yup.number().nullable()
    }).when('determinationType', {
      is: val => val === 'FLAT' || val === 'SLIDING',
      then: getBasisSchema('Max Percentage')
    }).nullable(),
  assistanceScales: Yup.array().nullable().default(null)
    .when('determinationType', {
      is: 'SLIDING',
      then: Yup.array()
        .required('Please add at least one scale')
        .min(1, 'Please add at least one scale')
        .of(Yup.object().shape({
          minPercentage: getBasisSchema('Min Percentage'),
          maxPercentage: getBasisSchema('Max Percentage'),
          financialAssistancePercentage: getPercentageSchema('Coverage Percentage'),
        }))
        .nullable(),
      otherwise: Yup.array().nullable().default(null)
    })
}).nullable();

const financialAssistancPolicySchema = Yup.object().shape({
  type: Yup.string().nullable().default(null),
  configuration: Yup.object()
    .when('type', {
      is: 'ACA501R4',
      then: policy501Schema
    }).nullable()
});

const financialAssistancePoliciesSchema = Yup.array()
  .of(financialAssistancPolicySchema).nullable();

export const careCenterProfileValidationSchema = Yup.object().shape({
  careCenter: Yup.object().shape({
    name: Yup.string().required('Name required').nullable(),
    tenant: Yup.object().test('tenant', 'Tenant required', 
      tenant =>  tenant?.id?.length > 0).nullable(),
    address: Yup.object().test('address', 'Address required', 
      address =>  address?.line1?.length > 0).nullable(),
    overview: Yup.string().required('Overview required').nullable(),
    termsAndConditions: Yup.string().required('Terms and Conditions required').nullable(),
    financialAssistancePolicies: financialAssistancePoliciesSchema
  })
});
