import { EditButton } from "shared/components/buttons/editButton";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { useState } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import { FaGripVertical } from 'react-icons/fa';
import styles from 'payers/payers.module.css';

export const PlanBenefitDisplay = ({benefit, index, registerPrefix}) => {
  const [showModal, toggleModal] = useState(false);
  const EmptyConfig = () => { return ( <></> ) };
  const ConfigBody = benefit.config ?? EmptyConfig;
  
  return (
    <>
      <Row>
        <Col>
          <Row className={`${styles.benefitListItem} ${styles.shake}`}>
            <Col xs={1}>
              <FaGripVertical />
            </Col>
            <Col>
              {benefit.name}
            </Col>
          </Row>
        </Col>
        <Col>
          <EditButton disabled={ConfigBody === EmptyConfig} onClick={() => toggleModal(true)}/>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        aria-labelledby="help-modal"
        centered
      >
        <Modal.Body id="help-modal">
          <Container>
            <Row className="justify-content-end">
              <Col xs={9}>
                <h4>{benefit.name} Configuration</h4>
              </Col>
              <Col className="text-end">
                <AiOutlineClose size={25} className={styles.closeicon} onClick={() => toggleModal(false)} />
              </Col>
            </Row>
            <ConfigBody index={index} registerPrefix={registerPrefix} />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
