import { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { getCountryCallingCode } from 'react-phone-number-input';
import { getError } from "shared/services/validationService";
import { DisplayPhoneNumberInForm } from './displayPhoneNumber';
import { DropDown, Input, Section } from '../form';
import { DeleteButton, EditButton, IconSpacer, SaveButton } from '../buttons';
import 'react-phone-number-input/style.css'
import styles from './contact.module.css';

export const EditPhoneNumber = ({ 
    registerPrefix, 
    index, 
    editIndex, 
    canEdit,
    onEdit,
    onComplete,
    onDelete,
    errors,
    showButtons=true
}) => {
  const { control, getValues, register, setValue, trigger } = useFormContext();
  const registerField = `${registerPrefix}.${index}`;
  let defaultCountry = getValues(`${registerField}.country.code`);
  const defaultPrefix = getValues(`${registerField}.country.prefix`);
  if (!defaultPrefix) setValue(`${registerField}.country.prefix`, '1');
  if (!defaultCountry) {
    defaultCountry = 'US';
    setValue(`${registerField}.country.code`, defaultCountry);
  }

  const phoneTypes = {
    'HOME': 'Home',
    'WORK': 'Work',
    'MOBILE': 'Mobile',
    'FAX': 'Fax'
  };

  useEffect(() => {
    register(`${registerField}.country.code`);
    register(`${registerField}.country.prefix`);
  }, [index, register, registerField]);

  const onPhoneNumberKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSave();
    }
  };

  const getCurrentItem = () => {
    return getValues(`${registerField}`);
  };

  const onSave = async() => {
    const result = await trigger(registerField);
    if (result === true) onComplete();
  };

  const onCountryChanged = useCallback((value) => {
    if (!value) return;
    const callingCode = getCountryCallingCode(value);
    setValue(`${registerField}.country.code`, value);
    setValue(`${registerField}.country.prefix`, callingCode);
  }, [registerField, setValue]);

  const baseClassName = `form-control ${styles.phoneField}`;
  const [phoneNumberProps, setPhoneNumberProps] = useState({
    onKeyDown: onPhoneNumberKeyDown,
    placeholder: 'Number',
    autoFocus: true,
    className: baseClassName
  });
  const countrySelectProps = {
    className: baseClassName
  };

  useEffect(() => {
    const phoneNumberErr = getError(`${registerField}.number`, errors)?.message
    if (phoneNumberErr) {
      phoneNumberProps.className = `${baseClassName} is-invalid`
      setPhoneNumberProps(phoneNumberProps);
    }

  }, [baseClassName, errors, phoneNumberProps, registerField]);

  return (
    <Section>
      <Col lg={3} sm={4} xs={12} className="my-top">
      {editIndex === index
        ?
          <>
            <PhoneInputWithCountry
              data-testid="phoneInput"
              autoFocus={true}
              initialValueFormat="national"
              defaultCountry={defaultCountry}
              name={`${registerField}.number`}
              onCountryChange={onCountryChanged}
              numberInputProps={phoneNumberProps}
              countrySelectProps={countrySelectProps}
              control={control} />
          <div className={`invalid-feedback ${styles.showPhoneValidationError}`}>
            {getError(`${registerField}.number`, errors)?.message}
          </div>
          </>
        :
          <DisplayPhoneNumberInForm
            namePrefix={`${registerField}`}
            defaultPhoneNumber={getCurrentItem}
          />
      }
      </Col>
      {editIndex === index &&
        <Col lg={2} sm={{span: 2, offset: 0}} xs={{span: 9, offset: 3}}>
          <Controller
            control={control}
            name='extension'
            render={({ ref }) => (
              <Input
                data-testid="extension"
                inputRef={ref}
                label="Extension"
                onKeyDown={onPhoneNumberKeyDown}
                registerInput={`${registerField}.extension`} />
            )} />
        </Col>
      }
      {editIndex === index &&
        <Col lg={2} sm={{span: 2, offset: 0}} xs={{ span: 9, offset: 3}}>
          <DropDown
            testid="type"
            size="sm"
            label="Type"
            items={Object.entries(phoneTypes).map((item) => { return { value: item[0], text: item[1] }; })}
            registerInput={`${registerField}.type`} 
            errors={errors}
          />
        </Col>
      }
      { showButtons && 
        <Col lg={2} sm={{ span: 3, offset: 0 }} xs={{ span: 5, offset: 7 }} className="my-auto">
          {editIndex !== index
            ?
            <EditButton data-testid="edit" tooltip="Edit" disabled={!canEdit} onClick={() => onEdit(index)} />
            :
            <SaveButton tooltip="Save" onClick={() => onSave()} />
          }
          <IconSpacer />
          <DeleteButton tooltip="Delete" onClick={() => onDelete(index)} />
        </Col>
      }
    </Section>
  );
};