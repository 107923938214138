import { Container } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { Pager } from "./pager";

export const ItemList = ({ 
  data,
  detailLink,
  dataRowElement,
  headerRowElement,
  handlePageChange,
  idFunc,
  itemsPerPage,
  numberOfItems,
  onClick,
  loading,
  loadingComponent,
  resetPager,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  let Loading = loadingComponent ?? (<div>Loading...</div>)
  const defaultHandlePageChange = useCallback(function (page) {
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(data.slice(indexOfFirstItem, indexOfLastItem));
  }, [data, itemsPerPage]);

  const activeHandlePageChange = handlePageChange ?? defaultHandlePageChange;
  itemsPerPage = itemsPerPage ?? 10;

  const totalCount = numberOfItems ?? data.length;
  let HeaderRow = headerRowElement ?? Empty;
  let DataRow = dataRowElement ?? Empty;

  /* 
    handlePageChange is a passed in handler for using server based pagination.
    when it is used, the data that is passed in should all be displayed.
    when it is null, we will instead handle pagination via defaultHandlePageChange
    and will need to explicitly choose which section of the data is active
  */
  useEffect(() => {
    if (!handlePageChange) {
      defaultHandlePageChange(1);
    } else {
      setCurrentItems(data);
    }
  }, [data, defaultHandlePageChange, handlePageChange]);

  return(
    <Container fluid>
      <HeaderRow />
      <hr />
      { loading && Loading }
      { !loading && currentItems.length === 0 && <div>No results found</div> }
      { !loading && currentItems.length > 0 && currentItems.map((item, index) => (
        <DataRow key={index} item={item} index={index} detailLink={detailLink} idFunc={idFunc} onClick={onClick} /> 
      ))}
      <Pager itemCount={totalCount} itemsPerPage={itemsPerPage} onPageChanged={activeHandlePageChange} resetPager={resetPager} />
    </Container>
  )
};

const Empty = () => {
  return (<></>);
};