import { gql } from '@apollo/client';
import { NETWORK_FIELDS } from '../fragments';

export const CREATE_NETWORK = gql`
  ${NETWORK_FIELDS}

  mutation CreateNetwork($input: CreateNetworkInput!) {
    createNetwork(input: $input) {
      ...NetworkFields
    }
  }
`;