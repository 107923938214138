import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { useParams } from 'react-router';
import { FindHospitalHelp, FindHospitalHelpButton } from "benefits/indemnity/components/help";
import { ViewHospitalDetail } from 'careCenter/components/viewHospitalDetail';
import styles from 'benefits/indemnity/indemnity.module.css';

export const HospitalDetail = () => {
  const { careCenterId, score } = useParams();
  const { consumer } = useCurrentConsumer();

    return (
      <>
      { consumer && 
        <>
          <ViewHospitalDetail careCenterId={careCenterId} consumerId={consumer.id} score={score}/>
          <div className={styles.helpadjustment}>
            <FindHospitalHelp />
          </div>
          <FindHospitalHelpButton />
        </>
      }
      </>
    );
};