import { FaSave } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const SaveButton = ({...rest}) => {

  return (
    <IconButton 
      IconComponent={FaSave}
      iconClass={styles.save}
      {...rest} />
  );
};