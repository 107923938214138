import { gql } from '@apollo/client';
import { CORRESPONDENT_FIELDS } from 'message/operations/fragments';

export const GET_MESSAGE_RECIPIENT_CORRESPONDENT = gql`
  ${CORRESPONDENT_FIELDS}

  query FindMessageRecipients($messageId: ID) {
    findMessageRecipients(filter: {
      message: {
        id: {
          eq: $messageId
        }
      }
    }) {
      items {
        recipient {
          ...CorrespondentFields
        }
      }
    }
  }
`;