import { EditPlanBenefits } from "payers/components/edit/editPlanBenefits";
import { EditPlanNetworks } from "payers/components/edit/editPlanNetworks";
import { EditPlanBenefitPeriod } from "payers/components/edit/editPlanBenefitPeriod";
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from "shared/components/form/input";
import { SectionHeader, Section, TextArea } from "shared/components/form";
import { ClipboardButton } from "shared/components/buttons";
import { Container, Col, Row } from "react-bootstrap";
import { PlanLogoSmall } from "benefits/shared";
import { FaInfoCircle } from "react-icons/fa";
import { EmployeeFileUpload } from "./employeeFileUpload";
import styles from 'payers/payers.module.css';

export const EditPlan = ({registerPrefix, errors, isNew}) => {
  const { control } = useFormContext();

  return (
    <Container fluid>
      <SectionHeader>
        <Controller
          name={`${registerPrefix}.id`}
          control={control}
          render={({ field }) => (
            <Row  className={styles.planHeader}>
              <Col>
                <PlanLogoSmall /> 
                <span className={styles.planId}>
                  Plan ID: {field.value}
                  <ClipboardButton copyText={field.value} size={15} />
                </span>
              </Col>
            </Row>
          )}
        />
      </SectionHeader>
      <Section>
        <Col lg={4}>
          <SectionHeader>
            <h4>Basic Information</h4>
          </SectionHeader>
          <Container>
            <Col>
              <Input 
                autoFocus={true}
                label="Name"
                registerInput={`${registerPrefix}.name`} 
                errors={errors}
              />
            </Col>
            <Col>
              <Input 
                label="Title"
                registerInput={`${registerPrefix}.title`} 
                errors={errors}
              />
            </Col>
          </Container>
        </Col>
        <Col lg={4}>
          <SectionHeader>
            <h4>Intro</h4>
          </SectionHeader>
          <Container>
            <Controller
              name="controller"
              control={control}
              render={({ ref }) => (
                <TextArea 
                  placeHolder="Intro"
                  registerInput={`${registerPrefix}.intro`} 
                  errors={errors}
                  className={styles.planIntro}
                />
              )}
            />
          </Container>
        </Col>
        <Col lg={4}>
          <SectionHeader><h4>Benefit Period</h4></SectionHeader>
          <EditPlanBenefitPeriod registerPrefix={registerPrefix}/>
        </Col>
      </Section>
      <div style={{marginTop: '75px'}}></div>
      <Section>
        <Col lg={4}>
          <SectionHeader>
            <h4>Benefits <FaInfoCircle size={15} data-tip="Drag benefits to rearrange them"/></h4>
          </SectionHeader>
          <EditPlanBenefits registerPrefix={registerPrefix} />
        </Col>
        <Col lg={4}>
          <SectionHeader><h4>Networks</h4></SectionHeader>
          <EditPlanNetworks registerPrefix={registerPrefix}/>
        </Col>
        <Col lg={4}>
          <SectionHeader>
            <h4>Upload Employee File</h4>
          </SectionHeader>
          { isNew
            ? <span>A plan must be created before this can be done.</span>
            : <EmployeeFileUpload registerPrefix={registerPrefix} />
          }
        </Col>
      </Section>
    </Container>
  );
};
