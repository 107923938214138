import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const phoneValidationSchema = Yup.object().shape({
  number: Yup.string()
    .required('Valid phone number is required')
    .test('is-phone', 'Valid phone number is required', (value) => {
      if (value) return isPossiblePhoneNumber(value);

      return false;
    })
    .nullable(),
  type: Yup.string()
    .required('Type is required')
    .nullable()
});

 export const atLeast1PhoneNumberValidationSchema = Yup.array()
  .of(phoneValidationSchema)
  .min(1, 'Please enter at least 1 phone number')
  .required();