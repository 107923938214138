export const IconSpacer = () => {

  const spacerStyle = {
    margin: '5px'
  };

  return (
    <span style={spacerStyle}>{" "}</span>
  );
};
