import { Policies } from './pages/policies';

export const policyPaths = {
  root: '/policies',
  tandc: '/policies/tandc',
  privacy: '/policies/privacy'
};

export const policyRoutes = [{
  exact: false,
  path: policyPaths.root,
  key: 'policies',
  component: Policies,
  routes: [
    {
      path: policyPaths.tandc,
      key: 'tandc',
      component: Policies,
    },
    {
      path: policyPaths.privacy,
      key: 'privacy'
    }
  ]
}];