import { makeVar } from '@apollo/client';
import { getReadRecordsFunction, getMergeRecordsFunction } from 'apollo/merge';
import { MessagesPerPage } from "message/constants";

const currentMessageRecipientsOffset = makeVar();
const currentMessagesOffset = makeVar();

const readMessageRecipientRecords = getReadRecordsFunction(currentMessageRecipientsOffset, MessagesPerPage);
const mergeMessageRecipientRecords = getMergeRecordsFunction(currentMessageRecipientsOffset);
const readMessageRecords = getReadRecordsFunction(currentMessagesOffset, MessagesPerPage);
const mergeMessageRecords = getMergeRecordsFunction(currentMessagesOffset);

export const messageCaching = {
  findMessageRecipients: {
    read(existing) { 
      return readMessageRecipientRecords(existing);
    },
    keyArgs: ['filter'],
    merge(existing, incoming, { args }) {
      return mergeMessageRecipientRecords(existing, incoming, args);
    }
  },
  findMessages: {
    read(existing) { 
      return readMessageRecords(existing);
    },
    keyArgs: ['filter'],
    merge(existing, incoming, { args }) {
      return mergeMessageRecords(existing, incoming, args);
    }
  }
}