import { appSettings } from "appSettings"

export function getCoverImageUrl(size) {

  if (this.media && this.media.length > 0) {
    const coverImage = this.media.find(x => x.isCoverImage === true);
    if (coverImage) {
      return `${appSettings.MEDIA_URL}/careCenters/${this.id}/profile/${coverImage.fileName}/${size}.${coverImage.extension}`;
    }
  }

  return undefined;
};