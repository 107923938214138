import { Container, Col, Nav, Row } from "react-bootstrap";
import { NavLink, Route, Switch, useHistory, useRouteMatch, useParams } from "react-router-dom";
import { FaArchive, FaInbox, FaFolderOpen, FaSignOutAlt } from "react-icons/fa";
import { RecipientFolder } from "./recipientFolder";
import { SentFolder } from "./sentFolder";
import { Message } from "./message";
import styles from './message.module.css';

export const MessageCenter = ({ correspondent }) => {
  const { path, url } = useRouteMatch();
  const params = useParams();
  const history = useHistory();

  let folder = params.folder;
  if (!folder) {
    history.replace(`${url}/inbox`);
    folder = 'inbox';
  }

  let messagePath = path.replace(':folder?', folder);
  messagePath = messagePath.endsWith('/:messageId?')
    ? messagePath.replace('messageId?', 'messageId')
    : `${messagePath}/:messageId`;

  const getFolderPath = (folder) => {
    const pathParts = path.split('/');
    const urlParts = url.split('/');
    const folderLocation = pathParts.indexOf(':folder?');

    const newUrl = [];
    for (let i = 0; i < folderLocation; i++) {
      if (pathParts[i].startsWith(':')) {
        const param = pathParts[i].replace(/[\W]/g, '');
        const paramValue = params[param];
        newUrl.push(paramValue);
      } else {
        newUrl.push(urlParts[i]);
      }
    }
    newUrl.push(folder);
    return newUrl.join('/');
  };

  return (
    <Container className={styles.messageCenter}>
      <Row>
        <Col md={3} lg={2} className={styles.folderlistcontainer}>
          <Row>
            <Col className={styles.folderlistheader}>
              <FaFolderOpen size={25} className={styles.folderlistheadericon} />
            </Col>
          </Row>
          <Row>
            <Col className={styles.folderlist}>
              <Nav>
                <FolderLink link={getFolderPath('inbox')} label="Inbox" Icon={FaInbox} />
                <FolderLink link={getFolderPath('sent')} label="Sent" Icon={FaSignOutAlt} />
                <FolderLink link={getFolderPath('archive')} label="Archive" Icon={FaArchive} />
              </Nav>
            </Col>
          </Row>
        </Col>
        <Col md={9}>
          <Switch>
            <Route path={messagePath} exact>
              <Message 
                correspondent={correspondent}
                direction={folder.toLowerCase() === 'sent' ? 'OUT' : 'IN'}
              />
            </Route>
            <Route path={getFolderPath('inbox')} exact>
              <RecipientFolder 
                correspondent={correspondent}
                title="Inbox"
                statuses={['UNREAD', 'READ']}
              />
            </Route>
            <Route path={getFolderPath('sent')} exact>
              <SentFolder correspondent={correspondent} />
            </Route>
            <Route path={getFolderPath('archive')} exact>
              <RecipientFolder 
                correspondent={correspondent}
                title="Archive"
                statuses={['ARCHIVED']}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

const FolderLink = ({ link, label, Icon }) => { 

  return (
    <NavLink
      to={link}
      activeClassName={styles.activefolderlink}
      className={styles.folderlink}
    >
      <Icon className={styles.foldericon} size={20} />
      {label}
    </NavLink>
  );
};