import { gql } from '@apollo/client';
import { NOTE_FIELDS } from 'case/operations/fragments';

export const CREATE_NOTE = gql`
  ${NOTE_FIELDS}
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      ...NoteFields
    }
  }
`;
