import { useQuery } from "@apollo/client";
import { GET_SENDER_MESSAGE_LIST } from "message/operations/queries/getSenderMessageList";
import { MessageFolder } from "./messageFolder";

export const SentFolder = ({correspondent}) => {

  const filter = {
    sender: {
      type: { eq: correspondent.type },
      id: { eq: correspondent.id }
    },
    sentTo: {name: {ne: null}}
  };

  const { data, fetchMore } = useQuery(
    GET_SENDER_MESSAGE_LIST, {
      variables: { filter: filter, offset: 0 },
      notifyOnNetworkStatusChange: true
    }
  );

  if (data) {
    const messages = data.findMessages.items.map(x => ({
      id: x.id,
      subject: x.subject,
      correspondents: x.sentTo.map(y => y.name),
      sentAt: x.sentAt,
    }));

    const totalCount = data.findMessages.count;

    return (
      <MessageFolder 
        title="Sent"
        messages={messages}
        direction="OUT"
        fetchMore={fetchMore}
        totalCount={totalCount}
      />
    );
  }

  return 'Loading...';
};