import { OrderType } from "./orderType";
import { FollowupOrder } from "consumer/components/todo/doctorsOrders/followupOrder";
import { InstructionsOrder } from "consumer/components/todo/doctorsOrders/instructionsOrder";
import { ReferralOrder } from "consumer/components/todo/doctorsOrders/referralOrder";
import { RxOrder } from "consumer/components/todo/doctorsOrders/rxOrder";

export const OrderComponentMap = {
  [OrderType.Followup]: FollowupOrder,
  [OrderType.Instructions]: InstructionsOrder,
  [OrderType.Referral]: ReferralOrder,
  [OrderType.Rx]: RxOrder
};