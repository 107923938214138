import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Menu } from './menu';
import { SecuredMenuItem } from './securedMenuItem';
import { adminPaths } from 'admin/routes';
import { conciergePaths } from 'concierge/routes';
import { permissions } from 'auth/permissions';

export const AdminMenu = ({ changeMode }) => {

  return (
    <Menu canSwitch={true} switchToMode='Consumer' changeMode={changeMode}>
      <>
        <NavDropdown title="Concierge" id="collasible-nav-dropdown" variant="light">
          <SecuredMenuItem minimumPermission={permissions.concierge.access}>
            <NavDropdown.Item>
              <Nav.Link as={Link} to={conciergePaths.conciergeCases}>Cases</Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Nav.Link as={Link} to={conciergePaths.conciergeConsumers}>Consumers</Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Nav.Link as={Link} to={conciergePaths.conciergeMessages}>Messages</Nav.Link>
            </NavDropdown.Item>
          </SecuredMenuItem>
        </NavDropdown>
        <SecuredMenuItem minimumPermission={permissions.careCenter.edit}>
          <Nav.Link as={Link} to={adminPaths.careCenters}>Care Centers</Nav.Link>
        </SecuredMenuItem>
        <SecuredMenuItem minimumPermission={permissions.network.edit}>
          <Nav.Link as={Link} to={adminPaths.networks}>Networks</Nav.Link>
        </SecuredMenuItem>
        <SecuredMenuItem minimumPermission={permissions.payer.edit}>
          <Nav.Link as={Link} to={adminPaths.payers}>Payers</Nav.Link>
        </SecuredMenuItem>
        {/* <Nav.Link href="#">Medical Services</Nav.Link> */}
        <SecuredMenuItem minimumPermission={permissions.admin.tenant}>
          <Nav.Link as={Link} to={adminPaths.users}>Users</Nav.Link>
        </SecuredMenuItem>
        {/* <Nav.Link href="#">Tenants</Nav.Link> */}
      </>
    </Menu>
  );
};
