import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { IconButton } from 'shared/components/buttons/iconButton';
import { GET_CONSUMER_FOR_ACCOUNT } from 'consumer/operations/queries/getConsumerForAccount';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

export const SenderAction = ({content}) => {
  const [sender, setSender] = useState(null);
  useQuery(GET_CONSUMER_FOR_ACCOUNT, {
    variables: { accountId: content.createdBy},
    onCompleted: (data) => {
      setSender(data.findConsumers.items[0]);
    }
  });

  return (
    <OverlayTrigger
      trigger={[ 'hover', 'focus' ]}
      placement="bottom"
      rootClose
      delay={{ show: 0, hide: 300 }}
      overlay={
        <Popover>
          <Popover.Body>
            { sender &&
              <>
                {`${sender.contact.name.first} ${sender.contact.name.last}`}
              </>
            }
          </Popover.Body>
        </Popover>
      }
    >
      <span>
        <IconButton IconComponent={FaUserCircle} size={15} tooltip='Sender' /> 
      </span>
    </OverlayTrigger>
  );
};