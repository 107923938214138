import { gql } from '@apollo/client';
import { PAYER_FIELDS } from '../fragments';

export const GET_PAYER = gql`
  ${PAYER_FIELDS}
  
  query Query($id: ID!) {
  getPayer(id: $id) {
    ...PayerFields
  }
}
`;