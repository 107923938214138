export async function initializeConfiguration() {
    const configResponse = await fetch('/config.json', {
        headers: {
            'Cache-Control': 'no-cache'
        }
    });

    appSettings = await configResponse.json();
}

export let appSettings = {};