import { makeVar } from '@apollo/client';
import { CasesPerPage } from "case/constants";
import { getReadRecordsFunction, getMergeRecordsFunction } from 'apollo/merge';

const currentOffset = makeVar();
const readOrderRecords = getReadRecordsFunction(currentOffset, CasesPerPage);
const mergeOrderRecords = getMergeRecordsFunction(currentOffset);

export const casesCaching = {
  findCases: {
    read(existing) { 
      return readOrderRecords(existing);
    },
    keyArgs: ['filter'],
    merge(existing, incoming, { args }) {
      return mergeOrderRecords(existing, incoming, args);
    }
  }
};
