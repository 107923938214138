import { Col } from 'react-bootstrap';
import { SimpleDropDown } from 'shared/components/form';
import { useState } from 'react';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { GET_LYRIC_REASONS } from 'integrations/lyric/operations/queries/getReasons';
import { Multiselect } from 'react-widgets';
import { useEffect } from 'react';
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { getError } from "shared/services/validationService";

export const LyricProblemsSelector = ({onSelected, errors, registerInput}) => {
  const [availableLyricReasons, setAvailableLyricReasons] = useState([]);
  const [chiefComplaint, setChiefComplaint] = useState('');
  const [otherProblems, setOtherProblems] = useState([]);
  const [shouldCallOnSelected, setShouldCallOnSelected] = useState(false);
  useQueryWrapper(GET_LYRIC_REASONS, {
    onCompleted: (data) => setAvailableLyricReasons(data.findLyricReasons.items.map(reason => ({
      text: reason.name.replace(/"/g, ''),
      value: reason.problemId
    })).sort((a, b) => a.text.localeCompare(b.text)))
  });

  const getChiefComplaintError = () => getError(`${registerInput}.chiefComplaint`, errors);

  const onChiefComplaintSelected = (event) => {
    var newChiefComplaint = parseInt(event.target.value);
    if (isNaN(newChiefComplaint)) newChiefComplaint = '';
    setChiefComplaint(newChiefComplaint);
    setOtherProblems(otherProblems.filter(x => x.value !== newChiefComplaint));
    setShouldCallOnSelected(true);
  };

  const onOtherProblemSelected = (problems) => {
    setOtherProblems(problems);
    setShouldCallOnSelected(true);
  }

  const secondaryProblems = availableLyricReasons.filter(item => item.value !== chiefComplaint);

  useEffect(() => {
    if (shouldCallOnSelected) {
      setShouldCallOnSelected(false);
      onSelected({ chiefComplaint: chiefComplaint, otherProblems: otherProblems.map(x => x.value) });
    }
  }, [onSelected, chiefComplaint, otherProblems, shouldCallOnSelected, setShouldCallOnSelected]);

  return (
    <>
      <Col xs={12}>
        <SimpleDropDown label='Primary Reason for Consult' items={availableLyricReasons} onSelected={onChiefComplaintSelected}/>
        <div className={`invalid-feedback ${styles.chiefComplaintValidationError}`}>
          {getChiefComplaintError()?.message}
        </div>
      </Col>
      <Col xs={12}>
        <div className={styles.secondaryProblems}>
          <FloatingLabelControl label={'Secondary Concerns'} for={`otherProblemsSelect`}>
            <Multiselect
              aria-autocomplete='list'
              id={'otherProblemsSelect'}
              containerClassName={styles.secondaryProblemsContainer}
              placeholder=' '
              value={otherProblems}
              textField={item => item.text}
              data={secondaryProblems}
              onChange={onOtherProblemSelected}
            />
          </FloatingLabelControl>
        </div>
      </Col>
    </>
  );
};