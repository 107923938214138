import { appSettings } from "../appSettings";

export function initializeAuth() {
  msalConfig = {
    auth: {
      clientId: appSettings.REACT_APP_B2C_WEB_CLIENTID,
      authority: `https://${appSettings.REACT_APP_B2C_DIRECTORY_NAME}.b2clogin.com/${appSettings.REACT_APP_B2C_DIRECTORY_NAME}.onmicrosoft.com/B2C_1_susi`,
      knownAuthorities: [`${appSettings.REACT_APP_B2C_DIRECTORY_NAME}.b2clogin.com`],
      redirectUri: appSettings.REACT_APP_WEB_URL
    }
  };

  loginRequest = {
    scopes: [ `https://${appSettings.REACT_APP_B2C_DIRECTORY_NAME}.onmicrosoft.com/${appSettings.REACT_APP_B2C_API_CLIENTID}/api.exec` ]
  };
}

export let msalConfig = {};
export let loginRequest = {};