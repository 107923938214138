import { DetailBase } from "../detailBase";
import { Contents } from "./contents";
import { Filter } from "./filter";
import { useReducer } from 'react';
import { ContentTypes } from "./filter";

function reducer(state, action) {
  switch (action.type) {
    case 'case':
      return {...state, caseData: action.caseData };
    case 'filter': 
      return {...state, filter: action.filter};
    default:
      throw new Error('Unknown action.');
  }
};

export const Activity = ({ caseData }) => {
  const [state, dispatch] = useReducer(reducer, {caseData, filter: [ContentTypes.message, ContentTypes.note]});

  return (
    <DetailBase 
      caseData={state.caseData} 
      dispatch={dispatch}
      contents={<Contents filter={state.filter} caseData={state.caseData} />} 
      filter={<Filter dispatch={dispatch} initialValues={state.filter} />} />
  );
};
