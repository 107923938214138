import { FaSearch } from 'react-icons/fa';
import { IconButton } from '.';
import styles from './button.module.css';

export const SearchButton = ({...rest}) => {

  return (
    <IconButton 
      IconComponent={FaSearch}
      iconClass={styles.search}
      {...rest} />
  );
};