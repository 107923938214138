import { makeVar } from '@apollo/client';
import { OrdersPerPage } from "orders/constants";
import { getReadRecordsFunction, getMergeRecordsFunction } from 'apollo/merge';

const currentOffset = makeVar();
const readOrderRecords = getReadRecordsFunction(currentOffset, OrdersPerPage);
const mergeOrderRecords = getMergeRecordsFunction(currentOffset);

export const ordersCaching = {
  findOrders: {
    read(existing) { 
      return readOrderRecords(existing);
    },
    keyArgs: ['filter'],
    merge(existing, incoming, { args }) {
      return mergeOrderRecords(existing, incoming, args);
    }
  }
};
