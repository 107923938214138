import { gql } from '@apollo/client';
import { CASE_FIELDS } from 'case/operations/fragments';

export const GET_CASE = gql`
  ${CASE_FIELDS}
  query Query($id: ID!) {
    getCase(id: $id) {
      ...CaseFields
    }
  }
`;
