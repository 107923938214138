import { gql } from '@apollo/client';
import { PAYER_FIELDS } from '../fragments';

export const UPDATE_PAYER = gql`
  ${PAYER_FIELDS}

  mutation UpdatePayer($input: MutatePayerInput!) {
    updatePayer(input: $input) {
      ...PayerFields
    }
  }
`;