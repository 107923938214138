import { useState } from "react";
import { Col, Row, Modal } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import { OffCanvasWrapper } from "shared/components/offCanvasWrapper";
import offCanvasStyles from "shared/components/shared.module.css";
import ReactToolTip from 'react-tooltip';
import { LyricRequestAppointmentForm } from "./lyricRequestAppointmentForm";
import { ViewAppointmentRequest } from './viewAppointmentRequest'
import { GET_NETWORK } from 'network/operations/queries';
import { useQueryWrapper } from "apollo/useQueryWrapper";
import { useEffect } from "react";
import { useCurrentConsumer } from "consumer/hooks/useCurrentConsumer";
import isEmpty from "lodash.isempty";
import { GET_OPEN_LYRIC_APPOINTMENT } from "benefits/telehealth/operations/queries/getOpenLyricAppointment";

export const RequestAppointmentButton = ({network}) => {
  const { consumer } = useCurrentConsumer(); 
  const [activeIntegrationType, setActiveIntegrationType] = useState('');
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allowedNetworkIntegrationsTypes, setAllowedNetworkIntegrationsTypes] = useState([]);
  const [appointmentRequest, setAppointmentRequest] = useState({});
  const [hasOpenLyricAppointment, setHasOpenLyricAppointment] = useState(false);
  
  useQueryWrapper(GET_OPEN_LYRIC_APPOINTMENT, {
    skip: !consumer?.id,
    variables: { consumerId: consumer?.id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setHasOpenLyricAppointment(data.findAppointments.items.length > 0);
    }
  });

  useQueryWrapper(GET_NETWORK, {
    skip: !network,
    variables: { id: network },
    onCompleted: (data) => {
      setAllowedNetworkIntegrationsTypes(data.getNetwork?.integrations?.map(i => i.type) ?? []);
    }
  });

  const onAppointmentRequestCreated = (createdAppointmentRequest) => {
    setShowOffCanvas(false);
    setShowModal(true);
    setAppointmentRequest(createdAppointmentRequest);
  };

  useEffect(() => {
    if (!isEmpty(allowedNetworkIntegrationsTypes) && consumer) {
      if (consumer.integrations?.find(x => x.type === 'LYRIC')) {
        setActiveIntegrationType('LYRIC');
      } else if (consumer.integrations?.find(x => x.type === 'DOXYME')) {
        setActiveIntegrationType('DOXYME');
      }
    }
  }, [consumer, allowedNetworkIntegrationsTypes])

  const isDisabled = hasOpenLyricAppointment;

  return (
    <Row className="justify-content-center">
      <Col xs="auto" data-tip={isDisabled ? 'You already have an open telehealth appointment' : ''}>
        <Button 
          disabled={isDisabled} 
          variant="secondary" 
          text={'Request Appointment'} 
          onClick={() => setShowOffCanvas(true)}
        />
      </Col>
      <OffCanvasWrapper shouldShow={showOffCanvas} toggleShow={setShowOffCanvas} offCanvasClass={offCanvasStyles.offCanvas35}>
        { !activeIntegrationType && 
          <div>Your plan does not have an active Telehealth provider. Please reach out to your Admin to find out more</div> 
        }
        { activeIntegrationType === 'LYRIC' &&
          <LyricRequestAppointmentForm consumer={consumer} onAppointmentRequestCreated={onAppointmentRequestCreated} />
        }
      </OffCanvasWrapper>
      <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="help-modal"
          centered
        >
        <Modal.Body id="help-modal">
          <ViewAppointmentRequest appointmentRequest={appointmentRequest} setShowModal={setShowModal}/>
        </Modal.Body>
        <ReactToolTip />
      </Modal>
    </Row>
  )
};