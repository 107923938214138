import { gql } from '@apollo/client';
import { CASE_FIELDS } from 'case/operations/fragments';

export const UPDATE_CASE = gql`
  ${CASE_FIELDS}
  mutation UpdateCase($input: MutateCaseInput!) {
    updateCase(input: $input) {
      ...CaseFields
    }
  }
`;
