import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaTags } from 'react-icons/fa';
import { IconButton } from 'shared/components/buttons/iconButton';
import { MessageTags } from 'case/components/activity/messageTags';

export const TagsAction = ({props}) => {
  let {content, dispatch} = props;
  content = content ?? {tags: []};

  return (
    <OverlayTrigger
      trigger={[ 'click' ]}
      placement="bottom"
      rootClose
      overlay={
        <Popover>
          <Popover.Body>
            <MessageTags content={content} dispatch={dispatch} />
          </Popover.Body>
        </Popover>
      }
    >
      <span>
        <IconButton IconComponent={FaTags} size={15} tooltip='Add Tag'/>
      </span>
    </OverlayTrigger>
  );
};