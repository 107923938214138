import { MediaDragAndDropUpload } from 'shared/components/dragAndDropUpload';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { useLocation } from 'react-router';
import { FaInfoCircle } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { Input } from 'shared/components/form';
import { EditButton } from 'shared/components/buttons';
import ReactToolTip from 'react-tooltip';
import styles from 'payers/payers.module.css';

export const EmployeeFileUpload = ({ onFilesUploaded, registerPrefix }) => {
  const TEMPLATE_ROOT = '/templates/employeeImport';
  const DEFAULT_TEMPLATE = `${TEMPLATE_ROOT}/ImportEmployeesTemplate.xlsx`;
  const { getValues, setValue, register } = useFormContext();
  const [showModal, toggleModal] = useState(false);
  const [uploadRoot, setUploadRoot] = useState('');
  const [template, setTemplate] = useState(DEFAULT_TEMPLATE);
  const location = useLocation();
  const account = useCurrentAccount();
  let currentUrlParams = new URLSearchParams(location.search);
  let planId = currentUrlParams.get('id');
  register(`${registerPrefix}.configuration`);

  const headerText = 
    <Row>
      <Col lg={8}>Register employees for this plan 
        <EditButton 
          size={20}
          iconClass={styles.editEmployeeUploadConfig}
          onClick={() => toggleModal(true)}
          />
      </Col>
      <Col>
        <Link style={{textDecoration: 'none'}} to={template} target="_blank" download>Download Template</Link>
      </Col>
    </Row>
  ;

  useEffect(() => {
    let plan = getValues(registerPrefix);

    if (!plan.configuration) {
      setValue(`${registerPrefix}.configuration`, {});
    }

    const uploadRootPath = plan.configuration?.employeeTemplateUploadRoot
      ? plan.configuration.employeeTemplateUploadRoot
      : 'plans';
    setUploadRoot(`${uploadRootPath}/${account.id}/${planId}`);

    if (plan.configuration?.employeeTemplate) {
      setTemplate(`${TEMPLATE_ROOT}/${plan.configuration.employeeTemplate}`);
    } else {
      setTemplate(DEFAULT_TEMPLATE);
    }
  }, [DEFAULT_TEMPLATE, account, getValues, planId, registerPrefix, setValue]);
  
  return (
    <>
      <MediaDragAndDropUpload
          headerText={headerText}
          uploadRoot={uploadRoot}
          onFilesUploaded={onFilesUploaded}
          acceptedMediaTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Modal
          show={showModal}
          onHide={() => toggleModal(false)}
          aria-labelledby="help-modal"
          centered
        >
        <Modal.Body id="help-modal">
          <Container>
            <Row className="justify-content-end">
              <Col xs={10}>
                <h4>Employee Upload Configuration</h4>
              </Col>
              <Col className="text-end">
                <AiOutlineClose size={25} className={styles.closeicon} onClick={() => toggleModal(false)} />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Input label='Upload root' registerInput={`${registerPrefix}.configuration.employeeTemplateUploadRoot`} />
              </Col>
              <Col className={styles.configHelpIcon}>
                <FaInfoCircle size={30} data-tip='Location where the file should be uploaded to for further processing'/>
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Input label='Template to use' registerInput={`${registerPrefix}.configuration.employeeTemplate`} />
              </Col>
              <Col className={styles.configHelpIcon}>
                <FaInfoCircle size={30} data-tip='The excel template that will be used to import employees from'/>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <ReactToolTip />
      </Modal>
    </>
  );
};