import buttonStyles from 'shared/components/buttons/button.module.css';
import contactStyles from 'concierge/components/contact/contactButton.module.css';
import { conciergePaths } from "concierge/routes";
import { Link } from "react-router-dom";

export const SurveyButton = ({ code, variant }) => {
  let classNames = variant === 'secondary'
    ? buttonStyles.buttonSecondary
    : buttonStyles.buttonPrimary;
  classNames = `${buttonStyles.button} ${classNames} ${contactStyles.button}`;
  
  return (
    <Link to={`${conciergePaths.conciergeSurvey}/${code}`} className={classNames}>
      I'm ready. Take me to the questions!
    </Link>
  );
};