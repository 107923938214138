import { gql } from '@apollo/client';

export const GET_LYRIC_STATES = gql`
  query FindLyricStates{
    findLyricStates {
      items {
        id
        stateId
        name
        abbreviation
      }
    }
  }
`;