import { Col } from 'react-bootstrap';
import get from 'lodash/get'
import ReactToolTip from 'react-tooltip';
import styles from './availableVisits.module.css';

export const AvailableVisits = ({ 
  title,
  enrollment,
  benefitUsage,
  benefitName,
  allowedProperty,
  usageProperty
}) => {
  const benefit = enrollment.plan.benefits.find(x => x.benefit === benefitName);
  const allowedVisits = get(benefit.configuration, allowedProperty);
  const usage = benefitUsage?.usage?.find(x => x.benefit === benefitName);
  const used = get(usage?.configuration, usageProperty);
  const isUnlimited = allowedVisits.unlimited;
  let available;
  if (!isUnlimited) {
     available = Math.max(0, used  !== undefined
      ? allowedVisits.count - used
      : allowedVisits.count);
  }

  return(
    <Col className={`d-flex flex-column align-items-center ${styles.card}`}>
      {title && <h5>{title}</h5>}
      {isUnlimited
        ? <h5>Unlimited</h5>
        : <div className="d-flex justify-content-center">
            <h4 data-tip={`${available} Visits Available`}>{available}</h4>
            <span className={styles.available}>
              &nbsp;/&nbsp;
              <span data-tip={`${allowedVisits.count} visits included in current benefit period`}>{allowedVisits.count}</span>
            </span>
            <ReactToolTip />
          </div>
      }
    </Col>
  );
};