import { Col, Container, Row } from 'react-bootstrap';
import { useCurrentEnrollment } from "enrollment/hooks/useCurrentEnrollment";
import { FindHospitalHelp, FindHospitalHelpButton } from "../components/help";
import { FindCareCenters } from "../components";
import { FindLoading } from 'careCenter/components/findLoading';
import styles from '../indemnity.module.css';

export const FindHospital = () => {
  const { consumer, enrollment } = useCurrentEnrollment();

  return (
    <>
      <FindHospitalHeader />
      <Container>
        {consumer && enrollment
          ? <FindCareCenters consumer={consumer} enrollment={enrollment} />
          : <FindLoading />
        }
        <Row>
          <Col className={styles.helpadjustment}>
            <FindHospitalHelp />
          </Col>
        </Row>
      </Container>
      <FindHospitalHelpButton />
    </>
  );
};

const FindHospitalHeader = () => (
  <Container className={styles.header} fluid>
    <Row className="justify-content-center">
      <Col className="text-center">
        <h1 className="inverse">Hospitals For You</h1>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col lg={5} className={`text-center d-none d-lg-block d-md-block ${styles.headercopy}`}>
        Finding a hospital can be overwhelming. That is why our team has already selected hospitals with you in mind.
      </Col>
    </Row>
  </Container>
);