import styles from './button.module.css';
import ReactTooltip from "react-tooltip";

export const Button = ({variant, text, type, onClick, toolTip, testid, disabled}) => {
  type = type ?? 'button';
  let style = styles.button;
  variant = variant ?? '';
  let variantStyle;
  switch(variant.toLowerCase()) {
    case 'secondary':
      variantStyle = styles.buttonSecondary;
      if (disabled) variantStyle = `${variantStyle} ${styles.disabledSecondary}`;
      break;
    case 'action': 
      variantStyle = styles.buttonAction;
      if (disabled) variantStyle = `${variantStyle} ${styles.disabledPrimary}`;
      break;
    default:
      variantStyle = styles.buttonPrimary;
      if (disabled) variantStyle = `${variantStyle} ${styles.disabledPrimary}`;
      break;
  }
  style = `${style} ${variantStyle}`;


  return (
    <>
      <button 
        type={type} 
        aria-label={text} 
        className={style} 
        onClick={onClick} 
        data-testid={testid}
        data-tip={toolTip}
        disabled={disabled}
      >{text}</button>

      <ReactTooltip />
    </>
  );
};