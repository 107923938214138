import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Button, Col, Row } from 'react-bootstrap'
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions'
import styles from 'shared/components/shared.module.css'

export const DateRangePickerWithPreview = ({onChange, onClose, className}) => {
  const { width } = useWindowDimensions();
  const [state, setState] = useState([{
    startDate: DateTime.now().toJSDate(),
    endDate: DateTime.now().plus({days: 7}).toJSDate(),
    key: 'selection'
  }]);

  const direction = width <= 600 ? 'vertical' : 'horizontal';

  const setDate = (days) => {
    setState([{
      startDate: DateTime.now().toJSDate(),
      endDate: DateTime.now().plus({days: days}).toJSDate(),
      key: 'selection'
    }]);
  };

  useEffect(() => {
    onChange(state[0]);
  }, [state, onChange]);

  return (
    <Row>
      <Col>
        <DateRange
          minDate={new Date()}
          onChange={item => setState([item.selection])}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction={direction}
          className={className}
        />
      </Col>
      <Col className={styles.dateRangePresets} style={{marginRight: '15px'}}>
        <Row>
          <Button onClick={() => setDate(7)}>In the next week</Button>
        </Row>
        <Row>
          <Button onClick={() => setDate(14)}>In the next 2 weeks</Button>
        </Row>
        <Row>
          <Button onClick={() => setDate(30)}>In the next month</Button>
        </Row>
        <Row>
          <Button onClick={() => setDate(91)}>In the next 3 months</Button>
        </Row>
        <Row>
          <Button onClick={() => setDate(182)}>In the next 6 months</Button>
        </Row>
        <Row className={styles.dateRangeClose}>
          <Button onClick={onClose}>Close</Button>
        </Row>
      </Col>
    </Row>
  )
};