import { DPCTeaser } from "./components/teaser";
import { YouGetDPC } from "./components/youGet";
import { DPCConfig } from "./components/config";

export const DPCApp = {
  key: 'dpc',
  name: 'Primary Care',
  teaser: DPCTeaser,
  youGetCard: YouGetDPC,
  config: DPCConfig
};