import { Row, Col, Container } from 'react-bootstrap';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useEffect, useState, useMemo } from 'react';
import { SectionHeader } from 'shared/components/form';
import { RoleSelector } from 'roles/components/roleSelector';
import { AddButton, CancelButton, DeleteButton } from 'shared/components/buttons';
import { getError } from 'shared/services/validationService';
import styles from '../../../admin/components/edit/edit.module.css';
import { useQuery } from '@apollo/client';
import { GET_ALL_ROLES } from 'roles/operations/getAllRoles';

export const EditAccountRoles = ({isNew, registerPrefix, errors}) => {
  const [roleBeingAdded, setRoleBeingAdded] = useState(false);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({name: `${registerPrefix}.roles`});

  const { loading, data } = useQuery(GET_ALL_ROLES);

  const getRolesError = () => {
    return getError(`${registerPrefix}.roles`, errors);
  };

  const onRoleSelected = (data) => {
    let chosenIndex = data.target.selectedIndex;
    let chosenOption = data.target.options[chosenIndex];
    append({id: chosenOption.value, name: chosenOption.text})
    setRoleBeingAdded(false);
  };

  const consumerRole = useMemo(() => (data?.findRoles?.items?.filter(x => x.name === 'Consumer')[0] ?? {}), [data?.findRoles?.items]);
  
  useEffect(() => {
    if (!loading) {
        if (isNew) { 
          append({id: consumerRole.id, name: consumerRole.name});
        }
      }
  }, [consumerRole, append, loading, isNew]);
  
  return (
    <>
    <Row>
      <SectionHeader>
        <h4>Roles</h4>
      </SectionHeader>
      <ul>
      {fields.map((field, index) => (
        <Controller
          control={control}
          key={field.id} 
          name={field.id} 
          render = {(props) => {
            return(
              <Container fluid>
                <li>
                  <Row>
                  <Col lg={4} md={6} sm={9} xs={9}>{field.name}</Col>
                  {field.name === consumerRole.name
                    ? <></>
                    : <Col>
                        <DeleteButton tooltip='Remove' size={20} onClick={() => remove(index)}/>
                      </Col>
                  }
                  </Row>
                </li>
              </Container>
            );
          }}
        />
      ))}
      {getRolesError()?.message &&
        <div className={`invalid-feedback ${styles.showValidationError}`}>
          {getRolesError()?.message}
        </div>
      }
      {roleBeingAdded 
        ? <Container fluid>
            <li>
              <Row>
                <Col lg={4} md={6} sm={9} xs={9}>
                  <RoleSelector ignoreIds={[...fields, consumerRole.id].map(x => x.id)} onSelected={onRoleSelected}/>
                </Col>
                <Col className={styles.cancelButtonSpacer}>
                  <CancelButton tooltip='Cancel' onClick={() => setRoleBeingAdded(false)}/>
                </Col>
              </Row>
            </li>
          </Container>
        : <></>
      }
      </ul>
    </Row>
    { !roleBeingAdded && <AddButton tooltip='Add new Role' onClick={() => setRoleBeingAdded(true)} /> }
    </>
  );
};
