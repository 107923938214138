import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { appSettings } from 'appSettings';
import { MapMarker } from 'shared/components/maps/mapMarker';
import styles from './maps.module.css';

export const CareCenterMap = ({
  label,
  startLocation,
  initialZoom=7,
  zoomControl=true,
  mapTypeControl=false,
  scaleControl=true,
  streetViewControl=false,
  rotateControl=true,
  fullscreenControl=false,
}) => {
  const [location] = useState(startLocation);
  const [zoomLevel] = useState(initialZoom);
  const [, setGoogleMaps] = useState({});
  const mapOptions = {
      zoomControl,
      mapTypeControl,
      scaleControl,
      streetViewControl,
      rotateControl,
      fullscreenControl
  };

  function handleApiLoaded(googleMaps) {
    setGoogleMaps(googleMaps);
  };

  return (
    <div className={styles.dpcHeaderMap}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: appSettings.GOOGLE_MAPS_API_KEY }}
        defaultCenter={startLocation}
        defaultZoom={zoomLevel}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={(googleMaps) => handleApiLoaded(googleMaps)}
      >
        <MapMarker color='blue' lng={location.lng} lat={location.lat} name={label} />
      </GoogleMapReact>
    </div>
  );
};
