import { useQuery } from '@apollo/client';
import { createMutableCopy } from 'apollo/utils';
import { CURRENT_ACCOUNT } from './queries/getCurrentAccount';

/* 
  NOTE: this is not a standard way of doing this.
  This assumes that the current user NEVER changes
  If it ever needs to (i.e. impersonation), this will not work
*/
export const useCurrentAccount = () => {
  const { data } = useQuery(CURRENT_ACCOUNT);

  return createMutableCopy(data.currentAccount);
};