import { gql } from '@apollo/client';
import { CASE_FIELDS } from 'case/operations/fragments';
import { CasesPerPage } from 'case/constants';

export const GET_CASES = gql`
  ${CASE_FIELDS}
  query GetCasesPaginated($filter: CaseFilter, $offset: Int! = 0) {
    findCases(
      filter: $filter,
      page: {
        limit: ${CasesPerPage},
        offset: $offset
      }
      orderBy: { field: "createdAt", order: DESC }) {
      items {
        ...CaseFields
      }
      count
    }
  }
`;
