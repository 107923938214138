import { BaseLoading } from "./baseLoading";

export const LinesLoading = ({width, height, numLines}) => {
  const lines = [];
  for (let i=0; i < numLines; i++) {
    lines.push(<rect key={`line_${i}`} width={width} height={height} y={height * 2 * i} />);
  }

  return (
    <BaseLoading>
      {lines}
    </BaseLoading>
  );
};