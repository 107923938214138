import { useState } from 'react';
import { Container, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DropDown } from 'shared/components/form/dropDown';
import { EditExistingCareCenterForm } from './editForm';
import { EditCareCenter501 } from './edit501';

const financialAssistanceTypes = {
  ACA501R4: { 
    display: '501(r)(4)',
    component: EditCareCenter501
  }
};

export const EditFinancialAssistance = ({...props}) => {
  return (
    <EditExistingCareCenterForm
      component={EditFinancialAssistanceForm}
      {...props}
    />
  );
};

const EditFinancialAssistanceForm = ({registerPrefix, errors}) => {
  const [activeTab, setActiveTab] = useState(0);
  const registerInput = `${registerPrefix}.financialAssistancePolicies`;
  const { control } = useFormContext();
  const { append, fields } = useFieldArray({ control, name: registerInput });

  const onAdd = (type) => {
    const newIndex= fields.length;
    append({ type });
    setActiveTab(newIndex);
  };

  return (
    <Tab.Container activeKey={activeTab}>
      <Container fluid>
        <Row>
          <Col lg={2}>
            <Row>
              <Nav className='flex-column'>
                {fields.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index} onClick={() => setActiveTab(index)}>
                      {financialAssistanceTypes[item.type].display}
                    </Nav.Link>
                  </Nav.Item>
                ))} 
              </Nav>
            </Row>
            <Row>
              <Col>
                  <AddNew fields={fields} onAdd={onAdd} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Tab.Content>
              { fields.map((item, index) => (
                <Tab.Pane eventKey={index} key={index}>
                  <EditPolicy 
                    registerPrefix={registerInput} 
                    index={index} 
                    errors={errors} 
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Container>
    </Tab.Container>
  );
};

const AddNew = ({ fields, onAdd }) => {
  const existing = fields?.map(item => item.type);
  const items = Object.entries(financialAssistanceTypes)
    .map(([key, value]) => ({ 
      value: key,
      text: value.display
    }))
    .filter(x => !existing?.includes(x.value));

  return (
    <>
    { items && items.length > 0 && 
        <DropDown
          label="Add New"
          size="sm"
          items={items} 
          onSelected={(item) => onAdd(item.target.value)}
        />
    }
    </>
  );
};

const EditPolicy = ({registerPrefix, index}) => {
  const { getValues } = useFormContext();
  const registerInput = `${registerPrefix}.${index}`;
  const type = getValues(`${registerInput}.type`);
  const Component = financialAssistanceTypes[type].component;

  return (<Component registerPrefix={registerInput} />);
};