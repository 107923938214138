import { gql } from '@apollo/client';
import {MESSAGELIST_FIELDS} from '../fragments';
import { MessagesPerPage } from 'message/constants';

export const GET_SENDER_MESSAGE_LIST = gql`
  ${MESSAGELIST_FIELDS}

  query GetSenderMessages(
    $filter: MessageFilter,
    $offset: Int) {
      findMessages(
        filter: $filter,
        page: {
          limit: ${MessagesPerPage},
          offset: $offset
        },
        orderBy: {
          field: "sentAt",
          order: DESC
        }
      ) {
        count
        items {
          ...MessageListFields
        }
      }
    }
`;