import { gql } from '@apollo/client';
import { CONSUMER_FIELDS } from '../fragments';

export const UPDATE_CONSUMER = gql`
  ${CONSUMER_FIELDS}
  mutation UpdateConsumer($input: MutateConsumerInput!) {
    updateConsumer(input: $input) {
      ...ConsumerFields
    }
  }
`;