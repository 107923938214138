import { gql } from '@apollo/client';

export const GET_SURVEY_RESPONSE = gql`
  query GetSurveyResponse($id: ID!) {
    getSurveyResponse(id: $id) {
      id
      survey {
        id
        code
        title
        preamble
        questions {
          id
          subject
          title
          text
          options {
            value
            text
          }
        }
      }
      questionAnswers {
        id
        text
        value
      }
      context
    }
  }
`;
