import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRouteMatch, useHistory, useParams } from 'react-router';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { permissions } from 'auth/permissions';
import { FIND_CARECENTERS } from 'careCenter/operations/queries/findCareCenters';
import { AddButton } from 'shared/components/buttons';
import { Search, SectionHeader } from 'shared/components/form';
import { CareCenterSearchList } from '../components/careCenterSearchList';
import { adminPaths } from 'admin/routes';

export const CareCenterAdminSearch = () => {
  const { path }  = useRouteMatch();
  const { search } = useParams();
  const history = useHistory();
  let careCenters;
  let baseFilter = useMemo(() => ({}), []);
  const account = useCurrentAccount();

  const filterPermission = account.getMostPermissive(permissions.careCenter.edit);
  if (filterPermission.context === 'tenant') {
    baseFilter = {
      tenant: { id: { in: account.allowedTenants.map(x => x.id)}}
    };
  }

  const getSearchUrl = useCallback((searchValue) => {
    if (path.endsWith(':search')) {
      return path.replace(':search', searchValue)
    } else if (path.endsWith('careCenters')) {
      return `${path}/search/${searchValue}`;
    } else {
      const parts = path.split('/');
      parts.pop();
      return `${parts.join('/')}/${searchValue}`;
    }
  }, [path]);
  
  const [searchCareCenters, {loading, data}] = useLazyQuery(FIND_CARECENTERS);
  
  const onSearch = useCallback((searchValue, changeUrl = true) => {
    if (changeUrl) history.replace(getSearchUrl(searchValue));
    const filter = {
      ...baseFilter,
      name: { contains: searchValue } 
    };
    searchCareCenters({variables: { filter: filter }});
  }, [baseFilter, getSearchUrl, history, searchCareCenters]);

  useEffect(() => {
    if (search) onSearch(search, false);
  }, [search, onSearch]);

  if (loading) { return (<p>Loading...</p>); }

  if (data) {
    careCenters = data.findCareCenters?.items;
  } 

  return (
    <Container fluid>
      <SectionHeader>
        <h2>Care Centers</h2>
      </SectionHeader>
      <Row>
        <Col lg={11}>
          <Search placeHolder='Search for Care Center' onSearch={onSearch} />
        </Col>
        <Col lg={1} className="align-self-center">
          <Link to={`${adminPaths.careCenters}/new`}>
            <AddButton tooltip='Add new Care Center' />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          {data && careCenters.length > 0 &&
            <CareCenterSearchList careCenters={careCenters} detailLink={adminPaths.careCenters} />
          }
          {data && careCenters.length === 0 && 
            <div>No results found</div>
          }
        </Col>
      </Row>
    </Container>
  );
};