import { FaRegCopy } from 'react-icons/fa';
import { IconButton } from '.';
import { toastError, toastInfo } from 'shared/components/toasts';
import styles from './button.module.css';

export const ClipboardButton = ({copyText, inverse, ...rest}) => {

  const iconClass = inverse
    ? styles.clipboardinverse 
    : styles.clipboard;
     
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText)
      .then(() => {
        toastInfo(`Copied to clipboard: ${copyText}`);
      }).catch(() => {
        toastError('Could not copy to clipboard');
      });
  };

  return (
    <IconButton 
      IconComponent={FaRegCopy}
      iconClass={iconClass}
      tooltip="Copy to clipboard"
      onClick={copyToClipboard}
      {...rest} />
  );
};