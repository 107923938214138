import { Col, Row } from 'react-bootstrap';
import { EditEnrollment } from "./editEnrollment";
import styles from './components.module.css';

export const EnrollmentAdditionalInfo = ({enrollment, onCompleted}) => {
  const buttonOpts = {
    text: 'See my plan!',
    alignmentClass: 'justify-content-md-center',
    variant: 'action'
  };

  return (
    <>
    <Row>
      <Col className={styles.header}>
        <h2>Add a few details</h2>
      </Col>
    </Row>
    <Row>
      <Col className={styles.instructions}>
        Your plan is custom-fit to your life and your needs, so by providing a few additional pieces of information, we can make sure you get the benefits you need.
      </Col>
    </Row>
    <Row>
      <Col>
        <EditEnrollment 
          enrollment={enrollment}
          buttonOpts={buttonOpts}
          onSaved={onCompleted}
        />
      </Col>
    </Row>
    </>
  );
};