import { SectionHeader } from 'shared/components/form';
import { Col, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from 'network/network.module.css';
import { useState } from 'react';
import { AddButton, CancelButton, DeleteButton, SaveButton } from 'shared/components/buttons';
import { Multiselect } from 'react-widgets/cjs';

export const EditIntegrationVisitType = ({title, registerInput, availableAppointmentTypes, onAppointmentTypesChanged}) => {
  const [addingType, setAddingType] = useState(false);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({control, name: registerInput });

  const onAppointmentTypesSelected = (data) => {
    append(data);
    setAddingType(false);
    onAppointmentTypesChanged(data, true);
  };

  return (
    <>
      <SectionHeader>
        <h4>{title}</h4>
      </SectionHeader>
      <ul>
        {fields.map((field, index) => (
          <li key={index}>
            <Row>
              <Col lg={6} md={6} sm={9} xs={9} aria-label={`${title}.${index}`}>
                {field.name}
              </Col>
              <Col>
                <DeleteButton tooltip='Remove' size={20} onClick={() => {remove(index); onAppointmentTypesChanged([field], false);}}/>
              </Col>
            </Row>
          </li>
        ))}
        {addingType 
          ? <li>
              <AppointmentTypeSelector appointmentTypes={availableAppointmentTypes} onSelected={onAppointmentTypesSelected}/>
            </li>
          : <></>
        }
      </ul>
      { !addingType && <AddButton tooltip="Add new Appointment Type" onClick={() => setAddingType(true)} /> }
    </>
  )
}

export const AppointmentTypeSelector = ({appointmentTypes, onSelected}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  let data = appointmentTypes.map(x => ({
    id: x.appointmentTypeId,
    name: x.name
  }));

  data = data.filter(x => selectedValues.filter(y => y.id === x.id).length === 0);

  return (
    <Row>
      <Col lg={6} md={6} sm={9} xs={9}>
        <Multiselect
          textField="name"
          data={data}
          value={selectedValues}
          onChange={item => setSelectedValues(item)}
        />
      </Col>
      <Col lg={1} className={styles.buttonSpacer}>
       <SaveButton tooltip='Save' onClick={() => onSelected(selectedValues)} />
      </Col>
      <Col lg={1} className={styles.buttonSpacer}>
        <CancelButton tooltip='Cancel' onClick={() => onSelected([])} />
      </Col>
    </Row>
  );
};