import {CORRESPONDENT_FIELDS, MESSAGE_FIELDS} from '../fragments';
import { gql } from '@apollo/client';

export const GET_SENDER_MESSAGE = gql`
  ${CORRESPONDENT_FIELDS}
  ${MESSAGE_FIELDS}

  query GetSenderMessage(
    $messageId: ID,
    $senderId: String,
    $senderType: String
  ) {
    findMessages(filter: {
        id: { eq: $messageId },
        sender: {
          id: { eq: $senderId },
          type: { eq: $senderType }
        }
    }) {
      items {
        ...MessageFields
      }
    }
  }
`;