import Multiselect from "react-widgets/Multiselect";
import styles from "case/case.module.css";

export const ContentTypes = {
  message: 'Messages',
  note: 'Notes',
  activity: 'Activity'
};

export const Filter = ({dispatch, initialValues}) => {
  const data = Object.values(ContentTypes);

  return (
    <>
      <span className={`my-auto ${styles.filterheading}`}>Show:</span>
      <span className={`my-auto ${styles.filteroptions}`}>
        <Multiselect
          defaultValue={initialValues}
          data={data}
          onChange={(e) => dispatch({type: 'filter', filter:e })}
        />
      </span>
    </>
  );
};