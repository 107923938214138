import { Col, Container, Row } from 'react-bootstrap';
import { FaHospitalAlt } from 'react-icons/fa';
import { DisplayAddress } from 'shared/components/contact';
import { BoxLoading, LinesLoading } from 'shared/components/spinners';
import { GenerosityScore } from 'benefits/indemnity/components/generosityScore';
import { useAmenities, useCareCenter } from 'careCenter/hooks';
import styles from './hospitaldetail.module.css';
import { fapCalculator } from 'benefits/indemnity/services/fapCalculator';
import { useConsumerEnrollment } from 'enrollment/hooks/useConsumerEnrollment';
import { useEffect, useState } from 'react';

export const ViewHospitalDetail = ({careCenterId, consumerId, score, condenseUI=false}) => {
  const { amenities } = useAmenities();
  const { careCenter } = useCareCenter(careCenterId);
  const { consumer, enrollment } = useConsumerEnrollment(consumerId);
  const [displayScore, setDisplayScore] = useState(score);

  let coverImageUrl = null;
  let careCenterAmenities = [];
  const imageClassName = condenseUI ? styles.hospitaldetailimagecondensed : styles.hospitaldetailimage

  if (careCenter && amenities) {
    coverImageUrl = careCenter.getCoverImageUrl('sm');
    careCenterAmenities = careCenter.amenities && careCenter.amenities.length > 0
      ? careCenter.amenities.map(x => amenities.find(a => a.id === x.id))
      : [];
  }

  let lineWidth = window.screen.width < 800 ? 150 : 400;

  let careCenterName = careCenter ? careCenter.name : 'Loading...';

  useEffect(() => {
    if (displayScore === 'undefined' && careCenter && consumer && enrollment) {
      setDisplayScore(fapCalculator.calculateScore(
        careCenter,
        enrollment.benefits.find(x => x.benefit === 'indemnity').configuration,
        consumer.contact.addresses.find(x => x.type.includes('PHYSICAL'))
      ));
    }
  }, [displayScore, setDisplayScore, careCenter, enrollment, consumer])

  return (
    <>
      <Container className={condenseUI ? styles.detailHeaderSmall : styles.detailheader} fluid>
        <Row className="justify-content-center">
          <Col className="text-center">
            { condenseUI ? <h3 className="inverse">{careCenterName}</h3> : <h1 className="inverse">{careCenterName}</h1> }
          </Col>
        </Row>
      </Container>
      <Container className={styles.hospitaldetail}>
        <Row>
          <Col md={condenseUI ? 12 : 3} sm={12}>
            <Row className="justify-content-center">
              <Col>
                { careCenter
                  ?  <img lg="auto" src={coverImageUrl} height={240} width={320} alt="Cover" className={imageClassName}  />
                  : <BoxLoading width={330} height={240} className={imageClassName} />
                }
              </Col>
            </Row>
            <Row>
              <Col className={styles.detailscore}>
                <GenerosityScore careCenterResult={{score: displayScore}} />
              </Col>
            </Row>
          </Col>
          <Row className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-block">
            <Col className={styles.detaildivider} />
          </Row>
          <Col className={condenseUI ? styles.detailcontentcondensed : styles.detailcontent}>
            <Row className={styles.detailaddress}>
              <Col lg="auto" xs="auto">
                <FaHospitalAlt className={styles.icon} />
              </Col>
              <Col>
                { careCenter 
                  ?  <DisplayAddress address={careCenter.address} lines={2} />
                  :  <LinesLoading width={lineWidth - 20} height={20} numLines={2} />
                }
              </Col>
            </Row>
            <Row><Col className={styles.detaildivider} /></Row>
            <Row>
                <Row>
                  <Col><h6>Amenities offered:</h6></Col>
                </Row>
                <Row data-testid="amenities">
                  {careCenterAmenities.map(amenity => (
                    <Col key={amenity.id} sm={condenseUI ? 12 : 6}>
                      <i data-testid="amenity" className={amenity.iconClass} />{amenity.name}
                    </Col>
                  ))}
                </Row>
            </Row>
            <Row><Col className={styles.detaildivider} /></Row>
            <Row>
              <Row>
                <Col>
                  <h6>About {careCenter?.name}</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  { careCenter
                    ? careCenter.overview
                    : <LinesLoading width={lineWidth} height={20} numLines={6} />
                  }
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )

}