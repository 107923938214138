import { LyricRedirect } from "./components/lyric/redirect";

const pathRoot = '/benefits/telehealth';

export const telehealthPaths = {
  lyricRedirect: `${pathRoot}/lyricredirect`
};

export const telehealthRoutes = [
  {
    path: telehealthPaths.lyricRedirect,
    key: 'lyricRedirect',
    component: LyricRedirect
  }
];