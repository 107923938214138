import { Accordion, Col, Row } from 'react-bootstrap';
import styles from 'consumer/components/todo/todo.module.css';
import { FaRegBookmark, FaRegCalendarCheck } from 'react-icons/fa'
import { useCurrentEnrollment } from 'enrollment/hooks/useCurrentEnrollment';
import { RequestAppointmentButton } from 'benefits/dpc/components/requestAppointment/requestAppointmentButton';
import { useCareCenter } from 'careCenter/hooks';

export const FollowupOrder = ({order}) => {
  const { enrollment } = useCurrentEnrollment();
  const { careCenter } = useCareCenter(order.careCenter.id);
  const hasDpcBenefit = enrollment?.plan?.benefits?.find(x => x.benefit === 'dpc');

  return (
    <Accordion defaultActiveKey="1" className={styles.appointmentAccordion}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.appointmentAccordionHeaderWrapper}>
          <div>
            <Row>
              <Col>
                <FaRegBookmark className={styles.icon} />{order.careCenter.name}
              </Col>
            </Row>
            <Row>
              <Col className={styles.followupOrderAccordionHeaderDate}>
                <FaRegCalendarCheck className={styles.icon} />{order.configuration.appointmentDate}
              </Col>
            </Row>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className={styles.appointmentAccordionBody}>
            <span>
              Remember to schedule a follow up with {order.provider.contact.name.first} {order.provider.contact.name.last}.
            </span>
            { hasDpcBenefit && careCenter &&
              <div className={styles.followupRequestAppointment}>
                <RequestAppointmentButton 
                  careCenterResult={{careCenter: careCenter}}
                  context={{order: order.id}}
                  text='Request Appointment &nbsp; &#9656;' 
                />
                
              </div>
            }
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}