import { DateTime } from 'luxon';
import { telehealthPaths } from '../../routes';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import styles from './lyric.module.css';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Button as FormButton } from 'shared/components/buttons/button';
import { CANCEL_LYRIC_APPOINTMENT } from 'benefits/telehealth/operations/mutations/lyric_cancelAppointment';
import { useMutationWrapper } from 'apollo/useMutationWrapper';

export const LyricAppointment = ({ appointment }) => {
  const { consumer } = useCurrentConsumer();
  const [showModal, setShowModal] = useState(false);
  const canCancel = appointment.status === "OPEN";
  const lyricIntegration = appointment.integrations.find(x => x.type === 'LYRIC');
  const hasVideo = lyricIntegration?.configuration?.modalities?.some(y => y === 'video');
  const consultationId = lyricIntegration.id;

  const [cancelAppointment] = useMutationWrapper(CANCEL_LYRIC_APPOINTMENT,
  {
    refetchQueries: ['FindOpenLyricAppointments', 'FindAppointments']
  });

  const onCancelAppointment = async () => {
    setShowModal(false);
    await cancelAppointment({variables: {consultationId: consultationId, consumerId: consumer.id}});
  };

  return (
    <>
      <LyricAppointmentDetails appointment={appointment} />
      <Row>
        <Col className={styles.links}>
        {hasVideo && 
          <Button href={telehealthPaths.lyricRedirect} target='_blank' variant='link' className={styles.actionLink}>
            Click here to Join 
          </Button>
        }
        { canCancel &&
          <Button onClick={() => setShowModal(true)} variant='link' className={styles.actionLink}>
            Cancel
          </Button>
        }
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="help-modal"
        centered
      >
        <Modal.Body id="help-modal" className={styles.cancelModalBody}>
          <Row className="justify-content-end">
            <Col xs={10}>
              <h3>Cancel Appointment</h3>
            </Col>
            <Col className="text-end">
                <AiOutlineClose size={25} className={styles.closeModalIcon} onClick={() => setShowModal(false)} />
            </Col>
          </Row>
          <Row>
            <Col>Are you sure you'd like to cancel this appointment?</Col>
          </Row>
          <Row>
            <Col className={styles.cancelSubmitButton}>
              <FormButton text='Cancel' onClick={() => onCancelAppointment()}/>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
};

const LyricAppointmentDetails = ({ appointment }) => {
  return (
    <span data-testid="details"> 
      Your telehealth appointment has been confirmed to start within a 2 hour window starting on {DateTime.fromISO(appointment.date).toLocaleString(DateTime.DATE_HUGE)} at{" "} {appointment.time}.
    </span>
  );
};