import { Container, Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { useCurrentEnrollment } from 'enrollment/hooks/useCurrentEnrollment';
import { ConsumerHeader } from '../../components/header/header';
import { YouGetList } from '../../components/youGet/youGetList';
import { enrollmentPaths } from 'enrollment/routes';
import { MyPerks } from '../../components/perks/perks';
import { Todo } from 'consumer/components/todo';
import styles from './home.module.css';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export const ConsumerHome = () => {
  const { consumer, enrollment } = useCurrentEnrollment();
  const [redirectToTerminatedEnrollment, setRedirectToTerminatedEnrollment] = useState(false);
  const [redirectToEnrollment, setRedirectToEnrollment] = useState(false);

  useEffect(() => {
    if (enrollment && enrollment.period?.end) {
      const benefitEndDate = DateTime.fromFormat(enrollment.period.end, 'yyyy-MM-dd', { zone: 'utc'});
      if (benefitEndDate < DateTime.utc()) {
        setRedirectToTerminatedEnrollment(true);
      }
    }
    
    if (enrollment) setRedirectToEnrollment(!enrollment.completed);

  }, [enrollment]);

  return (
    <>
    { !enrollment && <h1>Loading...</h1>}
    { enrollment && redirectToTerminatedEnrollment && <Redirect to={enrollmentPaths.terminated} /> }
    { enrollment && !redirectToTerminatedEnrollment && redirectToEnrollment && <Redirect to={enrollmentPaths.root} /> }
    { enrollment && !redirectToTerminatedEnrollment && !redirectToEnrollment &&
      <Container fluid>
        <Row>
          <ConsumerHeader consumer={consumer} enrollment={enrollment} />
        </Row>
        <Row className={styles.cardContainer}>
          <Row>
            <Col md={12}>
            <Todo consumer={consumer} />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <YouGetList enrollment={enrollment} />
            </Col>
            <Col md={4}>
              <MyPerks />
            </Col>
          </Row>
        </Row>
      </Container>
    }
    </>
  )
};
