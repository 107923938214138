import { useLazyQuery } from "@apollo/client";
import { useEffect, useReducer } from "react";
import { useErrorHandler } from 'react-error-boundary';
import { createMutableEnrollment } from "./createMutableEnrollment";
import { GET_ENROLLMENT_FOR_CONSUMER } from '../operations/queries/getConsumerEnrollment';

const reducer = (state, action) => {
  switch (action.type) {
    case 'consumerLoading':
      return { ...state, loading: true};
    case 'consumerLoaded': 
      return { ...state, loading: true, consumer: action.payload };
    case 'enrollmentLoading':
      return { ...state, loading: true, consumer: state.consumer };
    case 'enrollmentLoaded':
      return { ...state, loading: false, consumer: state.consumer, enrollment: action.payload }
    default: 
      throw new Error('invalid action');
  }
};

export const useEnrollmentBase = (useConsumerHook) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    consumer: null,
    enrollment: null,
    consumerRefetch: null
  });
  const { loading: consumerLoading, consumer, error: consumerError } = useConsumerHook();
  useErrorHandler(consumerError);
  const [loadEnrollment, { loading: enrollmentLoading, data: enrollmentData, error: enrollmentError }] = useLazyQuery(GET_ENROLLMENT_FOR_CONSUMER);
  useErrorHandler(enrollmentError);

  useEffect(() => {
    if (consumerLoading === true) dispatch({type: 'consumerLoading'});
  }, [consumerLoading]);

  useEffect(() => {
    if (consumer) {
      dispatch({type: 'consumerLoaded', payload: consumer});
      loadEnrollment({ variables: { consumerId: consumer.id }});
    }
  }, [consumer, loadEnrollment]);

  useEffect(() => {
    if (enrollmentLoading === true) dispatch({type: 'enrollmentLoading'});
  }, [enrollmentLoading]);

  useEffect(() => {
    if (enrollmentData) {
      const enrollment = createMutableEnrollment(enrollmentData.findEnrollments.items[0]);
      dispatch({type: 'enrollmentLoaded', payload: enrollment});
    }
  }, [enrollmentData]);

  return state;
};