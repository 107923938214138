import { makeVar } from '@apollo/client';
import { getReadRecordsFunction, getMergeRecordsFunction } from 'apollo/merge';
import { AppointmentsPerPage } from 'consumer/constants';

const currentAppointmentsOffset = makeVar();
const readAppointmentRecords = getReadRecordsFunction(currentAppointmentsOffset, AppointmentsPerPage);
const mergeAppointmentRecords = getMergeRecordsFunction(currentAppointmentsOffset);

export const consumerCaching = {
  findAppointments : {
    read(existing) { 
      return readAppointmentRecords(existing);
    },
    keyArgs: ['filter'],
    merge(existing, incoming, { args }) {
      return mergeAppointmentRecords(existing, incoming, args);
    }
  }
};