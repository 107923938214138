import { gql } from '@apollo/client';
import { ENROLLMENT_FIELDS } from '../fragments';

export const UPDATE_ENROLLMENT = gql`
  ${ENROLLMENT_FIELDS}
  mutation UpdateEnrollment($input: MutateEnrollmentInput!) {
    updateEnrollment(input: $input) {
      ...EnrollmentFields 
    }
  }
`;