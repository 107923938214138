import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Input, Section } from 'shared/components/form';
import { useState } from 'react';
import { AppointmentDatePicker } from 'benefits/telehealth/components/requestAppointment/appointmentDatePicker';
import { Disclaimer } from 'benefits/telehealth/components/requestAppointment/disclaimer';
import { LyricTimezoneSelector } from 'integrations/lyric/components/timezoneSelector';
import { LyricStateSelector } from 'integrations/lyric/components/stateSelector';
import { LyricModalitiesSelector } from 'integrations/lyric/components/modalitiesSelector';
import { LyricProblemsSelector } from 'integrations/lyric/components/problemsSelector';
import { LyricPharmacySelector } from 'integrations/lyric/components/pharmacySelector/pharmacySelector';
import { PhoneNumberDropdown } from 'shared/components/contact/phoneNumberDropdown';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TelehealthIcon } from '../icon';
import { TextArea } from 'shared/components/form';
import { CREATE_LYRIC_CONSULTATION } from 'integrations/lyric/operations/mutations/createConsultation';
import { useMutation } from '@apollo/client';
import { NotificationPreference } from 'benefits/telehealth/components/requestAppointment/notificationPreference';
import { DateTime } from 'luxon';

export const LyricRequestAppointmentForm = ({consumer, context, onAppointmentRequestCreated}) => { 
  const [selectSpecificTime, setSelectSpecificTime] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timezone, setTimezone] = useState('');
  const [state, setState] = useState('');
  const [modality, setModality] = useState('');
  const [pharmacy, setPharmacy] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const phoneNumberLabel = modality === 'video' ? 'Phone Number to use in case of video issues' : 'Phone Number';
  context = context ?? {}; 
  const disclaimerTitle = "Important Note";
  const disclaimerBody  = "All appointment requests have a 2-hour window. For example: If you request 2:30 PM, your window is 2:30 PM - 4:30 PM. However: Most appointments take place within 20-30 minutes of the requested start time.<br/><br/>Make sure you have selected a 2-hour time window when you are available, then tap “Request Appointment”." 

  const [createConsultation] = useMutation(CREATE_LYRIC_CONSULTATION, 
    {
      refetchQueries: ['FindOpenLyricAppointments', 'FindAppointments']
    });

  const onAppointmentTimeChange = (shouldSelectSpecificTime, value) => {
    formMethods.setValue('selectSpecificTime', shouldSelectSpecificTime);
    formMethods.setValue('date', value);
    setSelectedDate(value);
    setSelectSpecificTime(shouldSelectSpecificTime);
  };

  const onModalitySelected = (selectedModality) => {
    setModality(selectedModality);
    formMethods.setValue('isVideo', selectedModality === 'video')
  };
  
  const validationSchema = Yup.object({
    time: Yup.string().when('selectSpecificTime', {
      is: true,
      then: Yup.string().required('Please select a time').test({
        name: 'Time',
        message: 'Selected time cannot be in the past',
        params: { selectedDate },
        test: (value) => {
          let formattedDate = DateTime.fromFormat(`${selectedDate} ${value}`, 'M/d/yyyy hh:mm');
          return formattedDate > DateTime.now();
        }
      })
    }),
    phoneNumber: Yup.string().required('Please select a phone number'),
    problems: Yup.object(({
      chiefComplaint: Yup.number().transform(value => (isNaN(value) ? undefined : value))
      .required('Please select a primary reason'),
    })),
    pharmacy : Yup.number().transform(value => (isNaN(value) ? undefined : value))
                  .required('Please select a pharmacy'),
    notify: Yup.string().required('Please provide a notification value')
  });

  const formMethods = useForm({
    defaultValues: {
      selectSpecificTime: false,
      isVideo: false,
      time: '',
      date: '',
      phoneNumber: '',
      problems: {
        chiefComplaint: '',
        otherProblems: []
      },
      pharmacy: null,
      notify: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  const onProblemsSelected = (reasons) =>  {
    formMethods.setValue('problems', reasons, {shouldValidate: true});
  };

  const onPharmacySelected = (pharmacy) => {
    setPharmacy(pharmacy);
    formMethods.setValue('pharmacy', pharmacy.sureScriptPharmacy_id, {shouldValidate: true});
  }

  const onSubmit = async (data) => {
    setFormSubmitted(true);
    let createConsultRequest = {
      consumerId: consumer.id,
      additionalNotes: data.additionalNotes,
      pharmacy: pharmacy,
      notify: data.notify,
      consult: {
        state: state,
        modalities: [modality],
        phoneNumber: data.phoneNumber,
        sureScriptPharmacy_id: data.pharmacy,
        translate: '',
        patientDescription: '',
        roi: 'Nothing',
        problems: data.problems,
      }
    };

    // rhf removes empty arrays... even if empty we need this one
    createConsultRequest.consult.problems.otherProblems = createConsultRequest.consult.problems.otherProblems ?? [];

    if (selectSpecificTime) {
      createConsultRequest.consult = {...createConsultRequest.consult,
        whenScheduled: `${data.date} ${data.time}`,
        timezoneOffset: timezone,
      }
    } else {
      createConsultRequest.consult = {...createConsultRequest.consult, 
        whenScheduled: "now"
      }
    }

    try {
      await createConsultation({ variables: { input: createConsultRequest } });
      onAppointmentRequestCreated(createConsultRequest)
    } catch (error) {
      onAppointmentRequestCreated({error: error.message});
    }
  };

  return (
    <Container>
      <Row className={styles.header}>
        <Col xs={12} className={styles.headerIcon}><TelehealthIcon size={20}/> <span>Telehealth</span></Col>
        <Col xs={12}><h4>Request Telehealth Appointment</h4></Col>
      </Row>
      <div>
      <FormProvider {...formMethods}>
        <form name="account" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Section>
            <AppointmentDatePicker onAppointmentTimeChange={onAppointmentTimeChange}/>
            { selectSpecificTime && 
            <>
              <Col xs={12}>
                <Input type='time' label='Preferred time' name='time' registerInput={'time'} errors={formMethods.formState.errors} />
              </Col>
              <Col xs={12}>
                <LyricTimezoneSelector consumer={consumer} onSelected={(e) => setTimezone(e.target.value)}/>
              </Col>
            </>
            }
            <Col xs={12}>
              <LyricStateSelector consumer={consumer} onSelected={(e) => setState(e.target.value)} />
            </Col>
            <Col xs={12} className={styles.capitalizeTextSelect}>
              <LyricModalitiesSelector consumer={consumer} state={state} onSelected={(e) => onModalitySelected(e.target.value)} />
            </Col>
            <Col xs={12}>
              <PhoneNumberDropdown consumer={consumer} errors={formMethods.formState.errors} registerInput='phoneNumber' label={phoneNumberLabel} />
            </Col>
            <Col xs={12}>
              <NotificationPreference consumer={consumer} registerInput='notify' />
            </Col>
            <LyricProblemsSelector onSelected={onProblemsSelected} errors={formMethods.formState.errors} registerInput={'problems'}/>
            <Col className={styles.additionalNotesTextArea}>
              <Controller
                name="controller"
                control={formMethods.control}
                render={() => (
                  <TextArea 
                    placeholder="Additional notes"
                    registerInput='additionalNotes' 
                    errors={formMethods.formState.errors}
                  />
                )}
              />
            </Col>
          </Section>
        </form>
      </FormProvider>          
      <Section>
        <Col xs={12}>
          <LyricPharmacySelector consumer={consumer} onSelected={(pharmacy) => onPharmacySelected(pharmacy)} errors={formMethods.formState.errors} />
        </Col>
      </Section>
      <Section>
        <Disclaimer title={disclaimerTitle} body={disclaimerBody} />
      </Section>
      <Row className={styles.requestAppointmentSubmit}>
        <Button type="submit" variant="primary" text="Request Appointment" disabled={formSubmitted} onClick={formMethods.handleSubmit(onSubmit)}/>
      </Row>
      </div>
    </Container>
  )
};