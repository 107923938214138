import { useState } from 'react';
import { Carousel, Col, Row } from "react-bootstrap";
import { PerkCard } from "./perkCard";
import { useInterval } from 'shared/hooks/useInterval';
import { BoxLoading, LinesLoading } from 'shared/components/spinners';
import { CategoryTitle } from "./categoryTitle";
import { CarouselNavigator } from './carouselNavigator';
import styles from './perks.module.css';

export const PerkCategory = ({ title, Icon, category, perks, loading }) => {

  return loading 
    ? <PerkCategoryLoading title={title} Icon={Icon} /> 
    : <PerkCategoryLoaded title={title} Icon={Icon} category={category} perks={perks} />;
};

const PerkCategoryLoaded = ({ title, Icon, category, perks}) => {
  const categoryPerks = perks.filter(perk => perk.category === category);
  const [activeIndex, setActiveIndex] = useState(0);
  const [playing, togglePlaying ] = useState(false);

  useInterval(() => {
    if (activeIndex === categoryPerks.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  } , playing ? null : 10000);

  return (
    <>
      <Row className={styles.categoryTitleRow}>
        <CategoryTitle title={title} Icon={Icon} />
      </Row>
      <Row>
        <Carousel
          controls={false}
          variant="dark"
          indicators={false}
          activeIndex={activeIndex}
        >
          {categoryPerks.map((perk, i) => (
            <Carousel.Item key={`carousel_${perk.id}`}>
              <PerkCard
                key={perk.id}
                perk={perk}
                categoryTitle={title}
                CategoryIcon={Icon}
                inactive={activeIndex !== i}
                onTogglePlaying={togglePlaying}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
      <Row>
        <Col className={`text-center ${styles.perkNavigator}`}>
          <CarouselNavigator
            key={category}
            activeIndex={activeIndex}
            numSlides={categoryPerks.length}
            onSelect={setActiveIndex}
          />
        </Col>
      </Row>
    </>
  );
};

const PerkCategoryLoading = ({title, Icon}) => {
  return (
    <Row>
      <Col className={styles.PerkCard}>
        <Row className={styles.categoryTitleRow}>
          <CategoryTitle title={title} Icon={Icon} />
        </Row>
        <Row>
          <Col>
            <BoxLoading width={280} height={170} />
          </Col>
        </Row>
        <Row>
          <Col className={styles.perkTitle}>
            <LinesLoading width={280} height={20} numLines={3} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};