import { Col, Row, Container } from 'react-bootstrap';
import styles from '../rx.module.css';
import "react-widgets/styles.css";
import { GoodRxSearch } from '../components/search';
import { ConciergeHelp } from 'concierge/components/contact/helpSection';
import { FloatingHelpButton } from 'concierge/components/contact/floatingHelp';
import { useParams } from "react-router-dom";
import { PrescriptionDetailsMenu } from '../components/detailsMenu';
import { PrescriptionPriceResults } from '../components/priceResults';
import { RxHints } from '../components/hints';
import { useApolloClient } from '@apollo/client';
import { GET_DRUG_PRICES } from '../operations/queries/getPrices';
import { useEffect, useState } from 'react';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';

export const PrescriptionDetails = () => {
  const { prescriptionName } = useParams();
  const { consumer } = useCurrentConsumer();
  const [searchVariables, setSearchVariables] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [searchEquivalents, setSearchEquivalents] = useState([]);
  const [searchErrors, setSearchErrors] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [consumerPostalCode, setConsumerPostalCode] = useState('');
  const client = useApolloClient();

  const searchDrugPrices = async(variables) => {
    setSearchLoading(true);
    setSearchVariables(variables);
    setSearchResults([]);
    const { errors, data } = await client.query({
      query: GET_DRUG_PRICES,
      errorPolicy: 'all',
      variables: variables
    });
    
    if (errors) {
      setSearchErrors(errors);
      return;
    }

    setSearchErrors('');
    setSearchResults(data.rx.getPrices);
    setSearchEquivalents(data.rx.getPrices.equivalents);
    setSearchLoading(false);
  };

  const copy = 'Having trouble filling your prescription or finding a pharmacy using the search box above? Contact us anytime.';
 
  useEffect(() => {
    if (consumer) {
      setConsumerPostalCode(consumer.contact.addresses[0].postalCode);
    }
  }, [consumer, setConsumerPostalCode])

  useEffect(() => {
    setSearchErrors('');
    setSearchResults([]);
    setSearchEquivalents([]);
  }, [prescriptionName, setSearchResults, setSearchEquivalents]);

  return (
    <>
    <PrescriptionDetailHeader />
    <Container>
      <Row>
        <Col lg={4} md={4} sm={4} xs={12}>
          <PrescriptionDetailsMenu name={prescriptionName} searchPrices={searchDrugPrices} equivalents={searchEquivalents} postalCode={consumerPostalCode}/>
        </Col>
        <Col lg={8} md={8} sm={8}>
          <Row>
            <Col className='d-none d-sm-block'>
              <PrescriptionName name={prescriptionName}/>
            </Col>
            <Col>
              <PrescriptionPriceResults consumer={consumer} 
                                        drugName={prescriptionName}
                                        items={searchResults}
                                        loading={searchLoading}
                                        searchErrors={searchErrors}
                                        searchVariables={searchVariables}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={styles.helpAdjustment}>
          <ConciergeHelp copy={copy}/>
        </Col>
      </Row>
      <RxHints drugName={prescriptionName}/>
    </Container>
    <FloatingHelpButton copy={copy} />
    </>
  )
}

const PrescriptionName = ({name}) => {
  return (
    <Row className={styles.prescription}>
      <Col lg={7} sm={6}>
        <div className={styles.prescriptionName}>{name}</div>
      </Col>
      <Col lg={5} className={styles.leftBorder}>
        <div className={styles.prescriptionSearch}>
          <GoodRxSearch placeholder='New drug search...'/>
        </div>
      </Col>
    </Row>
  )
}

const PrescriptionDetailHeader = () => (
  <Container className={styles.header} fluid>
    <Row className="justify-content-center">
      <Col className="text-center">
        <h1 className="inverse">Prescription Search</h1>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col lg={5} className={`text-center d-none d-lg-block d-md-block ${styles.headercopy}`}>
        We are dedicated to finding the best price for your prescription drugs.
      </Col>
    </Row>
  </Container>
);