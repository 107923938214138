const Actions = {
  TagAdded: 'tagAdded',
  TagRemoved: 'tagRemoved',
  Refresh: 'refresh'
};

export const TagsReducer = (state, action) => {
  switch (action.type) {
    case Actions.TagAdded:
      return {...state, tags: [...state.tags, action.tag]};
    case Actions.TagRemoved: 
      return { ...state, tags: state.tags.filter(t => t !== action.tag)};
    case Actions.Refresh: 
      return { tags: [] };
    default: 
      throw new Error('invalid action');
  }
};

export const TagAdded = (dispatch, tag) => {
  dispatch({type: Actions.TagAdded, tag: tag});
};

export const TagRemoved = (dispatch, tag) => {
  dispatch({type: Actions.TagRemoved, tag: tag});
};

export const RefreshTags = (dispatch) => {
  dispatch({type: Actions.Refresh});
}