import { useFormContext, Controller, useController } from 'react-hook-form';
import { Container, Col, Row } from "react-bootstrap";
import styles from 'payers/payers.module.css';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Form } from "react-bootstrap";
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { useLocation } from "react-router";

export const EditPlanBenefitPeriod = ({registerPrefix}) => {
  const registerField = registerPrefix ? `${registerPrefix}.benefitPeriod` : 'benefitPeriod';

  return (
    <Container>
      <TypeDropDown registerField={`${registerField}.type`}/>
      <Row className={styles.planBenefitPeriodDates}>
        <Col lg={6}>
          <DateDropDown registerField={`${registerField}.configuration.startDate`} label='Start' defaultDate="01/01" />
        </Col>
        <Col lg={6}>
          <DateDropDown registerField={`${registerField}.configuration.endDate`} label='End' defaultDate="12/31" />
        </Col>
      </Row>
    </Container>
  );
}

const TypeDropDown = ({registerField}) => {
  const planYear = 'PLAN_YEAR';
  const [resetValues, setResetValues] = useState(false);
  const { setValue, register, control } = useFormContext();
  const location = useLocation();
  
  /*
    responsible for setting the initial value for dates after a plan change
  */
  useEffect(() => {
    if (resetValues) {
      setValue(registerField, planYear)
      setResetValues(false);
    }
  }, [registerField, planYear, resetValues, setResetValues, setValue]);
    
  /*
    responsible for detecting a plan change via url search param update
  */
  useEffect(() => {
    setResetValues(true);
  }, [location.search]);

  return (
    <Controller
      name='controller'
      control={control}
      render={({ ref }) => (
        <FloatingLabelControl label="Type">
          <Form.Control
            ref={ref}
            className="form-select"
            as="select"
            type="select"
            {...register(`${registerField}`)} 
          >
            <option value={planYear}>{planYear}</option>
          </Form.Control>
        </FloatingLabelControl>
      )}
    /> 
  )
};

const DateDropDown = ({label, registerField, defaultDate }) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [resetValues, setResetValues] = useState(false);
  const { control, setValue } = useFormContext();
  const { field } = useController({name: registerField, control});
  const months = useMemo(() => [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ], []);
  const days = [...Array(31).keys()].map(x => { let temp = (x + 1).toString(); if (temp.length === 1) temp = '0' +temp; return temp;} );
  const location = useLocation();
  const parseMonth = useCallback((monthAsInt) => {
    return months[parseInt(monthAsInt) - 1];
  }, [months]);

  if (month === '' && day === '') {
    if (field.value) {
      let parts = field.value.split('/');
      setMonth(parseMonth(parts[0]));
      setDay(parts[1]);
    } else {
      setDay(defaultDate.split('/')[1])
      setMonth(parseMonth(defaultDate.split('/')[0]));
    } 
  }

  /*
    responsible for setting state in rhf for the combined field upon updates
  */
  useEffect(() => {
    let newDay = day, newMonth = (months.indexOf(month) + 1).toString();
    if (newMonth.length === 1) {
      newMonth = '0' + newMonth;
    }

    setValue(registerField, `${newMonth}/${newDay}`)
  }, [month, day, months, registerField, setValue]);

  /*
    responsible for setting the initial value for dates after a plan change
  */
  useEffect(() => {
    if (resetValues) {
      if (!field.value) {
        setDay(defaultDate.split('/')[1]);
        setMonth(parseMonth(defaultDate.split('/')[0]));
      } else {
        setDay(field.value.split('/')[1]);
        setMonth(parseMonth(field.value.split('/')[0]));
      }

      setResetValues(false);
    }
  }, [field, defaultDate, parseMonth, resetValues, setResetValues]);
  
  /*
    responsible for detecting a plan change via url search param update
  */
  useEffect(() => {
    setResetValues(true);
  }, [location.search]);

  return (
    <Row>
      <Col lg={3} className={styles.planBenefitPeriodDatesLabel}>
        <h5>{label}:</h5>
      </Col>
      <Col>
        <FloatingLabelControl label='Month'>
          <Form.Control
            aria-label={`${label}.month`}
            className="form-select"
            as="select"
            type="select"
            onChange={(e) => setMonth(e.target.value)}
            value={month}
          >
            {months.map((item) => (
              <option key={item.value ?? item} value={item.value ?? item}>{item.text ?? item}</option>
            ))}
          </Form.Control>
        </FloatingLabelControl>
      </Col>
      <Col>
        <FloatingLabelControl label='Day'>
          <Form.Control
            aria-label={`${label}.day`}
            className="form-select"
            as="select"
            type="select"
            onChange={(e) => { setDay(e.target.value); }}
            value={day}
          >
            {days.map((item) => (
              <option key={item.value ?? item} value={item.value ?? item}>{item.text ?? item}</option>
            ))}
          </Form.Control>
        </FloatingLabelControl>
      </Col>
    </Row>
  )
};