import { gql } from '@apollo/client';

export const GET_DRUG_COUPON = gql`
query query($key: String!) {
  rx {
    getGoodRxCoupon(key: $key) {
      price
      memberId
      rxbin
      groupNumber
      rxpcn
      customerServicePhone
      pharmacyServicePhone
    }
  }
}
`;
