import { useEffect, useReducer } from "react";
import { useQuery } from '@apollo/client';
import { useErrorHandler } from 'react-error-boundary';
import { DateTime } from 'luxon';
import { createMutableCopy } from 'apollo/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'consumerLoading':
      return { ...state, loading: true };
    case 'consumerLoaded':
      return { ...state, loading: false, consumer: action.payload };
    default:
      throw new Error('invalid action');
  }
};

export const useConsumerBase = (query, variables, getData) => {
  const [state, dispatch] = useReducer(reducer, {});

  const { loading, data, error } = useQuery(query, {
    variables: variables
  });
  useErrorHandler(error);

  useEffect(() => {
    if (loading === true) dispatch({ type: 'consumerLoading' });
  }, [loading]);

  useEffect(() => {
    if (!data) return;

    const output = getData(data);
    if (output) {
      const result = createMutableCopy(output);
      if (result.dateOfBirth) {
        const date = DateTime.fromISO(result.dateOfBirth);
        result.dateOfBirth = date.toISODate();
      }
      dispatch({ type: 'consumerLoaded', payload: result });
    }
  }, [data, getData]);

  return state;
};