import { FloatingLabel } from "react-bootstrap";
import style from './form.module.css';

export const FloatingLabelControl = ({label, children}) => {

  return (
    <div className={style.input}>
      <FloatingLabel label={label}>
        {children}
      </FloatingLabel>
    </div>
  );
};