import { useState } from 'react';
import { Col, Container} from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Input, Section, SectionHeader } from 'shared/components/form';
import { AddButton, DeleteButton } from 'shared/components/buttons';
import { getError } from 'shared/services/validationService';
import { EditHeader } from './editHeader';
import styles from './edit.module.css';

export const EditCareCenter501 = ({registerPrefix, errors}) => {
  const registerInput = `${registerPrefix}.configuration`;
  const { register, watch } = useFormContext();
  let [type, setType] = useState(watch(`${registerInput}.determinationType`));

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  return (
    <Container fluid>
      <EditHeader title="501(r)(4)" />
      <SectionHeader><h4>Determination Type</h4></SectionHeader>
      <Section>
        <Col lg={5}>
          <DeterminationTypeRadio register={register} onChange={onTypeChange} registerPrefix={registerInput} value="UNKNOWN" label="Unknown" />
          <DeterminationTypeRadio register={register} onChange={onTypeChange} registerPrefix={registerInput} value="FLAT" label="Flat" />
          <DeterminationTypeRadio register={register} onChange={onTypeChange} registerPrefix={registerInput} value="SLIDING" label="Sliding" />
        </Col>
      </Section>
      { (type === 'FLAT' || type === 'SLIDING') && 
        <Indemnity registerPrefix={registerInput} type={type} errors={errors} />
      }
    </Container>
  );
};

const DeterminationTypeRadio = ({register, registerPrefix, value, label, onChange}) => {
  const id=`determination${value}`;

  return (
    <div className="form-check form-check-inline">
      <input 
        {...register(`${registerPrefix}.determinationType`)}
        className="form-check-input" 
        type="radio"
        value={value}
        onChange={onChange}
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  );
};

const Indemnity = ({registerPrefix, type, errors}) => {

  return (
    <>
      <SectionHeader><h4>Indemnity</h4></SectionHeader>
      <Section>
        <Col lg={3}>
        <Input
          type="number"
          autoFocus={true}
          max="100"
          label="Max FPL Basis"
          registerInput={`${registerPrefix}.maxPercentage`}
          errors={errors}
        />
        </Col>
      </Section>
      {type === 'SLIDING' &&
        <SlidingScales registerPrefix={registerPrefix} errors={errors} />
      }
    </>
  );
};

const SlidingScales = ({registerPrefix, errors}) => {
  const { control, getValues, register, setValue, watch } = useFormContext();
  const registerInput = `${registerPrefix}.assistanceScales`;
  const existing = getValues(registerInput);
  if (!existing) setValue(registerInput, []);
  const { fields, append } = useFieldArray({control, name: registerInput});

  const add = () => {
    let newMin;
    if (fields && fields.length > 0) {
      const lastIndex = fields.length - 1;
      const lastMax = getValues(`${registerInput}.${lastIndex}.maxPercentage`);
      newMin = parseInt(lastMax) + 1;
    }
    append({ minPercentage: newMin, maxPercentage: null, financialAssistancePercentage: null, isUnknown: false});
  };

  const onRemove = (index) => {
    const current = getValues(registerInput);
    current.splice(index, 1);
    setValue(registerInput, current);
  };

  return (
    <>
      <Section>
        <Col lg={3}><h4>Coverage Scales</h4></Col>
        <Col lg={1}><AddButton tooltip="Add" onClick={add} /></Col>
      </Section>
      { getError(registerInput, errors)?.message && 
        <Section>
          <div className={`invalid-feedback && ${styles.showValidationError}`}>
            {getError(registerInput, errors)?.message}
          </div>
        </Section>
      }
      <Section>
        <Col lg={2}><b>Min FPL Basis</b></Col>
        <Col lg={2}><b>Max FPL Basis</b></Col>
        <Col lg={2}><b>Coverage %</b></Col>
        <Col lg={1}><b>Unknown?</b></Col>
      </Section>
      {fields.map((item, index) => (
        <Section key={index}>
          <Col lg={2}>
            <Input
              type="number"
              max="100"
              autoFocus={true}
              label="Min"
              registerInput={`${registerInput}.${index}.minPercentage`}
              errors={errors}
            />
          </Col>
          <Col lg={2}>
            <Input
              type="number"
              max="100"
              label="Max"
              registerInput={`${registerInput}.${index}.maxPercentage`}
              errors={errors}
            />
          </Col>
          <Col lg={2}>
            <Input
              type="number"
              max="100"
              label="Coverage %"
              registerInput={`${registerInput}.${index}.financialAssistancePercentage`}
              isReadOnly={watch(`${registerInput}.${index}.isUnknown`)}
              errors={errors}
            />
          </Col>
          <Col lg={1} className="my-auto align-items-center">
            <input 
              type="checkbox" 
              {...register(`${registerInput}.${index}.isUnknown`)}
            />
          </Col>
          <Col lg={1} className="my-auto">
            <DeleteButton onClick={() => onRemove(index)} tooltip="Delete" />
          </Col>
        </Section>
      ))}
    </>
  );
};
