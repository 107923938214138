import { ConciergeHelp, FloatingHelpButton } from 'concierge/components/contact';
import { SurveyButton } from './surveyButton';
import parse from 'react-html-parser';

const copy = parse('Please take some time to review each of the hospitals before contacting your Care Concierge. Please - do NOT contact the hospital(s) directly.<br/><br/>You can either answer a few quick questions to get the process started or call your Care Concierge.');
const children = ( <SurveyButton variant='secondary' code='hospitalFinder' /> );
const title = 'Ready to take the next steps? Care Concierge is here!';

export const FindHospitalHelpButton = () => {
  return (<FloatingHelpButton title={title} copy={copy} children={children} />);
};

export const FindHospitalHelp = () => {
  return (<ConciergeHelp title={title} copy={copy} children={children} />);
};