import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useEffect, useState } from "react";
import { Container, Col, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, DeleteButton } from 'shared/components/buttons'
import { Section, Input } from 'shared/components/form';
import { toastSuccess } from 'shared/components/toasts';
import { useMutationWrapper } from 'apollo/useMutationWrapper';
import { networkIntegrationValidationSchema } from 'network/networkValidationSchema';
import { UPDATE_NETWORK } from 'network/operations/mutations';
import { prepareNetworkForSave } from 'network/services/formHelper';
import { EditAthenaIntegration } from "./editAthenaIntegration";
import { EditLyricIntegration } from './editLyricIntegration';
import { EditDoxymeIntegration } from "./editDoxymeIntegration";

export const Integrations = {
  ATHENA: {
    title: 'Athena',
    idLabel: 'Practice ID',
    component: EditAthenaIntegration,
  },
  LYRIC : {
    title: 'Lyric',
    idLabel: 'Group Code',
    component: EditLyricIntegration
  },
  DOXYME: {
    title: 'Doxy.me',
    idLabel: 'ID',
    idValue: 'doxy.me',
    component: EditDoxymeIntegration,
  }
};

export const EditNetworkIntegration = ({network, integrationType, setBlockNavigation}) => {
  const [integration, setIntegration] = useState(network.integrations.find(x => x.type === integrationType));
  const formMethods = useForm({
    defaultValues: {integration: integration ?? { id: ''}},
    resolver: yupResolver(networkIntegrationValidationSchema),
    mode: 'onChange'
  });

  let resetForm = formMethods.reset;
  useEffect(() => {
    resetForm({integration: integration});
  }, [integration, resetForm]);

  const onSaved = () => {
    toastSuccess(`Integration saved`);
    setBlockNavigation(false);
  };
  const [saveNetwork] = useMutationWrapper(UPDATE_NETWORK, {
    onCompleted: onSaved
  });

  const onCreate = () => {
    const newIntegration = {
      type: integrationType,
      id: Integrations[integrationType].idValue
    };
    setIntegration(newIntegration);
    network.integrations.push(newIntegration);
    setBlockNavigation(true);
  };

  const onSubmit = async(data) => {
    const updated = data.integration;
    const index = network.integrations.findIndex(x => x.type === integrationType);
    network.integrations[index] = updated;
    await save(network);
  };

  const onDelete = async() => {
    const index = network.integrations.findIndex(x => x.type === integrationType);
    network.integrations.splice(index, 1);
    await save(network);
  };

  const save = async(network) => {
    const input = prepareNetworkForSave(network);
    await saveNetwork({ variables: { input: input }});
  };

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields);
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  return (
    <FormProvider {...formMethods}>
      <form name="networkIntegrations" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Container fluid>
          <Header 
            integrationType={integrationType} 
            integration={integration}
            onDelete={onDelete} 
          />
          {integration 
            ? <>
                <ExistingIntegration 
                  integrationType={integrationType} 
                  integration={integration} 
                  errors={formMethods.formState.errors}
                />
                <Section>
                  <Button type="submit" text='Save' />
                </Section>
              </>
            : <NoIntegration 
                integrationType={integrationType}
                onCreate={onCreate} 
              />
          }
        </Container>
      </form>
    </FormProvider>
  );
};

const Header = ({integrationType, integration, onDelete}) => {
  const title = Integrations[integrationType].title;
  const [showConfirm, toggleConfirm] = useState(false);

  return (
    <>
      <Section>
        <Col sm={6}>
          <h3>Integration: {title}</h3>
        </Col>
        {integration && 
          <Col>
            <DeleteButton onClick={() => toggleConfirm(true)} tooltip="Delete" />
          </Col>
        }
      </Section>
      <Modal show={showConfirm} onHide={() => toggleConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete integration?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm deletion of the integration.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggleConfirm(false)} text="Cancel" />
          <Button onClick={onDelete} text="Delete" />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const NoIntegration = ({integrationType, onCreate}) => {
  const title = Integrations[integrationType].title;

  return (
    <Section>
      <Col>
        {title} integration does not exist. Click to create one:
        <br />
        <br />
        <Button onClick={onCreate} text="Add integration" />
      </Col>
    </Section>
  );
};

const ExistingIntegration = ({integrationType, integration, errors }) => {
  const { idLabel, component } = Integrations[integrationType];
  const Component = component;

  return (
    <>
    <Section>
      <Col sm={6}>
        <Input 
          label={idLabel}
          registerInput='integration.id'
          errors={errors}
        />
      </Col>
    </Section>
    <Component integration={integration} />
    </>
  );
};