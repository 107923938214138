import { gql } from '@apollo/client';
import { CONSUMER_FIELDS } from '../fragments';

export const GET_CONSUMER_FOR_ACCOUNT = gql`
  ${CONSUMER_FIELDS}
  query GetConsumerForAccount($accountId: ID!) {
    findConsumers(filter: {
      account: { id: { eq: $accountId}}
    }, page: { limit: 1, offset: 0 }) {
      items {
        ...ConsumerFields
      }
    }
}
`;