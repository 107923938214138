import { Row, Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Section } from 'shared/components/form';
import { EditName } from 'shared/components/contact/editName';
import { Input } from 'shared/components/form/input';
import { TenantSelector } from 'tenant/components/tenantSelector';
import { getError } from 'shared/services/validationService';
import styles from '../../../admin/components/edit/edit.module.css';

export const EditAccountBasicInfo = ({registerPrefix, isNew, errors}) => {
  const { control } = useFormContext();
  
  const getTenantError = () => {
    return getError(`${registerPrefix}.tenant`, errors);
  };

  return (
    <>
    <Row>
      <Section>
        <Col md={4}>
          {isNew 
            ? <Input 
                autoFocus={true}
                label="Email"
                registerInput={`${registerPrefix}.email`}
                errors={errors}
              />
            : <Controller
                control={control}
                name={`${registerPrefix}.email`}
                render = {({ field }) => (
                  <div className={styles.emailDisplay}>
                    Email: {field.value}
                  </div>
                )}
              />
          }
          
        </Col>
        <Col>
            {isNew
              ?
              <>
                <TenantSelector registerPrefix={registerPrefix} />
                {getTenantError()?.message &&
                  <div className={`invalid-feedback ${styles.showValidationError}`}>
                    {getTenantError()?.message}
                  </div>
                }
              </>
              : <Controller
                control={control}
                name={`${registerPrefix}.tenant.name`}
                render={({ field }) => (
                  <div className={styles.emailDisplay}>
                    Tenant: {field.value}
                  </div>
                )}
              />
            }
        </Col>
      </Section>
    </Row>
    {isNew &&
      <Row>
        <Controller
          control={control}
          name='controller'
          render = {({ ref }) => (
            <EditName inputRef={ref} registerPrefix={registerPrefix} errors={errors} />
          )}
        />
      </Row>
    }
    </>
  )
}
