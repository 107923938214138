import { useQuery } from '@apollo/client';
import { FaCashRegister, FaFileInvoice } from 'react-icons/fa';
import { useParams } from 'react-router';
import { createMutableCopy } from 'apollo/utils';
import { TabLayout } from 'shared/components/tablayout';
import { GET_PAYER, GET_PLANS_FOR_PAYER } from 'payers/operations/queries';
import { EditPayerBasicInfoForm } from 'payers/components/editPayerBasicInfoForm';
import { EditPayerPlansForm } from 'payers/components/editPayerPlansForm';
import { AdminHeader } from 'shared/components/header/adminHeader';
import styles from '../payers.module.css';

export const EditPayer = () => {
  const { payerId } = useParams();
  const isNew = payerId === 'new';

  const tabs = [
    {
      key: 'basic',
      title: 'Basic Info',
      icon: FaCashRegister,
      default: true,
      component: ({payer, setBlockNavigation, isNew }) => (<EditPayerBasicInfoForm payer={payer} setBlockNavigation={setBlockNavigation} isNew={isNew}/>)
    },
    {
      key: 'plan',
      title: 'Plans',
      icon: FaFileInvoice,
      disabled: isNew,
      component: ({payer, plans, setBlockNavigation, isNew }) => (<EditPayerPlansForm payer={payer} plans={plans} setBlockNavigation={setBlockNavigation} isNew={isNew}/>)
    }
  ];

  const { loading, data } = useQuery(GET_PAYER, {
    skip: payerId === 'new',
    variables: { id: payerId } 
  });

  const { loading: planLoading, data: planData } = useQuery(GET_PLANS_FOR_PAYER, {
    skip: payerId === 'new',
    variables: { payerId: payerId } 
  });


  let payer = data?.getPayer ? createMutableCopy(data?.getPayer) : {};
  let plans = planData?.findPlans ? planData.findPlans.items : [];
  const title = isNew ? 'New Payer' : payer?.name;

  return (
    <>
      { (loading || planLoading) &&  <h1>Loading...</h1> }
      { !(loading || planLoading) &&
        <>
          <AdminHeader title={title} id={payer?.id} />
          <div className={styles.tabs}>
            <TabLayout 
              tabs={tabs} 
              payer={payer}
              plans={plans}
              isNew={isNew}
            />
          </div>
        </>
      }
    </>
  )
}