import { Container, Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon'
import { FaRegStickyNote } from 'react-icons/fa';
import styles from 'case/case.module.css';
import { TagsAction } from 'case/components/activity/actions';
import { useState, useReducer } from 'react';
import parse from 'html-react-parser';
import { Tag } from '../tag';
import { TagsReducer, TagRemoved } from './tagsReducer';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTE } from 'case/operations/mutations';
import { useEffect } from 'react';
import { isEqual } from 'lodash';

export const Note = ({content}) => {
  const [note, setNote] = useState(content);
  const [state, dispatch] = useReducer(TagsReducer, {tags:content.tags ?? []});
  const [showActions, setShowActions] = useState(false);

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted: (data) => {
      setNote(data.updateNote);
    }
  });

  useEffect(() => {
    if (!isEqual(state.tags, note.tags)) {
      updateNote({variables: { 
        input: {
          id: note.id,
          tags: state.tags
        }
      }});
    }
  }, [state, updateNote, note])

  return (
    <Container onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}>
      <Row>
      <Col lg={1} className={styles.messageThreadType}>
        <FaRegStickyNote size={25} className={styles.noteIcon} />
      </Col>
      <Col>
        <Row className={styles.messageHeader}>
          <Col>
            <span className={styles.messageSender}>{content.sender ?? 'SENDER'}</span>
            <span className={styles.messageTime}>
              {DateTime.fromMillis(content.sentAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </span>
          </Col>
          <Col className={styles.messageActions}>
            { showActions && 
              <TagsAction props={{content: {...content, tags: state.tags}, dispatch: dispatch}} />
            }
          </Col>
        </Row>
        <Row>
          <Col className={styles.messageBody}>
            {parse(content.text)}
          </Col>
        </Row>
        <Row className={styles.messageTagsList}>
        {state.tags.map((item, index) => (
          <Tag key={item} tag={item} allTags={[]} onRemove={() => TagRemoved(dispatch, item)} />
        ))}
        </Row>
      </Col>
      </Row>
      <hr />
    </Container>
  );
};
