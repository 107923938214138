import { useQuery } from "@apollo/client";
import { useEffect, useReducer } from "react";
import { useErrorHandler } from 'react-error-boundary';

const reducer = (state, action) => {
  switch(action.type) {
    case 'loading':
      return {...state, loading: true};
    case 'data':
      return {...state, loading: false, data: action.payload};
    default: 
      throw new Error('invalid action');
  }
};

export const useQueryWrapper = (query, opts) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(query, opts);
  const [state, dispatch] = useReducer(reducer, {}, () => {
    return {
      loading: loading,
      data: data,
      refetch: refetch,
      fetchMore: fetchMore
    };
  });
  useErrorHandler(error);

  useEffect(() => {
    if (loading === true) dispatch({ type: 'loading' });
  }, [loading]);

  useEffect(() => {
    if (data) dispatch({ type: 'data', payload: data });
  }, [data]);

  return state;
};