import { Container, Col } from 'react-bootstrap';
import { Controller, useFormContext } from "react-hook-form";
import { DropDown, Input, Section, SectionHeader } from 'shared/components/form';

export const EditAdditionalInformation = ({ registerPrefix, errors }) => {
  const { control } = useFormContext();

  const sexes = [
    { value: 'MALE', text: 'Male' },
    { value: 'FEMALE', text: 'Female' }
  ];

  const pronouns = [
    'she/her/hers',
    'he/him/his',
    'they/them/themselves',
    'xey/xem/xyr ',
    'ze/hir ',
    'ze/zir'
  ];

  return (
    <Container>
      <SectionHeader>
        <h4>Additional Information</h4>
      </SectionHeader>
      <Section>
        <Col sm={3}>
          <Controller
            control={control}
            name='controller'
            render={({ onChange, ref }) => (
              <Input
                type="date"
                inputRef={ref}
                label='Date Of Birth'
                registerInput={`${registerPrefix}.dateOfBirth`}
                onChange={onChange}
                errors={errors}
              />
            )}
          />
        </Col>
        <Col sm={3}>
          <Controller
            control={control}
            name='controller'
            render={({ ref }) => (
              <DropDown
                items={sexes}
                inputRef={ref}
                label='Sex'
                registerInput={`${registerPrefix}.sex`}
              />
            )}
          />
        </Col>
        <Col sm={3}>
          <Controller
            control={control}
            name='controller'
            render={({ ref }) => (
              <DropDown
                items={pronouns}
                inputRef={ref}
                label='Pronoun'
                registerInput={`${registerPrefix}.pronoun`}
              />
            )}
          />
        </Col>
      </Section>
      <hr />
    </Container>
  );
};