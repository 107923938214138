import { DateTime } from 'luxon';
import { createMutableCopy } from "apollo/utils";

export const createMutableEnrollment = (enrollment) => {

  let mutable = createMutableCopy(enrollment);
  mutable.getCurrentBenefitPeriod = getCurrentBenefitPeriod.bind(mutable);

  return mutable;
};

// assumes PLAN_YEAR type for now
function getCurrentBenefitPeriod () {
  const startParts = this.plan.benefitPeriod.configuration.startDate.split('/');
  const endParts = this.plan.benefitPeriod.configuration.endDate.split('/');
  const today = DateTime.now();

  const testStart = getDate(today.year, startParts);
  const startYear = (testStart > today) ? today.year - 1 : today.year;
  const start = getDate(startYear, startParts);

  const endTest = getDate(today.year, endParts);
  const endYear = (endTest > today) ? today.year : today.year + 1;
  const end = getDate(endYear, endParts);

  return { start, end };
};

const getDate = (year, parts) => {
  return DateTime.utc(year, parseInt(parts[0]), parseInt(parts[1]));
}