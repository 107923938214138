/*
* Sorts an array by a nested contact property.
* contact.name.last asc, contact.name.first asc
*/
export const sortContactsByLastThenFirst = (a, b) => {
  if (a.contact.name.last === b.contact.name.last) {
    if (a.contact.name.first === b.contact.name.first) {
      return 0;
    } else {
      return a.contact.name.first > b.contact.name.first ? 1 : -1;
    }
  }

  return a.contact.name.last > b.contact.name.last ? 1 : -1;
};

/*
* Sorts an array of payers by name and 
* a nested tenant name property.
*/
export const sortPayersByNameThenTenantName = (a, b) => {
  if (a.name === b.name) {
    if (a.tenant.name === b.tenant.name) {
      return 0;
    } else {
      return a.tenant.name > b.tenant.name ? 1 : -1;
    }
  }

  return a.name > b.name ? 1 : -1;
};