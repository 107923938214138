import { useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { createMutableCopy } from "apollo/utils";
import { useQueryWrapper } from "apollo/useQueryWrapper";
import { GenerosityScore } from 'benefits/indemnity/components/generosityScore';
import { getCoverImageUrl } from 'careCenter/services/getCoverImageUrl';
import { fapCalculator } from '../services/fapCalculator';
import { FIND_501_CARECENTERS } from '../operations/queries/find501CareCenters';
import { FindHospitalResults } from "careCenter/components/findHospitalResults";
import { FindLoading } from "careCenter/components/findLoading";

export const FindCareCenters = ({consumer, enrollment}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const consumerAddress = consumer.contact.addresses.find(x => x.type.includes('PHYSICAL'));
  const { data, refetch } = useQueryWrapper(FIND_501_CARECENTERS, {
    variables: { search: {
      coordinates: consumerAddress.geometry.coordinates,
      networks: enrollment.plan.networks.map(x => x.id)
    }}
  });

  /*
    Changing the fetchPolicy on the query does not have an effect, but this
    query can go stale and require a hard refresh without refetch as careCenter(s)
    will be added / removed from this resultset based on their published status
  */
  if (!isInitialized) {
    if (data) {
      refetch();
    }
    setIsInitialized(true);
  };

  if (data) {
    const items = data.indemnity.find501CareCenters.careCenters;
    let results;

    if (items && items.length > 0) {
      results = items
        .map(result => {
          const clean = createMutableCopy(result);
          clean.score = fapCalculator.calculateScore(
            result.careCenter,
            enrollment.benefits.find(x => x.benefit === 'indemnity').configuration,
            consumerAddress
          );
          clean.careCenter.getCoverImageUrl = getCoverImageUrl.bind(clean.careCenter);

          return clean;
        })
        .sort((a, b) => ((b.score ?? -1) - (a.score ?? -1)))
        .slice(0,3);
    }

    return (
      <>
        { results
          ? <FindHospitalResults results={results} optionalComponent={GenerosityScore}/>
          : 
          <Row>
            <Col><h1>No Results Found</h1></Col>
          </Row>
        }
      </>
    );
  }

  return (<FindLoading />);
};