import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import styles from 'shared/components/contact/contact.module.css';
import { DropdownList } from 'react-widgets';
import { DisplayPhoneNumber } from 'shared/components/contact';
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { getError } from "shared/services/validationService";
import { useCallback } from 'react';

export const PhoneNumberDropdown = ({consumer, errors, registerInput, label}) => { 
  const [phoneNumbers, setPhoneNumbers] = useState(consumer?.contact?.phoneNumbers
                                                ? consumer.contact.phoneNumbers.filter(x => x.type !== 'FAX')
                                                : []);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { setValue } = useFormContext();
  label = label ?? 'Phone Number';

  const getInputError = () => getError(registerInput, errors);

  const onPhoneNumberSelected = useCallback((phoneNumber) => {
    setSelectedNumber(phoneNumber);
    setValue(registerInput, phoneNumber.number.slice(2));
  }, [setSelectedNumber, setValue, registerInput]);

  const onPhoneNumberCreated = (phoneNumber) => { 
    if (!phoneNumber) {
      return;
    }

    const cleanedNumber = phoneNumber.replace(/[^0-9]/g, '');
    if (cleanedNumber.length !== 10) {
      return;
    }

    const mappedNumber = {
      number: `+1${cleanedNumber}`,
      country: {
        code: 'US',
        prefix: '1'
      },
      type: 'MOBILE'
    }
    setPhoneNumbers([...phoneNumbers, mappedNumber]);
    setSelectedNumber(mappedNumber);
    setValue(registerInput, mappedNumber.number.slice(2));
  };

  const onSearchChanges = (number) => {
    let phoneNumber = normalizePhoneNumber(number)
    setSearchTerm(phoneNumber);
  };

  useEffect(() => {
    if (!selectedNumber && phoneNumbers.length > 0) {
      onPhoneNumberSelected(phoneNumbers[0]);
    }
  }, [selectedNumber, phoneNumbers, onPhoneNumberSelected]);

  return (
    <div className={styles.phoneDropdown}>
      <FloatingLabelControl label={label} for={`phoneDropDown`}>
        <DropdownList
          containerClassName={styles.phoneDropdownContainer}
          id='phoneDropDown'
          autoComplete="on"
          allowCreate={true}
          filter="contains"
          placeholder=" "
          value={selectedNumber}
          onSelect={onPhoneNumberSelected}
          onCreate={onPhoneNumberCreated}
          onSearch={onSearchChanges}
          searchTerm={searchTerm}
          textField={(item) => item.number}
          renderValue={(item) => ( <DisplayPhoneNumber phoneNumber={item.item} /> )}
          renderListItem={(item) => (<DisplayPhoneNumber phoneNumber={item.item} />)}
          data={phoneNumbers}
        />
      </FloatingLabelControl>

      <div className={`invalid-feedback ${styles.showPhoneDropdownValidationError}`}>
        {getInputError()?.message}
      </div>
  </div>
  )
}

export const normalizePhoneNumber = (number) => {
  let phoneNumber = number.replace(/[^0-9]/g, '');
  if (phoneNumber.length > 3 && phoneNumber.length < 7) {
    phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else if (phoneNumber.length >= 7){
    phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  return phoneNumber;
}