import { useCurrentEnrollment } from 'enrollment/hooks/useCurrentEnrollment';
import { EditProfile } from 'consumer/components/profile/editProfile';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';

export const MyProfile = () => {
  const { consumer, enrollment } = useCurrentEnrollment();
  const account = useCurrentAccount();

  if (consumer && enrollment) {
    return (
      <EditProfile
        consumer={consumer}
        enrollment={enrollment}
        account={account}
      />
    );
  }

  return (<h2>Loading...</h2>);
};