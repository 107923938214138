export const formatPrice = (price) => {
  let priceParts = price.toString().split('.');
  if (priceParts.length === 2) {
    if (priceParts[1].length !== 2) {
      priceParts[1] = priceParts[1] + '0';
    }
  } else {
    priceParts.push('00');
  }
  
  return priceParts.join('.');
};