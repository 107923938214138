import { useQuery } from '@apollo/client';
import { Nav } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactToolTip from 'react-tooltip';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { GET_MESSAGE_COUNT } from 'message/operations/queries/getMessageCount';
import styles from './messages.module.css';
import { accountPaths } from 'account/routes';

export const Messages = () => {
  const account = useCurrentAccount();
  const filter = {
    status: { eq: 'UNREAD'},
    recipient: { type: { eq: 'ACCOUNT' }, id: { eq: account.id } }
  };
  const { data } = useQuery(GET_MESSAGE_COUNT, { 
    variables: { filter },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <>
    { data?.findMessageRecipients?.count > 0
      ? <UnreadMessages count={data?.findMessageRecipients?.count} />
      : <AllReadMessages />
    }
    </>
  );
};

const AllReadMessages = () => {
  return (
    <MessagesLink testId="no-messages">
      <FaEnvelope size={20} className={styles.noMessages} />
    </MessagesLink>
  );
};

const UnreadMessages = ({count}) => {
  const style =  styles.hasMessages + " badge badge-pill badge-danger";
  return (
    <MessagesLink testId="messages" tooltip={`${count} unread messages`}>
      <FaEnvelope size={20} className={styles.messages} />
      <sup><span className={style}>&nbsp;</span></sup> 
      <ReactToolTip />
    </MessagesLink>
  );
};

const MessagesLink = ({testId, tooltip, children}) => {
  return (
    <Nav.Item as={Link} to={accountPaths.messages} data-testid={testId} data-tip={tooltip}>
      {children}
    </Nav.Item>
  );
};