
export const attachPermissionHelpers = (account) => {
  account.effectivePermissions = account.roles.reduce((a, b) => a.concat(b.permissions), []).sort();
  account.hasPermission = hasPermission.bind(account);
  account.getMostPermissive = getMostPermissive.bind(account);
};

export const parsePermission = permission => {
  const parts = permission.split('.');

  return {
    scope: parts[0],
    action: parts[1],
    context: parts.length > 2 ? parts[2] : null,
    value: permission
  };
};

//can either pass permission object or permission string
function hasPermission (requestedPermission) {
  const effectivePermissions = this.effectivePermissions.map(parsePermission);
  const requested = typeof requestedPermission === 'object'
    ? requestedPermission
    : parsePermission(requestedPermission);

  return effectivePermissions.some(permission => {
    if (permission.scope === '*') return true;

    return permission.scope === requested.scope
      && [requested.action, '*', 'self'].includes(permission.action);
  });
}

//can either pass permission object or permission string
function getMostPermissive (requestedPermission) {
  const effectivePermissions = this.effectivePermissions.map(parsePermission);
  const requested = typeof requestedPermission === 'object'
    ? requestedPermission
    : parsePermission(requestedPermission);

  //we only care about permissions that are either wildcard or the requested scope
  const scopePermissions = effectivePermissions.filter(perm => {
    return [requested.scope, '*'].includes(perm.scope);
  });

  if (scopePermissions.length < 1) throw new Error(`account does not have access to scope ${requested.scope}`);

  // check wildcard scopes first
  const wildCardScopes = scopePermissions.filter(x => x.scope === '*');
  if (wildCardScopes.length > 0) {
    //TODO: who wins: network vs tenant (vs other)?
    const global = wildCardScopes.find(x => x.context === null);

    return global ? global : wildCardScopes[0];
  }

  //check requested scope
  const requestedScopes = scopePermissions.filter(x => x.scope === requested.scope);
  const actionPrecendence = ['*', 'self', requested.action];
  if (requestedScopes.length > 0) {
    requestedScopes.sort((a, b) => {
      const ai = actionPrecendence.indexOf(a.action);
      const bi = actionPrecendence.indexOf(b.action);
      if (ai === bi) {  // check for context (tie goes to global)
        return a.context === null
          ? -1
          : 1;
      }

      return ai - bi;
    });
    
    //ensure we don't return a permission other than what was asked for.
    const returnedPermission = requestedScopes[0];
    if (!actionPrecendence.includes(returnedPermission.action) 
      && returnedPermission.action !== requested.action) {
        throw new Error(`account does not have ${requested.action} permission on ${requested.scope}`);
    }

    return returnedPermission;
  }

  throw new Error(`account does not have access to ${requestedPermission}`);
}