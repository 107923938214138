import {createMutableCopy} from 'apollo/utils';

export const prepareNetworkForForm = (network) => {
  const copy = createMutableCopy(network);

  if (!copy.integrations) {
    copy.integrations = [];
  }

  return copy;
};

export const prepareNetworkForSave = (network) => {
  network.tenant = {id: network.tenant.id};

  return network;
};