import { useMutation } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UPDATE_CARECENTER } from '../../../operations/mutations/updateCareCenter';
import { MediaGallery } from './mediaGallery';
import { MediaUploadInstructions } from './instructions';
import { MediaUpload } from './mediaUpload';
import { EditExistingCareCenterForm } from '../editForm';
import { EditHeader } from '../editHeader';
import { prepareCareCenterForSave } from '../../../services/formHelper';

export const EditCareCenterMedia= ({...props}) => {
  return (
    <EditExistingCareCenterForm
      component={EditCareCenterMediaForm}
      {...props}
    />
  );
};

//TODO: this is ripe for extracting to a re-usable component...will wait until 2nd time it is needed to figure out best pattern
export const EditCareCenterMediaForm = ({registerPrefix}) => {
  const { careCenterId } = useParams();
  const [updateCareCenter] = useMutation(UPDATE_CARECENTER);
  const { control, getValues, setValue } = useFormContext();
  const registerField = `${registerPrefix}.media`;
  const { fields, remove } = useFieldArray({ 
    control, 
    name: registerField, 
    keyName: 'fileName'
  });

  const onFilesUploaded = ({filesUploaded}) => {
    const media = getValues(registerField);
    let sequenceNumber = fields.length + 1;

    for (var x = 0; x < filesUploaded.length; x++) {
      var newMedia = filesUploaded[x];
      newMedia.sequenceNumber = sequenceNumber++;
      media.push(newMedia);
    }
    setValue(registerField, media);

    const update = {
      id: careCenterId,
      media: media
    };
    prepareCareCenterForSave(update);
    updateCareCenter({variables: { input: update }});
  };

  const onRemoveFile = (index) => {
    remove(index);
  };

  const onCoverSelected = (index) => {
    for(let i=0; i < fields.length; i++) {
      if (i !== index) {
        setValue(`${registerField}.${i}.isCoverImage`, false);
      }
    }
  };

  return (
    <Container fluid>
      <EditHeader title="Media" />
      <Row>
        <Col lg={6} className="my-auto">
          <MediaUpload onFilesUploaded={onFilesUploaded} />
        </Col>
        <Col lg={6}>
          <MediaUploadInstructions />
          </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <MediaGallery 
            registerPrefix={registerField} 
            fields={fields} 
            onRemove={onRemoveFile} 
            onCoverSelected={onCoverSelected}
          />
        </Col>
      </Row>
    </Container>
  );
};