import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import { BoxLoading } from 'shared/components/spinners';
import { GET_BENEFIT_USAGES } from 'benefits/operations/queries/getBenefitUsages';
import { AvailableVisits } from 'benefits/shared/availableVisits';
import { TelehealthIcon } from './icon';
import { RequestAppointmentButton } from 'benefits/telehealth/components/requestAppointment/requestAppointmentButton';

export const YouGetTelehealth = ({enrollment}) => {
  const benefitPeriod = enrollment.getCurrentBenefitPeriod();
  const activeNetwork = enrollment.plan?.benefits?.find(x => x.benefit === 'telehealth')?.configuration?.activeNetwork;
  const { data } = useQuery(GET_BENEFIT_USAGES, { 
    variables: {
      filter: {
        enrollment: { id: { eq: enrollment.id } },
        benefitPeriod: {
          start: { eq: benefitPeriod.start.toISO() },
          end: { eq: benefitPeriod.end.toISO() }
        }
      }
    }
  });

  return (
    <YouGetCard 
      title='Telehealth'
      icon={<TelehealthIcon />}
      copy="Get access to many types of necessary care via your Primary Care team, from wherever you are, via our secure telehealth services. As always, Care Concierge will help with scheduling and follow-up."
    >
      <>
        {data?.findBenefitUsages
        ?
          <Row>
            <Col>
              <AvailableVisits
                title="Visits:"
                enrollment={enrollment}
                benefitUsage={data?.findBenefitUsages?.items[0]}
                benefitName="telehealth"
                allowedProperty="visits"
                usageProperty="visits"
              />
            </Col>
          </Row>
        : 
          <Row style={{maxHeight: '60px'}}>
            <Col><BoxLoading height={80} width={240} /></Col>
          </Row>
        }
        <Row className="justify-content-center" style={{ 'position': 'relative', 'bottom': -25}}>
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <RequestAppointmentButton network={activeNetwork} />
          </Col>
        </Row>
      </>
    </YouGetCard>
  );
};