import { Row } from 'react-bootstrap';
import styles from '../rx.module.css';
import poweredByGoodRx from 'benefits/rx/images/powered_by_goodrx_yellow_xs.png';
import { FaSearch } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FIND_DRUG_NAMES } from '../operations/queries/findNames';
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import { useHistory } from 'react-router';
import { rxPaths } from '../routes';

export const GoodRxSearch = ({placeholder}) => {
  placeholder = placeholder ?? 'Type drug name...';
  const [searchTerm, setSearchTerm] = useState('');  
  const [searchDrugs, {loading, data}] = useLazyQuery(FIND_DRUG_NAMES);
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();

  const onSelect = (selection) => {
    history.push(`${rxPaths.prescriptionDetail}/${selection}`);
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      searchDrugs({variables: {term: searchTerm}});
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, searchDrugs]);

  useEffect(() => {
    let newSuggestions = data?.rx?.findNames ?? [];
    setSuggestions(newSuggestions);
  }, [loading, data?.rx?.findNames]);
 
  return (
    <>
      <Row>
        <Combobox placeholder={placeholder} 
                  className={styles.goodRxInput} 
                  data={suggestions} 
                  onChange={(e) => setSearchTerm(e)}
                  hideEmptyPopup
                  filter={false}
                  selectIcon={<FaSearch className={styles.goodRxSearchIcon}/>}
                  onSelect={onSelect}
                  busy={loading}
        /> 
      </Row>
      <Row className={styles.goodRxLogoContainer}>
        <a href="https://www.goodrx.com" target="_blank" rel="noreferrer">
          <img src={poweredByGoodRx} alt="poweredByGoodRx" className={styles.goodRxLogo}/>
        </a>
      </Row>
    </>
  )
}