import { Container, Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon'
import { FaRegEnvelope, FaReply } from 'react-icons/fa';
import { IconButton } from 'shared/components/buttons/iconButton';
import styles from 'case/case.module.css';
import { useState, useReducer } from 'react';
import parse from 'html-react-parser';
import { Tag } from '../tag';
import { TagsReducer, TagRemoved } from './tagsReducer';
import { RecipientsAction, SenderAction, TagsAction } from './actions';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_MESSAGE } from 'case/operations/mutations/updateMessage';
import { isEqual } from 'lodash';
import { ContentEditor } from './contentEditor';

export const MessageThread = ({caseData, content, onReplyCompleted, parentMessage}) => {
  const [message, setMessage] = useState(content);
  const [replyMessage, setReplyMessage] = useState(null);
  const [state, dispatch] = useReducer(TagsReducer, {tags:content.tags ?? []});
  const [showActions, setShowActions] = useState(false);
  
  const [updateMessage] = useMutation(UPDATE_MESSAGE, {
    onCompleted: (data) => {
      setMessage({...data.updateMessage, replies: message.replies});
    }
  });

  const replyCompleted = (addedMessage) => {
    if (addedMessage) {
      setMessage({...message, replies: [...message.replies, addedMessage]})
    }

    setReplyMessage(null);
  };

  useEffect(() => {
    setMessage(content);
  }, [content])

  useEffect(() => {
    // if tags have changed and our message was already persisted
    if (!isEqual(state.tags, message.tags) && message.id) {
      updateMessage({variables: { 
        input: {
          id: message.id,
          tags: state.tags
        }
      }});
    }
  }, [state, updateMessage, message])

  let replies = message.replies ? [...message.replies] : [];
  if (replyMessage) {
    replies.push(replyMessage);
  }

  return (
    <Container>
      <div onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}>
      <Row>
        <Col lg={1} className={styles.messageThreadType}>
          { message.parent 
            ? <FaReply size={25} className={styles.messageReplyIcon} />
            : <FaRegEnvelope size={25} className={styles.messageIcon} />
          }
        </Col>
        <Col>
        { !('id' in message)
          ? <ContentEditor 
              caseData={caseData}
              contentType={'Message'}
              replyToMessage={parentMessage}
              onCompleted={onReplyCompleted}
              tags={state.tags}
              dispatch={dispatch}
            />
          : <>
            <Row className={styles.messageHeader}>
              <Col>
                <span className={styles.messageSender}>{message.sender.name}</span>
                <span className={styles.messageTime}>
                  {DateTime.fromMillis(message.sentAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                </span>
              </Col>
              <Col className={styles.messageActions}>
                { showActions && 
                  <>
                  { message.sender.type === 'ACCOUNT' && 
                    <IconButton IconComponent={FaReply} size={15} tooltip='Reply' onClick={() => setReplyMessage({parent: true})}/>
                  }
                    <SenderAction content={message} />
                    <RecipientsAction content={message} />
                    <TagsAction props={{message: {...message, tags: state.tags}, dispatch: dispatch}} />
                  </>
                }
              </Col>
            </Row>
            <Row>
              <Col className={styles.messageBody}>
                {parse(message.text)}
              </Col>
            </Row>
            <Row className={styles.messageTagsList}>
            {state.tags.map((item, index) => (
              <Tag key={item} tag={item} allTags={[]} onRemove={() => TagRemoved(dispatch, item)} />
            ))}
            </Row>
            </> 
        }
        </Col>
      </Row>
      </div>
      { replies?.map((item, index) => (
        <Row className={styles.messageReplyContainer} key={index}>
          <div style={{width: '98%'}}>
            <MessageThread caseData={caseData} content={item} parentMessage={message} onReplyCompleted={replyCompleted}/>
          </div>
        </Row>
      ))}
      { !parentMessage && <hr />}
    </Container>
  );
};
