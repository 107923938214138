import { Col, Row } from 'react-bootstrap';
import { ConciergeIcon } from './icon';
import { ContactButton } from 'concierge/components/contact/contactButton';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';

export const YouGetConcierge = () => {

  return (
    <YouGetCard 
      title='Care Concierge'
      icon={<ConciergeIcon />}
      copy="Let's face it - few people understand healthcare - we do. Don't be shy - got a question? Give our Care Concierge a call - from booking an appointment, finding affordable care, getting a referral, and much, much more. Our qualified team is here to be your advocate and take the healthcare journey with you."
    >
      <Row style={{ 'position': 'relative', 'bottom': -5}}>
        <Col>
          <ContactButton variant="secondary" />
        </Col>
      </Row>
    </YouGetCard>
  );
};