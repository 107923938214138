import { GET_ALL_NETWORKS } from 'network/operations/queries';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { useState } from 'react';
import { DropDown } from 'shared/components/form';

export const NetworkSelector = ({ ignoreIds=[], onSelected }) => {
  const [networks, setNetworks] = useState([]);

  useQueryWrapper(GET_ALL_NETWORKS, {
    onCompleted: (data) =>  {
        setNetworks(data.findNetworks.items.filter(x => ignoreIds.indexOf(x.id) < 0).map(x => ({
          value: x.id,
          text: x.name
        })));
      }
    }
  );

  return (
    <DropDown
      label="Networks"
      items={networks}
      registerInput={null}
      onSelected={onSelected} 
    />
  );
};



