import { useEffect, useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import ReactToolTip from 'react-tooltip';
import styles from './tablayout.module.css';

export const TabLayout = ({ tabs, onSaved, ...props }) => {
  const params = useParams();
  const { tab, childtab } = params;
  const history = useHistory();
  const { path } = useRouteMatch();
  const activeTabKey = tab ?? tabs.find(t => t.default).key;
  const activeTab = tabs.find(t => t.key === activeTabKey);
  let hasChildTabs, activeChildTabKey;
  if (activeTab.forceChildPath === undefined) {
    hasChildTabs = activeTab.tabs && activeTab.tabs.length > 0;
    activeChildTabKey = childtab ?? (hasChildTabs 
          ? activeTab.tabs.find(t => t.default).key
          : undefined);
  }
  const activeChildTab = activeChildTabKey ? activeTab.tabs.find(t => t.key === activeChildTabKey) : undefined;
  const ActiveComponent = activeChildTab ? activeChildTab.component : activeTab.component;
  const contentColClassName = activeChildTabKey ? styles.contentcol : styles.contentcolsingle;
  const [ activeKey, setActiveKey] = useState({parent: activeTab.key, child: activeChildTab?.key});
  const [ blockNavigation, setBlockNavigation ] = useState(false);

  const handleSelect = (nextParent, nextChild) => {
    if (blockNavigation && !window.confirm('Navigating away from this tab will result in any unsaved changes being lost!')) return;

    setBlockNavigation(false);
    const pathParts = path.split('/');
    const newPath = [];
    const end = pathParts.findIndex(p => p.startsWith(':childtab')) + 1;

    for (let i = 0; i < end; i++) {
      const p = pathParts[i];
      if (!p.startsWith(':')) {
        newPath.push(p);
      } else {
        const paramName = p.replace(/[\W]/g, '');
        switch (paramName) {
          case 'tab':
            newPath.push(nextParent);
            break;
          case 'childtab':
            if (nextChild) newPath.push(nextChild);
            break;
          default:
            newPath.push(params[paramName]);
        }
      }
    }

    setActiveKey({parent: nextParent, child: nextChild});
    history.push(newPath.join('/'));
  };

  const handleSave = (...args) => {
    setBlockNavigation(false);
    if (onSaved) onSaved(...args);
  };

  useEffect(() => {
    if (tab && (activeKey.parent !== tab || activeKey.child !== childtab)) {
      setActiveKey({parent: tab, child: childtab});
    }
  }, [tab, childtab, activeKey]);

  return (
    <Container className={`${styles.container} h-100`} fluid>
      <Row>
        <Col lg={1} sm={1} className={`${styles.parentnavcontainer} ${styles.nopadding}`}>
          <ParentTabNavs tabs={tabs} activeKey={activeKey.parent} onSelect={handleSelect} />
        </Col>
        { hasChildTabs && 
          <Col lg={2} className={styles.childnavcontainer}>
            <ChildTabNavs parentTab={activeTab} activeKey={activeKey.child} onSelect={handleSelect} />
          </Col>
        }
        <Col className={contentColClassName}>
          <ActiveComponent 
            setBlockNavigation={setBlockNavigation} 
            blockNavigation={blockNavigation}
            onSaved={handleSave}
            {...props}
          />
        </Col>
      </Row>
    </Container>
  );
};

const ParentTabNavs = ({ tabs, activeKey, onSelect }) => {
  return (
      <Nav>
        {tabs.map((tab, index) => (
          <Row key={`row_${index}`}>
            <Col className={styles.nopadding}>
              <ParentTabNav tab={tab} activeKey={activeKey} onSelect={onSelect} index={index} />
            </Col>
          </Row>
        ))}
        <Row className="vh-100">
          <Col className={styles.parentnavfiller}>&nbsp;</Col>
        </Row>
        <ReactToolTip />
      </Nav>
  );
};

const ParentTabNav = ({ tab, activeKey, onSelect, index }) => {
  const Icon = tab.icon;;
  const isActive = tab.key === activeKey;
  let className = `${styles.parentnav} ${isActive ? styles.parentnavactive  : styles.parentnavinactive}`;
  if (index === 0) className += ` ${styles.parentnavfirst}`;
  if (isActive && !(tab.tabs)) className += ` ${styles.parentnavnochildrenactive}`;
  const iconClass = isActive 
    ? styles.naviconactive 
    : tab.disabled 
        ? styles.navicondisabled
        : styles.navicon;

  const handleSelect = isActive ? undefined : () => onSelect(tab.key, tab.forceChildPath);
  const iconSize = window.innerWidth < 600 ? 20 : 25;

  return (
    <Nav.Item className={className}>
      <Nav.Link onClick={handleSelect} data-tip={tab.title} disabled={tab.disabled}>
        <Icon className={iconClass} size={iconSize} />
        {/* <Icon className={iconClass} size={25} /> */}
      </Nav.Link>
    </Nav.Item>
  );
 };

const ChildTabNavs = ({ parentTab, activeKey, onSelect }) => {
  activeKey = activeKey ?? parentTab.tabs.find(t => t.default).key;
  return (
    <>
    <div className={styles.childnavheader}>
      {parentTab.title}
    </div>
    <Nav className="flex-column">
      {parentTab.tabs.map((childTab, index) => (
        <Row key={`row_${index}`} xl={1}>
          <Col className={styles.childnavcol}>
            <ChildTabNav tab={childTab} activeKey={activeKey} onSelect={onSelect} parent={parentTab.key} />
          </Col>
        </Row>
      ))}
    </Nav>
    </>
  );
};

const ChildTabNav = ({ tab, parent, activeKey, onSelect }) => {
  const isActive = tab.key === activeKey;
  const handleSelect = isActive ? undefined : () => onSelect(parent, tab.key);
  let className = `${styles.childnav} `;
  className += isActive 
    ? styles.childnavactive 
    : styles.childnavinactive;

  return (
    <Nav.Item>
      <Nav.Link onClick={handleSelect} className={className} disabled={tab.disabled}>
        {tab.title}
        { isActive &&
          <span style={{float: 'right', fontSize: '12px'}}>&#10148;</span>
        }
      </Nav.Link>
    </Nav.Item>
  );
};
