import { useFormContext, useFieldArray } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SectionHeader, Section } from 'shared/components/form';
import { EditVisits } from 'benefits/shared/editVisits';

/* 
  config:
    {
      activeNetworks: [...id],
      visits: {
        wellness: { unlimted: false, count: 0 },
        sickness: { unlimted: false, count: 0 }
      }
    }
*/

export const DPCConfig = ({registerPrefix, index}) => {
  const registerField = `${registerPrefix}.benefits.${index}.configuration`;
  const { getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({name: `${registerField}.activeNetworks`});
  const networks = getValues(`${registerPrefix}.networks`) ?? [];
  const [activeNetworks, setActiveNetworks] = useState([]);

  const onNetworkSelect = (network) => {
    let config = getValues(registerField) ?? { activeNetworks: [] };
    let index = config.activeNetworks.indexOf(network);
    if (index < 0) {
      append(network);
    } else {
      remove(index);
    }
  };

  useEffect(() => {
    let currentActiveNetworks = getValues(`${registerField}.activeNetworks`);
    if (!currentActiveNetworks) {
      currentActiveNetworks = [];
      setValue(registerField, currentActiveNetworks);
    }
    setActiveNetworks([...currentActiveNetworks]);
  }, [fields, getValues, registerField, setValue]);

  return (
    <>
      <SectionHeader>
        <h5>Active Networks</h5>
      </SectionHeader>
      <Section>
      {networks.map((network, networkIndex) => (
        <Row key={networkIndex}>
          <Form.Check type="checkbox" name={network.name} >
            <Form.Check.Input checked={activeNetworks.includes(network.id)} onChange={() => onNetworkSelect(network.id)} type="checkbox" />
            <Form.Check.Label style={{marginLeft: '10px'}}>
              {network.name}
            </Form.Check.Label>
          </Form.Check>
        </Row>
      ))}
      </Section>
      <hr />
      <SectionHeader>
        <h5>Visits</h5>
      </SectionHeader>
      <Section>
        <Col className="my-auto" sm={3}>
          <h6>Wellness</h6>
        </Col>
        <EditVisits registerPrefix={`${registerField}.visits.wellness`} />
      </Section>
      <Section>
        <Col className="my-auto" sm={3}>
          <h6>Sickness</h6>
        </Col>
        <EditVisits registerPrefix={`${registerField}.visits.sickness`} />
      </Section>
    </>
  );
};