import { TeaserCard } from "enrollment/components/teaserCard";
import { RxIcon } from "./icon";

export const RxTeaser = () => {
  return (
    <TeaserCard
        title="Prescription Search"
        icon={<RxIcon />}
    >
      Search the best prices available of prescription drugs
    </TeaserCard>
  );
};