import { toastSuccess } from 'shared/components/toasts';
import * as Yup from 'yup';
import { atLeast1AddressValidationSchema, atLeast1PhoneNumberValidationSchema } from 'shared/components/contact/validation';
import { getDefaultValidationSchema } from '../../validationSchema';
import { EditConsumer } from '../edit/editConsumer';

export const PersonalInformation = ({ setBlockNavigation, consumer, account, onSaved }) => {
  const validationSchema = getDefaultValidationSchema()
    .concat(
      Yup.object().shape({
        consumer: Yup.object().shape({
          contact: Yup.object().shape({
            addresses: atLeast1AddressValidationSchema,
            phoneNumbers: atLeast1PhoneNumberValidationSchema
          }),
          dateOfBirth: Yup.date().nullable()
                                 .transform((curr, original) => original === '' ? null : curr)
                                 .required('Date of Birth is required')
        })
      })
    );

  const options = {
    contact: {
      addresses: { show: 1 },
    },
    button: {
      text: 'Save'
    }
  };

  const handleSaved = () => {
    toastSuccess('Profile Saved');
    if (onSaved) {
      onSaved();
    }
  };

  if (consumer) {
    return (
      <EditConsumer
        consumer={consumer}
        account={account}
        validationSchema={validationSchema}
        onSaved={handleSaved}
        options={options}
        setBlockNavigation={setBlockNavigation}
      />
    );
  }
};