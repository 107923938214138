import { toast } from 'react-toastify';
import { FaExclamationCircle } from 'react-icons/fa';
import styles from './toast.module.css';

export const toastError = (msg) => {
  toast(<ErrorToast msg={msg} />);
};

const ErrorToast = ({msg}) => {

  return (
    <>
      <FaExclamationCircle className={styles.error} />
      {" "}
      {msg}
    </>
  );
};