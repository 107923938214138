import { Col, Popover, OverlayTrigger } from 'react-bootstrap';
import { useState } from 'react';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import { Calendar } from 'react-date-range';
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { DropdownList } from 'react-widgets/cjs';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useRef } from 'react';

export const AppointmentDatePicker = ({onAppointmentTimeChange}) => {
  const nowString = 'Consultation within 2hrs';
  const [selectedDate, setSelectedDate] = useState(nowString);
  const [open, setOpen] = useState(false);
  const componentRef = useRef(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    if (selectedDate === nowString) {
      onAppointmentTimeChange(false, 'now');
    } else {
      onAppointmentTimeChange(true, DateTime.fromISO(selectedDate).toFormat('MM/dd/yyyy'));
    }
  }, [selectedDate, nowString, onAppointmentTimeChange]); 
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((componentRef.current && !componentRef.current.contains(event.target)) &&
        (popoverRef.current && !popoverRef.current.contains(event.target))) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [componentRef, setOpen]);

  return (
    <Col ref={componentRef}>
      <OverlayTrigger 
        container={this}
        trigger='click'
        rootClose={true}
        placement='bottom'
        show={open}
        overlay={
          <Popover className={styles.calendarPopover}>
            <Popover.Body style={{padding: 0}} ref={popoverRef}>
              <Calendar
                minDate={new Date()}
                onChange={(e) => { console.log(typeof(e)); setSelectedDate(e.toISOString()); setOpen(false); }}
                date={ selectedDate === nowString ? null : new Date(selectedDate) }
              />
            </Popover.Body>
          </Popover>
        }
      >
        <div className={styles.preferredDateDropdown}>
          <FloatingLabelControl label='Preferred date' for='date-range-dropdown'>
            <DropdownList
              containerClassName={styles.preferredDateContainer}
              aria-label='Preferred Date'
              id='date-range-dropdown'
              onClick={() => setOpen(true)}
              open={open}
              value={selectedDate}
              renderValue={(value) => value.item === nowString ? value.item : DateTime.fromISO(value.item).toFormat('MM/dd/yyyy') }
              onChange={(e) => {setSelectedDate(e); setOpen(false); }}
              data={[nowString, '']}
            />
          </FloatingLabelControl>
        </div>
      </OverlayTrigger>
    </Col>
  )
};