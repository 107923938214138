import { Col, Container, Row } from 'react-bootstrap';
import { FaClipboardList, FaRegUserCircle, FaUsersCog } from 'react-icons/fa';
import { permissions } from 'auth/permissions';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { toastSuccess } from 'shared/components/toasts';
import { PersonalInformation } from '../../components/profile/personalInformation'
import { EditEnrollment } from 'enrollment/components/editEnrollment';
import { EnrollmentInfo } from './enrollmentInfo';
import { CareCenters501 } from './careCenters501';
import { TabLayout } from 'shared/components/tablayout/tablayout';
import { AppointmentList } from '../clinical/appointments';
import { DoctorsOrdersList } from '../clinical/doctorsOrders';
import { CaseList } from 'case/components/caseList';
import styles from './profile.module.css';
import { AddCase } from 'case/components/addCase';

export const EditProfile = ({ 
    consumer, 
    enrollment, 
    account, 
    isAdminView
  }) => {
  const currentAccount = useCurrentAccount();

  const enrollmentOpts = {
    text: 'Save', 
    alignmentClass: 'justify-content-left'
  };

  const tabs = [
    {
      key: 'personal',
      tile: 'Personal Info',
      default: true,
      icon: FaRegUserCircle,
      component: ({setBlockNavigation, onSaved, consumer, account}) => (
        <>
          <h3 className={styles.title}>Personal Info</h3>
          <PersonalInformation 
            setBlockNavigation={setBlockNavigation}
            consumer={consumer}
            account={account}
            onSaved={onSaved}
          />
        </>)
    },
    {
      key: 'enrollment',
      title: 'Enrollment Info',
      icon: FaClipboardList,
      component: ({setBlockNavigation, onSaved, enrollment}) => (
        <Container fluid="lg">
          <h3 className={styles.title}>Enrollment Info</h3>
          <Row>
            <Col xs={12} sm={7}>
              <EditEnrollment 
                setBlockNavigation={setBlockNavigation}
                enrollment={enrollment}
                onSaved={() => onSaved('Enrollment saved!')}
                buttonOpts={enrollmentOpts}
              />
            </Col>
            <Col xs={12} sm={5}>
              <EnrollmentInfo enrollment={enrollment} />
            </Col>
          </Row>
        </Container>)
    }
  ];

  if (isAdminView && currentAccount.hasPermission(permissions.consumer.tenant)) {
    const adminTab = {
      key: 'admin',
      title: 'Admin',
      icon: FaUsersCog,
      tabs: []
    };
    
    if (enrollment.plan.benefits.some(b => b.benefit === 'indemnity')) {
      adminTab.tabs.push({
          key: '501',
          title: '501 Care Centers',
          default: true,
          component: ({consumer, enrollment}) => (
            <>
              <h3 className={`${styles.title} justify-content-center`}>501 Care Centers</h3>
              <CareCenters501 consumer={consumer} enrollment={enrollment} />
            </>)
        },
        {
          key: 'appointments',
          title: 'Appointments',
          component: ({ consumer })  => (
            <>
              <h3 className={`${styles.title} justify-content-center`}>Appointments</h3>
              <br /><br />
              <AppointmentList consumer={consumer} />
            </>
          )
        },
        {
          key: 'doctorsOrders',
          title: 'Doctors Orders',
          component: ({ consumer })  => (
            <>
              <h3 className={`${styles.title} justify-content-center`}>Doctors Orders</h3>
              <DoctorsOrdersList consumer={consumer} />
            </>
          )
        }
      );       
    } 
    
    if (currentAccount.hasPermission(permissions.concierge.access)) {
      adminTab.tabs.push({
        key: 'cases',
        title: 'Cases',
        default: false,
        component: ({ consumer }) => (
          <>
            <Row className='justify-content-center'>
              <Col style={{display: 'contents'}}>
                <h3 className={`${styles.title} justify-content-center`}>Cases</h3>
                <AddCase consumer={consumer}/>
              </Col>
            </Row>
            <CaseList baseFilter={{consumer: {id: {eq: consumer.id}}}} />
          </>
        )
      });
    }

    tabs.push(adminTab);
  }

  const onSaved = async(message) => {
    if (message) toastSuccess(message);
  };

  return (
    <div>
      {!isAdminView && 
      <Container className={styles.header} fluid>
        <Row className="justify-content-center">
          <Col className="text-center">
            <h1 className="inverse">My Profile</h1>
          </Col>
        </Row>
      </Container>
      }
    <div className={styles.container}>
      <TabLayout 
        tabs={tabs} 
        onSaved={onSaved} 
        consumer={consumer}
        enrollment={enrollment}
        account={account}
      />
    </div>
    </div>
  );
};