import {MESSAGE_RECIPIENT_FIELDS, MESSAGE_FIELDS} from '../fragments';
import { gql } from '@apollo/client';

export const GET_RECIPIENT_MESSAGE = gql`
  ${MESSAGE_RECIPIENT_FIELDS}
  ${MESSAGE_FIELDS}

  query GetRecipientMessaget(
    $messageId: ID,
    $recipientId: String,
    $recipientType: String
  ) {
    findMessageRecipients(filter: {
      message: { id: { eq: $messageId } },
      recipient: { 
        id: { eq: $recipientId },
        type: { eq: $recipientType }
      }
    }) {
      items {
        ...MessageRecipientFields
        message {
          ...MessageFields
        }
      }
    }
  }
`;