import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DateTime } from 'luxon';
import { createMutableCopy } from "apollo/utils";
import { BoxLoading } from "shared/components/spinners/boxLoading";
import { CREATE_MESSAGE, CREATE_MESSAGE_RECIPIENT } from "message/operations";
import { MessageComposer } from "./messageComposer";
import { toastSuccess } from "shared/components/toasts";

export const Reply = ({correspondent, message, onComplete}) => {
  const tenant = createMutableCopy(message.tenant);
  const originalSender = createMutableCopy(message.sender);
  const recipient = {
    recipient: originalSender,
    tenant: tenant,
    status: 'UNREAD'
  };
  let [sentAt, setSentAt] = useState();
  let [isSending, toggleSending] = useState(false);

  const [createRecipient] = useMutation(CREATE_MESSAGE_RECIPIENT, {
    onCompleted: () => {
      toastSuccess('Message Sent!');
      onComplete();
    }
  });
  const [createMessage] = useMutation(CREATE_MESSAGE, {
    onCompleted: (data) => {

      recipient.message = { id: data.createMessage.id };
      recipient.sentAt = sentAt;
      createRecipient({ variables: { input: recipient }});
    }
  });

  const handleSend = (replyContent) => {
    toggleSending(true);
    const sentAt = DateTime.now().toMillis();
    setSentAt(sentAt);
    const parentId = message.parent?.id ?? message.id;
    const newMessage = {
      tenant: tenant,
      subject: message.subject.startsWith('Re: ') 
        ? message.subject
        : `Re: ${message.subject}`,
      text: replyContent,
      sender: correspondent,
      sentAt: sentAt,
      replyTo: { id: message.id },
      sentTo: [originalSender],
      parent: { id: parentId },
    };

    if (message.case) {
      newMessage.case = {id: message.case.id};
    }

    createMessage({ variables: { input: newMessage }});
  };


  return (
    <>
    { isSending
      ?  <BoxLoading height={200} width={1000} viewBox="0,0,1000,220" />
      : <MessageComposer
          onCancel={onComplete}
          onSend={(content) => handleSend(content)}
        />
    }
    </>
  );
}; 