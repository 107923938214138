import { gql } from '@apollo/client';

export const GET_DRUG_PRICES = gql`
query query($filter: RxPriceFilter) {
  rx {
    getPrices(filter: $filter) {
      display
      format
      dosage
      quantity
      type
      equivalents {
        type
        equivalents
      }
      prices {
        couponKey
        pharmacy
        type
        price
        retailPrice
        savings
      }
    }
  }
}
`;
