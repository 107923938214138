import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, DropDown, Section, SectionHeader } from '../form';

export const EditName = ({ registerPrefix, errors }) => {
  const { control } = useFormContext();
  const registerField = registerPrefix ? `${registerPrefix}.name` : 'name';
  const prefixes = ['Ms', 'Miss', 'Mrs', 'Mr'];
  const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];

  return (
      <>
      <SectionHeader>
          <h4>Name</h4>
      </SectionHeader>
      <Section>
        <Col md={1}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <DropDown inputRef={ref} label="Prefix" items={prefixes} registerInput={`${registerField}.prefix`} />
            )}
          />
        </Col>
        <Col md={3}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <Input inputRef={ref} label='First' registerInput={`${registerField}.first`} errors={errors} />
            )}
          />
        </Col>
        <Col md={2}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <Input inputRef={ref} label='Middle' registerInput={`${registerField}.middle`} />
            )}
          />
        </Col>
        <Col md={3}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <Input inputRef={ref} label='Last' registerInput={`${registerField}.last`} errors={errors} />
            )}
          />
        </Col>
        <Col md={1}>
          <Controller
            name="controller"
            control={control}
            render={({ ref }) => (
              <DropDown inputRef={ref} label="Suffix" items={suffixes} registerInput={`${registerField}.suffix`} />
            )}
          />
        </Col>
      </Section>
    </>
  );
};