import { useLazyQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { useRouteMatch, useHistory, useParams } from 'react-router';
import { GET_CONCIERGE_CONSUMERS } from "../operations/getConciergeConsumers";
import { UserList } from '../../shared/components/userList';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { sortContactsByLastThenFirst } from "shared/services/comparisonService";
import { Search, SectionHeader } from 'shared/components/form';
import { Col, Row } from 'react-bootstrap';
import { conciergePaths } from "concierge/routes";
import { telemetryService } from "telemetry/telemetryService";

export const ConciergeConsumers = () => {
  const account = useCurrentAccount();
  const [searchConsumers, {loading, data, error}] = useLazyQuery(GET_CONCIERGE_CONSUMERS);
  const [searchFromUrl, toggleFromUrl] = useState(true);
  const { path }  = useRouteMatch();
  const { search } = useParams();
  const history = useHistory();

  const getSearchUrl = useCallback((searchValue) => {
    if (path.endsWith(':search')) {
      return path.replace(':search', searchValue);
    } else if (path.endsWith('consumers')) {
      return `${path}/search/${searchValue}`;
    } else {
      const parts = path.split('/');
      parts.pop();
      return `${parts.join('/')}/${searchValue}`;
    }
  }, [path]);

  // set permission portion of filter
  let permissionFilter = {
      account: { id: { ne: account.id } }, // cannot administer self #75655
      tenant: { id: { eq: account.tenant.id } }
  };
  
  //run search
  const onSearch = (searchValue) => {
    if (!searchValue) return;

    const searchFilter = {
      ...permissionFilter,
      or: [ 
        {searchTerms: {firstName: {eq: searchValue.toLowerCase()}}},
        {searchTerms: {lastName: {eq: searchValue.toLowerCase()}}},
      ]
    };
    searchConsumers({variables: {filter: searchFilter}});
  };

  // new searches
  const onNewSearch = (searchValue) => {
    toggleFromUrl(false);
    history.replace(getSearchUrl(searchValue));
    onSearch(searchValue);
  };

  // searches from URL
  if (search && searchFromUrl) { 
    toggleFromUrl(false)
    onSearch(search);
  }

  if (error){
    telemetryService.trackException(error);
  }

  return (
    <>
      <SectionHeader>
        <h2>Concierge Consumers</h2>
      </SectionHeader>
      <Row>
        <Col lg={11}>
          <Search placeHolder='Search for Consumer' onSearch={onNewSearch} minimumSearchLength={0} />
        </Col>
      </Row>
      {loading
        ? <p>Loading...</p>
        : data && data.findConsumers.items.length !== 0 
          ? <UserList 
              data={[...data.findConsumers.items].sort(sortContactsByLastThenFirst)} 
              detailLink={conciergePaths.conciergeConsumers} 
              idFunc={item => item.id}
            />
          : <div>No results found</div>
      }
    </>
  );
};