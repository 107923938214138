import { PublicClientApplication, InteractionType, InteractionStatus } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useMsal, useAccount } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { msalConfig, loginRequest } from './config';
import { BICCToken, setToken } from './biccToken';
import { ConnectedApp } from 'apollo/connectedApp';
import { appSettings } from '../appSettings';

const ProtectedContent  = () => {
  const { instance, accounts, inProgress} = useMsal();
  const account = useAccount(accounts[0] || {});
  const [atsResponse, setAtsResponse] = useState(null);
  const handleError = useErrorHandler();

  useEffect(() => {

    const getB2CAccessTokenResponse = async() => {
        return await instance.acquireTokenSilent({
          ...loginRequest,
          account: account
        });
    };

    const getBICCAccessToken = async(b2cAccessToken) => {
        const getAccountUrl = `${appSettings.REACT_APP_BICC_API_URL}/auth/getAccount`;

        const tokenResponse = await fetch(getAccountUrl, {
          headers: {
            "Authorization": `Bearer ${b2cAccessToken}`
          }
        });

        return await tokenResponse.text();
    };

    const getAccessToken = async() => {

      if (!atsResponse && account && inProgress === InteractionStatus.None) {
        
        const b2cResponse = await getB2CAccessTokenResponse();
        const biccAccessToken = await getBICCAccessToken(b2cResponse.accessToken);

        setToken(biccAccessToken);
        setAtsResponse(b2cResponse);
      }
    };

    getAccessToken().catch(handleError);
  }, [account, inProgress, atsResponse, instance, handleError]);

  return (
    <>{ atsResponse ? 
      <ConnectedApp />
      : null
    }</>
  );
};

const isUsingTestAccount = () => {
  let useTestAccount = false;
  if (process.env.NODE_ENV === 'development' && appSettings.REACT_APP_USE_TEST_ACCOUNT) {
    BICCToken.bearer = 'testing';
    BICCToken.object = { id: appSettings.REACT_APP_USE_TEST_ACCOUNT };
    useTestAccount = true;
  }

  return useTestAccount;
};

export const ProtectedApp = () => {
  const msal = new PublicClientApplication(msalConfig);

  return (
    <>{
    isUsingTestAccount() ?
      <ConnectedApp />
    : 
      <MsalProvider instance={msal}>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}>

          <ProtectedContent />

        </MsalAuthenticationTemplate>
      </MsalProvider>
    }</>
  );
};