import { Section, SectionHeader } from 'shared/components/form';
import { Container, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { GET_ATHENA_APPOINTMENT_TYPES } from 'network/operations/queries';
import { useQuery } from '@apollo/client';
import { EditIntegrationVisitType } from 'network/components/edit/editIntegrationVisitTypes';

export const EditAthenaIntegration = ({integration}) => {
  const registerPrefix = 'integration.configuration.visitTypes';
  const { setValue } = useFormContext();
  if (!integration.configuration) {
    integration.configuration = {
      visitTypes: {
        wellness: [],
        sickness: [],
        telehealth: []
      }
    };
    setValue(`integration.configuration`, integration.configuration);
  }
  let alreadyUsedAppointmentTypes = [
    ...integration?.configuration?.visitTypes?.wellness ?? [],
    ...integration?.configuration?.visitTypes?.sickness ?? [],
    ...integration?.configuration?.visitTypes?.telehealth ?? []
  ];
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [usedAppointmentTypes, setUsedAppointmentTypes] = useState(alreadyUsedAppointmentTypes);


  const { loading } = useQuery(GET_ATHENA_APPOINTMENT_TYPES, { 
    skip: !integration.id,
    variables: { 
      practiceId: integration.id
    }, 
    onCompleted: (data) => {
      setAppointmentTypes(data.athena.getAppointmentTypes);
    }
  });

  let availableAppointmentTypes = appointmentTypes.filter(x => usedAppointmentTypes.filter(y => y.id === x.appointmentTypeId).length === 0);

  const onAppointmentTypesChange = (appointmentTypes, wasAdded) => {
    if (wasAdded) {
      setUsedAppointmentTypes([...usedAppointmentTypes, ...appointmentTypes]);
    } else {
      setUsedAppointmentTypes([...usedAppointmentTypes].filter(x => appointmentTypes.filter(y => y.id === x.id).length === 0));
    }
  }
  
  return (
    loading 
      ? <h2>Loading...</h2>
      : 
          <Container fluid>
              <Section>
                <SectionHeader>
                  <h3>Appointment Types</h3>
                  <hr />
                </SectionHeader>
                <Col>
                  <EditIntegrationVisitType title='Wellness' 
                                           registerInput={`${registerPrefix}.wellness`}
                                           availableAppointmentTypes={availableAppointmentTypes}
                                           onAppointmentTypesChanged={onAppointmentTypesChange}
                  />
                </Col>
                <Col>
                  <EditIntegrationVisitType title='Sickness' 
                                           registerInput={`${registerPrefix}.sickness`}
                                           availableAppointmentTypes={availableAppointmentTypes}
                                           onAppointmentTypesChanged={onAppointmentTypesChange}
                  />
                </Col>
                <Col>
                  <EditIntegrationVisitType title='Telehealth' 
                                           registerInput={`${registerPrefix}.telehealth`}
                                           availableAppointmentTypes={availableAppointmentTypes}
                                           onAppointmentTypesChanged={onAppointmentTypesChange}
                  />
                </Col>
              </Section>
          </Container>
  );
};