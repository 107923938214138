import { onError } from '@apollo/client/link/error';
import { getExtendedProperties } from './apolloError';
import { telemetryService } from 'telemetry/telemetryService';

export const errorLink = onError(({ operation, graphQLErrors, networkError }) => {

  if (graphQLErrors) {
    graphQLErrors.forEach(err => logError(err, operation));
  }

  if (networkError && networkError?.result?.errors) {
    networkError.result.errors.forEach(err => logError(err, operation));
  }
});

const logError = (err, operation) => {
  const props = getExtendedProperties(err, operation);
  console.error(`GraphQL Error: ${err.message}`, err, props);
  telemetryService.trackException(err, props);
};