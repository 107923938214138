import { Nav } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './components.module.css';

export const EnrollmentNavigation = ({navKey, activeKey, setActiveKey, text}) => {

  const fontClassName = navKey === activeKey 
    ? `${styles.breadcrumb} ${styles.breadcrumbActive}`
    : styles.breadcrumb
  const iconClassName = navKey < activeKey
    ? styles.breadcrumbComplete
    : styles.breadcrumbIcon
  const linkClassName = navKey < activeKey 
    ? styles.breadcrumbNav
    : null

  return (
      <Nav.Item>
        <Nav.Link
           eventKey={navKey}
           disabled={activeKey <= navKey}
           onClick={() => setActiveKey(navKey)}
           className={linkClassName}
        >
          <FaCheckCircle className={iconClassName} size={13} />
          {" "}
          <span className={fontClassName}>{text}</span>
        </Nav.Link>
      </Nav.Item>
  );
};