import { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { getError } from 'shared/services/validationService';
import styles from './policy.module.css';

export const DisablingCheckBox = ({ field, errors, children }) => {
  const { control, getValues, setValue } = useFormContext();
  const [isAccepted, setAccepted] = useState(getValues(field) ?? false);

  const onChange = () => {
    setAccepted(true);
    setValue(field, true);
  };

  const getFieldError = () => getError(field, errors);

  return (
    <Controller
      name="disablingCheckBox"
      control={control}
      render={({ref}) => (
        <Form.Group as={Row} className="mb-3">
          <Form.Check type='checkbox'>
            <Form.Check.Input
              id={field}
              column={1}
              lg={1} md={1} sm={1} xs={1}
              type='checkbox'
              disabled={isAccepted}
              checked={isAccepted}
              isInvalid={getFieldError()}
              onChange={() => onChange()}
            />
            <Form.Label
              column={1}
              lg={11} md={11} sm={11} xs={11}
              className={styles.policyAcceptance}>
              {children}
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              {getFieldError()?.message}
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
      )} />
  );
};