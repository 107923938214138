import { FindHospital } from './pages/findHospital';
import { RenderRoutes } from '../../shared/routing/renderRoutes';

const pathRoot = '/benefits/indemnity';

export const indemnityPaths = {
  findHospital : `${pathRoot}/findHospital`,
};

export const indemnityRoutes = [
  {
    path: pathRoot,
    key: 'indemnity',
    component: RenderRoutes,
    routes: [
      {
        path: indemnityPaths.findHospital,
        key: 'indemnity_find_hospital',
        component: FindHospital
      }
    ]
  }
]