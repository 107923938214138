import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FloatingLabelControl } from './floatingLabelControl';
import { getError } from "shared/services/validationService";

export const DropDown = ({
  id, 
  items, 
  label, 
  registerInput, 
  size, 
  onSelected,
  errors,
  disabled=false
}) => {
  const { register } = useFormContext();

  const getInputError = () => {
    return registerInput
      ?  getError(registerInput, errors)
      : undefined;
  };

  return (
    <FloatingLabelControl label={label}>
         <Form.Control
            className="form-select"
            as="select"
            type="select"
            size={size} 
            {...register(`${registerInput}`)} 
            onChange={onSelected}
            isInvalid={getInputError()}
            disabled={disabled}
          >
              <option value={null}></option>
              {items.map((item) => (
                <option key={item.value ?? item} value={item.value ?? item}>{item.text ?? item}</option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {getInputError()?.message}
          </Form.Control.Feedback>
    </FloatingLabelControl>
  )
};
