import { Col,  Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ItemList } from "./itemList";

export const UserList = ({ data, itemsPerPage, detailLink, idFunc}) => {
  return(
    <ItemList data={data} 
              detailLink={detailLink}
              headerRowElement={UserHeaderRow}
              dataRowElement={UserDataRow}
              itemsPerPage={itemsPerPage}
              idFunc = {idFunc}
    />
  )
};

const UserHeaderRow = () => {
  return (
    <Row style={{marginTop: '10px'}}>
      <Col style={{marginLeft: '10px'}}>Last</Col>
      <Col className='d-none d-sm-block d-md-block d-lg-block'>First</Col>
      <Col>Email</Col>
    </Row>
  );
}

const UserDataRow = ({item, index, detailLink, idFunc}) => {
  idFunc = idFunc || (item => item.account.id);
  const emails = item.contact.emails;
  const email = emails && emails.length > 0 ? emails[0].email : 'NONE';

  return (
    <Link to={`${detailLink}/${idFunc(item)}`} style={{color: "inherit", textDecoration: "none"}} key={index}>
      <Row>
        <Col aria-label={`user.${index}.last`} style={{marginLeft: '10px'}}>{item.contact.name.last}</Col>
        <Col aria-label={`user.${index}.first`} className='d-none d-sm-block d-md-block d-lg-block'>{item.contact.name.first}</Col>
        <Col aria-label={`user.${index}.email`}>{email}</Col>
      </Row>
      <hr />
    </Link>
  );
};