import { useState } from "react";
import { useParams } from "react-router";
import { BoxLoading } from 'shared/components/spinners';
import { appSettings } from 'appSettings';

export const ImageLoader = ({item}) => {
  const [imgAvailable, setImgAvailable] = useState(false);
  const { careCenterId } = useParams();
  const url = `${appSettings.MEDIA_URL}/careCenters/${careCenterId}/profile/${item.fileName}/xs.${item.extension}`;

  const checkImage = () => {
    fetch(url, { method: 'GET' }).then(res => {
      if (res.status !== 404) {
        setTimeout(setImgAvailable(true), 500);
        clearInterval(interval);
      }
    });
  };
  let interval = setInterval(checkImage, 5000);
  checkImage();

  return (
    <>
    { imgAvailable
      ? <img src={url} alt={item.title} />
      : <BoxLoading width={320} height={200} />
    }
    </>
  );
};