import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { GET_LYRIC_SSO } from 'benefits/telehealth/operations/queries/getLyricSSO';

export const LyricRedirect = () => {
  const { consumer } = useCurrentConsumer();
  const { data } = useQueryWrapper(GET_LYRIC_SSO, {
    variables: { consumerId: consumer?.id },
    skip: !consumer,
  });

  if (data) {
    window.location.href = data.lyric.getSSOLink;
  }

  return (
    <>Redirecting... </>
  );
};