import { gql } from '@apollo/client';
import { NETWORK_FIELDS } from '../fragments';

export const UPDATE_NETWORK = gql`
  ${NETWORK_FIELDS}

  mutation UpdateNetwork($input: MutateNetworkInput!) {
    updateNetwork(input: $input) {
      ...NetworkFields
    }
  }
`;