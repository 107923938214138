export const prepareConsumerForSave = (consumer) => {
  delete consumer.tenant;
  delete consumer.account;
  setupSearchTerms(consumer);
  if (consumer.sex === '') consumer.sex = undefined;
  return consumer;
};

const setupSearchTerms = (consumer) => {
  consumer.searchTerms = {
    lastName: consumer.contact.name.last.toLowerCase(),
    firstName: consumer.contact.name.first.toLowerCase()
  };
};
