import { gql } from '@apollo/client';

export const NAME_FIELDS = gql`
  fragment NameFields on Name {
    prefix
    first
    middle
    last
    suffix
  }
`;

export const EMAIL_FIELDS = gql`
  fragment EmailFields on Email {
    email
    sendNotifications
  }
`;

export const CONTACT_FIELDS = gql`
  ${NAME_FIELDS}
  ${EMAIL_FIELDS}
  fragment ContactFields on Contact {
    name {
      ...NameFields
    }
    emails {
      ...EmailFields
    }
  }
`;

export const PHONE_FIELDS = gql`
  fragment PhoneFields on Phone {
    number
    extension
    type
    country {
      prefix
      code
    }
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
        type
        line1
        line2
        city
        state
        postalCode
        countryCode
  }
`;

export const ADDRESS_FIELDS_WITH_GEO = gql`
  ${ADDRESS_FIELDS}
  fragment AddressWithGeoFields on Address {
    ...AddressFields
    geometry {
      type
      coordinates
    }
  }
`;