import { useMutation } from "@apollo/client";
import { useEffect, useReducer } from "react";
import { useErrorHandler } from 'react-error-boundary';

const reducer = (state, action) => {
  switch(action.type) {
    case 'loading':
      return [state[0], { ...state[1], loading: true }]
    case 'data':
      return [state[0], { ...state[1], loading: false, data: action.payload }]
    default: 
      throw new Error('invalid action');
  }
};

export const useMutationWrapper = (mutation, opts) => {
  const [mutate, { loading, error, data }] = useMutation(mutation, opts);
  const [state, dispatch] = useReducer(reducer, [], () => {
    return [
      mutate,
      {
        loading: loading,
        data: data,
      }
    ];
  });
  useErrorHandler(error);

  useEffect(() => {
    if (loading === true) dispatch({ type: 'loading' });
  }, [loading]);

  useEffect(() => {
    if (data) dispatch({ type: 'data', payload: data });
  }, [data]);

  return state;
};