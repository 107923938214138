import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormLoading } from 'shared/components/spinners/formLoading';
import { getDefaultValidationSchema } from 'consumer/validationSchema';
import { atLeast1AddressValidationSchema, atLeast1PhoneNumberValidationSchema } from 'shared/components/contact/validation';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { useCurrentConsumer  } from 'consumer/hooks/useCurrentConsumer';
import { EditConsumer } from 'consumer/components/edit/editConsumer';
import { RegistrationHeader } from '../components/registration/registrationHeader';
import { AdditionalRegistrationSections } from '../components/registration/additionalRegistrationSections';

export const Registration = () => {
  const history = useHistory();
  const account = useCurrentAccount();
  const {consumer, loading} = useCurrentConsumer();
  const options = {
    contact: {
      emails: { hide: true },
      addresses: { show: 1 },
      phoneNumbers: { show: 1 }
    },
    button: {
      text: 'Continue',
      variant: 'action'
    }
  };
  const validationSchema = getDefaultValidationSchema()
    .concat(
      Yup.object().shape({
        consumer: Yup.object().shape({
          contact: Yup.object().shape({
            phoneNumbers: atLeast1PhoneNumberValidationSchema,
            addresses: atLeast1AddressValidationSchema
          }),
          dateOfBirth: Yup.date('Date of Birth is required').required('Date of Birth is required').nullable(),
          privacyPolicyAccepted: Yup.bool().oneOf([true], 'Please accept the Privacy Policy').nullable(),
          tandcAccepted: Yup.bool().oneOf([true], 'Please accept the Terms and Conditions').nullable()
        })
      })
    );

  const onSaved = () => {
    history.push('/');
  };

  if (loading || !consumer) {
    return (<FormLoading />);
  }

  if (consumer) {
    return (
      <EditConsumer
        options={options}
        consumer={consumer}
        account={account}
        header={<RegistrationHeader />}
        AdditionalSections={AdditionalRegistrationSections}
        validationSchema={validationSchema}
        onSaved={onSaved}
      />
    );
  }
};