import { useQuery } from "@apollo/client";
import { Col, Row } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { conciergePaths } from "concierge/routes";
import { GET_CONSUMER } from 'consumer/operations/queries/getConsumer';
import { BoxLoading } from 'shared/components/spinners/boxLoading';
import styles from 'case/case.module.css';

export const Consumer = ({ caseData }) => {
  const { data } = useQuery(GET_CONSUMER, {
    variables: { id: caseData.consumer.id }
  });

  const consumer = data?.getConsumer;
  const name = consumer?.contact?.name;
  const fullName = `${name?.first} ${name?.last}`;

  return (
    <>
    { !consumer 
      ? <BoxLoading width={50} height={50} viewBox="0 0 50 50" />
      : <>
          <Row>
            <Col>
              <h4>{fullName}</h4>
            </Col>
            <hr />
          </Row>
          <Row>
            <Col className={styles.lightheading}>
              Pronoun:
            </Col>
            <Col>
               {consumer?.pronoun}
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col className="my-start">
              <Link 
                  to={`${conciergePaths.conciergeConsumers}/${consumer?.id}`}
                  className={styles.sidebarlink}
                  target="_blank"
                  rel="noopener noreferrer">
                <FaRegUserCircle />&nbsp;View Full Profile
              </Link>
            </Col>
          </Row>
        </>
    }
    </>
  );
};