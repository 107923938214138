import { gql } from '@apollo/client';

export const GET_ALL_AMENITIES=gql`
  query GetAllAmenities {
    findCareCenterAmenities(orderBy: {field: "name" }) {
      items {
        id
        name
        iconClass
      }
    }
  }
`;