import * as Yup from 'yup';
import { ConciergeApp } from 'benefits/concierge/app';
import { IndemnityApp } from "benefits/indemnity/app";
import { DPCApp } from 'benefits/dpc/app';
import { FplApp } from 'benefits/fpl/app';
import { MarketplaceApp } from 'benefits/marketplace/app';
import { RxApp } from 'benefits/rx/app';
import { TelehealthApp } from 'benefits/telehealth/app';

export const benefitService = (benefits) => {
  let apps;
  if (benefits) benefits.sort((a, b) => a?.sequence - b?.sequence);

  const getApps = () => {
    apps = {};
    apps[IndemnityApp.key] = IndemnityApp;
    apps[ConciergeApp.key] = ConciergeApp;
    apps[DPCApp.key] = DPCApp;
    apps[FplApp.key] = FplApp;
    apps[MarketplaceApp.key] = MarketplaceApp;
    apps[RxApp.key] = RxApp;
    apps[TelehealthApp.key] = TelehealthApp;
  };

  const getApp = (benefit) => {
    return apps[benefit.benefit];
  };

  const getTeaser = (benefit) => {
    return apps[benefit.benefit].teaser;
  };

  const getEnrollmentForms = () => {
    return mapBenefitComponents('enrollmentForm');
  };

  const getYouGetCards = () => {
    return mapBenefitComponents('youGetCard');
  };

  const getValidationSchema = () => {
    const validationSchemas = mapBenefitComponents('validationSchema');
    if (Object.keys(validationSchemas).length === 0) return undefined;

    let configurationSchema = Yup.object();
    Object.keys(validationSchemas).forEach(key => {
      configurationSchema = configurationSchema.when('benefit', validationSchemas[key]).nullable();
    });

    return Yup.array()
      .nullable()
      .default(null)
      .of(Yup.object().shape({
        benefit: Yup.string().nullable().default(null),
        configuration: configurationSchema
      }));
  };

  const mapBenefitComponents = (component) => {
    const map = {};
    const benefitKeys = getBenefitKeys();
    benefitKeys.forEach(key => {
      if (key in apps && component in apps[key]) {
        map[key] = apps[key][component];
      }
    });

    return map;
  };

  const getBenefitKeys = () => {
    return benefits.map(x => x.benefit);
  };

  getApps();

  return {
    apps,
    getApp,
    getTeaser,
    getEnrollmentForms,
    getValidationSchema,
    getYouGetCards
  };
};
