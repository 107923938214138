import { useParams } from "react-router-dom";
import { useLocation } from 'react-router';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { SectionHeader } from 'shared/components/form';
import { GET_ACCOUNT, GET_CONSUMERS_FOR_ACCOUNT_IDS } from 'admin/operations/queries';
import { prepareAccountForForm } from 'admin/services/formHelper';
import { EditAccountForm } from 'admin/components/edit/editAccountForm';

export const EditAdminUser = (props) => {
  const registerPrefix  = 'account';
  const { userId } = useParams();
  const isNew = userId === 'new';
  const { pathname } = useLocation();

  const { loading, data } = useQueryWrapper(GET_ACCOUNT, {
    skip: isNew,
    variables: { accountId: userId } 
  });

  if (!isNew && loading){
    return (<h1>Loading...</h1>);
  }

  return (
    <>
    { isNew 
      ? <>
          <h2>New Admin User</h2> 
          <EditAccountForm isNew={true} registerPrefix={registerPrefix} afterCreatePath={pathname}/>
        </>
      : <EditAdminUserHeader accountId={userId} />
    }

    { !isNew && data &&
      <EditAccountForm account={prepareAccountForForm(data.getAccount)} isNew={false} registerPrefix={registerPrefix}/>
    }
    </>
  )
}

const EditAdminUserHeader = ({accountId}) => {
  const {data} = useQueryWrapper(GET_CONSUMERS_FOR_ACCOUNT_IDS, {
    variables: { accountIds: [accountId] }
  });

  if (data) {
    const contact = data.findConsumers.items[0].contact;
    return (
      <SectionHeader>
        <h4>{`${contact.name.first} ${contact.name.last}`}</h4>
      </SectionHeader>
    );
  }

  return (<h1>Loading...</h1>);
};
