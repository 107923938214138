import * as Yup from 'yup';
import { nameValidationSchema } from 'shared/components/contact/validation/nameValidation';
import { atLeast1PhoneNumberValidationSchema } from 'shared/components/contact/validation/phoneValidation';

export const getDefaultValidationSchema = () => {
  return Yup.object().shape({
    consumer: Yup.object().shape({
      contact: Yup.object().shape({
        name: nameValidationSchema
      })
    })
  });
};

export const getDefaultValidationWithPhoneNumbersSchema = () => {
  return getDefaultValidationSchema().concat(
    Yup.object().shape({
      consumer: Yup.object().shape({
        contact: Yup.object().shape({
          phoneNumbers: atLeast1PhoneNumberValidationSchema
        })
      })
    })
  );
};