import { IndemnityTeaser } from './components/teaser';
import { YouGetIndemnity } from './components/youGet';
import { validationSchema } from './validationSchema';
import { IndemnityEnrollmentForm } from './components/enrollmentForm';

export const IndemnityApp = {
  key: 'indemnity',
  name: 'Hospital Finder',
  teaser: IndemnityTeaser,
  enrollmentForm: IndemnityEnrollmentForm,
  validationSchema: validationSchema,
  youGetCard: YouGetIndemnity
}