import { useState } from 'react';
import { useHistory }  from 'react-router-dom';
import { useCurrentAccount } from '../../../auth/operations/useCurrentAccount';
import { currentAppModeVar } from 'apollo/cache';
import { AdminMenu } from './adminMenu';
import { ConsumerMenu } from './consumerMenu';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export const Header = () => {

  const isAdmin = useCurrentAccount().isAdmin;
  const [mode, setMode] = useState(currentAppModeVar());
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [_, persistAppMode] = useLocalStorage('appMode');

  const changeMode = (newMode) => {
    currentAppModeVar(newMode); //set global state
    setMode(newMode); // set local state  -- TODO: this occasionally results in a React error (just in console...no discernable effect)
    persistAppMode(newMode); // add to local storage so it persists across refresh
    history.push('/');  // go to mode's home
  };

  if (mode === 'Admin') {
    return <AdminMenu changeMode={changeMode} />
  }

  return <ConsumerMenu canSwitch={isAdmin} changeMode={changeMode} />
};

