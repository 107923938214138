import { Col, Row } from 'react-bootstrap';
import styles from '../rx.module.css';
import poweredByGoodRx from 'benefits/rx/images/powered_by_goodrx_yellow_xs.png';
import biccLogo from 'shared/images/BICC_logo_thin.svg';
import { isEmpty } from 'lodash';
import { formatPrice } from './helpers';
import { BoxLoading } from 'shared/components/spinners/boxLoading';

export const Coupon = ({ drug, form, dosage, quantity, couponData }) => {
  return (
    <Row>
      <Col className={styles.couponContainer}>
        <Row className={styles.couponHeader}>
          <Col lg={5} xs={8}>
            <Row className={styles.couponDrug}>
              {drug}
            </Row>
            <Row className={styles.couponDrugDetails}>
              <Col style={{padding: 0}}>
                {quantity} <span>{form}s</span>
              </Col>
              <Col>
                {dosage}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles.couponBody}>
          {isEmpty(couponData)
            ? <div className={styles.loadingContainer}><BoxLoading width={330} height={330} /></div>
            : <>
                <div className={styles.couponBodyPrice}>
                  <span className={styles.dollarSign}>$</span>{formatPrice(couponData.price)}
                </div>
                <Row className={styles.couponBodyRow}>
                  <Col className={styles.couponBodyLabel}>BIN</Col>
                  <Col className={styles.couponBodyValue}>{couponData.rxbin}</Col>
                </Row>
                <Row className={styles.couponBodyRow}>
                  <Col className={styles.couponBodyLabel}>PCN</Col> 
                  <Col className={styles.couponBodyValue}>{couponData.rxpcn}</Col>
                </Row>
                <Row className={styles.couponBodyRow}>
                  <Col className={styles.couponBodyLabel}>Group</Col>
                  <Col className={styles.couponBodyValue}>{couponData.groupNumber}</Col>
                </Row>
                <Row className={styles.couponBodyRow}>
                  <Col className={styles.couponBodyLabel}>Member ID</Col>
                  <Col className={styles.couponBodyValue}>{couponData.memberId}</Col>
                </Row>
              </>
          }
        </Row>
        <Row className={styles.couponFooter}>
          <div>
            <img src={biccLogo} alt="bicc" className={styles.biccLogo}/>
            <a href="https://www.goodrx.com" target="_blank" rel="noreferrer">
              <img src={poweredByGoodRx} alt="poweredByGoodRx" className={styles.couponGoodRxLogo}/>
            </a>
          </div>
        </Row>
      </Col>
    </Row>
  );
}