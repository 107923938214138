import { useParams } from 'react-router-dom';
import { MediaDragAndDropUpload } from 'shared/components/dragAndDropUpload';

export const MediaUpload = ({ onFilesUploaded }) => {
  const { careCenterId } = useParams();
  const UPLOAD_ROOT=`careCenter/profile/images/${careCenterId}`;

  return (
    <MediaDragAndDropUpload 
      headerText="Add photographs for this Care Center"
      uploadRoot={UPLOAD_ROOT}
      onFilesUploaded={onFilesUploaded}
    />
  );
};