import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { Row, Col } from 'react-bootstrap';
import { AddButton, CancelButton, DeleteButton } from 'shared/components/buttons';
import { NetworkSelector } from "network/components/networkSelector";
import { useState } from 'react';
import styles from 'payers/payers.module.css';

export const EditPlanNetworks = ({registerPrefix}) => {
  const registerField = registerPrefix ? `${registerPrefix}.networks` : 'networks';
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: registerField });
  const [networkBeingAdded, setNetworkBeingAdded] = useState(false);
  
  const onNetworkSelected = (data) => {
    let chosenIndex = data.target.selectedIndex;
    let chosenOption = data.target.options[chosenIndex];
    if (chosenOption && chosenIndex) {
      append({id: chosenOption.value, name: chosenOption.text})
      setNetworkBeingAdded(false);
    }
  };

  return (
    <>
      <ul>
        {fields.map((network, index) => (
          <Controller
            control={control}
            key={network.id}
            name={network.id}
            render = {() => {
              return (
                <li key={network.id}>
                  <Row>
                    <Col xs={9}>{network.name}</Col>
                    <Col>
                      <DeleteButton tooltip='Remove' size={20} onClick={() => remove(index)}/>
                    </Col>
                  </Row>
                </li>
              );
            }}
          />
        ))}
        { networkBeingAdded && 
          <li>
            <Row>
              <Col xs={9}>
                <NetworkSelector ignoreIds={fields.map(x => x.id)} onSelected={onNetworkSelected}/>
              </Col>
              <Col className={styles.cancelButtonSpacer}>
                <CancelButton tooltip='Cancel' onClick={() => setNetworkBeingAdded(false)}/>
              </Col>
            </Row>
          </li>
        }
      </ul>
      <Col sm={1}>
      { !networkBeingAdded && <AddButton tooltip="Add a network" onClick={() => setNetworkBeingAdded(true)} />}
      </Col>
    </>
  );
}