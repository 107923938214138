import { useQueryWrapper } from "apollo/useQueryWrapper";
import { Accordion } from 'react-bootstrap';
import { GET_APPOINTMENT_REQUEST } from "benefits/dpc/operations/queries/getAppointmentRequest";
import { useState } from "react";
import { ViewAppointmentRequest } from "benefits/dpc/components/requestAppointment/viewAppointmentRequest";
import styles from 'case/case.module.css';

export const ViewAppointmentRequestWrapper = ({appointmentRequestId}) => { 
  const [appointmentRequest, setAppointmentRequest] = useState(null);
  useQueryWrapper(GET_APPOINTMENT_REQUEST, {
    skip: !appointmentRequestId,
    variables: { id: appointmentRequestId },
    onCompleted: (data) => setAppointmentRequest(data.getAppointmentRequest)
  });

  return (
    <>
    { appointmentRequest &&
      <Accordion defaultActiveKey="0" className={styles.contextViewAccordion}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className={styles.contextViewAccordionHeader}>
            <span className={styles.viewOrderHeader}>Appointment Request</span>
          </Accordion.Header>
          <Accordion.Body className={styles.viewAppoinmentRequestBody}>
            <ViewAppointmentRequest adminMode={true} appointmentRequest={appointmentRequest} careCenterResult={{careCenter: appointmentRequest.careCenter}}/>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }
    </>
  )
}