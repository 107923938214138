import { useFormContext, useFieldArray } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PlanBenefitDisplay } from "./planBenefitDisplay";
import { benefitService } from "benefits/services/benefitService";

export const EditPlanBenefits = ({registerPrefix}) => {
  const registerField = registerPrefix ? `${registerPrefix}.benefits` : 'benefits';
  const { control, getValues, setValue } = useFormContext();
  const { fields } = useFieldArray({ control, name: registerField });
  const apps = benefitService([fields]);

  const onDragEnd = result => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    let benefits = Array.from(getValues(registerField));
    let movedItem = benefits.splice(source.index, 1);
    benefits.splice(destination.index, 0, movedItem[0]);

    for (var x = 0; x < benefits.length; x++) {
      let benefit = benefits[x];
      benefit.sequence = x + 1;
    }

    setValue(registerField, benefits);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} >
      <Droppable droppableId={'7'}>
        {provided => (
          <ul style={{listStyle: 'none'}}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {fields.sort(x => x.sequence).map((benefit, index) => (
              <Draggable key={index} draggableId={`${benefit.sequence}`} index={index}>
                {(provided) => (
                  <li 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <PlanBenefitDisplay benefit={apps.getApp(benefit)} index={index} registerPrefix={registerPrefix} />
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}
