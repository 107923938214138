import { useState } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { Button, IconButton } from '../buttons';
import { FaUserSlash } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Input } from '../form';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutationWrapper } from 'apollo/useMutationWrapper';
import { REGISTER_EMAIL_ADDRESS } from 'shared/operations/mutations/registerEmailAddress';
import styles from './contact.module.css';

export const AddMissingEmailModal = ({account}) => {
  const [showModal, setShowModal] = useState(false);
  const [sendRegistrationEmail] = useMutationWrapper(REGISTER_EMAIL_ADDRESS);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    await sendRegistrationEmail({variables: {accountId: account.id, email: data.email}, refetchQueries: ['GetConsumer']});
    setShowModal(false);
  };

  const formMethods = useForm({
    defaultValues: {
      email: ''
    },
  });

  return (
    <>
    HELP
      <IconButton 
        tooltip='No account email!'
        IconComponent={FaUserSlash}
        iconClass={styles.missingAccountEmailIcon}
        onClick={() => setShowModal(true)}
        size={25}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="help-modal"
        centered
        size='lg'
      >
        <Modal.Body id="help-modal">
          <FormProvider {...formMethods}>
            <form name="account-email" onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Container>
                <Row className="justify-content-end">
                  <Col xs={10}>
                    <h3>Add Account / Consumer Email</h3>
                  </Col>
                  <Col className="text-end">
                    <AiOutlineClose size={25} onClick={() => setShowModal(false)} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input type='email' label='Email' registerInput='email'/>
                  </Col>
                  <Col style={{margin: 'auto'}}>Add an email for this consumer so that they can register for the site!</Col>
                </Row>
                <Row>
                  <Col className={styles.addEmailSubmit}>
                    <Button text='Submit' onClick={formMethods.handleSubmit(onSubmit)} />
                  </Col>
                </Row>
              </Container>
            </form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  )
}