import { PrescriptionDetails } from './pages/prescriptionDetails';
import { PrescriptionCoupon } from './pages/prescriptionCoupon';
import { RenderRoutes } from '../../shared/routing/renderRoutes';

const pathRoot = '/benefits/prescriptions';

export const rxPaths = {
  prescriptionDetail: `${pathRoot}/details`,
  prescriptionCoupon: `${pathRoot}/coupon`
};

export const rxRoutes = [
  {
    path: pathRoot,
    key: 'rx',
    component: RenderRoutes,
    routes: [
      {
        path: `${rxPaths.prescriptionDetail}/:prescriptionName`,
        key: 'rx_prescription_details',
        component: PrescriptionDetails
      },
      {
        path: `${rxPaths.prescriptionCoupon}/:prescriptionName`,
        key: 'rx_prescription_coupon',
        component: PrescriptionCoupon
      }
    ]
  }
]