import { gql } from '@apollo/client';

const PLANBENEFIT_FIELDS=gql`
  fragment PlanBenefitFields on PlanBenefit {
    benefit
    configuration
    sequence
  }
`;

export const PLAN_FIELDS=gql`
  ${PLANBENEFIT_FIELDS}
  fragment PlanFields on Plan {
    id
    title
    name
    intro
    benefits {
      ...PlanBenefitFields
    }
    networks { 
      name
      id
    }
    benefitPeriod {
      type
      configuration
    }
    configuration {
      employeeTemplate
      employeeTemplateUploadRoot
    }
  }
`;

const BENEFIT_FIELDS=gql`
  fragment BenefitFields on EnrollmentBenefit {
    benefit
    configuration
  }
`;

export const ENROLLMENT_FIELDS=gql`
  ${PLAN_FIELDS}
  ${BENEFIT_FIELDS}
  fragment EnrollmentFields on Enrollment {
    id
    completed
    plan {
      ...PlanFields
    }
    benefits {
      ...BenefitFields
    },
    period {
      start
      end
    }
  }
`;