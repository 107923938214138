import { Col, Row, Accordion } from 'react-bootstrap';
import { useEffect, useState, useCallback } from 'react';
import styles from '../rx.module.css';
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";
import { GoodRxSearch } from '../components/search';
import buttonStyles from 'shared/components/buttons/button.module.css';
import { useApolloClient } from '@apollo/client';
import { GET_DRUG_DETAILS } from '../operations/queries';
import { FloatingLabelControl } from 'shared/components/form/floatingLabelControl';
import { isEmpty } from 'lodash';
import { useLocation, useHistory } from "react-router-dom";

export const PrescriptionDetailsMenu = ({name, searchPrices, equivalents, postalCode}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [drugDetails, setDrugDetails] = useState({});
  const [drugNames, setDrugNames] = useState([]);  
  const [forms, setForms] = useState([]);  
  const [dosages, setDosages] = useState([]);  
  const [quantities, setQuantities] = useState([]);
  const [displayDrugName, setDisplayDrugName] = useState(name);
  const [activeDrug, setActiveDrug] = useState(name);
  const [activeForm, setActiveForm] = useState('');
  const [activeDosage, setActiveDosage] = useState('');
  const [activeQuantity, setActiveQuantity] = useState('');
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();

  const resetDropdowns = useCallback(() => {
    setActiveForm('');
    setActiveDosage('');
    setActiveQuantity('');
  }, [setActiveForm, setActiveDosage, setActiveQuantity]);

  const getDrugDetails = useCallback(async() => {
    const { data } = await client.query({
      query: GET_DRUG_DETAILS,
      variables: { name: activeDrug }
    });
    
    resetDropdowns();
    setDrugDetails(data.rx.getDetails)
    setForms(data.rx.getDetails.map(x => x.form));
  }, [activeDrug, client, resetDropdowns]);

  const drugSelect = useCallback((drug) => {
    if (displayDrugName === drug){
      return;
    }

    if (drug.indexOf('(') > 0) {
      setDisplayDrugName(drug);
      let normalized = drug.substring(0, drug.indexOf('(') -1);
      setActiveDrug(normalized);
    }
  }, [setDisplayDrugName, displayDrugName]);

  const formSelect = useCallback((form) => {
    if (form === activeForm) {
      return;
    }

    resetDropdowns();
    setActiveForm(form);

    var validOptions = drugDetails.filter(x => x.form === form).map(x => x.dosages)[0];
    var dosages = validOptions.map(x => x.dosage);

    setDosages(dosages);
    setQuantities([]);
  }, [activeForm, resetDropdowns, setActiveForm, drugDetails, setDosages, setQuantities]);

  const dosageSelect = useCallback((dosage) => {
    if (dosage === activeDosage) {
      return;
    }

    setActiveDosage(dosage);
    setActiveQuantity('');
    var validOptions = drugDetails.filter(x => x.form === activeForm)
                                  .map(x => x.dosages)[0]
                                  .filter(x => x.dosage === dosage)[0];

    setQuantities(validOptions.quantities);
  }, [activeDosage, setActiveDosage, setActiveQuantity, drugDetails, activeForm, setQuantities]);

  const quantitySelect = (quantity) => {
    setActiveQuantity(quantity);
  };

  const updateResults = useCallback((updateRoute=true) => {
    if (updateRoute) {
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('form', activeForm);
      currentUrlParams.set('dosage', activeDosage);
      currentUrlParams.set('quantity', activeQuantity);
      history.push(location.pathname + "?" + currentUrlParams.toString());
    }

    searchPrices({filter: {
      drug: activeDrug,
      format: activeForm,
      dosage: activeDosage,
      quantity: activeQuantity,
      postalCode: postalCode
    }});
  }, [location.search, activeForm, activeDosage, activeQuantity, location.pathname, searchPrices, postalCode, activeDrug, history]);

  /* responsible for responding to a url change due to a new search */
  useEffect(() => {
    setActiveDrug(name);
    setDisplayDrugName(name);
  }, [name, setActiveDrug]);

  /* responsible for maintaining integrity of the Drug Name dropdown */
  useEffect(()=> {
    if (drugNames.length > 0) {
      let activeDrugAlreadyPresent = drugNames.filter(x => x.includes(activeDrug));
      if (activeDrugAlreadyPresent.length <= 0) {
        setDisplayDrugName(activeDrug);
        if (drugNames.length > 0) {
          setDrugNames([]);
        }
      }
    }
  }, [activeDrug, drugNames]);

  /* responsible for supplying the values for the Drug Name drop down upon retrieving prices */
  useEffect(() => {
    if (equivalents && equivalents.length > 0) {
      let brands = equivalents.filter(x => x.type === 'brand')[0];
      let names = brands.equivalents.map(x => `${x} (brand)`);
      let generics = equivalents.filter(x => x.type === 'generic')[0];
      names = [...names, ...generics.equivalents.map(x => `${x} (generic)`)];
      setDrugNames(names);
      let current = names.filter(x => x.includes(activeDrug))[0];
      setDisplayDrugName(current);
    } else {
      setDrugNames([]);
    }
  }, [equivalents, activeDrug]);

  /* responsible for ensuring drug details are for the current active drug */
  useEffect(() => {
    if (!isEmpty(drugDetails))
    {
      if (drugDetails.filter(x => x.name === activeDrug).length <= 0) {
        resetDropdowns();
        getDrugDetails();
      }
    } 
  }, [activeDrug, drugDetails, resetDropdowns, getDrugDetails]);

  useEffect(() => {
    if (isEmpty(drugDetails)) {
      getDrugDetails()
    }
  }, [drugDetails, getDrugDetails])

  /* responsible for updating menu based on search params on load */ 
  useEffect(() => {
    if (!isInitialized) {
      let currentUrlParams = new URLSearchParams(location.search);
      if (!isEmpty(drugDetails)) {
        let urlForm = currentUrlParams.get('form');
        if (urlForm && urlForm !== activeForm) {
          formSelect(urlForm);
        }
      }
      if (activeForm.length > 0) {
        let urlDosage = currentUrlParams.get('dosage');
        if (urlDosage && urlDosage !== activeDosage) {
          dosageSelect(urlDosage);
        }
      }
      if (activeDosage.length > 0) {
        let urlQuantity = currentUrlParams.get('quantity');
        if (urlQuantity && urlQuantity !== activeQuantity) {
          quantitySelect(parseInt(urlQuantity));
        }
      }
    }
  }, [drugDetails, activeForm, activeDosage, activeQuantity, dosageSelect, formSelect, location.search, isInitialized]);

  useEffect(() => {
    if (!isInitialized && activeQuantity) {
      setIsInitialized(true);
      updateResults(isEmpty(location.search));
    }
  }, [isInitialized, updateResults, activeQuantity])

  return (
    <>
    <Col lg={4} className={styles.detailsMenu}>
      <Row className={styles.detailsMenuNameWrapper}>
        <Col className=".d-none .d-sm-block .d-md-none">
          <div className={styles.prescriptionName}>
            {name}
          </div>
        </Col>
      </Row>
      <Row>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header className={styles.accordionHeaderWrapper}>
              <h2 className={styles.detailsMenuHeader}>{'Prescription Details'}</h2>
              </Accordion.Header>
            <Accordion.Body style={{padding: 0}}>
              <PrescriptionDetailsDropdown 
                label='Drug Name'
                items={drugNames} 
                value={displayDrugName} 
                onSelect={drugSelect}
                disabled={drugNames.length === 0}
              />
              <PrescriptionDetailsDropdown 
                label='Form' 
                items={forms}
                onSelect={formSelect}
                value={activeForm} 
              />
              <PrescriptionDetailsDropdown
                label='Dosage'
                items={dosages}
                disabled={dosages.length === 0}
                value={activeDosage}
                onSelect={dosageSelect}
              />
              <PrescriptionDetailsDropdown 
                label='Quantity' 
                items={quantities} 
                disabled={quantities.length === 0} 
                value={activeQuantity} 
                onSelect={quantitySelect}
              />
              <div className={styles.bottomBorder} />
              <div className={styles.updateResultsBtnWrapper}>
                <button className={`${styles.updateResultsBtn} ${buttonStyles.button} ${buttonStyles.buttonPrimary}`}
                        onClick={() => updateResults()}
                >
                  Update results
                  </button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row>
        <Accordion defaultActiveKey="1" className={styles.accordionSearch}>
            <Accordion.Item eventKey="0">
              <Accordion.Header className={styles.accordionHeaderWrapper}>
                <h2 className={styles.detailsMenuHeader}>{'New drug search'}</h2>
              </Accordion.Header>
              <Accordion.Body style={{padding: 0}}>
                <div className={styles.detailsMenuSearch}>
                  <GoodRxSearch placeholder='Search...'/>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </Row>
    </Col>
    </>
  )
}

const PrescriptionDetailsDropdown = ({label, items, onSelect, value, disabled}) => {
  return (
    <div className={styles.detailsMenuDropdownLabel} style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
      <FloatingLabelControl label={!value ? '' : label} for={`${label}-dropdown`}>
        <DropdownList className={styles.detailsMenuDropdown} id={`${label}-dropdown`}
          placeholder={label}
          data={items}
          onSelect={onSelect}
          disabled={disabled}
          value={value}
          renderValue={({item}) => (
            <span data-testid={label}>{item}</span>
          )}
        />
      </FloatingLabelControl>
    </div>
  )
}