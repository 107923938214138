import { useCallback, useState, useEffect } from 'react'

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useCallback(() => {new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )}, [setIntersecting]);
    
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    );
    const localRef = ref; // to get rid of warnings

    localRef.current && observer.observe(localRef.current)
    // Remove the observer as soon as the component is unmounted
    return () => { localRef.current && observer.unobserve(localRef.current); }
  }, [ref, observer, setIntersecting])

  return isIntersecting
};