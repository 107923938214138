import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FloatingLabelControl } from './floatingLabelControl';
import { getError } from "shared/services/validationService";

export const Input = ({
  type, 
  label, 
  placeholder, 
  onClick, 
  onKeyDown, 
  inputRef, 
  registerInput, 
  isReadOnly, 
  autoFocus, 
  errors, 
  autoComplete,
  size='lg'
}) => {
  const { register } = useFormContext();
  placeholder = placeholder ?? label;

  const getInputError = () => getError(registerInput, errors);
  let registerOptions;
  if (type === 'number') registerOptions = { valueAsNumber: true };

  return (
    <FloatingLabelControl label={label}>
        <Form.Control 
          readOnly={isReadOnly} 
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          ref={inputRef} 
          onClick={onClick} 
          onKeyDown={onKeyDown}
          placeholder={placeholder} 
          type={type} 
          size={size}
          isInvalid={getInputError()}
          {...register(registerInput, registerOptions)} 
        />
        <Form.Control.Feedback type="invalid">
          {getInputError()?.message}
        </Form.Control.Feedback>
    </FloatingLabelControl>
  )
};