import * as Yup from 'yup';
import { atLeast1PhoneNumberValidationSchema } from 'shared/components/contact/validation';

export const payerBasicInfoValidationSchema = Yup.object().shape({
  payer: Yup.object().shape({
    name: Yup.string().required('Name required').nullable(),
    tenant: Yup.object().test('tenant', 'Tenant required', 
      tenant =>  tenant?.id?.length > 0).nullable(),
    address: Yup.object().test('address', 'Address required', 
      address =>  address?.line1?.length > 0).nullable(),
    phoneNumbers: atLeast1PhoneNumberValidationSchema
  })
});

export const planValidationSchema = (planNames) => {
  return Yup.object().shape({
    plan: Yup.object().shape({
      name: Yup.string().required('Name required').nullable().notOneOf(planNames, 'Plan names must be unique'),
      title: Yup.string().required('Title required').nullable(),
      intro: Yup.string().required('Intro required').nullable()
    })
  })
};
