import { Col, Container, Row } from 'react-bootstrap';
import styles from './media.module.css';

export const MediaUploadInstructions = () => (
  <Container fluid>
    <Row>
      <Col className={styles.instructions}>
        <b>Image Files Requirements</b>
        <br />
        We accept images in JPEG and PNG formats. We recommend JPEG images with min 1440× 960 size and min resolution 72ppi (pixels per inch). If an image is less than 72ppi resolution and less then 800x600 size, it might appear fuzzy (what we call pixelated) and we will not accept it.
      </Col>
    </Row>
    {/* <Row>
      <Col className={styles.instructions}>
        <b>Video Files Requirements</b>
        <br />
        We accept common video formats such as MP4, MOV, WMV, AVI, and FLV. We recommend MP4 format with min 1920x1080 resolution. The maximum file(s) size you can upload is 1 GB.
      </Col>
    </Row> */}
  </Container>
);