import { gql } from '@apollo/client';
import { CONSUMER_FIELDS } from '../fragments';

export const GET_CONSUMER = gql`
  ${CONSUMER_FIELDS}
  query GetConsumer($id: ID!) {
    getConsumer(id: $id) {
      account {
        id
        email
      }
      ...ConsumerFields
    }
  }
`;