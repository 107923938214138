import { gql } from '@apollo/client';
import { FIND_CARECENTER_FIELDS } from 'careCenter/operations/fragments'

export const FIND_DPC_CARECENTERS = gql`
  ${FIND_CARECENTER_FIELDS}
  query FindDpcCareCenters($search: DpcFilter!) {
    dpc {
      findDpcCareCenters(search: $search) {
        careCenters {
          careCenter {
            ...FindCareCenterFields
          }
          distance
        }
      }
    }
  }
`;