import { gql } from '@apollo/client';

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      date
      type
      status
      consumer {
        id
      }
      tenant {
        id
      }
      provider {
        contact {
          name {
            first
            last
          }
        }
      }
      careCenter {
        id
        name
      }
      integrations {
        type
        id
        configuration
      }
      configuration
    }
  }
`;