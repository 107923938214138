import { useParams } from "react-router-dom";
import { useConsumerEnrollment } from 'enrollment/hooks/useConsumerEnrollment';
import { EditProfile } from 'consumer/components/profile/editProfile';
import { AdminHeader } from 'shared/components/header/adminHeader';
import styles from './concierge.module.css';

export const EditConciergeConsumer = () => {
  const { consumerId } = useParams();
  const { consumer, enrollment } = useConsumerEnrollment(consumerId);

  if ( consumer && enrollment) {
    return (
      <>
        <AdminHeader title={consumer.contact.name.first + ' ' + consumer.contact.name.last} id={consumer.id} />
        <div className={styles.tabs}>
          <EditProfile
            consumer={consumer}
            account={consumer.account}
            enrollment={enrollment}
            isAdminView={true}
          />
        </div>
      </>
    )
  }

  return (<h1>Loading...</h1>)
}