import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { IconButton } from 'shared/components/buttons/iconButton';
import { GET_MESSAGE_RECIPIENT_CORRESPONDENT } from 'message/operations/queries/getMessageRecipients';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

export const RecipientsAction = ({content}) => {
  const [recipients, setRecipients] = useState([]);
  useQuery(GET_MESSAGE_RECIPIENT_CORRESPONDENT, {
    variables: { messageId: content.id},
    onCompleted: (data) => setRecipients(data.findMessageRecipients.items)
  });

  return (
    <OverlayTrigger
      trigger={[ 'hover', 'focus' ]}
      placement="bottom"
      rootClose
      delay={{ show: 0, hide: 300 }}
      overlay={
        <Popover>
          <Popover.Body>
            {recipients.map((item, index) => (
              <span key={index}>{item.recipient.name}</span>
            ))}
          </Popover.Body>
        </Popover>
      }
    >
      <span>
        <IconButton IconComponent={FaUsers} size={15} tooltip='Recipients'/>
      </span>
    </OverlayTrigger>
  );
};