import { Col, Row } from "react-bootstrap";
import style from './youGetCard.module.css';

export const YouGetCard = (props) => {

  const { icon, title, copy, children } = props;

  return (
    <Col md={5} className={style.cardmargin}>
      <Col className={style.card}>
        <Row>
          <Col>
            <div className={style.icon}>
              {" "}
              {icon}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
              <h4>{title}</h4>
              <hr />
              </Col>
            </Row>
            <Row>
              <Col className="d-none d-lg-block d-md-block">
                {copy}
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};