import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_AMENITIES } from '../operations/queries/getAllAmenities';

export const useAmenities = () => {
  const [state, setState] = useState({
    loading: undefined,
    amenities: undefined
  });
  const { loading, data } = useQuery(GET_ALL_AMENITIES);

  useEffect(() => {
    if (loading) setState({loading: true, amenities: undefined});
  }, [loading]);

  useEffect(() => {
    if(data) setState({loading: false, amenities: data.findCareCenterAmenities.items});
  }, [data]);

  return state;
};