import { Container, Row } from "react-bootstrap";
import { sortBy } from "lodash";
import { Tag } from "./tag";

export const TagList = ({ currentTags, allTags, onRemove }) => {
  const tags = sortBy(currentTags);

  return (
    <Container>
      <Row>
        { tags.map(tag => <Tag key={tag} tag={tag} allTags={allTags} onRemove={onRemove} />) }
      </Row>
    </Container>
  );
};
