import { gql } from '@apollo/client';
import { NETWORK_FIELDS } from '../fragments';

export const GET_ALL_NETWORKS = gql`
  ${NETWORK_FIELDS}

  query Query($filter: NetworkFilter) {
  findNetworks(filter: $filter) {
    items {
      ...NetworkFields
    }
  }
}
`;