import { Accordion, Button, Col, Row } from 'react-bootstrap';
import styles from 'consumer/components/todo/todo.module.css';
import { useQuery } from '@apollo/client';
import { GET_DRUG_LOW_PRICE } from 'benefits/rx/operations/queries/getLowPrice';
import { BoxLoading } from 'shared/components/spinners/boxLoading';
import { FaFilePrescription } from 'react-icons/fa'
import { useState } from 'react';
import { DateTime } from 'luxon';
import { rxPaths } from 'benefits/rx/routes';
import { useHistory } from "react-router";

export const RxOrder = ({order}) => {
  const [drugInfo, setDrugInfo] = useState(null);
  const [drugPriceUrl, setDrugPriceUrl] = useState('#');
  const history = useHistory();
  const { loading } = useQuery(GET_DRUG_LOW_PRICE, {
    skip: !order.configuration.activeNdc,
    variables: { filter: {ndc: order.configuration.activeNdc}},
    onCompleted: (data) => {
      let drugPrice = data.rx.getLowPrice;
      let drugName = drugPrice.equivalents.filter(x => x.type === 'generic')[0].equivalents[0];
      setDrugInfo(drugPrice)
      setDrugPriceUrl(`${drugName}?form=${drugPrice.format}&dosage=${drugPrice.dosage}&quantity=${drugPrice.quantity}`)
    }
  });

  const onLinkClicked = () => history.push(`${rxPaths.prescriptionDetail}/${drugPriceUrl}`); 

  return (
    <Accordion defaultActiveKey="1" className={styles.appointmentAccordion}>
    { loading && <BoxLoading height={50} width={200} viewBox="0,0,200,50" /> }
    { !loading && 
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.appointmentAccordionHeaderWrapper}>
          <div>
            <Row>
              <Col>
                <FaFilePrescription className={styles.actionIcon}/>
                <span>{drugInfo?.display}</span>
              </Col>
            </Row>
            <Row>
              <Col className={styles.orderAccordionHeaderDate}>
                {DateTime.fromMillis(order.date).toLocaleString(DateTime.DATE_MED)}
              </Col>
            </Row>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className={styles.appointmentAccordionBody}>
            <Row>
              <span>
                {order.configuration.unstructuredSig}
              </span>
            </Row>
            <Row>
              <Button onClick={onLinkClicked} data-testid={`drug-link-${order.id}`} variant='link' className={styles.actionLink}>
                See Prices as low as ${drugInfo?.prices[0].price.toFixed(2)} &nbsp; &#9656;
              </Button>
            </Row>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    }
    </Accordion>
  )
}