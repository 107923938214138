import { RxIcon } from './icon';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import { GoodRxSearch } from './search';

export const YouGetRx = () => {
  return (
    <YouGetCard 
      title='Prescription Search'
      icon={<RxIcon />}
      copy="There are not enough words in the universe to explain why drug prices are so confusing, so instead we are just making sure you get the best price available. Remember to stay on your medications till your doctor advises otherwise."
    >
      <div style={{padding: '0 10px'}}>
        <GoodRxSearch />
      </div>
    </YouGetCard>
  );
};
