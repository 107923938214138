import { accountRoutes } from 'account/routes';
import { adminRoutes } from 'admin/routes';
import { careCenterRoutes } from 'careCenter/routes';
import { conciergeRoutes } from 'concierge/routes';
import { consumerRoutes } from 'consumer/routes';
import { enrollmentRoutes } from 'enrollment/routes';
import { benefitRoutes } from 'benefits/routes';
import { policyRoutes } from 'registration/routes';
import { Home } from 'home';
import { RenderRoutes } from './renderRoutes';

export const AppRouter = () => {

  const routes = [
    {
      path: '/',
      exact: true,
      key: 'home',
      component: Home
    }
  ].concat(
    accountRoutes,
    adminRoutes,
    careCenterRoutes,
    conciergeRoutes,
    consumerRoutes, 
    enrollmentRoutes,
    benefitRoutes,
    policyRoutes);

  return (
    <RenderRoutes routes={routes} />
  );
};