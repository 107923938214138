import { gql } from '@apollo/client';
import { PAYER_FIELDS } from '../fragments';

export const GET_ALL_PAYERS = gql`
  ${PAYER_FIELDS}

  query Query($filter: PayerFilter) {
  findPayers(filter: $filter) {
    items {
      ...PayerFields
    }
  }
}
`;