import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { GET_ORDERS } from 'consumer/operations/queries/getOrders';
import { OrderComponentMap } from 'orders/components/orderComponentMap';
import { OrderStatus } from 'orders/components/orderStatus';

export const OrdersLoader = ({consumerId, onOrdersLoaded}) => {
  useQueryWrapper(GET_ORDERS, {
    skip: !consumerId,
    variables: { 
      filter: {
        consumer: { id: { eq: consumerId } },
        status: { in: [OrderStatus.Open]}
      },
      offset: 0
    },
    onCompleted: (data) => {
      let foundOrders = [...data.findOrders.items];
        
      for (var x = 0; x < foundOrders.length; x++) {
        let currentOrder = {...foundOrders[x]};
        currentOrder['Component'] = OrderComponentMap[currentOrder.type];
        foundOrders[x] = currentOrder;
      }

      onOrdersLoaded(foundOrders);
    }
  });

  return (<></>)
}