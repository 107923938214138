import { ConciergeHelp, FloatingHelpButton } from 'concierge/components/contact';

const copy = 'If you have a question, contact Care Concierge anytime.';
const title = 'Need help?';

export const FindHospitalHelpButton = () => {
  return (<FloatingHelpButton title={title} copy={copy} />);
};

export const FindHospitalHelp = () => {
  return (<ConciergeHelp title={title} copy={copy} />);
};