import { gql } from '@apollo/client';

export const GET_ACCOUNT = gql`
  query Query($accountId: ID!) {
  getAccount(id: $accountId) {
    id
    email
    isAdmin
    tenant {
      id
      name
    }
    roles {
      id
      name
      permissions
      assignableContexts
    }
    allowedTenants {
      id
      name
    }
  }
}
`;