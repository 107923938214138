import { Col, Row } from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import { FaGraduationCap, FaBookMedical, FaUtensils } from 'react-icons/fa';
import { createMutableCopy } from 'apollo/utils';
import { GET_MY_PERKS } from "consumer/operations/queries/getMyPerks";
import { PerkCategory } from './perkCategory';

export const MyPerks = () => {
  const { data, loading } = useQuery(GET_MY_PERKS);

  let perks = [];
  if (data) {
    perks = createMutableCopy(data.findPerks.items);
  }

  return(
    <>
      <Row>
        <Col>
          <h2>My Perks</h2>
        </Col>
      </Row>
      <Row>
        <PerkCategory Icon={FaGraduationCap} title="Education" category="EDUCATION" perks={perks} loading={loading} />
        <PerkCategory Icon={FaUtensils} title="Cooking Corner" category="COOKING" perks={perks} loading={loading} />
        <PerkCategory Icon={FaBookMedical} title="Health Tips" category="HEALTH" perks={perks} loading={loading} />
      </Row>
    </>
  );
};