import { gql } from '@apollo/client';
import { CONSUMER_FIELDS } from 'consumer/operations/fragments';

export const CASE_FIELDS = gql`
  ${CONSUMER_FIELDS}
  fragment CaseFields on Case {
    id
    tenant {
      id
    }
    status
    title
    consumer {
      ...ConsumerFields
    }
    context
    tags
    audit {
      fieldName
      oldValue
      newValue
      date
      changedBy
    }
    createdAt
  }
`;


export const NOTE_FIELDS = gql`
  fragment NoteFields on Note {
    id
    tenant {
      id
    }
    text
    sender
    sentAt
    tags
    case {
      id
    }
  }
`;