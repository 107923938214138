import { Row, Col, Container } from 'react-bootstrap';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { SectionHeader } from 'shared/components/form';
import { AddButton, CancelButton, DeleteButton } from 'shared/components/buttons';
import { TenantSelector } from 'tenant/components/tenantSelector';
import styles from '../../../admin/components/edit/edit.module.css';

export const EditAccountAllowedTenants = () => {
  const [tenantBeingAdded, setTenantBeingAdded] = useState(false);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({name: 'account.allowedTenants'});

  const onTenantSelected = (data) => {
    let chosenIndex = data.target.selectedIndex;
    let chosenOption = data.target.options[chosenIndex];
    if (chosenOption && chosenIndex) {
      append({id: chosenOption.value, name: chosenOption.text})
      setTenantBeingAdded(false);
    }
  };

  return (
    <>
    <Row>
    <SectionHeader>
      <h4>Allowed Tenants</h4>
    </SectionHeader>
    <ul>
    {fields.map((field, index) => (
      <Controller
        control={control}
        key={field.id} 
        name={field.id} 
        render = {(props) => {
          return(
            <Container fluid>
              <li>
                <Row>
                  <Col lg={4} md={6} sm={9} xs={9}>{field.name}</Col>
                  <Col>
                    <DeleteButton tooltip='Remove' size={20} onClick={() => remove(index)}/>
                  </Col>
                </Row>
                </li>
            </Container>
          );
        }}
      />
    ))}
    {tenantBeingAdded 
      ? <Container fluid>
          <li>
            <Row>
              <Col lg={4} md={6} sm={9} xs={9}>
                <TenantSelector ignoreIds={fields.map(x => x.id)} onSelected={onTenantSelected} registerPrefix={null}/>
              </Col>
              <Col className={styles.cancelButtonSpacer}>
                <CancelButton tooltip='Cancel' onClick={() => setTenantBeingAdded(false)}/>
              </Col>
            </Row>
          </li>
        </Container>
      : <></>
    }
    </ul>
    </Row>
    { !tenantBeingAdded && <AddButton tooltip='Add new Tenant' onClick={() => setTenantBeingAdded(true)} /> }
    </>
  );
};