import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FaFolderOpen, FaSortAmountDown, FaTasks } from "react-icons/fa";
import { GET_CASE } from 'case/operations/queries/getCase';
import { TabLayout } from 'shared/components/tablayout';
import { AdminHeader } from 'shared/components/header/adminHeader';
import { Activity } from "case/components/activity";
import styles from 'case/case.module.css';

export const CaseDetails = () => {
  const { caseId } = useParams();
  const { loading, data } = useQuery(GET_CASE, {
    skip: !caseId,
    variables: {id: caseId}
  });

  let caseData = data?.getCase;

  const tabs = [
    {
      key: 'activity',
      title: 'Activity',
      default: true,
      icon: FaSortAmountDown,
      component: ({caseData}) => (<Activity caseData={caseData} />)
    },
    {
      key: 'workflow',
      title: 'Workflow',
      icon: FaTasks,
      component: () => (<div>Workflow</div>)
    },
    {
      key: 'attachemnts',
      title: 'Attachments',
      icon: FaFolderOpen,
      component: () => (<div>Attachments</div>)
    }
  ];

  return (
    <>
    { loading 
      ? <h3>Loading...</h3>
      : <>
          <AdminHeader title={caseData?.title} id={caseData?.id} />
          <div className={styles.tabs}>
            <TabLayout 
              tabs={tabs} 
              caseData={caseData} />
          </div>
        </>
    }
    </>
  )
}