import { GET_LYRIC_TIMEZONES } from 'integrations/lyric/operations/queries/getTimezones';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { useEffect, useState } from 'react';
import { SimpleDropDown } from 'shared/components/form';
import { DateTime } from 'luxon';
import { appSettings } from 'appSettings';
import isEmpty from 'lodash.isempty';

export const LyricTimezoneSelector = ({ consumer, onSelected }) => {
  const [timezones, setTimezones] = useState([]);
  const [defaultTimezone, setDefaultTimezone] = useState(null);
  const [loading, setLoading] = useState(true);
  useQueryWrapper(GET_LYRIC_TIMEZONES, {
    onCompleted: (data) =>  {
      setTimezones(data.findLyricTimezones.items.map(x => ({
          value: x.offset,
          text: x.name
        })));
      }
    }
  );

  useEffect(() => {
    const getTimezone = async () => {
      const address = consumer?.contact?.addresses ? consumer.contact.addresses[0] : null;
      if (address?.geometry?.coordinates ?? false) {
        const googleTimezoneApi = 'https://maps.googleapis.com/maps/api/timezone/json';
        const lat = address.geometry.coordinates[1];
        const long = address.geometry.coordinates[0];
        const time = DateTime.utc().toSeconds();
        const googleKey = appSettings.GOOGLE_MAPS_API_KEY;
        const response = await fetch(`${googleTimezoneApi}?location=${lat},${long}&timestamp=${time}&key=${googleKey}`);
    
        const timezoneData = await response.json();
        const defaultTimezone = timezones.find(x => x.text === timezoneData?.timeZoneId);
        if (defaultTimezone) {
          setDefaultTimezone(defaultTimezone.value);
          onSelected({target: {value: defaultTimezone.value}});
        }
      }
      setLoading(false);
    }

    if (loading && !isEmpty(timezones) && consumer) {
      getTimezone();
    }
  }, [loading, timezones, consumer, onSelected]);

  return (
    loading 
      ? <></> 
      : <SimpleDropDown
          label="What Time zone will you be in during consultation"
          items={timezones}
          defaultValue={defaultTimezone}
          onSelected={onSelected}
          removeEmptyOption={true}
        />
  );
};



