import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import { GET_LYRIC_PHARMACIES } from 'integrations/lyric/operations/queries/getPharmacies';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Input, Section } from 'shared/components/form';
import isEmpty from 'lodash.isempty';
import styles from 'integrations/lyric/components/pharmacySelector/pharmacySelector.module.css';
import { FloatingLabel } from "react-bootstrap";
import { useForm, FormProvider } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { DisplayPharmacyAddress } from './displayPharmacyAddress';
import { SelectablePharmacy } from './selectablePharmacy';
import { getError } from "shared/services/validationService";
import { DisplayAddress } from 'shared/components/contact/displayAddress';
import { AddressLookup } from 'shared/components/contact/addressLookup';

export const LyricPharmacySelector = ({ consumer, onSelected, errors }) => {
  const autocompleteRef = useRef(null);
  const [pharmacies, setPharmacies] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [searchPharmacies] = useLazyQuery(GET_LYRIC_PHARMACIES, {
    onCompleted: (data) =>  {
      setPharmacies(data.lyric.getPharmacies ? [...data.lyric.getPharmacies] : []);
      setShowResults(true);
    }
  });

  const getInputError = () => getError('pharmacy', errors);

  const formMethods = useForm({
    defaultValues: {
      name: '',
      location: ''
    }
  });

  const onSubmit = async (data) => {
    searchPharmacies({
      variables: { input: {name: data.name, location: selectedLocation, consumerId: consumer.id} }
    });
  };

  const addressSelected = (place) => {
    let address = {
      city: '',
      state: '',
      zip: ''
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "postal_code": 
          address.zip = component.short_name;
          break;
        case "locality":
          address.city = component.short_name;
          break;
        case "administrative_area_level_1": 
          address.state = component.short_name;
          break;
        default:
          break;
      }
    }

    setSelectedLocation(`${address.city} ${address.state} ${address.zip}`.trim());
  };

  const onPharmacySelected = useCallback((pharmacy) => {
    setSelectedPharmacy(pharmacy);
    onSelected(pharmacy);
  }, [onSelected]);

  useEffect(() => {
    const setDefaultPharmacyIfAvailable = () => {
      let pharmacy = consumer.integrations.find(x => x.type === 'LYRIC').configuration.preferredPharmacy;
      if (pharmacy) onPharmacySelected(pharmacy);
    };

    const runDefaultSearch = () => {
      const consumerAddress = consumer.contact.addresses[0];
      const consumerAddressString = `${consumerAddress.city}, ${consumerAddress.state} ${consumerAddress.postalCode}`;
      searchPharmacies({
        variables: { input: {location: consumerAddressString, consumerId: consumer.id} }
      })
    };

    if (consumer && !selectedPharmacy) {
      setDefaultPharmacyIfAvailable();
      runDefaultSearch();
    }
  }, [consumer, searchPharmacies, onPharmacySelected, selectedPharmacy]);

  return (
    <>
      <FloatingLabel label={'Pharmacy Associated with this Account'} className={styles.pharmacyLabel}>
        { isEmpty(selectedPharmacy) 
          ? <span>Please select a pharmacy below.</span>
          : <div style={{marginLeft: '5px'}}><DisplayPharmacyAddress pharmacy={selectedPharmacy}/></div>
        }
        <div className={`invalid-feedback ${styles.showValidationError}`}>
          {getInputError()?.message}
        </div>
      </FloatingLabel>
      <Accordion defaultActiveKey="1" className={styles.pharmacySelectorAccordion}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className={styles.contextViewAccordionHeader}>
            <span className={styles.viewOrderHeader}>Select a Pharmacy</span>
          </Accordion.Header>
          <Accordion.Body className={styles.viewAppoinmentRequestBody}>
            <FormProvider {...formMethods}>
              <form name="account" onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Container>
                  <Section>
                    <div className={styles.pharmacyCurrentSearch}>
                      { isEmpty(selectedLocation) && 
                      <>
                        <span> Default search results based on your home address:</span>
                        <div className={styles.pharmacyCurrentSearchAddress}>
                          <DisplayAddress address={consumer.contact.addresses[0]} lines={2} />
                        </div>
                      </>
                      }
                      { !isEmpty(selectedLocation) && 
                      <>
                        <span> Current search results based on:</span>
                        <div className={styles.pharmacyCurrentSearchAddress}>
                          <span>{selectedLocation}</span>
                        </div>
                      </>
                      }
                    </div>
                  </Section>
                  <Section>
                    <Col>
                      <AddressLookup 
                        inputRef={autocompleteRef} 
                        id='pharmacy-search'
                        style={{width: '100%', 'height': 'calc(3.5rem + 2px)'}} 
                        callback={addressSelected} 
                        onFocus={() => {}}
                        onBlur={() => {}}
                        errorRegister={`location`}
                        errors={formMethods.formState.errors}
                        placeholder={'Enter a city, state, or zip code'}
                        placeApiOptions={{types: ['(regions)']}}
                      />
                    </Col>
                  </Section>
                  <Section>
                    <Col>
                      <Input label='Search by pharmacy name (optional)' registerInput='name'/>
                    </Col>
                  </Section>
                  <Row className={styles.pharmacySearchButton}>
                    <Col style={{margin: '0 4%'}}>
                      <Button type="submit" variant="primary" text="Search" />
                    </Col>
                  </Row>
                </Container>
              </form>
            </FormProvider>
            { showResults &&
              <>
                <Row>
                  <Col>
                    <span className={styles.pharmacySearchResults}>Search Results {`(${pharmacies.length})`}</span>
                  </Col>
                </Row>
                <Container className={styles.pharmacyResultsContainer}>
                  { !isEmpty(pharmacies) &&
                    <>
                      {pharmacies.map((pharmacy, index) => (
                        <SelectablePharmacy 
                          key={index} 
                          pharmacy={pharmacy} 
                          onSelected={onPharmacySelected} 
                          selected={selectedPharmacy?.sureScriptPharmacy_id === pharmacy.sureScriptPharmacy_id}
                        />
                      ))}
                    </>
                  }
                  { isEmpty(pharmacies) && <span>No pharmacies found.</span>}
                </Container>
              </>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
