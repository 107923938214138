import { useQuery } from "@apollo/client"
import { GET_MESSAGES_FOR_CASE } from "case/operations/queries/getMessagesForCase"

export const MessagesLoader = ({caseId, loadMessages, onMessagesLoaded}) => {
  useQuery(GET_MESSAGES_FOR_CASE, {
    skip: !loadMessages,
    variables: {caseId: caseId},
    onCompleted: (data) => {
      let messageLookup = {};
      let structuredMessages = [];
      let messages = data.findMessages.items;
      messages.forEach((item) => messageLookup[item.id] = {...item, sortDate: item.sentAt, replies: []});

      for (var message of Object.values(messageLookup)) {
        if (!message.replyTo) {
          structuredMessages.push(message);
        } else {
          messageLookup[message.replyTo.id].replies.push(message);
          let parentMessage = messageLookup[message.parent.id];
          if (message.sortDate > parentMessage.sortDate) {
            parentMessage.sortDate = message.sortDate;
          }
        }
      }

      onMessagesLoaded(structuredMessages)
    }
  });

  return (<></>)
}