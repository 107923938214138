import { Row } from 'react-bootstrap';
import styles from 'integrations/lyric/components/pharmacySelector/pharmacySelector.module.css';
import { DisplayPharmacyAddress } from './displayPharmacyAddress';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { UPDATE_CONSUMER } from 'consumer/operations/mutations';
import { useMutationWrapper } from 'apollo/useMutationWrapper';

export const SelectablePharmacy = ({ pharmacy, onSelected, selected }) => {
  const { consumer } = useCurrentConsumer();
  const [saveConsumer]  = useMutationWrapper(UPDATE_CONSUMER);

  const onClick = () => {
    const integrations = consumer.integrations;
    const lyricIntegration = integrations.find(i => i.type === 'LYRIC');
    lyricIntegration.configuration.preferredPharmacy = pharmacy;
    saveConsumer({ variables: { input: { 
      id: consumer.id,
      integrations: [...integrations]
    }}});
    onSelected(pharmacy);
  };

  return (
    <Row onClick={onClick}>
      <DisplayPharmacyAddress pharmacy={pharmacy} className={styles.selectablePharmacy} selected={selected} />
    </Row>
  )
};
