import { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { IconSpacer } from 'shared/components/buttons/iconSpacer';
import buttonStyles from 'shared/components/buttons/button.module.css';
import styles from './help.module.css';
import { constants } from './constants';
import { Help } from './help';

export const FloatingHelpButton = ({ title, copy, children }) => {
  const [showModal, toggleModal] = useState(false);
  const helpButtonClasses = `${styles.float} ${buttonStyles.button} ${buttonStyles.buttonPrimary}`;
  const ConciergeIcon = constants.icon;

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        aria-labelledby="help-modal"
        centered
      >
        <Modal.Body id="help-modal">
          <Container>
            <Row className="justify-content-end">
              <Col className="text-end">
                <AiOutlineClose size={25} className={styles.closeicon} onClick={() => toggleModal(false)} />
              </Col>
            </Row>
            <Help title={title} copy={copy} children={children} />
          </Container>
        </Modal.Body>
      </Modal>

      <button
        className={helpButtonClasses}
        onClick={() => toggleModal(true)}
      >
        <ConciergeIcon className={styles.buttonicon} />
        <IconSpacer />
        Need Help?
      </button>
    </>
  );
};