import { useQueryWrapper } from "apollo/useQueryWrapper";
import { Button } from 'react-bootstrap';
import { GET_SURVEY_RESPONSE } from "case/operations/queries/getSurveyResponse";
import { ViewSurveyResponse } from "shared/components/survey/viewSurveyResponse";
import { useState } from "react";
import styles from 'case/case.module.css';
import { ViewOrder } from "./viewOrder";
import { ViewAppointmentRequestWrapper } from "./viewAppointmentRequestWrapper";
import { OffCanvasWrapper } from "shared/components/offCanvasWrapper";
import offCanvasStyles from "shared/components/shared.module.css";

export const Context = ({ caseData }) => {
  const [showSurveyResponse, toggleSurveyResponse] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState(null);
  useQueryWrapper(GET_SURVEY_RESPONSE, {
    skip: !caseData.context.surveyResponse,
    variables: { id: caseData.context.surveyResponse },
    onCompleted: (data) => setSurveyResponse(data.getSurveyResponse)
  });

  return (
    <>
    <h4>Context</h4>
    { surveyResponse &&
      <>
        <Button variant='link' onClick={() => toggleSurveyResponse(true)} className={styles.contextLink}>
          Survey Response &nbsp; &#9656;
        </Button>
        <OffCanvasWrapper shouldShow={showSurveyResponse} toggleShow={toggleSurveyResponse} closeClass={offCanvasStyles.closeOffCanvasWhiteHover}>
          <ViewSurveyResponse surveyResponse={surveyResponse}/>
        </OffCanvasWrapper>
      </>
    }
    { caseData.context.order && <ViewOrder orderId={caseData.context.order} /> }
    { caseData.context.appointmentRequest && <ViewAppointmentRequestWrapper appointmentRequestId={caseData.context.appointmentRequest} /> }
    </>
  );
};

