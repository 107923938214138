import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ProtectedApp } from './auth/protectedApp';
import { initializeConfiguration } from './appSettings';
import { initializeTelemetry } from './telemetry/telemetryService';
import { initializeAuth } from './auth/config'
import './index.css';
import { ErrorBoundary } from 'shared/components/errorBoundary';

initializeConfiguration().then(() => {
  initializeTelemetry();
  initializeAuth();

  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <ErrorBoundary>
          <ProtectedApp />
        </ErrorBoundary>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

