import { gql } from '@apollo/client';

export const GET_DRUG_LOW_PRICE = gql`
query query($filter: RxPriceNdcFilter) {
  rx {
    getLowPrice(filter: $filter) {
      display
      format
      dosage
      quantity
      equivalents {
        type
        equivalents
      }
      prices {
        price
      }
    }
  }
}
`;
