import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAllTags } from 'admin/operations/useAllTags';
import { BoxLoading } from 'shared/components/spinners/boxLoading';
import { TagList } from "../tagList";
import { TagSelector } from "../tagSelector";
import { TagAdded, TagRemoved } from './tagsReducer';
import styles from 'case/case.module.css';

export const MessageTags = ({content, dispatch}) => {
  const { tags, loading: tagsLoading } = useAllTags();
  const [currentTags, setCurrentTags] = useState(content?.tags ?? []);

  const onTagAdded = (tag) => {
    const newTags = [...currentTags, tag];
    setCurrentTags(newTags);
    TagAdded(dispatch, tag);
  };

  const onTagRemoved = (tag) => {
    const newTags = currentTags.filter(t => t !== tag);
    setCurrentTags(newTags);
    TagRemoved(dispatch, tag);
  };

  return (
    <>
    {
      tagsLoading 
      ? <BoxLoading width={50} height={50} viewBox="0 0 50 50" />
      : <Container>
          <Row>
            <Col>
              <TagSelector
                currentTags={currentTags}
                allTags={tags}
                onAdd={onTagAdded}
              />
            </Col>
          </Row>
          <Row>
            <Col className={styles.tags}>
              <TagList
                currentTags={currentTags}
                allTags={tags}
                onRemove={onTagRemoved}
              />
            </Col>
          </Row>
        </Container>
    }
    </>
  );
};