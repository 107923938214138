import { Container, Col, Row } from "react-bootstrap";
import styles from "shared/components/survey/survey.module.css";

export const ViewSurveyResponse = ({surveyResponse}) => {
  let survey = surveyResponse.survey;
  let questions = survey.questions;
  let answers = surveyResponse.questionAnswers;

  return (
    <>
      <Container fluid className={styles.surveyHeader}>
        <Row className="justify-content-center">
          <Col className={`text-center my-auto`}>
            <h3 className='inverse'>{survey.title}</h3>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className={`text-center my-auto`}>
            {survey.preamble}
          </Col>
        </Row>
      </Container>
      <Container className={styles.surveyResponseContainer}>
        <Row className={styles.center}>
        {questions.map((question, questionIndex) => (
          <Container className={styles.surveyQuestionContainer} key={questionIndex}>
            <Row>
              <span><b>{question.title}</b></span>
            </Row>
            <Row>
              <span>{question.text}</span>
            </Row>
            <Row className={styles.surveyResponseAnswersContainer}>
              <div className={styles.leftAligned}>
              {question.options.map((item, index) => (
                <Row key={index}>
                  <Col>
                    <input
                      id={item.text}
                      type="radio"
                      checked={answers.find(x => x.id === question.id)?.value === item.value}
                      readOnly={true}
                    />
                    <label className={`form-check-label ${styles.surveyAnswerLabel}`} htmlFor={item.text}>{item.text}</label>
                  </Col>
                </Row>
              ))}
              </div>
            </Row>
          </Container>
        ))}
        </Row>
      </Container>
    </>
  )
};