import { ConsumerHome } from './pages/home/home';
import { MyProfile } from './pages/profile/profile';
import { Appointments } from './pages/clinical/appointments';
import { TabPath } from 'shared/components/tablayout';
import { DoctorsOrders } from './pages/clinical/doctorsOrders';

export const consumerPaths = {
  root: '/consumer',
  profile: '/consumer/profile',
  appointments: '/consumer/appointments',
  doctorsOrders: '/consumer/doctorsOrders',
};

export const consumerRoutes = [{
    exact: true,
    path: consumerPaths.root,
    key: 'consumer',
    component: ConsumerHome
  }, {
    exact: true,
    path: `${consumerPaths.profile}/${TabPath}`,
    key: 'profile',
    component: MyProfile
  }, {
    exact: true,
    path: consumerPaths.appointments,
    key: 'appointments',
    component: Appointments
  }, {
    exact: true,
    path: consumerPaths.doctorsOrders,
    key: 'doctorsOrders',
    component: DoctorsOrders
  }
];