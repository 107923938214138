import { gql } from '@apollo/client';

export const GET_ALL_TAGS = gql`
  query GetAllTags($tenantId: ID!) {
    findTags(
        filter: {
          or: [
            { tenant: { id: { eq: $tenantId }}},
            { isRoutable: { eq: true }}
          ]},
        orderBy: { field: "tag" }) {
      items {
        id
        tag
        isRoutable
      }
    }
  }
`;