import { gql } from '@apollo/client';

export const GET_LYRIC_TIMEZONES = gql`
  query FindLyricTimezones {
    findLyricTimezones {
      items {
        id
        timezoneId
        name
        offset
      }
    }
  }
`;