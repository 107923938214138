import { AdminHome } from './pages/home/home';
import { AdminUsers } from './pages/users/adminUsers';
import { EditAdminUser } from './pages/users/editAdminUser';
import { CareCenterAdminSearch } from 'careCenter/pages/careCenterAdminSearch';
import { EditCareCenter } from 'careCenter/pages/editCareCenter';
import { NetworksList } from 'network/pages/networksList';
import { EditNetwork } from 'network/pages/editNetwork';
import { PayersList } from 'payers/pages/payersList';
import { EditPayer } from 'payers/pages/editPayer';
import { TabPath } from 'shared/components/tablayout';

const rootPath = '/admin';

export const adminPaths = {
  root: rootPath,
  careCenters: `${rootPath}/careCenters`,
  networks: `${rootPath}/networks`,
  payers: `${rootPath}/payers`,
  users: `${rootPath}/users`
};

export const adminRoutes = [
  {
    exact: true,
    path: adminPaths.root,
    key: 'admin',
    component: AdminHome
  },
  {
    exact: true,
    path: [
      adminPaths.careCenters,
      `${adminPaths.careCenters}/search/:search`
    ],
    key: 'admin_carecenters',
    component: CareCenterAdminSearch
  },
  {
    path: `${adminPaths.careCenters}/:careCenterId/${TabPath}`,
    key: 'admin_carecenter_edit',
    component: EditCareCenter
  },
  {
    exact: true,
    path: adminPaths.users,
    key: 'admin_users',
    component: AdminUsers
  },
  {
    path: `${adminPaths.users}/:userId`,
    key: 'admin_users_edit',
    component: EditAdminUser
  },
  {
    exact: true,
    path: adminPaths.networks,
    key: 'admin_networks',
    component: NetworksList
  },
  {
    path: `${adminPaths.networks}/:networkId/${TabPath}/:folder?/:messageId?`,
    key: 'admin_networks_edit',
    component: EditNetwork
  },
  {
    exact: true,
    path: adminPaths.payers,
    key: 'admin_payers',
    component: PayersList
  },
  {
    path: `${adminPaths.payers}/:payerId/${TabPath}`,
    key: 'admin_payers_edit',
    component: EditPayer
  }
];