import { gql } from '@apollo/client';
import { CARE_CENTER_IMPORT_WITH_RECORD_PAYLOAD_FIELDS } from '../fragments';

export const GET_CARE_CENTER_IMPORT = gql`
  ${CARE_CENTER_IMPORT_WITH_RECORD_PAYLOAD_FIELDS}
  query GetCareCenterImport($id: ID!) {
    getCareCenterImport(id: $id) {
      ...CareCenterImportWithRecordPayloadFields
    }
  }
`;
