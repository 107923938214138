import { useQuery, useMutation } from "@apollo/client";
import { Container, Col, Row } from "react-bootstrap";
import { GET_SURVEY } from "shared/operations/queries/getSurvey";
import { CREATE_SURVEY_RESPONSE } from "shared/operations/mutations/createSurveyResponse";
import { UPDATE_SURVEY_RESPONSE } from "shared/operations/mutations/updateSurveyResponse";
import { CREATE_CASE } from "case/operations/mutations/createCase";
import { Section } from "../form/section";
import { Question } from "./question";
import { useEffect, useState } from "react";
import { processAction } from "./actionService";
import { useParams } from "react-router-dom";
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { Button } from "../buttons/button";
import { isEmpty } from 'lodash';
import parse from 'react-html-parser';
import styles from "shared/components/survey/survey.module.css";
import { useLocalStorage, localStorageKeys } from 'shared/hooks/useLocalStorage';

export const Survey = () => {
  const { code } = useParams();
  const { consumer } = useCurrentConsumer();
  const [storedSurveyData, setStoredSuveyData] = useLocalStorage(localStorageKeys.survey);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [surveyWillComplete, setSurveyWillComplete] = useState(false);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [surveyCompleteMessage, setSurveyCompleteMessage] = useState('');
  const [surveyResponse, setSurveyResponse] = useState({questionAnswers: []});
  const [caseToCreate, setCaseToCreate] = useState({});
  const [saveSurveyQuery, setSaveSurveyQuery] = useState(CREATE_SURVEY_RESPONSE);
  const [shouldSaveSurveyResponse, setShouldSaveSurveyResponse] = useState(false);

  const [saveSurveyResponse] = useMutation(saveSurveyQuery);
  const [createCase] = useMutation(CREATE_CASE);

  const { loading, data } = useQuery(GET_SURVEY, {
    skip: !code,
    variables: { filter: {code: {eq: code } } } 
  });

  let survey = data?.findSurveys?.items[0];
  let currentQuestion = survey?.questions[currentQuestionIndex];
  let isLastQuestion = survey?.questions[currentQuestionIndex + 1] === undefined;

  const onPreviousClicked = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const onNextClicked = () => {
    setShouldSaveSurveyResponse(true);
    if (surveyWillComplete) {
      setSurveyComplete(true);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const onAnswerSelected = (answer) => {
    const { completed, caseToCreate, message } = processAction(answer);
    let surveyAnswers = [...surveyResponse.questionAnswers];
    let previousAnswerToQuestion = surveyAnswers.find(x => x.id === currentQuestion.id);
    if (previousAnswerToQuestion) {
      previousAnswerToQuestion.value = answer.value;
      previousAnswerToQuestion.text = answer.text;
    } else {
      surveyAnswers.push({ id: currentQuestion.id, value: answer.value, text: answer.text});
    }

    if (!isEmpty(caseToCreate)) {
      caseToCreate.consumer = {id: consumer.id};
      caseToCreate.tenant = {id: consumer.tenant.id};
      setCaseToCreate(caseToCreate);
    }

    setSurveyResponse({...surveyResponse, questionAnswers: surveyAnswers});
    setSurveyWillComplete(completed);
    setSurveyCompleteMessage(message);
  };

  /*
    responsible for bootstrapping surveyResponse once data has resolved
  */
  useEffect(() => {
    if (consumer && survey) {

      const questionAnswers = [];
      if (storedSurveyData?.survey?.questionAnswers) {
        for (var x = 0; x < storedSurveyData.survey.questionAnswers.length; x++) {
          const question = survey.questions[x];
          const chosenOption = question.options[storedSurveyData.survey.questionAnswers[x]];
          questionAnswers.push({ id: question.id, value: chosenOption.value, text: chosenOption.text});
        }
      }

      setSurveyResponse({
        consumer: {id: consumer.id},
        survey: {id: survey.id},
        tenant: { id: consumer.tenant.id },
        questionAnswers: questionAnswers,
        context: storedSurveyData?.context ?? {}
      });

      setCurrentQuestionIndex(questionAnswers.length);
    }
  }, [consumer, setSurveyResponse, survey, storedSurveyData])

  /*
    responsible for data persistence. will save surveyResponse after each question is answered
    and will create a case if the survey has completed and one should be created
  */
  useEffect(() => {
    const saveResponse = async() => {
      let savedResponse = await saveSurveyResponse({variables: {input: surveyResponse}});
      if (!surveyResponse.id) { // want to swap to mutate and persist the id 
        setSurveyResponse({...surveyResponse, id: savedResponse.data.createSurveyResponse.id});
        setSaveSurveyQuery(UPDATE_SURVEY_RESPONSE);
      }

      if (surveyComplete && !isEmpty(caseToCreate)) { // save case if we are finished and a case is waiting to be created
        caseToCreate.context = {...surveyResponse.context, surveyResponse: {id: surveyResponse.id}};
        caseToCreate.context.surveyResponse = surveyResponse.id ?? savedResponse.data.createSurveyResponse.id;
        await createCase({variables: { input: caseToCreate }});
        setStoredSuveyData(null);
      }
    };

    if (shouldSaveSurveyResponse) {
      saveResponse();
      setShouldSaveSurveyResponse(false);
    }
  }, [surveyResponse, saveSurveyResponse, caseToCreate, createCase, surveyComplete, shouldSaveSurveyResponse, setStoredSuveyData, storedSurveyData]);

  return (
    <>
      { !loading && survey &&
      <>
        <Container fluid className={styles.surveyHeader}>
          <Row className="justify-content-center">
            <Col className={`text-center my-auto ${styles.title}`}>
              <h3 className="inverse">{survey.title}</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} className={`text-center my-auto inverse ${styles.preamble}`}>
              {survey.preamble}
            </Col>
          </Row>
        </Container>
        <Container className={styles.surveyContainer}>
          <Section>
            {surveyComplete
              ? <span className={`text-center ${styles.terminationMessage}`}>
                  {parse(surveyCompleteMessage)}
                </span>
              : <Row className={styles.center}>
                  <Question question={survey.questions[currentQuestionIndex]} previouslySelectedAnswer={surveyResponse.questionAnswers[currentQuestionIndex]} onAnswerSelected={onAnswerSelected} />
                  <Row className={styles.surveyQuestionButtons}>
                    <Col>
                      {currentQuestion && currentQuestion.sequence !== 1 &&
                        <Button text='Previous' disabled={currentQuestion.sequence === 1} onClick={() => onPreviousClicked()} />
                      }
                      </Col>
                      <Col>
                      <Button text={ isLastQuestion ? 'Submit' : 'Next'} disabled={!surveyResponse.questionAnswers[currentQuestionIndex]} onClick={() => onNextClicked()} />
                    </Col>
                  </Row>
                </Row>
            }
          </Section>
        </Container>
      </>
      }
    </>
  );
};
