const Actions = {
  Case: 'case',
  Next: 'next',
  Terminate: 'terminate'
};

export const processAction = (answer) => {
  let result = {
    completed: false,
    caseToCreate: {},
    message: ''
  };

  switch (answer.action.toLowerCase()) {
    case Actions.Case:
      result.completed = true;
      result.message = answer.actionConfiguration.content;
      result.caseToCreate = {
        context: {},
        tags: answer.actionConfiguration.caseTags ?? [],
        title: answer.actionConfiguration.caseTitle,
        status: 'NEW'
      };
      break;
    case Actions.Next:
      break;
    case Actions.Terminate:
      result.completed = true;
      result.message = answer.actionConfiguration.content;
      break;
    default:
      console.log(`Unknown action ${answer.action}`);
      break;
  };

  return result;
};
