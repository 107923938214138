import { gql } from '@apollo/client';
import { AdminCareCentersPerPage } from "careCenter/constants";

export const FIND_CARECENTERS_PAGINATED = gql`
  query FindCareCenters($filter: CareCenterFilter, $offset: Int) {
    findCareCenters(
      filter: $filter,
      page: {
        limit: ${AdminCareCentersPerPage},
        offset: $offset
      }
      orderBy: {
        field: "name",
        order: ASC
      }) {
      count
      items {
        id
        name
        isPublished
      }
    }
  }
`;