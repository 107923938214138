import { gql } from '@apollo/client';
import { NOTE_FIELDS } from 'case/operations/fragments';

export const GET_NOTES_FOR_CASE = gql`
  ${NOTE_FIELDS}
  query FindNotes($caseId: ID!) {
    findNotes(filter: {
      case: {
        id: {
          eq: $caseId
        }
      }
    }) {
      items {
        ...NoteFields
      }
    }
  }
`;
