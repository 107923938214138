import { gql } from '@apollo/client';
import { PLAN_FIELDS } from 'enrollment/operations/fragments';


export const CREATE_PLAN = gql`
  ${PLAN_FIELDS}
  
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      ...PlanFields
    }
  }
`;