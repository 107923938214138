import { BaseLoading } from "./baseLoading";

export const FormLoading = () => {

  const x = ((window.innerWidth / 3) - 300);
  const viewBox = [-x, -10, 500, 600];

  return (
    <BaseLoading viewBox={viewBox}>
      <rect x="10" y="0" rx="0" ry="0" width="175" height="20" />
      <rect x="195" y="0" rx="0" ry="0" width="105" height="20" />
      <rect x="10" y="30" rx="0" ry="0" width="100" height="20" />
      <rect x="120" y="30" rx="0" ry="0" width="180" height="20" />
      <rect x="10" y="60" rx="0" ry="0" width="290" height="20" />

      <rect x="140" y="105" rx="5" ry="5" width="75" height="25" />
      <rect x="225" y="105" rx="5" ry="5" width="75" height="25" />
    </BaseLoading>
  );
};