import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { FIND_CARECENTERS_PAGINATED } from "careCenter/operations/queries/findCareCentersPaginated";
import { ItemList } from "shared/components/itemList";
import { useParams } from "react-router";
import styles from 'network/network.module.css';
import { Link } from "react-router-dom";
import { adminPaths } from 'admin/routes';
import { AdminCareCentersPerPage } from "careCenter/constants";
import { FaCheckCircle } from "react-icons/fa";

export const CareCenterList = () => {
  const { networkId } = useParams();
  const shouldSkipQuery = networkId === 'new'
  const filter = {
    filter: {
      networks: {
        id: { eq: networkId }
      }
    },
    offset: 0
  };

  const { loading, data, fetchMore } = useQuery(FIND_CARECENTERS_PAGINATED, {
    variables: filter,
    notifyOnNetworkStatusChange: true
  });

  const handlePageChange = (page) => {
    const offset = ((page - 1) * AdminCareCentersPerPage);
    fetchMore({variables: { offset: offset }});
  };

  let careCenters = data?.findCareCenters?.items ?? [];
  const totalCount = data?.findCareCenters?.count;

  return (
    <Container fluid>
      { shouldSkipQuery && <h1>Network Not Found.</h1>}
      { !loading && careCenters.length === 0 
        ? <div>No results found</div>
        : <ItemList data={[...careCenters]} 
                    headerRowElement={CareCenterHeaderRow}
                    dataRowElement={CareCenterDataRow}
                    handlePageChange={handlePageChange}
                    itemsPerPage={AdminCareCentersPerPage}
                    numberOfItems={totalCount}
          />
      }
    </Container>
  );
};

const CareCenterHeaderRow = () => {
  return (
    <Row className={styles.importHeaderRow}>
      <Col md={6} style={{paddingLeft: '20px'}}>Name</Col>
      <Col md={6}>Published</Col>
    </Row>
  );
}

const CareCenterDataRow = ({item, index}) => {
  return (
    <Link to={`${adminPaths.careCenters}/${item.id}`} style={{color: "inherit", textDecoration: "none"}} key={index}>
      <Row key={index} className={styles.importHeaderRow}>
        <Col md={6} style={{paddingLeft: '20px'}}>{item.name}</Col>
        <Col md={6}>
        {item.isPublished && <FaCheckCircle size={30} style={{color: '#fd9f01'}}/>}
        </Col>
      </Row>
      <hr />
    </Link>
  );
};
