import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { GET_CASES } from "case/operations/queries/getCasesPaginated";
import { GET_CASE_TAGS } from 'case/operations/queries/getCaseTags';
import { ItemList } from "shared/components/itemList";
import { SectionHeader } from 'shared/components/form';
import { Col, Form, Row } from 'react-bootstrap';
import { conciergePaths } from "concierge/routes";
import { Link } from "react-router-dom";
import { Section } from "shared/components/form/section";
import { useEffect, useState } from "react";
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import styles from 'concierge/concierge.module.css';
import { DateTime } from 'luxon'
import { CasesPerPage } from 'case/constants';
import isEmpty from 'lodash.isempty';

export const CaseList = ({baseFilter}) => {
  const account = useCurrentAccount();
  const [resetPager, setResetPager] = useState(false);
  const [cases, setCases] = useState([]);
  const [activeStatusFilters, setActiveStatusFilters] = useState([]);
  const [activeTagFilters, setActiveTagFilters] = useState([]);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [tagsQueryFilter, setTagsQueryFilter] = useState({ tenant: account.tenant.id });

  const { loading, data, fetchMore, refetch } = useQueryWrapper(GET_CASES, {
    variables: { filter: baseFilter, offset: 0 },
    notifyOnNetworkStatusChange: true
  });

  const handlePageChange = (page) => {
    const offset = ((page - 1) * CasesPerPage);
    fetchMore({variables: { offset: offset }});
  };

  const toggleFilterCollection = (filterItem, filterCollection, setFilterCollection) => {
    let currentFilterCollection = [...filterCollection];
    let itemToAddIndex = currentFilterCollection.indexOf(filterItem);
    if (itemToAddIndex >= 0) {
      currentFilterCollection.splice(itemToAddIndex, 1);
    } else {
      currentFilterCollection.push(filterItem);
    }

    setFilterCollection(currentFilterCollection);
    setShouldRefetch(true);
  };

  const toggleStatusFilter = (status) => toggleFilterCollection(status, activeStatusFilters, setActiveStatusFilters);
  const toggleTagFilter = (tag) => toggleFilterCollection(tag, activeTagFilters, setActiveTagFilters);

  useEffect(() => {
    if (!shouldRefetch) return;

    let vars = {};

    if (activeTagFilters.length > 0) {
      vars = {...vars, tags: {in: activeTagFilters}};
    } 

    if (activeStatusFilters.length > 0) {
      vars = {...vars, status: {in: activeStatusFilters}};
    } 

    setTagsQueryFilter({...tagsQueryFilter, statuses: !isEmpty(activeStatusFilters) ? activeStatusFilters : null});

    refetch({ filter: {...baseFilter, ...vars}});
    setResetPager(true);
    setShouldRefetch(false);
  }, [activeStatusFilters, activeTagFilters, refetch, shouldRefetch, setShouldRefetch, baseFilter, tagsQueryFilter]);

  useEffect(() => {
    if (!loading && data) {
      setResetPager(false);
      setCases(data.findCases.items);
      setNumberOfItems(data.findCases.count);
    }
  }, [data, loading, setNumberOfItems, setCases]);

  useEffect(() => {
    if (baseFilter && baseFilter.consumer?.id?.eq){
      setTagsQueryFilter({...tagsQueryFilter, ...activeTagFilters, consumers: [baseFilter.consumer.id.eq]});
    }
  }, [baseFilter, activeTagFilters, tagsQueryFilter]);

  return (
    <Row>
      <Col lg={3}>
        <FilterCases baseFilter={tagsQueryFilter} toggleStatusFilter={toggleStatusFilter} toggleTagFilter={toggleTagFilter} />
      </Col>
      <Col lg={8} className={styles.casesResults}>
        <ItemList 
          data={cases} 
          detailLink={conciergePaths.conciergeCases} 
          headerRowElement={CaseHeaderRow}
          dataRowElement={CaseDataRow}
          handlePageChange={handlePageChange}
          itemsPerPage={CasesPerPage}
          numberOfItems={numberOfItems}
          loading={loading}
          resetPager={resetPager}
        />
      </Col>
    </Row>
  )
};

const FilterCases = ({baseFilter={}, toggleStatusFilter, toggleTagFilter}) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const statusFilters = [
    {label: 'New', value: 'NEW'},
    {label: 'In Progress', value: 'INPROGRESS'},
    {label: 'Resolved', value: 'RESOLVED'}
  ];

  useQueryWrapper(GET_CASE_TAGS, {
    variables: baseFilter,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTags(data.case.getCaseTags);
      selectedTags.forEach(x => {
        if (!data.case.getCaseTags.find(y => y.tag === x)) {
          selectTagFilter(x);
        }
      });
      
    }
  });

  const selectTagFilter = (tag) => {
    const tags = [...selectedTags];
    const index = tags.indexOf(tag);
    if (index >= 0) {
      tags.splice(index, 1);
    } else {
      tags.push(tag);
    }

    setSelectedTags(tags);
    toggleTagFilter(tag);
  };

  return (
    <div className={styles.filterContainer}>
      <SectionHeader>
        <h2 className={styles.centerText}>Filters</h2>
      </SectionHeader>
      <Section>
        <Col lg={3}>
          Status
        </Col>
        <Col lg={9}>
          {statusFilters?.map((item, index) => (
            <Row className={styles.filterRow} key={index}>
              <Form.Check 
                className={styles.filterCheckBox} 
                aria-label={item.value} 
                type='checkbox' 
                onChange={() => toggleStatusFilter(item.value)}
              />
              <Form.Label>{item.label}</Form.Label>
            </Row>
          ))}
        </Col>
        <hr />
        <Col lg={3}>
          Tags
        </Col>
        <Col lg={9}>
          {tags?.map((item, index) => (
            <Row className={styles.filterRow} key={index}>
              <Form.Check 
                className={styles.filterCheckBox}
                aria-label={item.tag}
                type='checkbox'
                checked={selectedTags.includes(item.tag)}
                onChange={() => selectTagFilter(item.tag)}
              />
              <Form.Label>{`${item.tag}`} <em>{`(${item.count})`}</em></Form.Label>
            </Row>
          ))}
        </Col>
      </Section>
    </div>
  )
}

const CaseHeaderRow = () => {
  return (
    <Row className={styles.centerText}>
      <Col lg={2}>Date</Col>
      <Col lg={1}>Status</Col>
      <Col lg={6}>Title</Col>
      <Col lg={3}>Tags</Col>
    </Row>
  );
}

const CaseDataRow = ({item, index}) => {
  return (
    <Link to={`${conciergePaths.conciergeCases}/${item.id}`} className={styles.plainTextLink} key={index}>
      <Row className={styles.centerText}>
        <Col lg={2} aria-label={`case.${index}.date`}>{DateTime.fromMillis(item.createdAt).toFormat('MM/dd/yyyy')}</Col>
        <Col lg={1} aria-label={`case.${index}.status`}>{item.status}</Col>
        <Col lg={6} aria-label={`case.${index}.title`}>{item.title}</Col>
        <Col lg={3} aria-label={`case.${index}.tags`}>{item.tags?.join(', ')}</Col>
      </Row>
      <hr />
    </Link>
  );
};