import { gql } from '@apollo/client';

export const LOAD_CURRENT_ACCOUNT = gql`
  query LoadCurrentAccount( $accountId: ID!) {
    getAccount(id: $accountId) {
      id
      email
      isAdmin
      roles {
        name
        permissions
      }
      allowedTenants {
        id
      }
      tenant {
        id
        name
      }
    }
  }
`;

export const CURRENT_ACCOUNT = gql`
  query GetCurrentAccount {
    currentAccount @client
  }
`;