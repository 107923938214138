import { useState } from 'react';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { Button as Link, Col, Container, Row } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import styles from './message.module.css';
import './react-draft-wysiwyg.css'

export const MessageComposer = ({onSend, onCancel, customToolbarActions, submitText}) => {
  submitText = submitText ?? 'Send';
  const [editorState, setEditorState] = useState();
  const [hasContent, toggleHasContent] = useState(false);

  const toolbar = {
    options: ['inline', 'list', 'textAlign', 'remove', 'history'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough' ]
    },
    textAlign: {
      options: ['left', 'center', 'right']
    }
  };

  const handleSend = () => {
    const raw = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onSend(raw);
  };

  // enable / disable Send button
  const onContentChanged = (content) => {
    if (content.blocks.length === 1 && content.blocks[0].text === '')  {
      toggleHasContent(false);
    } else {
      toggleHasContent(true);
    }
  }

  return (
    <Container className={styles.composercontainer}>
      <Row>
        <Col>
          <Editor
            toolbar={toolbar}
            wrapperClassName={styles.composerwrapper}
            toolbarStyle={{ border: "solid 1px #dbdbdb" }}  // toolBarClassName didn't seem to work
            onEditorStateChange={setEditorState}
            onContentStateChange={onContentChanged}
            toolbarCustomButtons={customToolbarActions?.map(item => 
              <div className={styles.customToolbarAction}><item.Component props={item.props} /></div>)
            }
          />
        </Col>
      </Row>
      <Row className={`justify-content-end ${styles.sendrow}`}>
        <Col xs="auto">
          <div style={{display: 'inline-block'}}>
            <Link variant="link" onClick={onCancel}>Cancel</Link>
          </div>
          <div style={{display: 'inline-block'}}>
            <Button text={submitText} toolTip={submitText} onClick={handleSend} disabled={!hasContent} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};