import { Col, Container, Row } from "react-bootstrap";
import { PlanLogoSmall } from "benefits/shared/planLogoSmall";
import styles from './header.module.css';

export const ConsumerHeader = ({ consumer, enrollment }) => {

  return (
    <Container className={styles.header} fluid>
      <Row className="justify-content-center">
        <Col className={`text-center my-auto ${styles.salutation}`}>
          <h1 className="inverse">Hi, {consumer?.contact?.name?.first}.</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className={`text-center my-auto ${styles.plan}`}>
          YOUR PLAN: <PlanLogoSmall variant="white" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} className={`text-center ${styles.intro}`}>
          Welcome to your benefit home page where carefully selected benefits have been chosen for you. Everything you need is managed right here, and if you ever need assistance just reach out to your Care Concierge.
        </Col>
      </Row>
    </Container>
  );
};