import { TeaserCard } from "enrollment/components/teaserCard";
import { DPCIcon } from "./icon";

export const DPCTeaser = () => {

  return (
    <TeaserCard
        title="Primary Care"
        icon={<DPCIcon />}
    >
      Included visits with your Primary Care Doctor every year 
    </TeaserCard>
  );
};