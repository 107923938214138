import 'shared/fontello/css/fontello.css';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useAmenities } from 'careCenter/hooks';

export const EditCareCenterAmenities = ({registerPrefix}) => {
  const { amenities } = useAmenities();
  const { getValues, setValue } = useFormContext();
  const registerInput = `${registerPrefix}.amenities`;
  let selectedAmenityIds = [];
  useFieldArray({name: registerInput})

  const selectedAmenities = getValues(registerInput);
  if (selectedAmenities) {
    selectedAmenityIds = selectedAmenities.map(x => x.id);
  }

  const onAmenityToggled = (e) => {
    const amenityId = e.target.id;
    const checked = e.target.checked;
    let currentAmenities = getValues(registerInput);
    if (checked) {
      currentAmenities.push({ id: amenityId});
    } else {
      currentAmenities = currentAmenities.filter(x => x.id !== amenityId);
    }

    setValue(registerInput, currentAmenities, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false
    });
  };

  if (amenities) {

    return (
      <Row>
        {amenities.map(amenity => (
          <Col sm={4} key={amenity.id}>
            <Form.Check 
              type="checkbox"
              name="amenities"
              id={amenity.id}
            >
              <Form.Check.Input 
                type="checkbox"
                onChange={onAmenityToggled} 
                id={amenity.id}
                checked={selectedAmenityIds.includes(amenity.id)}
              />
              <Form.Check.Label>
                <i className={amenity.iconClass} />{amenity.name}
              </Form.Check.Label>
            </Form.Check>
          </Col>
        ))}
      </Row>
    );
  }

  return (<h1>Loading...</h1>)
};