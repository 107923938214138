import cloneDeep from 'lodash.clonedeep';
/*
  common utilities for dealing with apollo idiosyncrasies
*/

export const createMutableCopy = (obj) => {
  const sanitized = cloneDeep(obj);
  deleteTypeName(sanitized);

  return sanitized;
};

const deleteTypeName = (obj) => {
  if (obj && obj.__typename) delete obj.__typename;

  for (const prop in obj) {
    if (typeof obj[prop] === 'object' && obj[prop]){
      deleteTypeName(obj[prop]);
    }
  }
};