import { Container, Col, Row } from 'react-bootstrap';
import { Appointments } from './appointments';
import { DoctorsOrders } from 'consumer/components/todo/doctorsOrders';
import { AppointmentsLoader } from 'consumer/components/todo/loaders/appointmentsLoader';
import { OrdersLoader } from 'consumer/components/todo/loaders/ordersLoader.jsx';
import { useState } from 'react';
import { NoTodos } from './noTodos';
import { BePrepared } from './bePrepared';
import styles from 'consumer/components/todo/todo.module.css';

export const Todo = ({consumer}) => {
  const [appointments, setAppointments] = useState([]);
  const [orders, setOrders] = useState([]);

  const onAppointmentsLoaded = (appointments) => setAppointments(appointments);
  const onOrdersLoaded = (orders) => setOrders(orders);

  const backupCards = [
    <NoTodos />,
    <BePrepared />
  ];

  let activeCards = [];

  if (appointments.length > 0) {
    activeCards.push(<Appointments appointments={appointments} />);
  }

  if (orders.length > 0) {
    activeCards.push(<DoctorsOrders orders={orders} />);
  }

  if (activeCards.length < 3) {
    if (activeCards.length === 0) {
      activeCards = backupCards;
    } else {
      activeCards.push(backupCards[1]);
    }
  }

  return (
    <>
      <AppointmentsLoader consumerId={consumer.id} onAppointmentsLoaded={onAppointmentsLoaded} />
      <OrdersLoader consumerId={consumer.id} onOrdersLoaded={onOrdersLoaded} />
      <Container className={styles.todocontainer}>
        <Row>
          <Col className={styles.sectionHeader}>
            <h2 className="inverse">To-Do</h2>
          </Col>
        </Row>
        <Row>
        { activeCards.map((card, index) => (
          <Col key={`todo-card-${index}`} md={4}>
            {card}
          </Col>
        ))}
        </Row>
      </Container>
    </>
  );
};
