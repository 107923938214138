import { Col, Row } from 'react-bootstrap';
import { BoxLoading } from "shared/components/spinners/boxLoading";
import styles from 'benefits/indemnity/indemnity.module.css';

export const FindLoading = () => {
  return (
  <Row className="justify-content-center">
    <Col lg={10} xs={10} className={`justify-content-center ms-auto ${styles.loading}`}>
      {[...Array(3).keys()].map(x => (
        <BoxLoading key={x} height={240} width={600} viewBox="-40,0,1000,280" />
      ))}
    </Col>
    </Row>
  );
};