import { Button, Col, Row } from 'react-bootstrap';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import buttonStyles from 'shared/components/buttons/button.module.css';
import { FplIcon } from './icon';

export const YouGetFpl = () => {
  return (
    <YouGetCard
      title="Full Plate Living"
      icon={<FplIcon />}
      copy='Let food be thy medicine. We have science backed programs for weight loss, diabetics, and great nutrition - including menus and recipes from the New York Times best sellers:  "The Full Plate Diet" and "Fiber Guide".'
    >
      <Row style={{ 'position': 'relative', 'bottom': -5 }}>
        <Col className="d-flex justify-content-center">
          <Button 
            className={`${buttonStyles.button} ${buttonStyles.buttonSecondary}`}
            variant="secondary"
            type="button"
            href="https://www.fullplateliving.org/pl/2147554189"
            target="_blank"
          >
            Fill My Plate!
          </Button>
        </Col>
      </Row>
    </YouGetCard>
  );
};