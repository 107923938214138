import { useQuery } from '@apollo/client';
import { BICCToken } from './biccToken';
import { currentAccountVar, currentAppModeVar } from 'apollo/cache';
import { LOAD_CURRENT_ACCOUNT } from './operations/queries/getCurrentAccount';
import { useLocalStorage, localStorageKeys } from 'shared/hooks/useLocalStorage';
import { attachPermissionHelpers } from './permissionHelper';

export const LoadUserAccount = (props) => {
  const { loading, error, data } = useQuery(LOAD_CURRENT_ACCOUNT, {
    variables: { accountId: BICCToken.object.id },
    fetchPolicy: 'no-cache'
  });
  const [savedAppMode, persistAppMode] = useLocalStorage(localStorageKeys.appMode);
  const [savedTenant, ] = useLocalStorage(localStorageKeys.tenant);

  const setDefaultAppMode = (account) => {
    if ((account.isAdmin && savedAppMode === 'Admin') || savedAppMode === 'Consumer') {
      currentAppModeVar(savedAppMode);
    } else {
      const appMode = account.isAdmin ? 'Admin' : 'Consumer';
      currentAppModeVar(appMode);
      persistAppMode(appMode);
    }
  };

  const updateTenant = (account) => {
    if (account.isAdmin && savedTenant) account.tenant = savedTenant;
  };

  if (loading)
    return 'Loading...';
  if (error)
    return error;

  const account = { ...data.getAccount };
  setDefaultAppMode(account);
  attachPermissionHelpers(account);
  updateTenant(account);
  currentAccountVar(account);

  return (
    <>{props.children}</>
  );
};