import { gql } from '@apollo/client';
import { CASE_FIELDS } from 'case/operations/fragments';

export const GET_CASES = gql`
  ${CASE_FIELDS}
  query GetCases($filter: CaseFilter) {
    findCases(filter: $filter) {
      items {
        ...CaseFields
      }
      count
    }
  }
`;
