import { gql } from '@apollo/client';
import { ENROLLMENT_FIELDS } from '../fragments';

export const GET_ENROLLMENT_FOR_CONSUMER = gql`
  ${ENROLLMENT_FIELDS}
  query GetConsumerEnrollment($consumerId: ID!) {
    findEnrollments(
      filter: { consumer: { id: { eq: $consumerId }}}, 
      page: { limit: 1, offset: 0 }) {
        items {
          ...EnrollmentFields 
        }
    }
  }
`;