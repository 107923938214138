import { MessageCenter } from "message/components/messageCenter";

export const NetworkMessages = ({network}) => {
  const correspondent = {
    type: 'NETWORK',
    id: network.id,
    name: network.name
  }
  
  return (
    <MessageCenter 
      correspondent={correspondent}
    />
  );
};