import { gql } from '@apollo/client';

export const CREATE_TAG = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      tag
      isRoutable
    }
  }
`;