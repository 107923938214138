import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Col } from 'react-bootstrap';
import { Input } from 'shared/components/form';
import { AddressLookup } from "./addressLookup";
import { getAddressDisplay } from './displayAddress';
// import { Col, Button, InputGroup } from 'react-bootstrap';
// import { CancelButton, CheckButton, DeleteButton, EditButton, IconSpacer } from 'shared/components/buttons';
// import { DisplayAddressInForm } from './displayAddress';

export const EditSingleAddress = ({ 
    registerPrefix, 
    allowCancelOnNew, 
    onCancel,
    showDelete,
    onDelete,
    autoFocus=true,
    onAddressSelected,
    errors
}) => {
  const autocompleteRef = useRef(null);
  const { getValues, setValue, register } = useFormContext();
  const [isEdit, setIsEdit] = useState(false);
  /*
  const [canEdit, toggleCanEdit] = useState(true);
  const [canCancel, setCanCancel] = useState(allowCancelOnNew);
  */

  register(`${registerPrefix}.type`);
  register(`${registerPrefix}.line1`);
  register(`${registerPrefix}.line2`);
  register(`${registerPrefix}.city`);
  register(`${registerPrefix}.state`);
  register(`${registerPrefix}.postalCode`);
  register(`${registerPrefix}.countryCode`);

  const getDisplay = useCallback(() => {
    var address = getValues(registerPrefix);
    return getAddressDisplay(address);
  }, [getValues, registerPrefix]);

  // set some defaults on load
  // show edit on new address
  useEffect(() => {
    var line1 = getValues(`${registerPrefix}.line1`);

    if (line1) {
      autocompleteRef.current.value = getDisplay();
      // setCanCancel(true);
    } else {
      setIsEdit(true);
      // setCanCancel(allowCancelOnNew); 
    }
  }, [getValues, registerPrefix, allowCancelOnNew, getDisplay]);

  // focus on input
  useEffect(() => {
    if (!autoFocus) return;

    window.setTimeout(() => {
      if (!autocompleteRef.current) return;
      if (isEdit && document.activeElement !== autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }, 0)
  }, [isEdit, autoFocus]);

  const addressSelected = (place) => {
    let line1 = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      
      switch (componentType) {
        case "street_number": 
          line1 += component.short_name + ' ';
          break;
        case "route": 
          line1 += component.short_name;
          break;
        case "postal_code": 
          setValue(`${registerPrefix}.postalCode`, component.short_name);
          break;
        case "locality":
          setValue(`${registerPrefix}.city`, component.short_name);
          break;
        case "administrative_area_level_1": 
          setValue(`${registerPrefix}.state`, component.short_name);
          break;
        case "country":
          setValue(`${registerPrefix}.countryCode`, component.short_name);
          break;
        default:
          break;
      }
    }

    setValue(`${registerPrefix}.line1`, line1);
    setValue(`${registerPrefix}.geometry`, { type: 'Point', coordinates: [place.geometry.location.lng(), place.geometry.location.lat()] });
    setValue(`${registerPrefix}.type`, ['MAILING', 'BILLING', 'PHYSICAL']);
    setIsEdit(false);
    autocompleteRef.current.value = getDisplay();
    if (onAddressSelected) onAddressSelected();
  };

  /*
  const handleCancel = () => {
    setIsEdit(false);
    if (onCancel) onCancel();
  };

  const handleDelete = () => {
    if (onDelete) onDelete();
  }
  */

  const onLine2KeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleAddressFocus = (e) => {
    setIsEdit(true);
    e.target.value = null;
  };
  const handleAddressBlur = (e) => {
    setIsEdit(false);
    e.target.value = getDisplay();
  };

  return (
    <>
      <Col lg={5} className="my-auto">
        <AddressLookup 
          inputRef={autocompleteRef} 
          style={{width: '100%', 'height': 'calc(3.5rem + 2px)'}} 
          callback={addressSelected} 
          onFocus={handleAddressFocus}
          onBlur={handleAddressBlur}
          readOnly={!isEdit}
          errorRegister={`${registerPrefix}.line1`}
          errors={errors}
        />
      </Col>
      <Col lg={2}>
              <Input 
                tabIndex={-1}
                autoFocus={false}
                registerInput={`${registerPrefix}.line2`} 
                label="Apt #, Floor, etc." 
                onKeyDown={onLine2KeyDown}
              />
      </Col>
      {/* temporarily commented out until we bring back multiple addresses */}
      {/* <Col lg={2} className="my-auto">
        <>
        { isEdit
          ? canCancel && <CancelButton tooltip="Cancel" onClick={handleCancel} />
          : canEdit && <EditButton tooltip="Edit" onClick={() => { setIsEdit(true); }} />
        }
        {showDelete && 
          <>
          <IconSpacer />
          <DeleteButton tooltip="Delete" onClick={handleDelete} />
          </>
        }
        </>
      </Col> */}
    </>
  );
};