import { AddButton, CancelButton } from "shared/components/buttons";
import { Button } from 'shared/components/buttons/button';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect, useReducer, useState } from "react";
import { FormProvider, Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextArea } from "shared/components/form";
import { Input } from "shared/components/form/input";
import { TagsReducer, TagRemoved, TagAdded, RefreshTags } from "./activity/tagsReducer";
import { Tag } from "./tag";
import styles from 'case/case.module.css';
import { TagSelector } from "./tagSelector";
import { useMutationWrapper } from "apollo/useMutationWrapper";
import { useAllTags } from "admin/operations/useAllTags";
import { CREATE_CASE } from "case/operations/mutations";
import { CREATE_NOTE } from "case/operations/mutations";
import { useCurrentConsumer } from "consumer/hooks/useCurrentConsumer";
import { DateTime } from "luxon";
import * as Yup from 'yup';

export const AddCase = ({consumer}) => {
  const { tags } = useAllTags();
  const { consumer: adminConsumer } = useCurrentConsumer();
  const [showModal, toggleModal] = useState(false);
  const [addingTag, setAddingTag] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [state, dispatch] = useReducer(TagsReducer, {tags: []});

  useEffect(() => {
    if (!tags) return;
    setAvailableTags(tags);
  }, [tags]);

  const [createCase] = useMutationWrapper(CREATE_CASE, {
    awaitRefetchQueries: true,
    refetchQueries: ['GetCasesPaginated'],
  });

  const [createNote] = useMutationWrapper(CREATE_NOTE);

  const validationSchema = Yup.object({
    title: Yup.string().required('Please enter a title'),
    initialNote: Yup.string().required('Please enter an initial note to describe why this case is being created')
  });

  const formMethods = useForm({
    defaultValues: {
      title: '',
      initialNote: ''
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    const caseResponse = await createCase({
      variables: { 
        input: {
          status: 'NEW',
          title: data.title,
          consumer: {id: consumer.id},
          tenant: {id: consumer.tenant.id},
          context: {},
          tags: state.tags
        }
      }
    });

    if (data.initialNote) {
      await createNote({
        variables: { 
          input: {
            text: data.initialNote,
            sender: `${adminConsumer.contact.name.first} ${adminConsumer.contact.name.last}`,
            sentAt: DateTime.now().toMillis(),
            tags: state.tags,
            case: {id: caseResponse.data.createCase.id}
          }
        }
      });
    }

    toggleModal(false);
  };

  const onTagAdded = (tag) => {
    TagAdded(dispatch, tag);
    setAddingTag(false);
  };

  const resetForm = formMethods.reset;
  useEffect(() => {
    if (showModal) {
      resetForm();
      RefreshTags(dispatch);
      setAddingTag(false);
    }

  }, [showModal, resetForm])

  return (
    <div style={{paddingTop: '33px', paddingLeft: '20px'}}>
      <AddButton tooltip='Add new Case' onClick={() => toggleModal(true)}/>
      <Modal
          show={showModal}
          onHide={() => toggleModal(false)}
          aria-labelledby="help-modal"
          centered
          size='lg'
        >
        <Modal.Body id="help-modal">
          <Container>
            <FormProvider {...formMethods}>
              <form name="addCase" onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Row className="justify-content-end">
                  <Col xs={10}>
                    <h4>Add new case for {consumer.contact.name.first} {consumer.contact.name.last}</h4>
                  </Col>
                  <Col className="text-end">
                    <AiOutlineClose size={25} className={styles.closeicon} onClick={() => toggleModal(false)} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input label='Title' registerInput='title' errors={formMethods.formState.errors} />
                  </Col>
                </Row>
                <Row className={styles.addCaseInitialNote}>
                  <Col>
                    <h5>Initial Note</h5>
                    <Controller
                      name="controller"
                      control={formMethods.control}
                      render={() => (
                        <div data-testid='initialNote'>
                          <TextArea 
                            registerInput='initialNote' 
                            errors={formMethods.formState.errors}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
                <Row className={styles.addCaseTags}>
                  <h5>Tags</h5>
                  {state.tags.map((item, index) => (
                    <Tag key={item} tag={item} allTags={[]} onRemove={() => TagRemoved(dispatch, item)} />
                  ))}
                  {!addingTag && <AddButton tooltip='Add tag' onClick={() => setAddingTag(true)} /> }
                  { addingTag && 
                    <Row>
                      <Col>
                        <TagSelector 
                          onAdd={onTagAdded} 
                          allTags={availableTags} 
                          currentTags={state.tags} 
                        />
                      </Col>
                      <Col>
                        <CancelButton onClick={() => setAddingTag(false)}/>
                      </Col>
                    </Row>
                  }
                </Row>
                <Row className={styles.addCaseSubmit}>
                  <Button type="submit" variant="primary" text="Create" />
                </Row>
              </form>
            </FormProvider>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
};