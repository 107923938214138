import { Col, Row } from 'react-bootstrap';
import { MessageComposer } from 'message/components/messageComposer';
import { TagsAction } from "./actions/tagsAction";
import { Tag } from "../tag";
import { TagRemoved } from './tagsReducer';
import { CREATE_MESSAGE, CREATE_MESSAGE_RECIPIENT } from "message/operations";
import { CREATE_NOTE } from 'case/operations/mutations';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { DateTime } from 'luxon'
import { FaRegStickyNote, FaRegEnvelope } from 'react-icons/fa';
import { useCurrentConsumer } from 'consumer/hooks/useCurrentConsumer';
import { BoxLoading } from "shared/components/spinners/boxLoading";
import {createMutableCopy} from 'apollo/utils';
import styles from 'case/case.module.css';

export const ContentEditor = ({caseData, contentType, replyToMessage, onCompleted, tags, dispatch}) => {
  const { consumer } = useCurrentConsumer();
  const [sentMessage, setSentMessage] = useState(null);
  const [isSending, setSending] = useState(false);
  const correspondent = {
    type: 'TENANT',
    id: caseData.consumer.tenant.id,
    name: 'Care Concierge'
  }

  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted: (data) => { 
      onCompleted(data.createNote)
      setSending(false);
    }
  });

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    onCompleted: (data) => {
      setSentMessage({...data.createMessage, replies: []})
      const recipient = {
        recipient: {
          type: 'ACCOUNT',
          id: caseData.consumer.account.id,
          name: `${caseData.consumer.contact.name.first} ${caseData.consumer.contact.name.last}`
        },
        tenant: {id: caseData.consumer.tenant.id},
        status: 'UNREAD',
        sentAt: DateTime.now().toMillis(),
        message: { id: data.createMessage.id }
      };
      createRecipient({ variables: { input: recipient }});
    }
  });

  const [createRecipient] = useMutation(CREATE_MESSAGE_RECIPIENT, {
    onCompleted: () => {
      onCompleted(sentMessage);
      setSending(false);
    }
  });

  const closeEditor = (replyContent) => {
    if (replyContent) {
      setSending(true);
      if (contentType === 'Message') {
        let completedReply = {
          text: replyContent,
          subject: caseData.title,
          sentAt: DateTime.now().toMillis(),
          tags: tags,
          sender: correspondent,
          tenant: { id: caseData.consumer.tenant.id },
          case: { id: caseData.id },
        };

        if (replyToMessage) {
          completedReply.text = `<div className='${styles.quotedText}'>${replyToMessage.text}</div><div>${completedReply.text}</div>`;
          completedReply.subject = replyToMessage.subject.includes('Re:') ? replyToMessage.subject : `Re: ${replyToMessage.subject}`;
          completedReply.parent = { id: replyToMessage.parent?.id ?? replyToMessage.id };
          completedReply.replyTo = { id: replyToMessage.id };
          completedReply.sentTo = [ createMutableCopy(replyToMessage.sender) ]
        }

        createMessage({variables: {input: completedReply}}); 
      } else {
        createNote({
          variables: { 
            input: {
              text: replyContent,
              tenant: {id: caseData.consumer.tenant.id},
              sender: `${consumer.contact.name.first} ${consumer.contact.name.last}`,
              sentAt: DateTime.now().toMillis(),
              tags: tags,
              case: {id: caseData.id}
            }
          }
        });
      }
    } else {
      onCompleted();
    }
  }

  return (
    <>
    <Row className={styles.messageComposerTagsList}>
      { !replyToMessage &&
      <Col lg={1} className={styles.messageComposerIconWrapper}>
        { contentType === 'Note'
        ? <FaRegStickyNote size={25} className={styles.noteIcon} />
        : <FaRegEnvelope size={25} className={styles.messageIcon} />
        }
      </Col>
      }
      <Col>
      { tags.map((item, index) => (
        <Tag key={item} tag={item} allTags={[]} onRemove={() => TagRemoved(dispatch, item)} />
      ))}
      </Col>
    </Row>
    <Row>
      <Col className={styles.messageComposer}>
      { isSending
      ? <BoxLoading height={200} width={1000} viewBox="0,0,1000,220" />
      : <MessageComposer
          onCancel={() => closeEditor()}
          onSend={(content) => closeEditor(content)}
          customToolbarActions={[{Component: TagsAction, props: {content: {tags: tags}, dispatch: dispatch}}]}
          submitText={contentType === 'Note' ? 'Add' : 'Send'}
        />
      }
      </Col>
    </Row>
    </>
  );
}