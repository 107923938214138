import { gql } from '@apollo/client';

export const GET_MESSAGES_FOR_CASE = gql`
  query FindMessages($caseId: ID!) {
    findMessages(filter: {
      case: {
        id: {
          eq: $caseId
        }
      }
    }) {
      items {
        id
        text
        sentAt
        case {
          id
        }
        sender {
          type
          id
          name
        }
        parent {
          id
        }
        replyTo {
          id
        }
        subject
        sentTo {
          type
          id
          name
        }
        tags
        createdBy
      }
    }
  }
`;
