import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import styles from "shared/components/survey/survey.module.css";

export const Question = ({question, previouslySelectedAnswer, onAnswerSelected}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(-1);

  useEffect(() => {
    setSelectedAnswer(-1);
  }, [question]);

  useEffect(() => {
    if (previouslySelectedAnswer) {
      let answer = question.options.find(x => x.value === previouslySelectedAnswer.value);
      let index = question.options.indexOf(answer);
      if (index >= 0) {
        setSelectedAnswer(index);
      }
    }
  }, [previouslySelectedAnswer, question.options])

  return (
    <div>
      <Row>
        <span><b>{question.title}</b></span>
      </Row>
      <Row>
        <span>{question.text}</span>
      </Row>
      <Row className={styles.surveyQuestionAnswersContainer}>
        <div className={styles.leftAligned}>
        {
          question.options.map((item, index) => (
            <Row key={index}>
              <Col>
              <input
                id={index}
                type="radio"
                name={`questionOption${index}`}
                checked={index === selectedAnswer}
                onChange={() => { setSelectedAnswer(index); onAnswerSelected(item); }}
              />
              <label className={`form-check-label ${styles.surveyAnswerLabel}`} htmlFor={index}>{item.text}</label>
              </Col>
            </Row>
          ))
        }
        </div>
      </Row>
    </div>
  );
};
