import { Col, Row } from 'react-bootstrap';
import { FindHospitalCard } from './findHospitalCard';

export const FindHospitalResults = ({results, optionalComponent}) => (
  <>
    {results.map(result => (
      <Row key={result.careCenter.id} className="justify-content-center">
        <Col lg={10} xs="auto" className="justify-content-center">
          <FindHospitalCard careCenterResult={result} OptionalComponent={optionalComponent} />
        </Col>
      </Row>
    ))}
  </>
);