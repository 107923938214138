import {createMutableCopy} from 'apollo/utils';
import { getCoverImageUrl } from './getCoverImageUrl';

export const prepareCareCenterForForm = (careCenter) => {
  const copy = createMutableCopy(careCenter);
  copy.financialAssistancePolicies = copy.financialAssistancePolicies || [];
  copy.media = copy.media ?? [];
  copy.getCoverImageUrl = getCoverImageUrl.bind(copy);

  return copy;
};

export const prepareCareCenterForSave = (careCenter) => {

  prepareMediaforSave(careCenter);
  temporarilyAssignDefaultNetworks(careCenter);

  return careCenter;
};

const temporarilyAssignDefaultNetworks = (careCenter) => {
  if (careCenter.networks?.length > 0) return;

  //we are temporarily assigning all careCenters to the only 2 networks
  careCenter.networks = [
    { id: '00000000-0000-0000-0000-000000000000' },
    { id: '00000000-0000-0000-0000-000000000001' }
  ];
};

const prepareMediaforSave = (careCenter) => {
  if (!careCenter.media) return;

  careCenter.media.forEach(media => {
    media.isCoverImage = isTrue(media.isCoverImage);
    media.isVideo = isTrue(media.isVideo);
  });
};

const isTrue = (val) => {
  return val === 'true' || val === true;
};