import { GET_LYRIC_RULES_FOR_STATE } from 'integrations/lyric/operations/queries/getStateRulesForMember';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { useEffect, useState } from 'react';
import { SimpleDropDown } from 'shared/components/form';
import isEmpty from 'lodash.isempty';

export const LyricModalitiesSelector = ({ consumer, state, onSelected }) => {
  const [modalities, setModalities] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  useQueryWrapper(GET_LYRIC_RULES_FOR_STATE, {
    skip: !state,
    variables: { consumerId: consumer.id, state: state }, 
    onCompleted: (data) => setModalities([...data.lyric.getStateRulesForMember])
   }
  );

  useEffect(() => {
    if (!isInitialized && !isEmpty(modalities)) {
      onSelected({target: { value: modalities[0]}});
      setIsInitialized(true);
    }
  }, [isInitialized, modalities, onSelected])

  return (
    <SimpleDropDown
      label="Type of telehealth appointment"
      items={modalities}
      onSelected={onSelected}
      removeEmptyOption={true}
      disabled={modalities.length === 1}
    />
  );
};



