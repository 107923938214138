import { useEffect, useReducer } from "react";
import { useErrorHandler } from 'react-error-boundary';
import { useQueryWrapper } from "apollo/useQueryWrapper";
import { useCurrentAccount } from "auth/operations/useCurrentAccount";
import { GET_ALL_TAGS } from 'admin/operations/queries/getAllTags';

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading': 
      return {...state, loading: true};
    case 'loaded':
      return { ...state, loading: false, tags: action.payload };
    default:
      throw new Error('invalid action');
  }
};

export const useAllTags = () => {
  const account = useCurrentAccount();
  const [state, dispatch] = useReducer(reducer, {});
  const { loading, data, error } = useQueryWrapper(GET_ALL_TAGS, { 
    variables: { tenantId: account.tenant.id } 
  });

  useErrorHandler(error);

  useEffect(() => {
    if (loading === true) dispatch({ type: 'loading' });
  }, [loading]);

  useEffect(() => {
    if (!data) return;

    dispatch({ type: 'loaded', payload: data.findTags.items });
  }, [data]);

  return state;
};