import { Col } from 'react-bootstrap';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import { FaExclamationCircle } from 'react-icons/fa';
import parse from 'html-react-parser';

export const Disclaimer = ({title, body}) => {
  title = title ?? "Disclaimer"; 

  return (      
    <>
      <Col xs={12} className={styles.disclaimerIcon}>
        <FaExclamationCircle size={20}/>
      </Col>
      <Col xs={12} className={styles.disclaimerHeader}>
        <h5>{title}</h5>
      </Col>
      <Col xs={12} className={styles.disclaimerText}>
        <span>{parse(body)}</span>
      </Col>
    </>
  )
}