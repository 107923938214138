import { useMutation } from "@apollo/client";
import DropdownList from "react-widgets/DropdownList";
import { toastError } from "shared/components/toasts";
import { useCurrentAccount } from "auth/operations/useCurrentAccount";
import { CREATE_TAG } from 'admin/operations/mutations/createTag';

export const TagSelector = ({currentTags, allTags, onAdd}) => {
  const account = useCurrentAccount();

  const [createTag] = useMutation(CREATE_TAG, { 
    awaitRefetchQueries: true,
    refetchQueries: ['GetAllTags'],
    onCompleted: (data) => {
      onAdd(data.createTag.tag);
    }
  });


  const tags = allTags && allTags
    .filter(x => !x.isRoutable && !currentTags.includes(x.tag) )
    .map(x => x.tag);

  const handleTagSelected = (tag) => {
    onAdd(tag);
  };

  const handleTagCreated = (tag) => {
    if (!tag || tag.trim().length === 0) {
      toastError('Tag cannot be empty');
      return;
    }

    const existingTag = allTags.find(x => x.tag === tag);
    if (existingTag) {
      toastError(existingTag.isRoutable ? 'New tag matches existing system tag' : 'Tag already exists on Case');
      return;
    }

    createTag({ variables: { input: { 
      tag: tag, 
      isRoutable: false,
      tenant: { id: account.tenant.id }
    }}});
  };

  return (
    <DropdownList
      data={tags}
      autoComplete="on"
      allowCreate={true}
      filter="contains"
      placeholder="# Add a tag"
      onSelect={handleTagSelected}
      onCreate={handleTagCreated}
    />
  );
};