import {createMutableCopy} from 'apollo/utils';

export const prepareAccountForForm = (account) => {
  const copy = createMutableCopy(account);
  copy.tenant = copy.tenant ?? {};
  return copy;
};

export const prepareAccountForSave = (account) => {
  prepareRelationships(account);
  delete account.name;
  return account;
};

const prepareRelationships = (account) => {
  prepareTenant(account);
  prepareAllowedTenants(account);
  prepareRoles(account);
};

const prepareTenant = (account) => {
  if (account.tenant) {
    if (account.tenant.id !== '') {
      delete account.tenant.name;
    }
    else {
      delete account.tenant;
    }
  }
  return;
}

const prepareAllowedTenants = (account) => {
  if (account.allowedTenants) {
    account.allowedTenants.forEach(currValue => {
      delete currValue.name;
    });
  }

  return;
}

const prepareRoles = (account) => {
  if (account.roles) {
    account.roles.forEach(currValue => {
      delete currValue.name;
      delete currValue.assignableContexts;
      delete currValue.permissions;
    });
  }

  return;
}
