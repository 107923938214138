import { useQuery } from '@apollo/client';
import { DropDown } from 'shared/components/form';
import { GET_ALL_ROLES } from 'roles/operations/getAllRoles';

export const RoleSelector = ({ignoreIds=[], onSelected}) => {
  const { data } = useQuery(GET_ALL_ROLES);
  let roles;

  if (data) {
    roles = data.findRoles.items.filter(x => ignoreIds.indexOf(x.id) < 0)
                                .map(x => ({
                                  value: x.id,
                                  text: x.name
                                })
    );

    return (
      <DropDown
        label="Roles"
        items={roles}
        onSelected={onSelected}
      />
    );
  }

  return (<h1>Loading...</h1>)
};