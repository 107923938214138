import { useMutation } from '@apollo/client';
import { Container, Row, Col } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_CONSUMER } from 'consumer/operations/mutations/updateConsumer';
import { Button } from 'shared/components/buttons';
import { EditContact } from 'shared/components/contact/editContact';
import { FormLoading } from 'shared/components/spinners/formLoading';
import { EditAdditionalInformation } from './editAdditionalInformation';
import { prepareConsumerForSave } from 'consumer/services/formHelper';
import styles from './editConsumer.module.css'
import { useEffect } from 'react';
import isEmpty from "lodash.isempty";

export const EditConsumer = ({
  consumer,
  account,
  options,
  header,
  AdditionalSections,
  validationSchema,
  onSaved,
  setBlockNavigation
}) => {
  setBlockNavigation = setBlockNavigation ?? function() {};

  options = options ?? {
    contact: {
      email: {
        header: 'Email Addresses',
        showAccountEmail: true
      }
    }
  };

  const [saveConsumer, {loading}]  = useMutation(UPDATE_CONSUMER, {
    onCompleted: onSaved
  });

  const formMethods = useForm({
    defaultValues: { consumer: consumer },
    resolver: validationSchema ? yupResolver(validationSchema) : undefined
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    prepareConsumerForSave(data.consumer);
    await saveConsumer({ variables: { input: data.consumer } });
  };

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields);
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  return (
    <>
    { loading 
      ? <FormLoading />
      : <FormProvider {...formMethods}>
        <form name="registration" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Container className={styles.form}>

            {header}

            <Controller
              control={formMethods.control}
              name="contact"
              render={({ ref }) => (
                <EditContact
                  account={account}
                  inputRef={ref}
                  options={options?.contact}
                  errors={formMethods.formState.errors}
                  registerPrefix="consumer"
                />
              )}
            />
            <Controller
              control={formMethods.control}
              name="addlInformation"
              render={({ ref }) => (
                <EditAdditionalInformation
                  inputRef={ref}
                  registerPrefix="consumer"
                  errors={formMethods.formState.errors}
                />
              )}
            />

            {AdditionalSections && 
              <AdditionalSections {...formMethods} />
            }

            <Row className="d-flex justify-content-center">
              <Col sm={1} lg={1} className="d-flex justify-content-center">
                <Button 
                  type='submit' 
                  testid='submit' 
                  text={options?.button?.text} 
                  variant={options?.button?.variant}
                />
              </Col>
            </Row>
          </Container>
        </form>
      </FormProvider>
    }
    {/* <DevTool control={formMethods.control} placement="bottom-right" /> */}
    </>
  );
};