import { gql } from '@apollo/client';
import { PLAN_FIELDS } from 'enrollment/operations/fragments';

export const UPDATE_PLAN = gql`
  ${PLAN_FIELDS}

  mutation UpdatePlan($input: MutatePlanInput!) {
    updatePlan(input: $input) {
      ...PlanFields
    }
  }
`;