import { TeaserCard } from "enrollment/components/teaserCard";
import { FplIcon } from "./icon";

export const FplTeaser = () => {
  return (
    <TeaserCard
      title="Full Plate Living"
      icon={<FplIcon />}
    >
      A large part of staying healthy is eating right - easily!
    </TeaserCard>
  );
};