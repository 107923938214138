import { dpcRoutes } from './dpc/routes';
import { indemnityRoutes } from './indemnity/routes';
import { rxRoutes } from './rx/routes'
import { telehealthRoutes } from './telehealth/routes';
import { RenderRoutes } from '../shared/routing/renderRoutes';

export const benefitRoutes = [
  {
    path: '/benefits',
    key: 'benefits',
    component: RenderRoutes,
    routes: [
      ...dpcRoutes, 
      ...indemnityRoutes, 
      ...rxRoutes,
      ...telehealthRoutes
  ]
  },
];