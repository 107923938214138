import { Col, Row } from 'react-bootstrap';
import { FaList } from 'react-icons/fa'
import parse from 'react-html-parser';
import styles from 'consumer/components/todo/todo.module.css';

export const InstructionsOrder = ({order}) => {
  return (
    <Row className={styles.cardContent}>
      <Col xs={1} className="my-start">
        <FaList className={styles.actionIcon} />
      </Col>
      <Col className={styles.orderInstructions}>
        {parse(order.configuration.instructions)}
      </Col>
    </Row>
  )
}