import { Col, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import Combobox from "react-widgets/Combobox";
import { MarketplaceIcon } from './icon';
import styles from '../marketplace.module.css';

export const YouGetMarketplace = () => {

  return (
    <YouGetCard 
      title='Marketplace'
      icon={<MarketplaceIcon />}
      copy="For non-medically necessary procedures, choose your destination and receive the highest level of care at the most competitive prices, along with full Care Concierge support from start to finish."
    >
      <Row className="justify-content-center" style={{ 'position': 'relative', 'bottom': -15}}>
        <Col lg="auto" md="auto" sm="auto" xs="auto">
          <Combobox placeholder="Procedure Name"
            className={styles.searchInput}
            hideEmptyPopup
            filter={false}
            onClick={() => { window.open('https://demo.bestinclasscare.com/patient/home', '_blank')}}
            selectIcon={<FaSearch className={styles.searchIcon} />}
          />
        </Col>
      </Row>
    </YouGetCard>
  );
};