import { useQuery } from '@apollo/client';
import { DropDown } from 'shared/components/form';
import { permissions } from 'auth/permissions';
import { GET_ALL_TENANTS } from '../operations/queries/getallTenants';

export const AdminTenantSelector = ({ registerInput, account, ignoreIds = [], onSelected, DropDownComponent }) => {
  const { data } = useQuery(GET_ALL_TENANTS);
  let tenants;

  if (data) {
    tenants = data.findTenants.items.filter(x => ignoreIds.indexOf(x.id) < 0).map(x => ({
      value: x.id,
      text: x.name
    }));

    if (account.getMostPermissive(permissions.admin.super).value ===
      permissions.admin.tenant.value) {
      const allowedIds = account.allowedTenants.map(x => x.id);
      tenants = tenants.filter(t => allowedIds.includes(t.value));
    }

    return (
      <>
        {DropDownComponent !== undefined
          ? <DropDownComponent
            tenants={tenants}
            onSelected={onSelected} />
          : <DropDown
            label="Tenant"
            items={tenants}
            registerInput={registerInput}
            onSelected={onSelected} />}
      </>
    );
  }

  return (<h1>Loading...</h1>);
};
