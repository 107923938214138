import { useQuery } from "@apollo/client";
import { GET_CONSUMERS_FOR_ACCOUNT_IDS } from "admin/operations/queries/getConsumersForAccountIds";
import { GET_ACCOUNT_IDS } from "admin/operations/queries/getAccountIds";
import { UserList } from '../../../shared/components/userList';
import { useCurrentAccount } from 'auth/operations/useCurrentAccount';
import { sortContactsByLastThenFirst } from "shared/services/comparisonService";
import { permissions } from "auth/permissions";
import { SectionHeader } from 'shared/components/form';
import { adminPaths } from 'admin/routes';
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddButton } from "shared/components/buttons/addButton";

export const AdminUsers = () => {
  let baseFilter = { isAdmin: { eq: true }};
  const account = useCurrentAccount();
  const filterPermission = account.getMostPermissive(permissions.admin.tenant);
  if (filterPermission.context === 'tenant') {
    baseFilter = {
      tenant: { id: { in: account.allowedTenants.map(x => x.id)}}
    };
  }

  const { loading: accountsLoading, error: accountsError, data: accountsData } = useQuery(GET_ACCOUNT_IDS, { variables: { filter: baseFilter } });

  const accountIds = accountsData?.findAccounts?.items?.map(x => x.id);
  const { loading: consumersLoading, error: consumersError, data: consumersData } = useQuery(GET_CONSUMERS_FOR_ACCOUNT_IDS, {
    skip: !accountIds,
    variables: { accountIds }
  });

  if (accountsLoading || consumersLoading)
    return 'Loading...';
  if (accountsError)
    return accountsError;
  if (consumersError)
    return consumersError;

  return (
    <>
      <SectionHeader>
        <h2>Admin Users</h2>
      </SectionHeader>
      <Row className="justify-content-end">
        <Col lg={1} md={1} sm={1} xs={1}>
          <Link to={`${adminPaths.users}/new`}>
            <AddButton tooltip='Add new Admin' />
          </Link>
        </Col>
      </Row>
      {consumersData.findConsumers.items.length === 0 
        ? <div>No results found</div>
        : <UserList data={[...consumersData.findConsumers.items].sort(sortContactsByLastThenFirst)} detailLink={adminPaths.users}></UserList>
      }
    </>
  );
};