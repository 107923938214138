import { TeaserCard } from "enrollment/components/teaserCard";
import { MarketplaceIcon } from "./icon";

export const MarketplaceTeaser = () => {

  return (
    <TeaserCard
        title="Marketplace"
        icon={<MarketplaceIcon />}
    >
      Access great care via our curated global provider network
    </TeaserCard>
  );
};