import ContentLoader from 'react-content-loader';

export const BaseLoading = ({children, viewBox}) => {

  return (
    <ContentLoader
      viewBox={viewBox}
      speed={2}
      backgroundColor='#ffffff' 
      foregroundColor='#999999' 
    >
      {children}
    </ContentLoader>
  );
};