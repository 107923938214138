import { gql } from '@apollo/client';
import { CONTACT_FIELDS } from 'shared/operations/fragments';

export const GET_CONCIERGE_CONSUMERS = gql`
  ${CONTACT_FIELDS}
  query Query($filter: ConsumerFilter) {
  findConsumers(filter:$filter) {
    items {
      id
      contact {
        ...ContactFields
      }
    }
  }
}
`;
