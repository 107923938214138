import { TeaserCard } from "enrollment/components/teaserCard";
import { TelehealthIcon } from "./icon";

export const TelehealthTeaser = () => {

  return (
    <TeaserCard
        title="Telehealth"
        icon={<TelehealthIcon />}
    >
      Virtual visits whenever you need them
    </TeaserCard>
  );
};