import { GET_LYRIC_STATES } from 'integrations/lyric/operations/queries/getStates';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { useEffect, useState } from 'react';
import { SimpleDropDown } from 'shared/components/form';
import isEmpty from 'lodash.isempty';

export const LyricStateSelector = ({ consumer, onSelected }) => {
  const [states, setStates] = useState([]);
  const [defaultState, setDefaultState] = useState(null);
  const [loading, setLoading] = useState(true);
  useQueryWrapper(GET_LYRIC_STATES, {
    onCompleted: (data) =>  {
      setStates(data.findLyricStates.items.map(x => ({
          value: x.abbreviation,
          text: x.name
        })));
      }
    }
  );

  useEffect(() => {
    const getState = async () => {
      const address = consumer?.contact?.addresses ? consumer.contact.addresses[0] : null;
      if (address?.state ?? false) {
        const defaultState = states.find(x => x.value === address.state);
        if (defaultState) {
          setDefaultState(defaultState.value);
          onSelected({target: { value: defaultState.value}});
        }
      }
      setLoading(false);
    }

    if (loading && !isEmpty(states) && consumer) {
      getState();
    }
  }, [loading, states, consumer, onSelected]);

  return (
    loading 
      ? <></> 
      : <SimpleDropDown
          label="What US state will you be in during consultation"
          items={states}
          defaultValue={defaultState}
          onSelected={onSelected}
          removeEmptyOption={true}
        />
  );
};



