import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { getError } from 'shared/services/validationService';
import { AddButton } from '../buttons';
import { Section, SectionHeader } from '../form';
import { EditPhoneNumber } from './editPhoneNumber';
import styles from './contact.module.css';


export const EditPhoneNumberList = ({ registerPrefix, options, errors }) => {
  options = options ?? {};
  const registerField = registerPrefix ? `${registerPrefix}.phoneNumbers` : 'phoneNumbers';
  const { control} = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: registerField });
  const [ editIndex, setEditIndex ] = useState(-1);
  const [ canEdit, enableEdit ] = useState(true);
  const header = options.header ?? 'Phone Numbers';

  const addNew = () => {
    const newIndex = fields.length;
    append({ number: '', type: '',  extension: '', country: { prefix: '1', code: 'US'} });
    setEditIndex(newIndex);
    enableEdit(false);
  };

  const onDelete = (index) => {
    if (index < editIndex) setEditIndex(editIndex -1 );

    if (index === editIndex) {
      enableEdit(true); 
      setEditIndex(-1);
    }

    remove(index);
  };

  const onEdit = (index) => {
    setEditIndex(index);
    enableEdit(false);
  };

  const onSave = () => {
    setEditIndex(-1);
    enableEdit(true);
  };

  return (
    <>
      <SectionHeader>
        <h4>{header}</h4>
        <div className={`invalid-feedback ${styles.showValidationError}`}>
          {getError(registerField, errors)?.message}
        </div>
      </SectionHeader>
      {fields.map((item, index) => (
        <EditPhoneNumber
          registerPrefix={`${registerField}`}
          index={index}
          key={`phone-${index}`}
          editIndex={editIndex}
          canEdit={canEdit}
          onComplete={onSave}
          onEdit={onEdit}
          onDelete={onDelete}
          errors={errors}
        />
      ))}
      <Section>
        <Col sm={1}>
          <AddButton disabled={!canEdit} tooltip="Add new phone number" onClick={addNew} />
        </Col>
      </Section>
    </>
  );
};