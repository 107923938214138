export const TermsAndConditions = () => {

  return (
    <>
      <p><strong>1. Use of Website and Services</strong></p>
      <p>
        &nbsp;These Terms and Conditions apply to our website and services. By accessing or using this website and/or the associated services, you acknowledge that you have read, understand and agree to these Terms and Conditions. You agree to comply with these Terms and Conditions each time you access and use the website and/or the services. If you don’t agree to the Terms and Conditions, you may not access or use the website or services.
        <br />
        <br />
        We reserve the right to amend or modify this Agreement at any time with no prior notice to you. Your usage of the site and/or the services subsequent to our modification or change of any element of this Agreement is your express agreement to be bound by each and every term and condition of the new version of this Agreement. You may use the website and services only for personal, non-commercial usage only, unless you are contracted with Best in Class Care to use otherwise. 
        <br />
        <br /><strong>2. Scope of Services&nbsp;</strong>
      </p>
      <p>&nbsp;Best in Class Care provides access to a global network of healthcare providers and services by way of a digital platform (i.e. website) and a Care Concierge service (including synchronous and asynchronous communications) that facilitates communication and transfer of medical records, scheduling of appointments and associated travel logistics.  We do not provide health care and we do not provide any medical advice regarding the nature of health care treatment available in any location. It is up to each individual who uses the website and services that we provide to conduct their own comprehensive due diligence of any healthcare provider and service. We encourage, as part of your decision-making process, that you consult with your local physician or other licensed health care provider so that you may be fully informed about all the necessary facts and information required in order to make an informed decision about your healthcare.&nbsp;</p>
      <p>Best in Class Care does not endorse, recommend, or approve any center of care, healthcare provider or any third-party service provider (hereinafter “Providers”) of any nature.  We provide information on Providers, prices for healthcare procedures, and options for travel and accommodations, so that you can begin to conduct your own research into whether our services are appropriate for you.  The Providers alone are responsible for the content, accuracy and updating of the descriptions of themselves, their services and their prices. We do not warrant the accuracy, currency or completeness of the information or reliability of any advice, opinion, statement or other materials displayed on or distributed through this website, including, without limitation, any information or materials regarding, or provided by, any Providers. You acknowledge that any reliance on any such information, opinion, advice, statement, memorandum or other materials shall be at your sole risk.&nbsp;</p>
      <p>You agree to assume all responsibility regarding whatever decision you make about obtaining healthcare services, and to indemnify Best in Class Care and hold us harmless from any and all claims, demands, liabilities, obligations, damages, costs and expenses, including attorneys’ fees and costs (collectively, “Damages”) arising from use if our website and services, including medical treatments or consultations that you receive from any Provider learned about through Best in Class Care, regardless of your location. <strong>&nbsp;</strong></p>
      <p><strong>3. Your Obligations as a User of the Best in Class Care Website and/or Services</strong></p>
      <p>&nbsp;To access some features of the website and some services, you will have to sign up for an account. This will require you to provide certain personal information, including but not limited to your name, birth date, and email address. By signing up for an account, you authorize Best in Class Care to access your personal information and your protected health information (PHI) in accordance with the Health Insurance Portability and Accountability Act (HIPAA).</p>
      <p>By requesting any service through the website, you authorize Best in Class Care to collect and store your health information and medical records and share them with the Provider(s) associated with your case. By requesting a service to be paid for by your employer and/or health insurance plan (hereinafter, “Payer”), you authorize Best in Class Care to share with your Payer, your health information and medical records associated with your case.</p>
      <p>You must be of adult age in your jurisdiction to use the website or to sign up for an account on the site. In any event, no user or account holder may be under the age of 18 years of age. Parents or guardians of minors may sign up for an account on behalf of their minor children/wards but are solely responsible for the direct supervision of their children/wards while their minors visit the website or use any of the tools or services that the site provides.</p>
      <p>You are obligated to protect your login data (username, password) carefully against unauthorized access by third persons. You alone are responsible for all activities on or through your account. You agree to inform Best in Class Care immediately if you have knowledge of an unauthorized use of your account or of any other security risk.</p>
      <p><strong>4. Restrictions</strong></p>
      <p><strong>4.1 Limitations on Use.</strong> You may not use the Services or accept this Agreement if you are not of a legal age to form a binding contract with us. The Site and Services may be used and accessed for lawful purposes only. By accessing or using the Site or Services, you agree to abide by all applicable local, state, national, and foreign laws, treaties, and regulations in connection with your use of the Site and its content, and you agree not to use the Services for any purpose that is prohibited by this Agreement. The Services may be subject to other limitations, as specified in the Services Agreement and/or Documentation.</p>
      <p><strong>4.2 Prohibited Activities.</strong> You shall not: (a) make the Services available to anyone other than Users; (b) sell, resell, rent or lease the Services; (c) use the Services to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights; (d) use the Services for any malicious purpose; (e) interfere with or disrupt the integrity or performance of the Services or third-party data contained therein; (f) build a product or service that is competitive to the Services or use the Services in a way that competes with us; or (g) attempt to gain unauthorized access to the Services or their related systems or networks.</p>
      <p><strong>4.3. Examples of Prohibited Activities.</strong> By way of example, and not as a limitation, you agree that you will not take any of the following actions while using or accessing the Site or Services:</p>
      <ul type="disc">
        <li><strong>4.3.1. </strong>in any manner transmit or submit any content to which you do not have the lawful right to copy, transmit, and display (including any content that would violate any confidentiality or fiduciary obligations that you might have with respect to the content);</li>
        <li><strong>4.3.2. </strong>in any manner transmit or submit any content that includes anyone's identification documents or sensitive financial information;</li>
        <li><strong>4.3.3. </strong>in any manner transmit or submit any content that infringes the intellectual property rights or violates the privacy rights of any third party (including, without limitation, copyright, trademark, patent, trade secret, or other intellectual property right, or moral right, or right of publicity);</li>
        <li><strong>4.3.4. </strong>in any manner transmit or submit content or material that is unlawful, threatening, harmful, hateful, abusive, harassing, defamatory, deceptive, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, indecent, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts sexual activity, promotes bigotry, discrimination or violence, or is otherwise inappropriate or objectionable, as determined by us in our sole discretion;</li>
        <li><strong>4.3.5. </strong>copy, reproduce, republish, upload, post, transmit, or distribute the Services, the Site, or any content thereof;</li>
        <li><strong>4.3.6. </strong>share or sell information derived from or related to the Services, the Site, or any content thereof;</li>
        <li><strong>4.3.7. </strong>modify, translate, alter, adapt, decompile, disassemble (except to the extent applicable laws specifically prohibit such restriction), reproduce, distribute, or display, or create derivative works, compilations, or collective works based on the Services, the Site, or any content thereof;</li>
        <li><strong>4.3.8. </strong>deciphers, decompiles, disassembles, reverse engineers or otherwise attempts to derive any source code or underlying ideas or algorithms of any part of the Services (including, without limitation, any application or widget), except to the limited extent applicable laws specifically prohibit such restriction;</li>
        <li><strong>4.3.9. </strong>knowingly or negligently permit other individuals or entities to use or copy the Services or “frame” or “mirror” the Services on any other server or wireless or Internet-based device;</li>
        <li><strong>4.3.10. </strong>circumvent, disable, or otherwise interfere with security-related features on the Site or features that prevent or restrict use or copying of any content;</li>
        <li><strong>4.3.11. </strong>impose or take any action that may impose (as we determine in our sole discretion) an unreasonable or disproportionately large load on our (or our third party providers') infrastructure;</li>
        <li><strong>4.3.12. </strong>interfere with or take any action that may interfere with (as we determine in our sole discretion) the proper working of the Services or any activities conducted on the Service;</li>
        <li><strong>4.3.13. </strong>use the Services to collect or store Personal Information about other users;</li>
        <li><strong>4.3.14. </strong>knowingly include or use any false or inaccurate information in any information form or communication, or impersonate any person or entity, including our employees or representatives;</li>
        <li><strong>4.3.15. </strong>in any way transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, “pyramid schemes,” or any other form of solicitation, as well as viruses or other computer code that may interrupt, destroy, limit the functionality of the Site, or interfere with the access of any other user to the Site;</li>
        <li><strong>4.3.16. </strong>conduct commercial activities (whether or not for profit) and/or sales, such as contests, sweepstakes, barter, or advertising, without our prior written consent;</li>
        <li><strong>4.3.17. </strong>attempt to probe, scan, or test the vulnerability of any system or network operated by us, or breach or impair or circumvent any security or authentication measures protecting the Site;</li>
        <li><strong>4.3.18. </strong>attack the Site via a denial-of-service attack or a distributed denial-of-service attack or otherwise attempt to interfere with the proper working of the Site;</li>
        <li><strong>4.3.19. </strong>access, copy, store, reproduce, display, transfer, extract, or harvest Site content or Site data through scraping, crawling, spidering, botting, or other similar means, whether manually or through an automated system or software, whether such Site content or Site data is displayed directly from the Site, Services, or through an Approved Developer’s application, service, website, or otherwise directly or indirectly through a third party;</li>
        <li><strong>4.3.20. </strong>transmit or upload any material to the Site that contains viruses, trojan horses, worms, time bombs, or any other computer codes, files, or programs that are designed or intended to disrupt, disable, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password or other information belonging to us or any third party, or are otherwise harmful or deleterious;</li>
        <li><strong>4.3.21. </strong>attempt to decipher, decompile, disassemble, reverse engineer, or otherwise attempt to discover or determine the source code of any software or any proprietary algorithm used to provide the Site;</li>
        <li><strong>4.3.22. </strong>encourage, collaborate, or instruct any other person or entity to do any of the foregoing; or</li>
        <li><strong>4.3.23. </strong>otherwise violate our guidelines and policies, including without limitation, our privacy policy and/or any other policies and rules incorporated herein.</li>
      </ul>
      <p><strong>ANY ATTEMPT TO DO ANY OF THE FOREGOING PROHIBITED ACTS OR TO OTHERWISE UNDERMINE THE OPERATION OF THE SERVICES MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAW. SHOULD SUCH AN ATTEMPT BE MADE, WE RESERVE THE RIGHT, IN ADDITION TO OUR OTHER REMEDIES, TO SEEK DAMAGES (INCLUDING WITHOUT LIMITATION ATTORNEYS’ FEES) FROM ANY SUCH INDIVIDUAL OR ENTITY TO THE FULLEST EXTENT PERMITTED BY LAW.</strong></p>
      <p>We reserve the right, in our sole discretion, to audit or otherwise monitor any communication transmitted using the Service. We further reserve the right at all times to review, retain, and/or disclose any information as necessary to satisfy any applicable law, regulation, legal process, governmental request, or business assessment.</p>
      <p><strong>NOTWITHSTANDING THE FOREGOING, WE HEREBY DISCLAIM ANY OBLIGATION TO MONITOR USE OF THIS SERVICES OR TO RETAIN THE CONTENT ON THIS SITE UNLESS OTHERWISE AGREED OR REQUIRED BY LAW.</strong></p>
      <p>
        <strong>5. Changes to Website</strong>
        <br />&nbsp;Best in Class Care may change, suspend or discontinue any aspect of the website and/or services at any time, including the availability of any website feature, database, or content. Best in Class Care also may impose limits on certain features and services or restrict your access to parts or the entire website without notice or liability.
      </p>
      <p>We may post rules, guidelines or policies. Upon the posting of such materials, they immediately become incorporated by reference into this Agreement as if fully set forth herein.</p>
      <p>
        <strong>6. Third Party Websites and Links</strong>
        <br />&nbsp;The Best in Class Care website may contain links and references to other websites maintained by third parties over whom Best in Class Care has no control. Similarly, Best in Class Care may be accessed from third party links over whom Best in Class Care has no control. Best in Class Care makes no warranties, representations, or responsibility of any kind as to the availability, accuracy, currency, or completeness of any information contained in such websites.
      </p>
      <p>Websites and all such commercial publications such as, but not limited to: medical websites, education websites, research websites, blogs, press releases, tourism websites, and local business development websites may refer or link to the Best in Class Care website under two circumstances:</p>
      <ol>
        <li>Prior approval is sought in writing, and approved in writing, by authorized personnel of Best in Class Care.</li>
        <li>Subject references or links are done in a way that is fair and legal and does not in any way, whether directly or indirectly, damage or take advantage of our reputation. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</li>
      </ol>
      <p>You must not establish a link from any website that is not owned by and/or operated by you unless you have obtained our prior written permission.</p>
      <p>
        <strong>7. Privacy</strong>
        <br />&nbsp;Best in Class Care respects the privacy of its website users. By your access and use of this website, you agree to the terms of Best in Class Care’s Privacy Policy, which explains users’ rights and responsibilities with respect to information that is disclosed on this website.&nbsp;
      </p>
      <p>
        <strong>8. Medical Information</strong>
        <br />&nbsp;Best in Class Care may contain general information relating to various medical procedures, conditions, and their treatment preparation. Such information is provided for informational purposes only and is not meant to be a substitute for advice provided by your doctors. Patients should not use the information contained herein for diagnosing a health or fitness problem or disease. Patients should always consult with a qualified healthcare provider.
        <br />
        <br /><strong>9. Disclaimer of Warranties.</strong>
        <br />&nbsp;We issue no warranty whatsoever and do not make any representations or warranties regarding the availability, suitability, reliability, merchantability, non-infringement, capability, usefulness or fitness for any general or particular purpose of the site, or the tools, products or services herein supplied or sold or regarding the characteristics of services provided by or through the site, or regarding the timeliness, accuracy or usefulness of information obtained from or through the site. The site and all content contained, distributed, sold or published via the site is provided to you "As Is, Where Is", without any warranty of any kind, express or implied.  Use of our website, including, but not limited to use of (i) any message feature or (ii) newsletters, is at your exclusive risk, and the risk of injury from the foregoing rests exclusively with you.
        <br />
        <br /><strong>10. Force Majeure</strong>
        <br />&nbsp;Neither party shall be liable for any delay or failure in performance due to Force Majeure, which shall mean acts of God, earthquake, labor disputes, changes in law, regulation or government policy, riots, war, fire, flood, insurrection, sabotage, embargo, epidemics, acts or omissions of vendors or suppliers, transportation difficulties, unavailability of interruption or delay in telecommunications or third party services (including DNS propagation), failure of third party software or hardware or inability to obtain raw materials, supplies, or power used in or equipment needed. We are not responsible for server downtime under any circumstances.
      </p>
      <p>
        <strong>11. Limitations on Liability</strong>
        <br />&nbsp;To the extent permitted by law, Best in Class Care and any of its officers, directors, employees, shareholders and agents, shall not be liable for any direct, indirect, incidental, special or consequential damages that may result to you or a third party arising out of use of the Best in Class Care website or services.
        <br />
        <br /><strong>12. Trademarks, Copyrights &amp; Ownership</strong>
        <br />&nbsp;“Best in Class Care”, including its product names, materials, and services, whether or not appearing in large print or with the trademark symbol, are trademarks of Best in Class Care, Inc. The use or misuse of these trademarks or any other materials, except as permitted herein, is expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and libel, the law of privacy and publicity, communications regulations and statutes, and other laws.
        <br />
        <br />“Best in Class Care”, including its product names, including but not limited to: Boutique, Fairparency, Marketplace, Executive, as well as materials, and services, whether or not appearing in large print or with the trademark symbol, are trademarks of Best in Class Care, Inc. The use or misuse of these trademarks or any other materials, except as permitted herein, is expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and libel, the law of privacy and publicity, communications regulations and statutes, and other laws.
      </p>
      <p>Best in Class Care is owned by Best in Class Care, Inc. All materials contained in Best in Class Care (the “Materials”) are either owned by or licensed to Best in Class Care, Inc. Best in Class Care and its licensors retain all proprietary rights to the Materials.</p>
      <p>All Materials provided within or via this site are protected by various US and international copyright laws, patent laws, trademark regulations and laws, and various intellectual property laws and international treaties and agreements. No intellectual property of any nature contained within or via this Site may be copied, published, or broadcast in any way without the written permission of the content owner. The Materials provided within or via this Site may not be “framed” or “mirrored”. All trademarks presented on or via this Site are owned by their respective owners and may not be used by you in any way.</p>
      <p>
        <strong>13. Termination of Service</strong>
        <br />&nbsp;We reserve the right to terminate any and all service provided to you (either as a group or as an individual user or member) at any time without notice for any reason we deem fit. We also reserve the right to discontinue any service or modify any service with no notice to you.
      </p>
      <p>You agree that monetary damages may not adequately provide a remedy for us if you violate any of the terms of this Agreement, and you agree that we may approach a Court of Equity of competent jurisdiction for the purpose of obtaining Orders in Equity should you violate any element of this Agreement.</p>
      <p>
        <strong>14. Automatic Viewing or Usage of this Site</strong>
        <br />&nbsp;You may not use any automated scripts or “robots” to access, copy, or manipulate any content provided on this site. You may not engage in denial of service attacks upon the servers that publish this Site.&nbsp;
      </p>
      <p><strong>15. Purchases</strong></p>
      <p>&nbsp;The pricing offered on the Best in Class Care Site is provided to Best in Class Care by the associated Provider. With respect to medical services available through the Best in Class Care network, we cannot confirm the price or medical service to be delivered until your individual Treatment Plan has been approved by your Provider and Payer. Unforeseen complexities discovered or arising during delivery of the &nbsp;Medical Service may alter final pricing.</p>
      <p>Should you purchase or otherwise receive service(s) through Best in Class Care, you agree that you will provide us with true, complete and correct credit information, that your credit card company will honor the charge(s) you have placed against your account, that you will refrain from “charging back” the charge for any reason. You further agree that you are responsible for all charges due to a Provider that are not covered by an allocated benefit, if any, received from your Payer.</p>
      <p>Best in Class Care is not liable for any change and/or cancellation fee(s) charged to you by any Provider nor refund of any payment made by you for services offered and/or delivered by any Provider. All change and cancellation fees charged by Providers are subject to the terms and conditions specific to each Provider. All refund requests for services purchased from Providers are subject to the terms and conditions specific to each Provider.&nbsp;</p>
      <p>
        <strong>16. Governing Law; Jurisdiction</strong>
        <br />&nbsp;This Agreement shall be governed by the laws of the State of Illinois without regard to its conflicts of law principles. &nbsp; The exclusive jurisdiction for any claims arising from or related to this Agreement or this website shall be the state and federal courts located in Chicago, Illinois.
      </p>
      <p>
        <strong>17. Foreign Usage</strong>
        <br />&nbsp;We make no representation that the usage of this site, or the content provided herein, will not violate the laws of your local jurisdiction. You are responsible for compliance with the laws of your jurisdiction, especially if you are accessing this site from outside the United States of America (USA). Do not use this site if such usage violates the laws of your jurisdiction, including the laws of any state of the United States or any USA federal law or regulation.
      </p>
      <p>
        <strong>18. Miscellaneous</strong>
        <br />&nbsp;This Agreement constitutes the entire agreement between the parties and supersedes all prior written or oral agreements or communications with respect to the subject matter herein. If any provision of this Agreement is held to be unlawful, void or unenforceable, then such provision shall be severable without affecting the enforceability of all remaining provisions. Best in Class Care in its sole discretion may amend this Agreement, and your use of our website after such amended terms are posted on such website will constitute acceptance of such amended terms by you. &nbsp;Any cause of action you may have with respect to your use of this website must be commenced within one year after the claim or cause of action arises. &nbsp;
      </p>
    </>
  );
};