import { ApolloClient, ApolloProvider, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { BICCToken } from '../auth/biccToken';
import { App } from '../app';
import { cache } from './cache';
import { appSettings } from '../appSettings';
import { LoadUserAccount } from '../auth/loadUserAccount';
import { errorLink } from './errorLink';

export const ConnectedApp = () => {

  const httpLink = new BatchHttpLink({
    uri: `${appSettings.REACT_APP_BICC_API_URL}/graphql`,
    headers: {
      authorization: `Bearer ${ BICCToken.bearer }`
    },
    batchInterval: 20,
    batchMax: 10,
  });

  const client = new ApolloClient({
    cache,
    link: from([errorLink, httpLink])
  });

  return (
    <ApolloProvider client={client}>
      <LoadUserAccount>
        <App />
      </LoadUserAccount>
    </ApolloProvider>
  );
};