import { Row } from 'react-bootstrap';
import { Consumer } from './consumer';
import { Context } from './context';
import { CaseTags } from './caseTags';
import styles from 'case/case.module.css';

export const SideBar = ({caseData, dispatch}) => {
  return (
    <>
      <Row className={styles.sidebarcard}>
        <Consumer caseData={caseData} />
      </Row>
      <Separator />
      <Row className={styles.sidebarcard}>
        <Context caseData={caseData} />
      </Row>
      <Separator />
      <Row className={styles.sidebarcard}>
        <CaseTags caseData={caseData} dispatch={dispatch} />
      </Row>
    </>
  );
};

const Separator = () => {
  return (
    <Row className={styles.separator} />
  );
};