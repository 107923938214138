import { gql } from '@apollo/client';
import { OrdersPerPage } from 'orders/constants';

export const GET_ORDERS = gql`
  query FindOrders($filter: OrderFilter, $offset: Int!) {
    findOrders(
      filter: $filter
      page: {
        limit: ${OrdersPerPage},
        offset: $offset
      }
      orderBy: {
        field: "date",
        order: DESC
      }) {
      items {
        id
        provider {
          contact {
            name {
              first
              prefix
              last
              middle
              suffix
            }
          }
          id
        }
        careCenter {
          id
          name
        }
        type
        status
        date
        configuration
      }
      count
    }
  }
`;