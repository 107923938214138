import { gql } from '@apollo/client';
import { AppointmentsPerPage } from 'consumer/constants';

export const GET_APPOINTMENTS = gql`
  query FindAppointments($filter: AppointmentFilter!, $offset: Int) {
    findAppointments(
      filter: $filter
      page: {
        limit: ${AppointmentsPerPage},
        offset: $offset
      }
      orderBy: {
        field: "date",
        order: DESC
      }) {
      items {
        id
        provider {
          contact {
            name {
              first
              prefix
              last
              middle
              suffix
            }
          }
          id
        }
        careCenter {
          id
          name
          address {
            line1
            line2
            city
            state
            postalCode
            countryCode
          }
        }
        type
        status
        date
        time
        duration
        integrations {
          id
          type
          configuration
        }
      }
      count
    }
  }
`;