import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { YouGetCard } from 'consumer/components/youGet/youGetCard';
import { Button } from 'shared/components/buttons/button';
import { FaExclamationCircle } from 'react-icons/fa';
import { IndemnityIcon } from './icon';
import { indemnityPaths } from '../routes';
import styles from '../indemnity.module.css';

export const YouGetIndemnity = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(indemnityPaths.findHospital);
  };

  return (
    <>
      <YouGetCard title="Hospital Finder"
        icon={<IndemnityIcon />}
        copy="Finding a hospital can be overwhelming.  Finding the right hospital even harder. That is why our team has already selected hospitals with the right prices for you."
      >
        <Row className="justify-content-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <Button variant="secondary" onClick={() => handleClick()} text="Find a hospital"/>
          </Col>
        </Row>
        <Row className={`justify-content-center ${styles.alertrow}`}>
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <FaExclamationCircle className={styles.alerticon} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto" className={styles.emergency}>
            Have an emergency?
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            Dial <strong>911</strong> on your phone.
          </Col>
        </Row>
      </YouGetCard>
    </>
  );
};