import { gql } from '@apollo/client';

export const GET_BENEFIT_USAGES = gql`
  query GetBenefitUsages($filter: BenefitUsageFilter!) {
    findBenefitUsages(filter: $filter) {
      items {
        id
        usage {
          benefit
          configuration
        }
      }
    }
  }
`;