import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { prepareCareCenterForSave } from '../../services/formHelper';
import { Button } from 'shared/components/buttons';
import { useCareCenter } from 'careCenter/hooks/useCareCenter';
import { CREATE_CARECENTER, UPDATE_CARECENTER } from 'careCenter/operations/mutations';
import { toastSuccess } from 'shared/components/toasts';
import { careCenterProfileValidationSchema } from './profileValidationSchema';
import isEmpty from 'lodash.isempty';

export const EditExistingCareCenterForm = ({component, ...props}) => {
  const { careCenterId } = useParams();
  const { careCenter } = useCareCenter(careCenterId);

  if (careCenter) {
    return (
      <ProfileForm 
        careCenter={careCenter} 
        component={component}
        isNew={false} 
        {...props}
      />
    );
  }

  return (<h1>Loading Care Center...</h1>);
};

export const ProfileForm = ({
    isNew, 
    careCenter, 
    component,
    onSaved,
    setBlockNavigation
}) => {
  const Component = component;

  
  const handleSaved = (data) => {
    toastSuccess(`Care Center ${isNew ? 'created' : 'saved'}`);
    formMethods.formState.dirtyFields = {};
    if (onSaved) onSaved(data);
  };

  const mutationQuery = isNew
    ? CREATE_CARECENTER
    : UPDATE_CARECENTER;
  const [saveCareCenter, { loading }] = useMutation(mutationQuery, {
    onCompleted: handleSaved
  });

  const formMethods = useForm({
    defaultValues: {careCenter},
    resolver: yupResolver(careCenterProfileValidationSchema)
  });

  useEffect(() => {
    var isDirty = !isEmpty(formMethods.formState.dirtyFields)
    setBlockNavigation(isDirty);
  }, [formMethods.formState, setBlockNavigation]);

  const onSubmit = async (data) => {
    const input = prepareCareCenterForSave(data.careCenter);
    await saveCareCenter({ variables: { input: input }});
  };

  if (loading) return (<h1>Saving...</h1>);

  return (
    <FormProvider {...formMethods}>
      <form name="careCenter" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Component
          registerPrefix="careCenter"
          errors={formMethods.formState.errors}
        />
        <div className="float-end">
        <Button type="submit" text={isNew ? 'Create' : 'Save'} />
        </div>
      </form>
    </FormProvider>
  );
};