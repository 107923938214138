import { Button, Col, Row } from 'react-bootstrap';
import { FaCheckCircle, FaShieldAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { consumerPaths } from 'consumer/routes';
import styles from 'consumer/components/todo/todo.module.css';

export const BePrepared = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(consumerPaths.profile);
  };

  return (
    <Row style={{justifyContent: 'end'}}>
      <Col className={styles.backupCard}>
        <Row>
          <Col className={styles.backupCardCategory}>
            <FaShieldAlt className={styles.icon}/> Be prepared
          </Col>
        </Row>
        <Row>
          <Col className={styles.backupCardCopy}>
            Make Sure you're prepared to use your services. Take a few minutes to do a little housekeeping in your account.
          </Col>
        </Row>
        <Row className={styles.backupCardCopyWrapper}>
          <Col sm={1}>
            <FaCheckCircle className={styles.actionIcon}/>
          </Col>
          <Col className={styles.backupCardCopy}>
            Are there alternate phone numbers to reach you?
          </Col>
        </Row>
        <Row className={styles.backupCardCopyWrapper}>
          <Col sm={1}>
            <FaCheckCircle className={styles.actionIcon}/>
          </Col>
          <Col className={styles.backupCardCopy}>
            Let us know your birthday!
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={onClick} data-testid='be-prepared-link' variant='link' className={styles.actionLink} style={{paddingLeft: '0'}}>
              Update my account &nbsp; &#9656;
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
