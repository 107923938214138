import * as Yup from 'yup';

const getField = (text) => {
  const message = `${text} required`;

  return Yup.number()
    .typeError(message)
    .required(message)
    .nullable()
}; 

export const validationSchema =  {
  is: 'indemnity',
  then: Yup.object().shape({
    householdIncome: getField('Household Income'),
    householdSize: getField('Household Size')
  })
};