import { gql } from '@apollo/client';
import { MESSAGE_FIELDS } from 'message/operations/fragments';

export const UPDATE_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation UpdateMessage($input: MutateMessageInput!) {
    updateMessage(input: $input) {
      ...MessageFields
      createdBy
    }
  }
`;
