import { AccountMessages } from "./pages/accountMessages";

export const accountPaths = {
  messages: '/messages'
};

export const accountRoutes = [
  {
    exact: true,
    path: [
      accountPaths.messages,
      `${accountPaths.messages}/:folder?/:messageId?`
    ],
    key: 'account-messages',
    component: AccountMessages
  }
];