import { gql } from '@apollo/client';
import { ADDRESS_FIELDS_WITH_GEO, PHONE_FIELDS } from 'shared/operations/fragments';

export const PAYER_FIELDS = gql`
  ${ADDRESS_FIELDS_WITH_GEO}
  ${PHONE_FIELDS}

  fragment PayerFields on Payer {
    id
    name
    tenant {
      name
      id
    }
    address {
      ...AddressWithGeoFields
    }
    phoneNumbers {
      ...PhoneFields
    }
    procedureDeposit
    # fee {
    #   feePercentage
    #   minimumFee
    #   minimumProcedurePrice
    # }
  }
`;
