import { Container, Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon'
import { FaHistory } from 'react-icons/fa';
import styles from 'case/case.module.css';

export const Audit = ({content}) => {
  return (
    <Container>
      <Row>
        <Col lg={1} className={styles.messageThreadType}>
          <FaHistory size={25} className={styles.auditIcon} />
        </Col>
        <Col>
          <Row className={styles.messageHeader}>
          <Col>
            <span className={styles.messageSender}>{content.changedBy ?? 'SENDER'}</span>
            <span className={styles.messageTime}>
              {DateTime.fromMillis(content.date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </span>
          </Col>
          </Row>
          <Row>
            <Col className={styles.messageBody}>
              {content.content}
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export const enrichAuditData = (caseData) => {
  let auditData = [{
    type: 'Activity',
    sortDate: caseData.createdAt,
    date: caseData.createdAt,
    content: 'Case created',
    changedBy: `${caseData.consumer.contact.name.first} ${caseData.consumer.contact.name.last}`
  }];
  
  let caseAudit = caseData.audit ?? [];

  return [...auditData, ...caseAudit.map(x => (
    {
      ...x,
      type: 'Activity', 
      sortDate: x.date,
      content: `${x.fieldName} was changed to ${x.newValue}`
    }))
  ];
};