import { EditPolicyAcceptance  } from "../policy/editPolicyAcceptance";

export const AdditionalRegistrationSections = ({ formState: { errors}}) => {

  return (
    <>
      <EditPolicyAcceptance errors={errors} />
      <hr />
    </>
  );
};