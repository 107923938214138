import {createMutableCopy} from 'apollo/utils';

export const preparePayerForForm = (payer) => {
  const copy = createMutableCopy(payer);

  return copy;
};

export const preparePayerForSave = (payer) => {

  payer.fee = payer.fee ?? { minimumProcedurePrice: -1, minimumFee: -1, feePercentage: -1 };
  payer.tenant = {id: payer.tenant.id};

  return payer;
};

export const preparePlanForSave = (plan) => {
  plan.benefits?.forEach(benefit => {
    delete benefit.id;
    fixUnlimitedVisits(benefit);
    fixActiveNetworks(benefit, plan.networks ?? []);
  });

  plan.networks?.forEach(network => {
    delete network.name;
  });

  return plan;
}

const fixUnlimitedVisits = (benefit) => {

  const fixVisit = (visit) => {
    if (visit.unlimited) delete visit.count;
  };

  const visits = benefit.configuration?.visits;
  if (!visits) return;

  // expecting either a visits object, or a dictionary of visits
  if (visits.count !== undefined) {
    fixVisit(visits);
  } else {
    Object.values(visits).forEach(visit => fixVisit(visit));
  }
};

const fixActiveNetworks = (benefit, availableNetworks) => {
  if (benefit.configuration?.activeNetwork) {
    const networkIsValid = availableNetworks.find(x => x.id === benefit.configuration.activeNetwork);
    if (!networkIsValid) {
      delete benefit.configuration.activeNetwork;
    }
  } else if (benefit.configuration?.activeNetworks) {
    const validActiveNetworks = [];
    for (var x = 0; x < benefit.configuration.activeNetworks.length; x++) {
      let activeNetwork = benefit.configuration.activeNetworks[x];
      const networkIsValid = availableNetworks.find(x => x.id === activeNetwork);
      if (networkIsValid) {
        validActiveNetworks.push(activeNetwork);
      }
    }
    benefit.configuration.activeNetworks = validActiveNetworks;
  }
};