import { Section, SectionHeader } from 'shared/components/form';
import { Container, Col } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from 'shared/components/form/input';
import styles from 'payers/payers.module.css';
import { TenantSelector } from 'tenant/components/tenantSelector';
import { getError } from 'shared/services/validationService';

export const EditNetworkBasicInfo = ({isNew, registerPrefix, errors}) => {
  const { control } = useFormContext();

  const getTenantError = () => {
    return getError(`${registerPrefix}.tenant`, errors);
  };

  return (
    <Container fluid>
      <SectionHeader>
        <h4>Overview</h4>
      </SectionHeader>
      <Section>
        <Col lg={5}>
          <Input 
            autoFocus={true}
            label="Name"
            registerInput={`${registerPrefix}.name`} 
            errors={errors}
          />
        </Col>
        <Col>
        {isNew
            ?
            <>
              <TenantSelector registerPrefix={registerPrefix} />
              {getTenantError()?.message &&
                <div className={`invalid-feedback ${styles.showValidationError}`}>
                  {getTenantError()?.message}
                </div>
              }
            </>
            : <Controller
              control={control}
              name={`${registerPrefix}.tenant.name`}
              render={({ field }) => (
                <div className={styles.readonlyFieldDisplay}>
                  Tenant: {field.value}
                </div>
              )}
            />
          }
        </Col>
      </Section>
    </Container>
  );
};