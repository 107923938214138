import { gql } from '@apollo/client';

export const GET_LYRIC_REASONS = gql`
  query FindLyricReasons{
    findLyricReasons {
      items {
        id
        name
        type
        problemId
      }
    }
  }
`;