import { useState } from "react";
import { Col, Row, Modal } from 'react-bootstrap';
import { Button } from 'shared/components/buttons/button';
import { OffCanvasWrapper } from "shared/components/offCanvasWrapper";
import offCanvasStyles from "shared/components/shared.module.css";
import ReactToolTip from 'react-tooltip';
import { RequestAppointmentForm } from "./requestAppointmentForm";
import { ViewAppointmentRequest } from './viewAppointmentRequest'

export const RequestAppointmentButton = ({careCenterResult, text, context}) => {
  text = text ?? 'Request Appointment';
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [appointmentRequest, setAppointmentRequest] = useState({});

  const onAppointmentRequestCreated = ({createdAppointmentRequest}) => {
    setShowOffCanvas(false);
    setShowModal(true);
    setAppointmentRequest(createdAppointmentRequest);
  }

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <Button variant="link" text={text} onClick={() => setShowOffCanvas(true)}/>
      </Col>
      <OffCanvasWrapper shouldShow={showOffCanvas} toggleShow={setShowOffCanvas} offCanvasClass={offCanvasStyles.offCanvas35}>
        <RequestAppointmentForm 
          appointmentType='dpc' 
          careCenterResult={careCenterResult}
          context={context}
          onAppointmentRequestCreated={onAppointmentRequestCreated}
        />
      </OffCanvasWrapper>
      <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="help-modal"
          centered
        >
        <Modal.Body id="help-modal">
          <ViewAppointmentRequest appointmentRequest={appointmentRequest} careCenterResult={careCenterResult} setShowModal={setShowModal}/>
        </Modal.Body>
        <ReactToolTip />
      </Modal>
    </Row>
  )
};