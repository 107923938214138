import { parsePermission } from './permissionHelper';

export const permissions = {
  admin: {
    super: parsePermission('*.*'),
    tenant: parsePermission('*.*.tenant')
  },
  careCenter: {
    edit: parsePermission('carecenter.edit')
  },
  case: {
    admin: parsePermission('case.*')
  },
  concierge: {
    access: parsePermission('concierge.access')
  },
  consumer: {
    admin: parsePermission('consumer.*'),
    tenant: parsePermission('consumer.*.tenant')
  },
  network: {
    edit: parsePermission('network.edit')
  },
  payer: {
    edit: parsePermission('payer.edit')
  }
};