import { useCallback } from 'react';
import { useConsumerBase } from "./useConsumerBase";
import { GET_CONSUMER } from 'consumer/operations/queries/getConsumer';

export const useConsumer = (consumerId) => {

  const getData = useCallback((data) => {
    return data?.getConsumer
  }, []);

  return useConsumerBase(
    GET_CONSUMER,
    { id: consumerId },
    getData
  );
};