import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { FaCheck, FaRegCalendarAlt, FaTimes } from 'react-icons/fa';
import { Col, Container, Form, Row } from 'react-bootstrap';
import ReactToolTip from 'react-tooltip';
import { useQueryWrapper } from 'apollo/useQueryWrapper';
import { GET_APPOINTMENTS } from 'consumer/operations/queries/getAppointments';
import { AppointmentsPerPage } from 'consumer/constants';
import { ItemList } from 'shared/components/itemList';
import { BoxLoading } from 'shared/components/spinners';
import { SectionHeader, Section } from 'shared/components/form';
import styles from './appointment.module.css';

const AppointmentStatus = {
  OPEN: {
    title: 'Open',
    icon: FaRegCalendarAlt,
    className: styles.open
  },
  CANCELLED: {
    title: 'Cancelled',
    icon: FaTimes,
    className: styles.cancelled
  },
  COMPLETED: {
    title: 'Completed',
    icon: FaCheck,
    className: styles.completed
  }
};

const AppointmentType = {
  WELLNESS: 'Wellness',
  SICKNESS: 'Sickness',
  TELEHEALTH: 'Telehealth'
};

export const AppointmentList = ({consumer}) => {
  const [appointments, setAppointments] = useState([]);
  const [shouldRefetch, toggleRefetch] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [typeFilters, setTypeFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const baseFilter = useMemo(() => ({ consumer: { id: { eq: consumer?.id } }}), [consumer?.id]);
  const { loading, data, fetchMore, refetch } = useQueryWrapper(
    GET_APPOINTMENTS, {
      skip: !consumer,
      variables: {
        filter: {...baseFilter},
        offset: 0
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const handlePageChange = (page) => {
    const offset = ((page - 1) * AppointmentsPerPage);
    fetchMore({variables: { offset: offset }});
  };

  const toggleFilterCollection = (filterItem, filterCollection, setFilterCollection) => {
    let currentFilterCollection = [...filterCollection];
    let itemToAddIndex = currentFilterCollection.indexOf(filterItem);
    if (itemToAddIndex >= 0) {
      currentFilterCollection.splice(itemToAddIndex, 1);
    } else {
      currentFilterCollection.push(filterItem);
    }

    setFilterCollection(currentFilterCollection);
    toggleRefetch(true);
  };

  const toggleStatusFilter = (status) => toggleFilterCollection(status, statusFilters, setStatusFilters);
  const toggleTypeFilter = (type) => toggleFilterCollection(type, typeFilters, setTypeFilters);

  useEffect(() => {
    if (!shouldRefetch) return;

    let vars = {};

    if (typeFilters.length > 0) {
      vars = {...vars, type: {in: typeFilters}};
    } 

    if (statusFilters.length > 0) {
      vars = {...vars, status: {in: statusFilters}};
    }

    refetch({ filter: {...baseFilter, ...vars}});
    toggleRefetch(false);
  }, [statusFilters, typeFilters, refetch, shouldRefetch, baseFilter]);

  useEffect(() => {
    if (!loading && data) {
      setAppointments(data.findAppointments.items);
      setNumberOfItems(data.findAppointments.count);
    }
  }, [data, loading, setAppointments, setNumberOfItems])

  //TODO: figure out a way to make this reusable
  const loadingHeight = (document.documentElement.clientHeight / 3);
  const loadingWidth = document.documentElement.clientWidth;

  return (
    <>
    <Container className={styles.bodyContainer}>
      <Row>
        <Col><AppointmentFilter toggleStatusFilter={toggleStatusFilter} toggleTypeFilter={toggleTypeFilter} /></Col>
        <Col lg={9} className={styles.ordersResults}>
          <ItemList
            loading={loading}
            loadingComponent={<BoxLoading height={loadingHeight} width={loadingWidth} viewBox={[0, 0, loadingWidth, loadingHeight]} />}
            data={appointments}
            headerRowElement={HeaderRow}
            dataRowElement={ItemRow}
            handlePageChange={handlePageChange}
            numberOfItems={numberOfItems}
          />
        </Col>
      </Row>
    </Container>
    </>
  );
};

const HeaderRow = () => {
  return (
    <Row>
      <Col><h5>Date / Time</h5></Col>
      <Col><h5>Type</h5></Col>
      <Col><h5>Provider</h5></Col>
      <Col><h5>Location</h5></Col>
    </Row>
  );
};

const ItemRow = ({ item }) => {

  const provider = item.provider.contact.name;
  const providerName = provider.first + ' ' + provider.last;

  return(
    <Row>
      <Col><Status status={item.status} />&nbsp;{DateTime.fromISO(item.date).toLocaleString()}&nbsp;&nbsp;{item.time}</Col>
      <Col>{AppointmentType[item.type]}</Col>
      <Col>{providerName}</Col>
      <Col>{item.careCenter.name}</Col>
    </Row>
  );
};

const Status = ({ status }) => {
  const appointmentStatus = AppointmentStatus[status];
  let Icon = appointmentStatus.icon;

  return(
    <>
      <Icon className={appointmentStatus.className} data-tip={appointmentStatus.title} />
        <ReactToolTip />
    </>
  );
};

const AppointmentFilter = ({toggleStatusFilter, toggleTypeFilter}) => {
  return (
    <div className={styles.filterContainer}>
      <SectionHeader>
        <h2 className={styles.centerText}>Filters</h2>
        <hr />
      </SectionHeader>
      <Section>
        <Col lg={3}>
          Status
        </Col>
        <Col lg={9}>
          {Object.entries(AppointmentStatus).map(([k,v]) => (
            <Form.Check key={k} aria-label={v.title} type='checkbox' label={<StatusLabel status={k} title={v.title} />} onChange={() => toggleStatusFilter(k)}/>
          ))}
        </Col>
        <hr />
        <Col lg={3}>
          Type
        </Col>
        <Col lg={9}>
          {Object.entries(AppointmentType).map(([k,v]) => (
            <Form.Check key={k} aria-label={v} type='checkbox' label={v} onChange={() => toggleTypeFilter(k)}/>
          ))}
        </Col>
      </Section>
    </div>
  );
};

const StatusLabel = ({status, title}) => (<><Status status={status} />&nbsp;{title}</>)