import { Col, FloatingLabel, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Input } from 'shared/components/form';
import { useState } from 'react';
import styles from 'benefits/telehealth/components/requestAppointment/requestAppointment.module.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { useEffect } from 'react';
import { getError } from "shared/services/validationService";
import 'react-phone-number-input/style.css'

export const NotificationPreference = ({consumer, registerInput}) => {
  const { control, setValue, formState, trigger } = useFormContext();
  const TEXT_MESSAGE = 'Text Message';
  const EMAIL = 'Email';
  const [selectedNotificationType, setSelectedNotificationType] = useState(null); 
  const notificationPreferenceOptions = [EMAIL, TEXT_MESSAGE];

  const onNotificationTypeSelected = (type) => {
    if (type === TEXT_MESSAGE) {
      const mobileNumber = consumer.contact.phoneNumbers.filter(x => x.type === 'MOBILE')[0];
      if (mobileNumber) {
        setValue(registerInput, mobileNumber.number);
      } else {
        setValue(registerInput, '');
      }
      setSelectedNotificationType(TEXT_MESSAGE);
    } else {
      setValue(registerInput, consumer.contact.emails.find(x => x.sendNotifications).email);
      setSelectedNotificationType(EMAIL);
    }

    trigger(registerInput);
  }

  useEffect(() => {
    if (consumer && !selectedNotificationType) {
      const mobileNumber = consumer.contact.phoneNumbers.filter(x => x.type === 'MOBILE')[0];
      if (mobileNumber) {
        setValue(registerInput, mobileNumber.number);
        setSelectedNotificationType(TEXT_MESSAGE);
      } else {
        setValue(registerInput, consumer.contact.emails.find(x => x.sendNotifications).email);
        setSelectedNotificationType(EMAIL);
      }

      trigger(registerInput);
    }
  }, [consumer, setValue, setSelectedNotificationType, selectedNotificationType])

  return (
    <>
      <Row>
        <label className={styles.notificationLabel}>Contact method when consult is ready</label>
      </Row>
      <Row>
      { notificationPreferenceOptions.map((type, index) => (
        <Col xs={3} key={index} className={styles.notificationRadio}>
          
          <input
            id={`preference-${index}`}
            type="radio"
            name={`preference-${type}`}
            checked={type === selectedNotificationType}
            onChange={() => onNotificationTypeSelected(type)}
          />
          <label className={`form-check-label`} htmlFor={`preference-${index}`}>{type}</label>
        </Col>
      ))}
        <Col xs={6} className={styles.notificationInput}>
          { selectedNotificationType === TEXT_MESSAGE && 
          <>
            <FloatingLabel label={TEXT_MESSAGE} className={styles.textMessageLabel}>
              <PhoneInputWithCountry
                initialValueFormat="national"
                name={registerInput}
                numberInputProps={{className: `form-control ${styles.textMessageField}`}}
                control={control}
              />
            </FloatingLabel>
            <div className={`invalid-feedback ${styles.showTextMessageValidationError}`}>
              {getError(registerInput, formState.errors)?.message}
            </div>
          </>
          }
          { selectedNotificationType === EMAIL &&
            <Input type='email' registerInput={registerInput} label={selectedNotificationType} errors={formState.errors} isReadOnly={true}/>
          }
        </Col>
      </Row>
    </>
  )
}