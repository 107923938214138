import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import style from './header.module.css';
import { Messages } from './messages';
import logo from "../../images/BICC_logo.png";
import { consumerPaths } from '../../../consumer/routes';

export const Menu = (props) => {
  const { children, canSwitch, switchToMode, changeMode } = props;
  const { instance } = useMsal();

  const logOut = async () => {
    await instance.logoutRedirect();
  };

  return (
    <div className={style.navigation}>
      <Navbar collapseOnSelect expand="lg" variant="light">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} width="193.24px" height="27.57px" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">

            {children}

            <Messages />
            <NavDropdown align="end" variant="light" title={<FaUser size={20} className={style.avatar} />} id="collasible-nav-dropdown dropdown-menu-right">
              <NavDropdown.Item as={Link} to={consumerPaths.profile}>My Profile</NavDropdown.Item>
              <NavDropdown.Item onClick={async() => {await logOut();}}>Sign Out</NavDropdown.Item>
              {canSwitch && <NavDropdown.Divider />}
              {canSwitch && <NavDropdown.Item onClick={() => changeMode(switchToMode)}>Switch to {switchToMode}</NavDropdown.Item>}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
